import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardService from "../../services/Dashboard";
import QuoteDescription from "./qouteDescriptionCard";
import QuoteTitle from "./qouteTitleCard";

 const QuoteShareView = () => {
    const { testId } = useParams();
    const [title, setTitle] = useState(null)
    const [name, setName] = useState(null)
    const [description, setDescription] = useState(null)
    useEffect(() => {
        if(testId) {
            DashboardService.GET_QUOTE_DATA({
                id: testId,
                uid: '1'
            }).then(data => {
                console.log("data?.response?.description", data?.response?.description)
                setTitle(data?.response?.title || '')
                setName((window.florishApp ? data?.response?.name_flourish_domain : data?.response?.name)  || '')
                setDescription(data?.response?.description)
            })
        }
    }, [testId])
    console.log("testId", testId);
    return (
        <>
            <QuoteTitle title={title} name={name}/>
            <QuoteDescription description={description}/>
        </>
    )
}

export default QuoteShareView