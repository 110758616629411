import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RightSideContentWrapper = styled("div")(({ theme }) => ({
  padding: "0px 20px",
  overflowY: "auto",
  position: 'relative',
  height: "100%",
}));

export const InputField = styled(TextField)`
    height: 56px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-input {
      font-size: 16px;
      color: #190d1a;
      ::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #434243;
      }
    }
  `;

export const ContainerWrapper = styled("div")(({ theme }) => ({
  overflowY: "auto",
  height: "90vh",
  [theme.breakpoints.down("md")]: {
    height: "fit-content",
    marginTop: "4px",
  },
}));
const CrossIcon = styled("div")(({ theme }) => ({
  padding: "0px",
  position: 'absolute',
  right: '16px',
  top: '16px',
  cursor: 'pointer'
}));
export const CrossIconModal = ({ onClick }) => {
  return <CrossIcon onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M11.9995 4L3.99951 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.99951 4L11.9995 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </CrossIcon>
}
const LeftIcon = styled("div")(({ theme }) => ({
  padding: "0px",
  position: 'absolute',
  left: '0px',
  top: '0px',
  cursor: 'pointer'
}));
export const LeftIconModal = ({ onClick }) => {
  return <LeftIcon onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M14.3999 16.8L9.5999 12L14.3999 7.20001" stroke="#190D1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </LeftIcon>
}
export const XP = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="6" fill="#9747FF" />
    <circle cx="5.99883" cy="6.00014" r="5.2" fill="white" />
    <path d="M3.25016 3.75L4.31184 5.51001H4.34651L5.40819 3.75H6.18386L4.80151 6L6.19253 8.25H5.41252L4.34651 6.51416H4.31184L3.24583 8.25H2.46582L3.88067 6L2.47449 3.75H3.25016Z" fill="#9747FF" />
    <path d="M6.84795 8.25V3.75H8.42964C8.77486 3.75 9.06087 3.81372 9.28765 3.94116C9.51443 4.0686 9.68415 4.24292 9.79682 4.46411C9.90949 4.68384 9.96582 4.9314 9.96582 5.20679C9.96582 5.48364 9.90876 5.73267 9.79465 5.95386C9.68198 6.17358 9.51154 6.3479 9.28331 6.47681C9.05653 6.60425 8.77125 6.66797 8.42747 6.66797H7.33979V6.09229H8.3668C8.58492 6.09229 8.76186 6.0542 8.89764 5.97803C9.03342 5.90039 9.13309 5.79492 9.19665 5.66162C9.2602 5.52832 9.29198 5.37671 9.29198 5.20679C9.29198 5.03687 9.2602 4.88599 9.19665 4.75415C9.13309 4.62231 9.0327 4.51904 8.89548 4.44434C8.7597 4.36963 8.58058 4.33228 8.35814 4.33228H7.51746V8.25H6.84795Z" fill="#9747FF" />
  </svg>
}
