import { Button, Typography } from "@mui/material";
import classes from "./EmptyStateBox.module.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import HTMLTooltip from "../../components/ToolTipWithIcon/HTMLTooltip";
const getButtonTextToolTipRoute = (grwothNotFound = false, testNotCompleted = false,
    resultNotUnlocked = false) => {
    if (testNotCompleted) {
        return {
            subtitle: "Actions need data.",
            buttonText: "Start Test",
            tooltip: "Your actions and habits will be displayed here. You need to complete a test first. ",
            routeName: "/testcategory"
        }
    } else if (resultNotUnlocked) {
        return {
            subtitle: "Actions need results.",
            buttonText: "Unlock dimension",
            tooltip: "Your actions and habits will be displayed here. You need to unlock a dimension first. ",
            routeName: "/results"
        }
    } else if (grwothNotFound) {
        return {
            subtitle: "Actions need goals.",
            buttonText: "Add growth goal",
            tooltip: "Your actions and habits will be displayed here. You need to select a dimension as a growth goal first. ",
            routeName: "/goals"
        }
    } else {
        return {
            subtitle: "Goals need actions and habits.",
            buttonText: "Add actions or habits",
            tooltip: "You have set a goal, but no actions and habits scheduled. Please add actions and habits. ",
            routeName: "/goals"
        }
    }
}

const EmptyStateBox = ({ grwothNotFound = false, testNotCompleted = false,
    resultNotUnlocked = false, contentLoading }) => {
    console.log("grwothNotFound", grwothNotFound);
    let navigate = useNavigate();
    const { buttonText, routeName, tooltip, subtitle } = getButtonTextToolTipRoute(grwothNotFound, testNotCompleted, resultNotUnlocked)
    return <div className={classes.mainEmptyBox}>
        <div className={classes.emptyContentBox}>
            <div className={classes.illuBox}>
                <div className={classes.rectangle}></div>

                <div className={classes.illuCheckboxContent}>
                    {new Array(6).fill(1).map((it, index) => {
                        return <div key={index} className={classes.frameWithCheckboxChecked}>
                            <div className={classes.emptycheckbox}>
                                <div className={classes.emptyinnercheckbox}>
                                    {index < 3 && (
                                        <span style={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#9747FF',
                                            textAlign: 'center'
                                        }}>
                                            &#10003;
                                        </span>
                                    )}
                                </div>
                            </div>
                            {index < 3 ? (
                                <div className={classes.zeroindexbarlines}>

                                    {!index && (
                                        <>
                                            <div className={classes.zeroBar1}></div>
                                            <div className={classes.zeroBar2}></div>
                                        </>

                                    )}
                                    {index === 1 && (
                                        <>
                                            <div className={classes.oneBar1}></div>
                                            <div className={classes.oneBar2}></div>
                                            <div className={classes.oneBar3}></div>
                                        </>
                                    )}
                                    {index === 2 && (
                                        <>
                                            <div className={classes.twoBar1}></div>
                                            <div className={classes.twoBar2}></div>
                                            <div className={classes.twoBar3}></div>
                                            <div className={classes.twoBar4}></div>
                                        </>
                                    )}
                                </div>
                            ) : null}

                        </div>
                    })}
                </div>
            </div>
            <div className={classes.emptyTextBox}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#190D1A',
                    textAlign: 'center'
                }}>
                    You haven’t added any actions and habits
                </Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#434243',
                    textAlign: 'center'
                }}>
                    {contentLoading ? null : subtitle}

                </Typography>
            </div>
        </div>
        {!contentLoading && (
            <div className={classes.emptyActionButton}>
                <Button variant="contained" sx={{
                    background: '#9747FF',
                    borderRadius: '100px',
                    fontSize: '14px !important',
                    padding: '12px 16px',
                    height: '38px'
                }} onClick={() => {
                    navigate(routeName);
                }}>{buttonText}</Button>
                <HTMLTooltip
                    title={tooltip}
                    placement="top"
                    showArrow={true}
                    sx={{
                        '& .MuiTooltip-tooltip': {
                            background: "#FFF",
                            color: "#000",
                            maxWidth: "300px"
                        }
                    }}
                />
            </div>
        )}

    </div>
}

export default EmptyStateBox
