import { Button, Grid, Typography } from "@mui/material";
import classes from "./plans.module.css"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContainerWrapper } from "./plans.styles";
import { imageURLRender } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import PlansServices from "../../services/Plans";
import { setStoreData } from "../../Store/Reducers/plans";
import PlanFallback from "../../assets/Svg/PlanFallback.svg"
import clsx from "clsx";
import { GeneralSX } from "../../components/Text/Text";
import palette from "../../theme/palette";
import PlanDetails from "./PlanDetails";
import PlanModal from "./PlanModals/CommonModal";

const PlanData = () => {
    const { planId } = useParams();
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState("")
    const { userData } = useSelector((state) => state.userProfile);
    const { plansTable, selectedPlan, activeTabObject } = useSelector((state) => state.planData);
    

    useEffect(() => {
        if(userData?.uid) {
            console.log("activeTabObject.type", activeTabObject.type)
            if (plansTable.length) {
                setSelectedPlanFromArray(plansTable, activeTabObject.type)
            } else {
                getPlanData()
            }
        }

    }, [planId, userData?.uid])
    useEffect(() => {
        console.log("planId", planId)
        if (planId) {
            getPlanActionData(planId, selectedPlan.planSelected)
        }
    }, [planId, selectedPlan.planSelected])
    const getPlanActionData = (planId, planSelected) => {
        const  serverObject = {
            "uid": userData?.uid,
            planId: planId,
        }
        if (!planSelected) {
            serverObject.type = 'GENERIC'
        }
        PlansServices.planActionPlanId(serverObject).then(data => {
            console.log("data?.planData", data?.planData)
            dispatch(setStoreData({
                planActions: [...data?.planData] || []
            }))
            // setSelectedPlanFromArray([...data?.planData])
            console.log("data ===", data)
        }).catch(err => {
            console.log("err")
        })
    }
    const getPlanData = (type = "ALL") => {
        PlansServices.PLANS({
            "uid": userData?.uid,
            "type": type,
        }).then(data => {
            dispatch(setStoreData({
                plansTable: [...data?.planData] || []
            }))
            setSelectedPlanFromArray([...data?.planData], type)
            console.log("data ===", data)
        }).catch(err => {
            console.log("err")
        })
    }
    const setSelectedPlanFromArray = (arr, type) => {
        const foundPlan = arr.find(it => it.planId === planId)
        if (foundPlan) {
            console.log("type in selectPlan", type, {
                selectedPlan: foundPlan ? {...foundPlan, planSelected: type === "MY_PLANS"} : {}
            })
            //selectedPlan
            dispatch(setStoreData({
                selectedPlan: foundPlan ? {...foundPlan, planSelected: type === "MY_PLANS"} : {}
            }))
        }
        if(!foundPlan) {
            getPlanData("MY_PLANS")
        }
    }
    console.log("planId", planId)
  
    console.log("selectedPlan", selectedPlan)
    return <Grid container spacing={1.5} className={classes.testListWrapper}>
        <PlanDetails />
    </Grid>
}

export default PlanData

