import { Box, Button, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { useCallback, useState } from "react";
import classes from "./ResultChart.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import styled from "@emotion/styled";
import DatePicker from "../DatePicker/DatePicker";

const DateRangePicker = ({
  value,
  setValue,
  //   rangePickerhandler,
  setStartTime,
  setEndTime,
  startTime,
  endTime,
  setStartPeriod,
  setEndPeriod,
  setFromdate,
  setTodate,
  setisCompare,
  setIsAskOtherModalOpen,
}) => {
  const SaveButton = styled(Button)(({ theme }) => ({
    borderRadius: "16px",
    color: " #9747FF",
    height: "28px",
    background: "#F7F2FE",
    border: "1px solid #f7f2fe",
    fontSize: "12px",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
    },
  }));
  const CustomiseDateRangePicker = styled(StaticDateRangePicker)`
    & .MuiDateRangePickerViewDesktop-root {
      margin: auto;
      justify-content: center;
    }
    // & .MuiButtonBase-root {
    //   background-color: #fff;
    //   color: #190d1a;
    //   border-radius: 4px;
    // }
    // .MuiDateRangePickerDay-root {
    //   background-color: #fff;
    // }
    // .MuiButtonBase-root:focus.Mui-selected {
    //   background-color: #9747ff;
    // }
  `;
  const InputField = styled(TextField)`
  height: 40px;
  background: #F9F9F9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-input {
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }
  }
`;
  console.log("value = ", value);
  const today = new Date();
  const [isStartTime, setIsStartTime] = useState(true);
  const [isEndTime, setIsEndTime] = useState(false);
  const rangePickerhandler = (start, end) => {
    console.log("start Date = ", moment.utc(new Date(start)).format("DD"));
    if (
      moment.utc(new Date(start)).format("DD") <=
      moment.utc(new Date(end)).format("DD")
    ) {
      setStartPeriod(moment(start).format("MMM Do"));
      setEndPeriod(moment(end).format("MMM Do"));
      setFromdate(moment(start).format("MM-DD-YYYY"));
      setTodate(moment(end).format("MM-DD-YYYY"));
      setisCompare(true);
      setIsAskOtherModalOpen(false);
    }

    // const uid = localStorage.getItem("userDetail");
    // // const uid = "tdl76tm6yJO2bGk3ac1Ro3rhlax2";
    // const type = "COMPARE";
    // let from;
    // let to;
    // let feedbackType
    // if(graphType==="BASED_ON_AVERAGE_POPULATION"){
    //   feedbackType=BASED_ON_AVERAGE_POPULATION
    // }
    // else{
    //   feedbackType=BASED_ON_AVERAGE_POPULATION
    // }
    // // const  = "BASED_ON_AVERAGE_POPULATION";
    // console.log("5555555", fromdate, to);
    // if (fromdate !== "") {
    //   from = fromdate;
    //   to = todate;
    // }
    // AllDiamentionAverageInvite(uid, type, from, to, feedbackType)
    //   .then((data) => {
    //     setchartdata(data?.allPercentage);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});
  };
  return (
    <>
      <div className={classes.startEndWrapper}>
        {/* <div className={classes.startWrapper}> */}
        <div
          className={classes.dateTimeRangePickerWrapper}
          style={{ paddingTop: "8px" }}
          onClick={() => {
            setIsStartTime(true);
            setIsEndTime(false);
          }}
        >
          <div
            className={classes.dateTimeRangePickerSubWrapper}
            style={{
              borderColor: isStartTime && "#9747FF",
            }}
          >
            <Typography className={classes.periodLabel}>
              Start period
            </Typography>
            <Typography className={classes.period}>
              {moment(startTime).format("MMMM Do")}
            </Typography>
          </div>
        </div>
        {/* </div> */}
        {/* <div className={classes.endWrapper}> */}
        <div
          className={classes.dateTimeRangePickerWrapper}
          style={{ paddingTop: "8px" }}
          onClick={() => {
            setIsEndTime(true);
            setIsStartTime(false);
          }}
        >
          <div
            className={classes.dateTimeRangePickerSubWrapper}
            style={{
              borderColor: isEndTime && "#9747FF",
            }}
          >
            <Typography className={classes.periodLabel}>End period</Typography>
            <Typography className={classes.period}>
              {moment(endTime).format("MMMM Do")}
            </Typography>
          </div>
        </div>

        {/* </div> */}
      </div>
      <div style={{ width: "300px", margin: "auto" }}>
        <DatePicker
          currentDate={isStartTime ? startTime : endTime}
          setCurrentDate={isStartTime ? setStartTime : setEndTime}
          maxDate={today}
        />
      </div>
      <div style={{ padding: "0px 54px 24px" }}>
        <SaveButton
          variant="outlined"
          sx={{ fontSize: "12px !important", marginLeft: "0px" }}
          onClick={() => rangePickerhandler(startTime, endTime)}
        >
          save
        </SaveButton>
      </div>
    </>
  );
};

export default React.memo(DateRangePicker);
