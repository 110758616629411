import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Typography,
  IconButton,
  Chip,
  Avatar,
  useMediaQuery,
  Box,
} from "@mui/material";
import XP from "../../assets/Svg/XP.svg";
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from "react";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import CheckedCheckbox from "../../assets/Svg/CheckedCheckbox.svg";
import CheckboxEndIcon from "../../assets/Svg/CheckboxEndIcon.svg";
import UncheckCheckbox from "../../assets/Svg/UncheckCheckbox.svg";
import { ReactComponent as Sparkles } from "../../assets/Svg/Sparkles.svg";
import PlusLargeIcon from "../../assets/Svg/PlusLargeIcon.svg";
import tripleDot from "../../assets/Svg/tripleDot.svg";
import classes from "./Accordian.module.css";
import { CustomShedualButton } from "../GoalComponent/GoalButton";
import styled from "@emotion/styled";
import {
  DuplicateSnackbar,
  EditActionSnakebar,
  EditRemoveSnackbar,
} from "../GoalComponent/GoalDialog";
import useCustomNotification from "../../Hooks/useCustomNotification";
import EditGoal from "../EditRemoveGoal/EditGoal/EditGoal";
import { useDispatch, useSelector } from "react-redux";
import ActionDialog from "../GoalAddMoreDialog/ActionDialog/ActionDialog";
import {
  getGoals,
  goalCreateHabitAction,
  goalDeleteHabitAction,
  goalDuplicateHabitAction,
  goalUpdateHabitAction,
  updateHabitActionCalender,
} from "../../services/auth";
import {
  updateActionData,
  updateisCompeletedAction,
  updateScheduleState,
  updateIsAddMore,
  updateAllActionData,
  updateisAllCompeletedAction,
} from "../../Store/Reducers/goalActionData";
import moment from "moment";
import EditAction from "../EditRemoveGoal/EditAction/EditAction";
import GoalDateTimePicker from "../EditRemoveGoal/GoalDateTimePicker/GoalDateTimePicker";
import DuplicateGrowthArea from "../EditRemoveGoal/DuplicateGrowthArea/DuplicateGrowthArea";
import SuggestedGrowthArea from "../GrowthDialog/SuggestedGrowthArea";
import { v4 as uuidv4 } from "uuid";
import useCustomNotificationActionHabit from "../../Hooks/useCustomNotiFicationActionHabit";
import useCustomNotificationColourPick from "../../Hooks/useCustomNotificationColourPick";
import { FindAndGetXPLevel, randomXP } from "../../utils";
import { updateUserCions } from "../../Store/Reducers/userProfileSlice";
import ChipSaveButton from "../ChipSaveButton";
import HTMLTooltip from "../ToolTipWithIcon/HTMLTooltip";
import { useNavigate } from "react-router-dom";
import GoalFavouriteModal from "../../pages/GoalListPage/GoalFavouriteModal";
import { updategetGoal } from "../../Store/Reducers/goalDataSlice";
function ActionAccordian({ selectedQuestionCategoriesId, goalName, actionRecommended = [], imageCompo, expanded, questionCategoryId }) {
  const { growthAreaDataSlice, ActiondataSlice, userProfile: { userData = {} } } = useSelector(
    (state) => state
  );

  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const [actionAccordian, setActionAccordian] = useState("panel");
  const [isActionOPen, setIsActionOPen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [idDialog, setidDialog] = useState("");
  const [actionIds, setActionId] = useState("");
  const [selectedAction, setSelectedAction] = useState({});
  const [isEditActionOpen, setisEditActionOpen] = useState(false);
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [actionState, setActionState] = useState({
    actionName: "",
    actionDetail: "",
    executionDate: "",
    executionDateEnd: "",
    actionId: "",
    scheduled_type: "DEFAULT",
  });
  const [isCompoletedMenuOpen, setIsCompoletedMenuOpen] = useState(false);
  const [showRecommented, setShowRecommended] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showRecommenedCount, setShowRecommendedCount] = useState(3);
  const [updateRecommened, setUpdateRecommended] = useState(1);
  const [recommendedData, setRecommendedData] = useState([]);
  const [growthVisible, setGrowthVisible] = useState(false)
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const {
    NotificationContentActionHabitJSX,
    customNotificationActionHabit,
    setCustomNotificationActionHabit,
  } = useCustomNotificationActionHabit();
  const {
    NotificationContentColourPickJSX,
    customNotificationColourPick,
    setCustomNotificationColourPick,
  } = useCustomNotificationColourPick();
  const dispatch = useDispatch();
  const ActionAccordianHandler = (panel) => (event, isExpanded) => {
    setActionAccordian(isExpanded ? panel : false);
  };
  const addMoreActionHandler = (actionState) => {
    let tempActionState = [];
    // let tempActionStateForRedux = [];
    actionState.forEach((item) => {
      tempActionState.push({
        actionDetail: item?.actionDetail,
        // actionId: uuidv4(),
        actionName: item.actionName,
        status: "IN-PROGRESS",
      });
    });
    // actionState.forEach((item) => {
    //   tempActionStateForRedux.push({
    //     actionDetail: item?.actionDetail,
    //     // actionId: uuidv4(),
    //     actionName: item.actionName,
    //     status: "IN-PROGRESS",
    //     executionDate: moment(new Date()).format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //     executionDateEnd: moment(new Date())
    //       .add({ hours: 1 })
    //       .format("yyyy-MM-DDTHH:mm:ss.SSS"),
    //   });
    // });
    // let tempActionAddedAction = [
    //   ...ActiondataSlice.actionData,
    //   ...tempActionStateForRedux,
    // ];
    // dispatch(updateActionData(tempActionAddedAction));
    const user = localStorage.getItem("userDetail");
    let createActiondata = {
      uid: user,
      questionCategoryId: selectedQuestionCategoriesId,
      type: "ACTION",
      action: actionState,
    };
    setUpdateRecommended(updateRecommened + 1)
    goalCreateHabitAction(createActiondata)
      .then((res) => {
        dispatch(updateIsAddMore(true));
      })
      .catch((err) => console.log(err))
      .finally(() => { });
  };
  const addActionHandler = (actionState) => {
    let tempActionState = [];
    let tempActionStateForRedux = [];
    actionState.forEach((item) => {
      tempActionState.push({
        actionDetail: item?.actionDetail,
        // actionId: uuidv4(),
        actionName: item.actionName,
        status: "IN-PROGRESS",
      });
    });
    actionState.forEach((item) => {
      tempActionStateForRedux.push({
        actionDetail: item?.actionDetail,
        // actionId: uuidv4(),
        actionName: item.actionName,
        status: "IN-PROGRESS",
        executionDate: moment(new Date()).format("yyyy-MM-DDTHH:mm:ss.SSS"),
        executionDateEnd: moment(new Date())
          .add({ hours: 1 })
          .format("yyyy-MM-DDTHH:mm:ss.SSS"),
      });
    });
    let tempActionAddedAction = [
      ...ActiondataSlice.allactionData,
      ...tempActionStateForRedux,
    ];
    dispatch(updateAllActionData(tempActionAddedAction));
    setUpdateRecommended(updateRecommened + 1)
    const user = localStorage.getItem("userDetail");
    let createActiondata = {
      uid: user,
      questionCategoryId: selectedQuestionCategoriesId,
      type: "ACTION",
      action: actionState,
    };
    goalCreateHabitAction(createActiondata)
      .then((res) => {
        dispatch(updateIsAddMore(true));

      })
      .catch((err) => console.log(err))
      .finally(() => {
      });
  };
  const updateProfileXp = (xp, add) => {
    const updatedXp = !add ? (userData.xp - xp) : (userData.xp + xp)
    dispatch(updateUserCions(updatedXp))
  }
  const makeAPIcall = (action) => {
    let createActionData;
    if (action.calenderStatus !== "IN_PROGRESS") {
      createActionData = {
        uid: localStorage.getItem("userDetail"),
        id: action.actionId,
        type: "ACTION",
        xpChange: "INCREASE",
        action: {
          scheduled_type: action.scheduled_type,
          // active: "false",
          colour: action.colour,
          textColour: action.textColour,
          executionDate: action.executionDate,
          executionDateEnd: action.executionDateEnd,
          calenderStatus: "COMPLETE",
          status: "COMPLETE",
          xpChange: "INCREASE"
        },
      };
    } else {
      createActionData = {
        uid: localStorage.getItem("userDetail"),
        id: action.actionId,
        type: "ACTION",
        xpChange: "DECREASE",
        action: {
          scheduled_type: action.scheduled_type,
          // active: "false",
          colour: action.colour,
          textColour: action.textColour,
          executionDate: action.executionDate,
          executionDateEnd: action.executionDateEnd,
          calenderStatus: "IN_PROGRESS",
          status: "IN_PROGRESS",
          xpChange: "DECREASE"
        },
      };
    }
    console.log("createActionData ========", createActionData);
    if (Object.keys(createActionData).length > 0) {
      updateHabitActionCalender(createActionData)
        .then((res) => {
          //setIsSetDurationAction(false);
          // this.callToGetData(
          //   this.state.selectedDate,
          //   this.state.selectedDate
          // );
          const xp = FindAndGetXPLevel(action.level, false)
          updateProfileXp(xp, createActionData.action.status !== "IN_PROGRESS")
          console.log("res = ", res);
          setUpdateRecommended(updateRecommened + 1)
        })
        .catch((err) => {
          console.log("err = ", err);
        });
    }
  }
  const unCheckToCheckActionHandler = (data) => {
    let tempAction = {};
    const user = localStorage.getItem("userDetail");
    const goalId = data?.actionId;
    tempAction = {
      actionName: data.actionName,
      actionDetail: data.actionDetail,
      level: data.level,
      actionId: [
        {
          actionDetail: data.actionDetail,
          actionId: data?.actionId,
          status: "COMPLETE",
          level: data.level,
        },
      ],
      executionDate: data?.executionDate,
      executionDateEnd: data?.executionDateEnd,

      status: "COMPLETE",
    };
    console.log("data", data);
    const findData = ActiondataSlice.allcompeletedAction?.find(
      (o) => o.actionName === data.actionName
    );
    if (findData) {
      let tempActionCompelete = [];
      ActiondataSlice.allcompeletedAction?.forEach((element) => {
        tempActionCompelete.push({
          actionName: element.actionName,
          count:
            data.actionName === element.actionName
              ? element.count + 1
              : element.count,
          _id: { actionName: element.actionName },
          actionId: [
            ...element.actionId,
            {
              actionDetail: tempAction.actionDetail,
              actionId: tempAction.actionId,
              status: "COMPLETE",
              calenderStatus: "COMPLETE",
              level: tempAction.level,
              scheduled_type: data.scheduled_type,
              executionDate
                : data.executionDateEnd,
              executionDateEnd
                : data.executionDateEnd,
            },
          ],
        });
      });
      dispatch(updateisAllCompeletedAction(tempActionCompelete))
      dispatch(updateisCompeletedAction([...tempActionCompelete].splice(0, 3)));
      setUpdateRecommended(updateRecommened + 1)
    } else {
      const tempActionCompelete = [
        ...ActiondataSlice.allcompeletedAction,
        {
          actionName: data.actionName,
          actionDetail: data.actionDetail,
          count: 1,
          actionId: [
            {
              actionDetail: data.actionDetail,
              actionId: data?.actionId,
              status: "COMPLETE",
              level: tempAction.level,
              scheduled_type: data.scheduled_type,
              executionDate
                : data.executionDateEnd,
              executionDateEnd
                : data.executionDateEnd,
              calenderStatus: "COMPLETE",

            },
          ],
          executionDate: data?.executionDate,
          executionDateEnd: data?.executionDateEnd,
          status: "COMPLETE",
          calenderStatus: "COMPLETE",
          level: tempAction.level
        },
      ];
      dispatch(updateisAllCompeletedAction(tempActionCompelete))
      dispatch(updateisCompeletedAction([...tempActionCompelete].splice(0, 3)));
      setUpdateRecommended(updateRecommened + 1)

    }
    const tempActionData = ActiondataSlice?.allactionData.filter(
      (item) => item.actionId !== data.actionId
    );
    dispatch(updateAllActionData(tempActionData));
    dispatch(updateActionData([...tempActionData].splice(0, 3)));
    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempAction,
    };
    makeAPIcall({
      ...data,
      calenderStatus: "COMPLETE",
    })
    // goalUpdateHabitAction(updatedActionState)
    //   .then((res) => {
    //     // console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});
  };
  const checkToUncheckActionHandler = (data) => {
    console.log("data", data);
    const user = localStorage.getItem("userDetail");
    const goalId = data?.actionId[0]?.actionId;
    const tempAction = {
      actionName: data.actionName,
      actionDetail: data?.actionId[0]?.actionDetail,
      actionId: data?.actionId[0]?.actionId,
      level: data.level,
      executionDate: data.executionDate ? data.executionDate : data.actionId[0] && data.actionId[0].executionDate ? data.actionId[0] && data.actionId[0].executionDate : moment(),
      scheduled_type: data.scheduled_type ? data.scheduled_type : data.actionId[0] && data.actionId[0].scheduled_type ? data.actionId[0] && data.actionId[0].scheduled_type : "DEFAULT",
      executionDateEnd: data.executionDateEnd ? data.executionDateEnd : data.actionId[0] && data.actionId[0].executionDateEnd ? data.actionId[0] && data.actionId[0].executionDateEnd : moment(),
      status: "IN_PROGRESS",
      calenderStatus: "IN_PROGRESS",
    };
    const tempActionCompeleteToProgress = [
      ...ActiondataSlice.allactionData,
      {
        ...tempAction,

      },
    ];
    dispatch(updateAllActionData(tempActionCompeleteToProgress));
    dispatch(updateActionData([...tempActionCompeleteToProgress].splice(0, 3)));
    setUpdateRecommended(updateRecommened + 1)

    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempAction,
    };
    if (data.count > 1) {
      let tempActionCompelete = [];
      ActiondataSlice.allcompeletedAction.forEach((element) => {
        tempActionCompelete.push({
          actionName: element.actionName,
          count:
            data?.actionId[0]?.actionId === element.actionId[0].actionId
              ? element.count - 1
              : element.count,
          actionDetail: element?.actionId[0]?.actionDetail,
          actionId: [
            ...element.actionId,
            {
              actionDetail: tempAction.actionDetail,
              actionId: tempAction.actionId,
              status: "COMPLETE",
              calenderStatus: "COMPLETE",
            },
          ],
          executionDate: data?.executionDate,
          executionDateEnd: data?.executionDateEnd,
          status: "IN_PROGRESS",
          calenderStatus: "IN_PROGRESS",
        });
      });
      dispatch(updateisAllCompeletedAction(tempActionCompelete))
      dispatch(updateisCompeletedAction([...tempActionCompelete].splice(0, 3)));
      setUpdateRecommended(updateRecommened + 1)

    } else {
      const tempCompletedAction = ActiondataSlice?.allcompeletedAction.filter(
        (item) => item.actionId !== data.actionId
      );
      dispatch(updateisAllCompeletedAction(tempCompletedAction))
      dispatch(updateisCompeletedAction([...tempCompletedAction].splice(0, 3)));
      setUpdateRecommended(updateRecommened + 1)

    }
    makeAPIcall({
      ...tempAction,
      actionId: data?.actionId[0]?.actionId
    })

    // goalUpdateHabitAction(updatedActionState)
    //   .then((res) => {
    //     // console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});
  };
  const removeActionHandler = () => {
    const tempActionData = ActiondataSlice?.allactionData.filter(
      (item) => item.actionId !== selectedAction.actionId
    );
    dispatch(updateAllActionData(tempActionData));
    dispatch(updateActionData([...tempActionData].splice(0, 3)));
    setIsEditMenuOpen(false);
    const removeUserdata = {
      ids: [selectedAction?.actionId],
      uid: localStorage.getItem("userDetail"),
      type: "ACTION",
    };
    setUpdateRecommended(updateRecommened + 1)

    goalDeleteHabitAction(removeUserdata)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editActionHandler = () => {
    setActionState({
      actionName: selectedAction?.actionName,
      actionDetail: selectedAction?.actionDetail,
      executionDate: selectedAction?.executionDate?.replace("Z", ""),
      executionDateEnd: selectedAction?.executionDateEnd?.replace("Z", ""),
      actionId: selectedAction?.actionId,
      scheduled_type: selectedAction?.scheduled_type,
    });
    setisEditActionOpen(true);
    setIsEditMenuOpen(false);
    setUpdateRecommended(updateRecommened + 1)

  };
  const editActionTextHandler = () => {
    const user = localStorage.getItem("userDetail");
    const goalId = actionState?.actionId;
    const tempEditedAction = {
      actionName: actionState.actionName,
      actionDetail: actionState.actionDetail,
      actionId: actionState?.actionId,
      executionDate: actionState.executionDate
        ? actionState.executionDate
        : `${new Date().format("yyyy-MM-DDThh:mm:ss")}.000`,
      executionDateEnd: actionState.executionDateEnd
        ? actionState.executionDateEnd
        : `${new Date().format("yyyy-MM-DDThh:mm:ss")}.000`,
    };
    let tempAction = [];
    ActiondataSlice?.allactionData?.map((item) => {
      return tempAction.push({
        actionName:
          item.actionId === actionState.actionId
            ? actionState.actionName
            : item.actionName,
        actionDetail:
          item.actionId === actionState.actionId
            ? actionState.actionDetail
            : item.actionDetail,
        status: "IN_PROGRESS",
        level: item.level,
        scheduled_type: item.actionId === actionState.actionId
        ? actionState.scheduled_type
        : item.scheduled_type,
        actionId: item.actionId,
        executionDate:
          item.actionId === actionState.actionId
            ? actionState.executionDate
            : item.executionDate,
        executionDateEnd:
          item.actionId === actionState.actionId
            ? actionState.executionDateEnd
            : item.executionDateEnd,
        calenderStatus: "IN_PROGRESS"
      });
    });
    dispatch(updateAllActionData(tempAction));
    dispatch(updateActionData([...tempAction].splice(0, 3)));
    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempEditedAction,
    };
    setUpdateRecommended(updateRecommened + 1)

    goalUpdateHabitAction(updatedActionState)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
    setisEditActionOpen(false);
  };
  const duplicateActionHandler = () => {
    let duplicateActiondata = {
      uid: localStorage.getItem("userDetail"),
      questionCategoryId: selectedQuestionCategoriesId,
      type: "ACTION",
      action: {
        actionName: selectedAction.actionName,
      },
    };
    let tempActionDataInProgress = {
      actionDetail: selectedAction?.actionId[0]?.actionDetail,
      actionName: selectedAction.actionName,
      executionDate: selectedAction?.actionId[0]?.executionDate,
      executionDateEnd: selectedAction.actionId[0]?.executionDateEnd,
      actionId: selectedAction?.actionId[0]?.actionId,
      status: "IN_PROGRESS",
      calenderStatus: selectedAction.calenderStatus
    };
    const obj = [
      ...ActiondataSlice?.allactionData,
      tempActionDataInProgress,
    ]
    dispatch(updateAllActionData(obj));
    dispatch(
      updateActionData([
        ...obj,
      ].splice(0, 3))
    );
    setUpdateRecommended(updateRecommened + 1)

    goalDuplicateHabitAction(duplicateActiondata)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsCompoletedMenuOpen(false);
  };
  const getGoalSuggested = () => {
    const user = localStorage.getItem("userDetail");
    const archive = false;
    const status = "IN_PROGRESS";
    getGoals(user, archive, status)
      .then((res) => {
        dispatch(updategetGoal(res?.data))
      })
  }
  const editShedualdataHandler = (executionStart, executionEnd) => {
    const user = localStorage.getItem("userDetail");
    const goalId = ActiondataSlice?.scheduleState?.actionId;
    const tempEditedAction = {
      actionName: ActiondataSlice?.scheduleState.actionName,
      actionDetail: ActiondataSlice?.scheduleState.actionDetail,
      executionDate: executionStart,
      executionDateEnd: executionEnd,
      scheduled_type: "TIME_BASED",
      status: "IN_PROGRESS",
      calenderStatus: "IN_PROGRESS",
    };
    let tempAction = [];
    ActiondataSlice?.allactionData?.map((item) => {
      return tempAction.push({
        actionName:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? ActiondataSlice?.scheduleState?.actionName
            : item.actionName,
        actionDetail:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? ActiondataSlice?.scheduleState?.actionDetail
            : item.actionDetail,
        status: "IN_PROGRESS",
        level: item.level,
        actionId: item.actionId,
        executionDate:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? executionStart
            : item.executionDate,
        executionDateEnd:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? executionEnd
            : item.executionDateEnd,
        scheduled_type:
          item.actionId === ActiondataSlice?.scheduleState?.actionId
            ? "TIME_BASED"
            : item.scheduled_type,
        calenderStatus: "IN_PROGRESS",


      });
    });
    dispatch(updateAllActionData(tempAction));
    dispatch(updateActionData([...tempAction].splice(0, 3)));
    setUpdateRecommended(updateRecommened + 1)

    const updatedActionState = {
      uid: user,
      id: goalId,
      type: "ACTION",
      action: tempEditedAction,
    };
    goalUpdateHabitAction(updatedActionState)
      .then((res) => {
        // console.log(res);
        dispatch(updateScheduleState({}));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
    setisEditActionOpen(false);
  };
  const CustomAddNewGoalDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};
        position: unset;
      }
    }
  `;
  const tempGoalaction = [...(ActiondataSlice?.allactionData || []), ...(ActiondataSlice?.allcompeletedAction || [])];
  // const getUniquePreselectedGrowthArea = (array1) => {

  //   const recommended = [...actionRecommended]
  //   return recommended.filter(obj2 => array1.every((obj1) => obj1.actionName !== obj2.point))
  // };
  const getUniquePreselectedGrowthArea = (array1 = [], array2 = []) => {
    console.log("action accordian array1", array1);
    console.log("action accordian array2", array2);
    let arr3 = [].concat(
      array1.filter((obj1) => array2.every((obj2) => obj1.actionName !== obj2.point)),
      array2.filter((obj2) => array1.every((obj1) => obj2.point !== obj1.actionName))
    ).filter(it => it.point);
    console.log("action accordian arr3", arr3);
    const abc = Object.values(
      arr3.reduce((acc, cur) => Object.assign(acc, { [cur.point]: cur }), {})
    );
    return abc
  };
  console.log("action accordian tempGoalaction", tempGoalaction);
  console.log("action accordian actionRecommended", actionRecommended);
  console.log("action accordian recommendedData", recommendedData);
  console.log("action accordian getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended)", getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended));
  useEffect(() => {
    setUpdateRecommended(updateRecommened + 1)
  }, [actionAccordian]);
  useEffect(() => {
    setUpdateRecommended(updateRecommened + 1)

  }, [expanded])
  useEffect(() => {
    console.log("action accordian this called for update")
    console.log("action accordian getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended)", getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended));
    setRecommendedData(getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).splice(0, showRecommenedCount))
  }, [showRecommenedCount, updateRecommened])
  const navigate = useNavigate()
  return (
    <>
      <Accordion
        expanded={actionAccordian === "panel"}
        onChange={ActionAccordianHandler("panel")}
        sx={{
          backgroundColor: "transparent",
          display: 'flex',
          padding: '0',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'stretch',
          width: '100%',
          '& .MuiCollapse-root': {
            width: '100%'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
          className={classes.subAccordianSummery}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            backgroundColor: "transparent",
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            alignSelf: 'stretch',

            "& .Mui-expanded": {
              margin: "0px 0px !important",
            },
            "& .MuiAccordionSummary-content": {
              margin: "0px 0px",
            },
          }}
        //
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                Actions
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.GrowthAccordianDetailContainer} sx={{
          width: '100%'
        }}>
          <Box className={classes.GrowthAccordianDetailContainerBox} sx={{
            gap: '12px'
          }}>
            <div className={classes.selectedWrapper}>
              <Typography variant="body2" sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                Selected
                <HTMLTooltip
                  showArrow
                  placement={"right"}
                  title={`The 'Selected' list combines the actions you’ve personally created or any liked actions you added`}
                  sx={{
                    '& .MuiTooltip-tooltip': {
                      background: "#FFF",
                      color: "#000",
                      maxWidth: "350px"
                    }
                  }}
                />
              </Typography>
              <CustomShedualButton
                style={{
                  maxWidth: "91px",
                  background: "#F9F9F9",
                  color: "#190D1A",
                }}
                onClick={() => {
                  setIsActionOPen(true);
                  setidDialog(growthAreaDataSlice?.goalID);
                }}
              >
                Add more +
              </CustomShedualButton>
            </div>
            <Box style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '12px',
              alignSelf: 'stretch',

            }}>
              {ActiondataSlice?.actionData?.map((data, index) => {
                const newActionsData = { ...data };
                if (newActionsData.scheduled_type === "DEFAULT") {
                  newActionsData.executionDate = moment().format()
                  newActionsData.executionDateEnd = moment().add(1, 'hour').format()
                }
                const { actionId, actionName, actionDetail, level, status } = newActionsData;
                const foundXp = FindAndGetXPLevel(level, false)
                return (
                  <div className={classes.customRadioActionGroup}>
                    {foundXp && (
                      <div className={classes.schedualWrapper}>
                        <Chip
                          label={foundXp}
                          avatar={<Avatar alt="Natacha" sx={{ width: '20px', height: '20px' }} src={XP} />}
                          className={classes.backgroundChip}
                        />
                      </div>
                    )}

                    <div className={classes.customRadioActionAction} key={index}>

                      <div className={classes.actionNameWrapper}>
                        <img
                          src={UncheckCheckbox}
                          alt="CheckboxStartIcon"
                          style={{
                            height: '12px',
                            width: '12px',
                          }}
                          onClick={() => {
                            unCheckToCheckActionHandler(newActionsData);
                          }}
                        />

                        <Typography
                          variant=" body2"
                          className={classes.checkBoxText}
                        >
                          {" "}
                          {actionName} 
                        </Typography>
                      </div>
                      <div className={classes.schedualWrapper}>
                        <div style={{ width: "100%" }}>
                          {data.scheduled_type === "DEFAULT" && (
                            <CustomShedualButton
                              onClick={() => {
                                setidDialog(actionId);
                                setIsSchedule(true);
                                dispatch(
                                  updateScheduleState({
                                    actionName: data.actionName,
                                    actionDetail: data.actionDetail,
                                    executionDate: data?.executionDate
                                      ? moment(data?.executionDate).format()
                                      : new Date(),
                                    executionDateEnd: data.executionDateEnd
                                      ? data.executionDateEnd
                                      : data?.executionDate
                                        ? data?.executionDate
                                        : new Date(),
                                    actionId: data.actionId,
                                  })
                                );
                              }}
                            >
                              Schedule
                            </CustomShedualButton>
                          )}

                        </div>
                        <img
                          style={{ width: "14px" }}
                          src={CheckboxEndIcon}
                          alt="CheckboxEndIcon"
                          onClick={() => {
                            setCustomNotificationActionHabit(true);
                            setActionId(index);
                          }}
                        />
                        <img
                          onClick={() => {
                            setActionId(index);
                            setIsEditMenuOpen(true);
                            setSelectedAction({
                              actionName: data.actionName,
                              actionDetail: data.actionDetail,
                              actionId: data.actionId,
                              executionDate: data.executionDate,
                              executionDateEnd: data.executionDateEnd
                                ? data.executionDateEnd
                                : data.executionDate,
                            });
                          }}
                          style={{
                            height: "18px",
                          }}
                          src={tripleDot}
                          alt="ExpandMore"
                        />
                        {actionIds === index && (
                          <>
                            <EditRemoveSnackbar
                              open={isEditMenuOpen}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              onClose={() => {
                                setIsEditMenuOpen(false);
                              }}
                              action={
                                <EditGoal
                                  editGrowthAreaTextHandler={editActionHandler}
                                  removeGrowthAreaHandler={removeActionHandler}
                                />
                              }
                            ></EditRemoveSnackbar>
                            <EditActionSnakebar
                              open={isEditActionOpen}
                              onClose={() => {
                                editActionTextHandler();
                              }}
                              action={
                                <EditAction
                                  actionState={actionState}
                                  setActionState={setActionState}
                                  selectedAction={selectedAction}
                                  editActionTextHandler={editActionTextHandler}
                                />
                              }
                            ></EditActionSnakebar>
                            {customNotificationActionHabit && index === actionIds && (
                              <NotificationContentActionHabitJSX
                                message={actionDetail}
                                isThumbIcon={false}
                                position="top"
                              />
                            )}
                          </>
                        )}
                      </div>

                    </div>
                  </div>
                );
              })}
              {ActiondataSlice?.actionData?.length > 0 && ActiondataSlice?.allactionData?.length && (ActiondataSlice?.actionData?.length < ActiondataSlice?.allactionData?.length) && <ChipSaveButton
                title="View more"
                onSave={() => {
                  dispatch(updateActionData([...ActiondataSlice?.allactionData].slice(0, ActiondataSlice?.actionData?.length + 3)))
                }}

              />}
            </Box>
          </Box>
          <Box className={classes.GrowthAccordianDetailContainerBox} sx={{
            gap: showRecommented ? '12px' : 0
          }}>
            <Box className={classes.selectedWrapper} sx={{ cursor: "pointer" }} onClick={() => setShowRecommended(!showRecommented)} >
              <Typography variant="body2" className={classes.recommendedClass} onClick={() => setShowRecommended(!showRecommented)}>
                <span style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                }}>
                  <Sparkles /> Recommended
                  <HTMLTooltip
                    showArrow
                    placement={"right"}
                    title={`The 'Recommended' lists showcase the pre-defined actions that you’ve expressed interest in the results, offering you vetted and tailored suggestions for personal growth`}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        background: "#FFF",
                        color: "#000",
                        maxWidth: "350px"
                      }
                    }}
                  />
                </span>
              </Typography>
              <span>
                <span style={{
                  marginRight: '20px',
                  background: '#F7F2FE',
                  padding: '0 5px',
                  borderRadius: '4px',
                }}>
                  {getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).length > -1 ? getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).length : ''}
                </span>
                <img src={ExpandMore} alt="ExpandMore" style={{
                  transform: `rotate(${showRecommented ? '180deg' : '0deg'})`
                }} />
              </span>
            </Box>
            <Box style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '12px',
              alignSelf: 'stretch',

            }}>
              {showRecommented && recommendedData.length === 0 ? (
                <div className={classes.dataNotFoundSubWrapper}>
                  <Typography variant="body2">
                    Recommended actions area not found
                  </Typography>
                </div>
              ) : (
                showRecommented && recommendedData?.map((data, index) => {
                  const { point: text, _id } = data;
                  const randomXPObject = randomXP()
                  return (
                    <div className={classes.customRadioAction} onClick={e => {
                      addActionHandler([{
                        actionId: _id,
                        actionName: text,
                        actionDetail: "",
                        active: "true",
                        level: randomXPObject.level,
                        scheduled_type: "DEFAULT",
                      }])
                    }} key={index}>
                      <div className={classes.actionNameWrapper}>
                        <IconButton aria-label="add-icon" color="primary" sx={{
                          height: '12px',
                          width: '12px'
                        }}>
                          <AddIcon />
                        </IconButton>
                        <Typography
                          variant=" body2"
                          className={classes.checkBoxText}
                        >
                          {" "}
                          {text}
                        </Typography>
                      </div>
                      <div className={classes.schedualWrapper}>
                        <Chip
                          label={randomXPObject.xp}
                          avatar={<Avatar alt="Natacha" sx={{ width: '20px', height: '20px' }} src={XP} />}
                          className={classes.backgroundChip}
                        />
                      </div>

                    </div>
                  );
                })
              )}
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '8px',
              }}>
                {showRecommented && recommendedData?.length > 0 && getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).length && (recommendedData?.length < getUniquePreselectedGrowthArea(tempGoalaction, actionRecommended).length) && <ChipSaveButton
                  title="View more"
                  onSave={() => {
                    setShowRecommendedCount(showRecommenedCount + 3)
                  }}

                />}
                {showRecommented && <ChipSaveButton
                  title="Open results"
                  type="contained"
                  onSave={() => {
                    // navigate('/results')
                    setGrowthVisible(true)
                    // setShowRecommendedCount(showRecommenedCount + 3)
                  }}

                />}
              </Box>

            </Box>
          </Box>
          {ActiondataSlice?.compeletedAction?.length > 0 && (
            <Box className={classes.GrowthAccordianDetailContainerBox} sx={{
              gap: showCompleted ? '12px' : 0
            }}>
              <Box className={classes.selectedWrapper} sx={{ cursor: "pointer" }} onClick={() => setShowCompleted(!showCompleted)} >
                <Typography variant="body2" sx={{ color: "#434243 !important", cursor: 'pointer' }} className={classes.recommendedClass} onClick={() => setShowCompleted(!showCompleted)}>
                  Completed

                </Typography>
                <img src={ExpandMore} alt="ExpandMore" style={{
                  transform: `rotate(${showCompleted ? '180deg' : '0deg'})`
                }} />
              </Box>
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',

              }}>
                {showCompleted && ActiondataSlice?.compeletedAction?.length === 0 ? (
                  <div className={classes.dataNotFoundSubWrapper}>
                    <Typography variant="body2">
                      Completed Action not found
                    </Typography>
                  </div>
                ) : (
                  showCompleted && ActiondataSlice?.compeletedAction?.map((data, index) => {
                    const { actionId, _id, actionName, count, level } = data;
                    return (
                      <div className={classes.customRadioActionGroup}>
                        <div className={classes.customRadioActionAction} key={index}>
                          <div className={classes.actionNameWrapper}>
                            {count > 1 ? (
                              <div className={classes.duplicateTextWrapper}>
                                <img
                                  src={CheckedCheckbox}
                                  className={classes.checkBoxIcon}
                                  alt=""
                                  onClick={() => {
                                    checkToUncheckActionHandler(data);
                                  }}
                                />
                                <Typography variant="body3">{count}x</Typography>
                              </div>
                            ) : (
                              <img
                                src={CheckedCheckbox}
                                className={classes.checkBoxIcon}
                                alt=""
                                onClick={() => {
                                  checkToUncheckActionHandler(data);
                                }}
                              />
                            )}
                            <Typography
                              variant=" body2"
                              className={classes.checkBoxChekedText}
                            >
                              {" "}
                              {actionName}
                            </Typography>
                          </div>
                          <div className={classes.schedualWrapper}>
                            <img
                              style={{ width: "14px" }}
                              src={CheckboxEndIcon}
                              alt="CheckboxEndIcon"
                              onClick={() => {
                                setCustomNotification(true);
                                setActionId(index);
                              }}
                            />
                            <img
                              style={{
                                height: "18px",
                              }}
                              src={tripleDot}
                              onClick={() => {
                                setActionId(index);
                                setIsCompoletedMenuOpen(true);
                                setSelectedAction(data);
                              }}
                              alt="ExpandMore"
                            />
                            {actionIds === index && isCompoletedMenuOpen && (
                              <DuplicateSnackbar
                                open={isCompoletedMenuOpen}
                                onClose={() => {
                                  setIsCompoletedMenuOpen(false);
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                action={
                                  <DuplicateGrowthArea
                                    duplicateGrowthAreaHandler={duplicateActionHandler}
                                  />
                                }
                              ></DuplicateSnackbar>
                            )}
                            {customNotification && actionIds === index && (
                              <NotificationContentJSX
                                message={actionId[0].actionDetail}
                                isThumbIcon={false}
                                position="top"
                              />
                            )}
                          </div>

                        </div>
                      </div>
                    );
                  })
                )}
                {showCompleted && ActiondataSlice?.compeletedAction?.length > 0 && ActiondataSlice?.allcompeletedAction?.length && (ActiondataSlice?.compeletedAction?.length < ActiondataSlice?.allcompeletedAction?.length) && <ChipSaveButton
                  title="View more"
                  onSave={() => {
                    dispatch(updateisCompeletedAction([...ActiondataSlice?.allcompeletedAction].slice(0, ActiondataSlice?.compeletedAction?.length + 3)))
                  }}

                />}
              </Box>
            </Box>
          )}
        </AccordionDetails>
        {isSchedule && idDialog === ActiondataSlice?.scheduleState?.actionId && (
          <GoalDateTimePicker
            isAskOtherModalOpen={isAskOtherModalOpen}
            setIsAskOtherModalOpen={setIsAskOtherModalOpen}
            setActionState={setActionState}
            actionState={actionState}
            editActionTextHandler={editShedualdataHandler}
            isSchedule={isSchedule}
            setIsSchedule={setIsSchedule}
            setCustomNotificationColourPick={setCustomNotificationColourPick}
          />
        )}
      </Accordion>
      {isActionOPen && idDialog === growthAreaDataSlice.goalID && (
        <CustomAddNewGoalDialog
          open={isActionOPen}
          onClose={() => {
            setIsActionOPen(false);
          }}
        >
          <ActionDialog
            selectedQuestionCategoriesId={selectedQuestionCategoriesId}
            goalName={goalName}
            setIsActionOPen={setIsActionOPen}
            addMoreActionHandler={addMoreActionHandler}
            imageCompo={imageCompo}
          ></ActionDialog>
        </CustomAddNewGoalDialog>
      )}
      {/* {isSchedule && idDialog === ActiondataSlice?.scheduleState?.actionId && (
        <GoalDateTimePicker
          isAskOtherModalOpen={isAskOtherModalOpen}
          setIsAskOtherModalOpen={setIsAskOtherModalOpen}
          setActionState={setActionState}
          actionState={actionState}
          editActionTextHandler={editShedualdataHandler}
          isSchedule={isSchedule}
          setIsSchedule={setIsSchedule}
          setCustomNotificationColourPick={setCustomNotificationColourPick}
        />
      )} */}
      {isActionOPen && idDialog === growthAreaDataSlice.goalID && (
        <SuggestedGrowthArea
          suggestedGrowthArea={growthAreaDataSlice?.suggestedGrowthAreaState}
        />
      )}
      {customNotificationColourPick && (
        <NotificationContentColourPickJSX
          message="Start Time should be less than End Time "
          isThumbIcon={false}
          position="top"
        />
      )}
      {growthVisible && <GoalFavouriteModal visible={growthVisible} onClose={() => {
        getGoalSuggested()
        setGrowthVisible(false);
      }} isGrowth={false} isCreate={false} questionCategoryId={questionCategoryId} goalName={goalName}/>}
    </>
  );
}

export default ActionAccordian;
