import { useDispatch, useSelector } from "react-redux"
import classes from "./journals.module.css"
import { InputField } from "./journalStyles";
import { useEffect, useRef, useState } from "react";
import { Box, Button, InputAdornment, Autocomplete, Typography, Popper, Grid, CircularProgress } from "@mui/material";
import { ReactComponent as Search } from "../../assets/Svg/Search.svg";
import JournalTable from "./journalTable";
import { GeneralSX } from "../../components/Text/Text";
import palette from "../../theme/palette";
import JournalEntry from "./Modals/JournalEntryModal";
import JournalServices from "../../services/JournalsService";
import moment from "moment";
import debounce from "lodash.debounce";
import { setJournalStoreData } from "../../Store/Reducers/journals";
import { ResultAllTestDiamention, getGoals } from "../../services/auth";
const PopperMy = function (props) {
    return <Popper {...props} sx={{
        borderRadius: '16px',
        background: '#FFF',
        padding: '20px',
        boxShadow: '0px 4px 40px 0px rgba(17, 17, 17, 0.08)',
        marginTop: '-5px !important',
        '& .MuiAutocomplete-listbox': {
            padding: 0,
            '& li': {
                marginLeft: 0
            },
            '& .MuiListSubheader-root': {
                color: '#B4B0B4',
                padding: 0
            }
        }
    }} placement="bottom-start" />;
};
const JournalData = () => {
    const { userData } = useSelector((state) => state.userProfile);
    const { selectedSearchJournal, questionCategoryData } = useSelector((state) => state.journalData);
    const [searchValue, setSearchValue] = useState("")
    const { journals } = useSelector((state) => state.journalData);
    const [disableButton, setDisableButton] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const SearchCategoryList = (e) => {
        setSearchValue(e.target.value);

    };
    const [dashData, setDashData] = useState({
        testNotCompleted: false,
        contentLoading: false,
        grwothNotFound: false,
        resultNotUnlocked: false
    })
    useEffect(() => {
        const user = localStorage.getItem("userDetail");
        const archive = false;
        const status = "IN_PROGRESS";
        setLoading(true)
        ResultAllTestDiamention(user)
            .then((data) => {
                if (data.allPercentage && data.allPercentage.length) {
                    const unLockResults = [...data.allPercentage].filter(it => it.unlock)
                    if (unLockResults && unLockResults.length) {
                        getGoals(user, archive, status)
                            .then((response) => {
                                getJournalData(moment().subtract(3, 'year').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"))
                                // if (response.data && response.data.length) {


                                // } else {
                                //     setDashData({
                                //         grwothNotFound: true,
                                //         contentLoading: false,
                                //     })
                                // }
                                // console.log("getGoals response ========", response);
                            })
                    } else {
                        setDashData({
                            resultNotUnlocked: true,
                            contentLoading: false,

                        })
                        setLoading(false)

                    }

                } else {
                    /*  */
                    setDashData({
                        testNotCompleted: true,
                        contentLoading: false,

                    })
                    setLoading(false)

                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            });
    }, [])
    const getSearchReults = () => {
        const user = localStorage.getItem("userDetail");
        JournalServices.GetJournalFilter({
            uid: user,
            search: inputValue
        }).then(res => {
            console.log("res", res)
            dispatch(setJournalStoreData({
                questionCategoryData: res.questionCategoryData || []
            }))
        })
    }

    const dispatch = useDispatch()
    const getJournalData = (startDate, endDate, categoryId) => {
        const user = localStorage.getItem("userDetail");
        let body = {
            uid: user,
            from: startDate,
            to: endDate,
        }
        if (categoryId) {
            body['questionCategoryId'] = categoryId
        }
        setLoading(true)

        JournalServices.GetJournalData(body).then(response => {
            let keys = {}
            if (response.groupedByDate && Object.keys(response.groupedByDate).length) {
                const sorted = Object.keys(response.groupedByDate).sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b) - new Date(a);
                });
                if (sorted && sorted.length) {
                    sorted.map(it => {
                        keys[it] = response.groupedByDate[it]
                        return it
                    })
                }
            }
            dispatch(setJournalStoreData({
                journals: keys
            }))
        }).finally(() => {
            setLoading(false)

        })
    }
    useEffect(() => {


    }, [])
    useEffect(() => {
        getSearchReults();
    }, [selectedSearchJournal, inputValue]);
    return <Box display={"flex"} justifyContent={"center"} sx={{
        maxHeight: 'calc(100vh - 70px)',
        overflowY: 'auto',
    }}>
        <div className={classes.planBody}>
            <Box className={classes.titleBox}>
                <Typography sx={{
                    ...GeneralSX({
                        color: palette.primary.mainBlack,
                        fontSize: 32,
                        fontWeight: 600,
                        lineHeight: "100%",
                        letterSpacing: '-0.32px'
                    })
                }}>All your journal notes</Typography>
            </Box>
            {journals && Object.keys(journals).length > 0 && <div className={classes.searchBox}>
                {/* <InputField
                    size="small"
                    fullWidth
                    type="text"
                    autoFocus={searchValue !== ""}
                    value={searchValue}
                    onChange={(e) => SearchCategoryList(e)}
                    placeholder="Search by dimension"
                    id="outlined-start-adornment"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search stroke="red" className={classes.searchSVG} />
                            </InputAdornment>
                        ),
                    }}
                /> */}
                {/* <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    sx={{
                        width: '100%'
                    }}
                    PopperComponent={PopperMy}
                    disableClearable
                    onChange={(e, val) => {
                        console.log("val", val)
                        // const found = topicData.find(it => it.topic === val.topic)
                        // console.log("found", found);
                        // if (found) {
                        //     setTopicInput(found.topic)
                        //     dispatch(updateSelectedTopic(found))
                        // } else {
                        //     dispatch(updateSelectedTopic({}))

                        // }
                        // setQuestionText(val)
                    }}
                    value={selectedSearchJournal}
                    inputValue={searchValue}
                    renderOption={(props, option) => {
                        console.log("res option", option);
                        return (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 },  marginLeft: 0, padding: '12px 0 12px 0!important', }} {...props}>
                                <Typography sx={{ color: '#190D1A', fontSize: '14px', fontWeight: 400 }}>
                                    {option.questionCategory}
                                </Typography>
                            </Box>
                        )
                    }}
                    getOptionLabel={(option) => option.topic}
                    options={questionCategoryData}
                    renderInput={(params) => (
                        <InputField
                            size="small"
                            fullWidth
                            type="text"
                            autoFocus={searchValue !== ""}
                            value={searchValue}
                            onChange={SearchCategoryList}
                            placeholder="Search by dimension"
                            id="outlined-start-adornment"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search stroke="red" className={classes.searchSVG} />
                                    </InputAdornment>
                                ),
                            }}
                            {...params}
                        />
                    )}
                /> */}
                {<Autocomplete
                    id="google-map-demo"
                    sx={{
                        width: '100%'
                    }}
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.questionCategory
                    }
                    filterOptions={(x) => x}
                    options={questionCategoryData}
                    autoComplete
                    includeInputInList
                    PopperComponent={PopperMy}
                    filterSelectedOptions
                    value={selectedSearchJournal}
                    noOptionsText="Please input to search"
                    onChange={(event, newValue) => {
                        dispatch(setJournalStoreData({
                            selectedSearchJournal: newValue
                        }))
                        getJournalData(moment().subtract(3, 'year').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"), newValue?.questionCategoryId)
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <InputField
                            size="small"
                            fullWidth
                            type="text"
                            autoFocus={searchValue !== ""}
                            value={searchValue}
                            onChange={SearchCategoryList}
                            placeholder="Search by dimension"
                            id="outlined-start-adornment"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search stroke="red" className={classes.searchSVG} />
                                    </InputAdornment>
                                ),
                            }}
                            {...params}
                        />
                    )}
                    renderOption={(props, option) => {
                        console.log("res option", option)
                        return (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, marginLeft: 0, padding: '12px 0 12px 0!important', }} {...props}>
                                <Typography sx={{ color: '#190D1A', fontSize: '14px', fontWeight: 400 }}>
                                    {option.questionCategory}
                                </Typography>
                            </Box>
                        )
                    }}
                />}
                <Button variant="contained" className={classes.searchBotton} onClick={getSearchReults}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.primary.contrastText,
                            fontSize: 18,
                            fontWeight: 400,
                            lineHeight: "100%",
                            letterSpacing: '-0.18px'
                        })
                    }}>Search</Typography>
                </Button>
            </div>}
            <div className={classes.journalsBody}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "calc(100vh - 228px)",
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <JournalTable setDisableButton={setDisableButton} dashData={dashData} loading={loading} />
                )}
            </div>
        </div>
        <JournalEntry getJournalData={getJournalData} />
    </Box >
}

export default JournalData