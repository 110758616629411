import { Avatar, Box, Dialog, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AskOther from "../../core-ui/AskOther/AskOther";
import { setIsAskOtherModalOpenTest, setOpenConfirmatinModal, setShowConformationModal } from "../../Store/Reducers/testCategories";
import { imageURLRender } from "../../utils";
const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        max-width: 692px;
        border-radius: 40px;
        position: unset;
      }
    }
  `;
const AskModal = ({ dimensions }) => {
    const showConfirmationModal = useSelector(state => state.testCategories.showConfirmationModal)
    const isAskOtherModalOpen = useSelector(state => state.testCategories.isAskOtherModalOpen)
    const selectedSubCat = useSelector(state => state.testCategories.selectedSubCat)
    const dispatch = useDispatch()
    const FieldEditor1 = useRef()
    const handleClose = () => {
        if (FieldEditor1.current && FieldEditor1.current.closeConforma) {
            FieldEditor1.current.closeConforma(true)
        }
        // handleClickOpenClose(false)
    };
    console.log("showConfirmationModal before", showConfirmationModal)

    const handleClickOpenClose = (visible = true) => {
        console.log("showConfirmationModal", showConfirmationModal)
        if (showConfirmationModal) {
            dispatch(setOpenConfirmatinModal(true))
        } else {
            dispatch(setShowConformationModal(true))
            dispatch(setIsAskOtherModalOpenTest(false))
        }
    }
    const handleClickOpen = (visible = true) => {
        console.log("visible", visible);
        dispatch(setIsAskOtherModalOpenTest(visible))
    };
    console.log("selectedSubCat", selectedSubCat)
    return isAskOtherModalOpen && <CustomDialog
        open={isAskOtherModalOpen}
        onClose={handleClose}
        keepMounted={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <AskOther
            onClose={handleClose}
            dimensions={dimensions}
            isAskOtherModalOpen={isAskOtherModalOpen}
            ref={FieldEditor1}
            setIsAskOtherModalOpen={(viisble) => handleClickOpen(viisble)}
            handleClickOpen={handleClickOpenClose}
        />
        <Box sx={{
            position: 'absolute',
            background: '#fff',
            top: 10, left: '10px',
            display: 'flex',
            padding: '1rem',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.75rem',
            borderRadius: '1rem',
            maxWidth: "350px"
        }}>
            <Typography
                sx={{
                    color: '#B4B0B4',
                    fontSize: '0.875rem !important',
                    fontWeight: 400,
                }}
            >You are inviting to
            </Typography>
            <Box sx={{
                display: 'flex',
                paddingRight: '1rem',
                alignItems: 'center',
                gap: '0.75rem',
                alignSelf: 'stretch',
                borderRadius: '0.75rem',
                background: '#F9F9F9',

            }}>
                <Avatar src={imageURLRender(selectedSubCat?.imageUrl)} sx={{
                    borderRadius: '0.75rem',
                    height: '3rem',
                    width: '3rem',
                    objectFit: 'cover'
                }} alt={selectedSubCat?.subCategory} />
                <Typography
                sx={{
                    color: '#190D1A',
                    fontSize: '1rem !important',
                    fontWeight: 500,
                }}
            >{selectedSubCat?.subCategory} 
            </Typography>
            </Box>
        </Box>
    </CustomDialog>
}

export default AskModal