import { Button, Dialog, Typography, useMediaQuery } from "@mui/material";
import classes from "./GoalNotFound.module.css";
import React, { useState } from "react";
import typography from "../../theme/typography";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import CreateGoal from "../../core-ui/CreateGoal/CreateGoal";
import { useDispatch, useSelector } from "react-redux";
import { updateGoalData } from "../../Store/Reducers/goalDataSlice";
import { useNavigate } from "react-router-dom";
function GoalNotFound({ setisAddNewGoal }) {
  const navigate = useNavigate()
  const {
    testNotCompleted,
    resultNotUnlocked,
  } = useSelector(
    (state) => state.goalData
  );
  const theme = useTheme();
  const dispatch = useDispatch()
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  // const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const handleClose = () => {
    setIsAskOtherModalOpen(false);
  };
  const setIsAskOtherModalOpen = (val) => {
    dispatch(updateGoalData({
      isAskOtherModalOpen: val
    }))
  }
  const CustomDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};

        position: unset;
      }
    }
  `;
  return (
    <div className={matches ? classes.smallMainWrapper : classes.mainWrapper}>
      <div className={classes.notAddGoalWrapper}>
        <Typography variant="body4" sx={{ marginBottom: "4px" }}>
          {testNotCompleted ? "You haven’t completed any tests yet" : resultNotUnlocked ? "You haven’t unlocked any results" : "You haven’t added any goals"}

        </Typography>
        {matches ? (
          <Typography
            variant="subtitle1"
            sx={{
              color: "#B4B0B4",
              margin: "2px 0px",
              textAlign: "center",
              lineHeight: "140%",
            }}
          >
            {testNotCompleted ? "To create a goal, you’ll first need to complete a test and unlock a dimension" : resultNotUnlocked ? "To create a goal, you’ll first need to unlock a dimension" : " Start creating goals to increase, decrease or calibrate your dimensions"}

          </Typography>
        ) : (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#B4B0B4",

                textAlign: "center",
                lineHeight: "140%",
              }}
            >
              {testNotCompleted ? "To create a goal, you’ll first need to complete a test and unlock a dimension" : resultNotUnlocked ? "To create a goal, you’ll first need to unlock a dimension" : " Start creating goals to increase, decrease or calibrate your dimensions"}
            </Typography>
          </>
        )}

        <Button
          className={classes.createGoalsBtn}
          sx={{ ...typography.subtitle1, marginTop: "20px" }}
          onClick={() => {
            if(testNotCompleted) navigate('/testcategory')
            else if (resultNotUnlocked) navigate("/results")
            else setIsAskOtherModalOpen(true);
          }}
        >
          {testNotCompleted ? "Start test" : resultNotUnlocked ? "Unlock results" : "Create a goal"}
        </Button>
      </div>
      {/* <CustomDialog
        open={isAskOtherModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CreateGoal
          handleClose={handleClose}
          setisAddNewGoal={setisAddNewGoal}
        />
      </CustomDialog> */}
    </div>
  );
}
export default GoalNotFound;
