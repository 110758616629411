import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import typography from "../../../../theme/typography";
import classes from "./StepFive.module.css";
import PlusIcon from "../../../../assets/Svg/PlusIcon.svg";
import styled from "@emotion/styled";
import Info from "../../../../assets/Svg/Info.svg";
import { inviteOther } from "../../../../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
} from "../../../../components/Input/Input";
import useCustomCheckBox from "../../../../Hooks/useCustomCheckBox";
import { LINKTYPE } from "../../../../Constant/Invite";
import useCustomNotification from "../../../../Hooks/useCustomNotification";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { setShowConformationModal } from "../../../../Store/Reducers/testCategories";
import useCopy from "use-copy";
import HTMLTooltip from "../../../../components/ToolTipWithIcon/HTMLTooltip";
const validEmail = (value) => {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (value.match(re)) {
    return true
  } else {
    return false
  }
}
const coppyTextContent = (link) => {
  if (!link) return ""
  return `Hey everyone!

I'm on an exciting journey of personal growth with an amazing app called ${window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery (SSD)"} . This platform uses science-backed personality tests to help understand and improve oneself.

But personal growth isn't a solo journey, and I believe that every one of us has unique perspectives and insights to offer. That's why I'd love to hear your thoughts! 

By clicking on the link below, you can provide me with some feedback on what you think are my strengths, areas for improvement, or any other observations you think could be helpful for my growth journey. Don't worry, you don't need to download the app. It's just a quick and if you like anonymous feedback form. 

Your insights would mean the world to me and greatly contribute to my journey. Thanks in advance for your time and thoughts! 💙

Link: ${link}`
}

const copyPrivateTextContent = (link, name = "") => {
  if (!link) return ""
  return `Hi!

I hope this message finds you well. I've recently started using an app called ${window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery (SSD)"} to support my personal growth journey. It involves scientifically-backed personality tests and a series of targeted actions for improvement.
  
I trust your perspective and value your input, and I was wondering if you would be willing to provide me with some feedback. Your insights and observations would be incredibly helpful in guiding my personal development.
  
Here's the link to the app where you can share your feedback: ${link}
  
Let me know if you'd be open to this. I'd greatly appreciate your support!
  
Best,
${name}`
}

const StepFive = (props) => {
  const { dimensions, subCategoryid } = useSelector(
    (state) => state.dimensions
  );
  const [copied, copy, setCopied] = useCopy("https://logrocket.com, this is the text to copy");
  const { state } = useLocation();
  const [inviteEmail, setInviteEmail] = useState("");
  const [description, setDescription] = useState("");
  const [showRed, setShowRed] = useState(false);
  const [inviteButtonText, setInviteButtonText] = useState("Invite via email");
  const [isValidaEmail, setIsValidEmail] = useState(false);
  // const [focus, setFocus] = useState("inviteEmail");
  const { firstCheckBox, secondCheckBox, handleChange } = useCustomCheckBox(
    LINKTYPE?.MULTIPLE,
    LINKTYPE?.SINGLE,
    props?.inviteData?.linkType
  );
  const [testLink, setTestLink] = useState("");
  const [selectedLinkType, setSelectedLinkType] = useState("");
  const navigate = useNavigate();
  const multilineInput = useRef("");

  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();

  console.log(222222, state);

  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // const copyLinkHandler = (type) => {
  //   if (selectedLinkType === type) {
  //     navigator.clipboard.writeText(testLink);
  //     setCustomNotification(true);
  //   }
  // };
  const dispatch = useDispatch()
  const { userData } = useSelector((state) => state.userProfile);
  
  const copyLink = async (type) => {
    const category_id = localStorage.getItem("selctedCategoryId");
    const subCategoryId = localStorage.getItem("selctedSubCategoryId");
    // props.updateInviteData("invites", inviteEmail)
    // props.updateInviteData("inviteDetails", description)
    const uid = localStorage.getItem("userDetail");
    const inviteData = {
      ...props.inviteData,
      invites: [],
      inviteDetails: description,
      uid,
      category_id: category_id,
      subCategory_id: subCategoryId,
      linkType: type,
    };
    const res = await inviteOther(inviteData)
    if (type === LINKTYPE?.MULTIPLE) {
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(coppyTextContent(res?.data?.testLink));
      } else {
        document.execCommand("copy", true, coppyTextContent(res?.data?.testLink));
      }
      setCustomNotification(true)
    }
    if (type === LINKTYPE?.SINGLE) {
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(copyPrivateTextContent(res?.data?.testLink, (userData.name ? userData.name + " " : '') + (userData.surName ? userData.surName : '')));
      } else {
        document.execCommand("copy", true, copyPrivateTextContent(res?.data?.testLink, (userData.name ? userData.name + " " : '') + (userData.surName ? userData.surName : '')));
      }
      setCustomNotification(true)
    }
  }

  const receiveCopy = () => {
    const category_id = localStorage.getItem("selctedCategoryId");
    const subCategoryId = localStorage.getItem("selctedSubCategoryId");
    // props.updateInviteData("invites", inviteEmail)
    // props.updateInviteData("inviteDetails", description)
    const uid = localStorage.getItem("userDetail");
    const inviteData = {
      ...props.inviteData,
      invites: userData?.user_email !== "" ? [userData?.user_email] : [],
      inviteDetails: description,
      uid,
      category_id: category_id,
      subCategory_id: subCategoryId,
      linkType: LINKTYPE?.SINGLE,
      self: true
    };
    console.log("inviteData", inviteData);
    inviteOther(inviteData)
      .then((response) => {
        console.log(120, response.data);
        setInviteButtonText("You’re invitation is sent");
        // props.onModalClose()
        // dispatch(setShowConformationModal(false))
        localStorage.setItem("showConfirmation", "false")
        setTestLink(response?.data?.testLink);
        setSelectedLinkType(response?.data?.linkType);
        // navigate("invite")
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const updateMainState = () => {
    const category_id = localStorage.getItem("selctedCategoryId");
    const subCategoryId = localStorage.getItem("selctedSubCategoryId");
    // props.updateInviteData("invites", inviteEmail)
    // props.updateInviteData("inviteDetails", description)
    const uid = localStorage.getItem("userDetail");
    const inviteData = {
      ...props.inviteData,
      invites: inviteEmail !== "" ? [inviteEmail] : [],
      inviteDetails: description,
      uid,
      category_id: category_id,
      subCategory_id: subCategoryId,
      linkType: LINKTYPE?.SINGLE,
    };
    console.log("inviteData", inviteData);
    inviteOther(inviteData)
      .then((response) => {
        console.log(120, response.data);
        setInviteButtonText("You’re invitation is sent");
        // props.onModalClose()
        // dispatch(setShowConformationModal(false))
        localStorage.setItem("showConfirmation", "false")
        setTestLink(response?.data?.testLink);
        setSelectedLinkType(response?.data?.linkType);
        // navigate("invite")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const revertToMainState = () => {
    setInviteButtonText("Invite via email");
    setTestLink("");
    setIsValidEmail(validEmail(inviteEmail))
    setSelectedLinkType("");
  }
  console.log("inviteButtonText", inviteButtonText, userData);
  return (
    <div className={classes.stepOneWrapper}>
      <div className={classes.middleContent}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Invite other people to test for you
        </Typography>
        <div className="option" style={{ marginTop: "2rem" }}>
          <Box className={classes.wrapper}>
            <TextField
              sx={{
                border:!isValidaEmail && showRed ? "1px solid red": "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border:"none",
                },
                
                "& .MuiOutlinedInput-input": {
                  fontSize: "16px",
                  color: "#190d1a",
                  "::placeholder": {
                    fontFamily: "Inter",
                    fontStyle: "16px",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "100%",
                    color: "#434243",
                  },
                },
              }}
              className={classes.add_email}
              size="small"
              fullWidth
              key="inviteEmail"
              type="text"
              placeholder="Add email"
              id="outlined-start-adornment"
              value={inviteEmail}
              onChange={(e) => {
                revertToMainState()
                setInviteEmail(e.target.value);
                setIsValidEmail(validEmail(e.target.value))

                // if (focus !== "inviteEmail") {
                //   setFocus("inviteEmail");
                // }

              }}
            />
          </Box>
          <Box className={classes.wrapper}>
            <TextField
              className={classes.add_description}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiOutlinedInput-root": {
                  minHeight: "180px",
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "18px 20px",
                },
                "& .MuiOutlinedInput-input": {
                  fontSize: "16px",
                  color: "#190d1a",
                  padding: "0px",
                  "::placeholder": {
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "100%",
                    color: "#434243",
                  }
                }
              }}
              size="small"
              fullWidth
              key="description"
              type="text"
              placeholder="Type in your custom note for who you are inviting"
              id="outlined-start-adornment"
              value={description}
              multiline
              onChange={(e) => {
                revertToMainState()

                setDescription(e.target.value);
              }}
            />
          </Box>
        </div>
        {/* <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // colSpacing="12px"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={classes.wrapper}>
              <CustomCheckBox
                checked={firstCheckBox}
                {...label}
                icon={<CheckBoxInput placeholder="Public Link" />}
                onChange={() => {
                  handleChange(LINKTYPE?.MULTIPLE);
                }}
                checkedIcon={<CheckBoxInputChecked placeholder="Public Link" />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={classes.wrapper}>
              <CustomCheckBox
                checked={secondCheckBox}
                {...label}
                icon={<CheckBoxInput placeholder="Private Link" />}
                onChange={() => {
                  handleChange(LINKTYPE?.SINGLE);
                }}
                checkedIcon={
                  <CheckBoxInputChecked placeholder="Private Link" />
                }
              />
            </Box>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={0}
          // colSpacing="12px"
          spacing={2}
        >
          {inviteButtonText === "You’re invitation is sent" ? (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                <Button
                  className={
                    testLink === "" ? classes.nextButton : classes.previousButton
                  }
                  sx={{
                    ...typography.subtitle1,
                    '&.Mui-disabled': {
                      color: "#FFF"
                    }
                  }}
                  variant="outlined"
                  disabled={!isValidaEmail}
                  onClick={inviteButtonText === "You’re invitation is sent" ? null : updateMainState}
                >
                  {inviteButtonText}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  className={
                    classes.nextButton
                  }
                  sx={{ ...typography.subtitle1 }}
                  variant="outlined"
                  onClick={receiveCopy}
                // onClick={updateMainState}
                >
                  {"Receive a copy"}
                </Button>

              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  className={
                    testLink === "" ? classes.reviewButton : classes.reviewButton
                  }
                  sx={{ ...typography.subtitle1 }}
                  variant="outlined"
                  onClick={() => window.open(`/share-review`, '_blank')}
                // onClick={updateMainState}
                >
                  {"Review email"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                <Button
                  className={
                    testLink === "" ? classes.nextButton : classes.previousButton
                  }
                  sx={{
                    ...typography.subtitle1,
                    '&.Mui-disabled': {
                      color: "#FFF"
                    }
                  }}
                  variant="outlined"
                  onClick={inviteButtonText === "You’re invitation is sent" ? null : !isValidaEmail ? () => setShowRed(true) :updateMainState}
                >
                  {inviteButtonText}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        {/* <div className="invite button" style={{ marginTop: "1rem" }}>
          <Button
            className={
              testLink === "" ? classes.nextButton : classes.previousButton
            }
            sx={{ ...typography.subtitle1 }}
            variant="outlined"
            onClick={updateMainState}
          >
            {inviteButtonText}
          </Button>
        </div> */}
        <Divider sx={{ margin: "1rem 0", borderColor: "#F7F2FE" }} />
        <div className={classes.linkWrapper}>
          <div className={classes.linkContent}>
            <Typography variant="body2" sx={{
              color: "#B4B0B4"
            }}>Public link</Typography>
            <HTMLTooltip title="Anyone who has the link can give you feedback." customIcon={<img src={Info} alt="" style={{ marginLeft: "6px" }} />} placement="right" showArrow={true}
              sx={{
                '& .MuiTooltip-tooltip': {
                  background: "#FFF",
                  color: "#000",
                  maxWidth: "300px"
                }
              }} />
          </div>
          {/* onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}} */}
          <span
            onClick={() => copyLink(LINKTYPE?.MULTIPLE)}
            style={{
              cursor: "pointer"
            }}
          // onCopy={() => setCustomNotification(true)}
          >
            <div
              className={classes.linkContent}
              // onClick={() => copyLinkHandler(LINKTYPE?.MULTIPLE)}
              style={{
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                color={
                  selectedLinkType === LINKTYPE?.MULTIPLE
                    ? "#190D1A"
                    : "#434243"
                }
              >
                Copy link
              </Typography>
            </div>
          </span>
        </div>
        <Divider sx={{ margin: "1rem 0", borderColor: "#F7F2FE" }} />
        <div className={classes.linkWrapper} style={{ marginBottom: "1rem" }}>
          <div className={classes.linkContent}>
            <Typography variant="body2" sx={{
              color: "#B4B0B4"
            }}>Generate individiual link</Typography>
            <HTMLTooltip title="The link stops working after one person has responded." customIcon={<img src={Info} alt="" style={{ marginLeft: "6px" }} />} placement="right" showArrow={true}
              sx={{
                '& .MuiTooltip-tooltip': {
                  background: "#FFF",
                  color: "#000",
                  maxWidth: "300px"
                }
              }} />

          </div>
          {console.log("testLink", testLink)}
          <span
            onClick={() => copyLink(LINKTYPE?.SINGLE)}
            style={{
              cursor: "pointer"
            }}
          // onCopy={() => setCustomNotification(true)}
          >
            <div
              className={classes.linkContent}
              // onClick={() => copyLinkHandler(LINKTYPE?.SINGLE)}
              style={{
                cursor: "pointer"
              }}
            >
              <Typography
                variant="body2"
                color={
                  selectedLinkType === LINKTYPE?.SINGLE ? "#190D1A" : "#434243"
                }
              >
                Copy link
              </Typography>
            </div>
          </span>
        </div>
      </div>
      {customNotification && (
        <NotificationContentJSX
          message="Copied to clipboard"
          position="bottom"
        />
      )}
    </div>
  );
};

export default StepFive;
