import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import classes from "./plaanModal.module.css"
import { GeneralSX } from "../../../components/Text/Text"
import { Avatar, Button, Chip, Typography } from "@mui/material"
import palette from "../../../theme/palette"
import { LeftIconModal } from "../plans.styles"
import { useDispatch, useSelector } from "react-redux"
import { setStoreData, updateQuoteRead } from "../../../Store/Reducers/plans"
import XP from "../../../assets/Svg/XP.svg";
import { ReactComponent as Mail } from "./../../../assets/Svg/Mail-24px.svg"
import PlansServices from "../../../services/Plans"
import { updateUserCions } from "../../../Store/Reducers/userProfileSlice"
import { imageURLRender } from "../../../utils"
let isLareadyCalled = false
const Step3PlanDetails = () => {
    const { userData = {} } = useSelector(
        (state) => state.userProfile
    );
    const { setp, selectedAction, selectedPlan } = useSelector((state) => state.planData);
    const listInnerRef = useRef();
    const { planQuotesData = [] } = selectedAction
    const dispatch = useDispatch()
    const [isRead, setIsRead] = useState(planQuotesData[0].userplanquoteData?.length > 0)
    console.log("isRead", isRead, planQuotesData)

    useEffect(() => {
        if (planQuotesData[0].userplanquoteData?.length > 0) {
            setIsRead(true)
        }
    }, [planQuotesData[0].userplanquoteData])
    const isVisible = useOnScreen(listInnerRef)
    console.log("isVisible", isVisible);
    useEffect(() => {
        if (isVisible && !planQuotesData[0].userplanquoteData?.length && !isRead && !isLareadyCalled) {
            isLareadyCalled = true
            const obj = {
                uid: localStorage.getItem("userDetail"),
                "quoteId": planQuotesData[0].quoteId
            }
            PlansServices.planQuoteRead(obj)
                .then((res) => {
                    isLareadyCalled = false

                    setIsRead(true)
                    const updatedXp = Number(userData.xp || 0) + Number(planQuotesData[0].xp || 0)
                    dispatch(updateUserCions(updatedXp))
                    dispatch(updateQuoteRead())
                    // dispatch(updateStatusOfAction(createActionData))

                    // this.props.setDahData({
                    //   completedXP: createActionData.action.status === "IN_PROGRESS" ?  (this.props.dashboardData.completedXP - foundXp) : (this.props.dashboardData.completedXP + foundXp)
                    // })
                    // updateXp(foundXp, createActionData.action.status !== "IN_PROGRESS")
                    //setIsSetDurationAction(false);
                    // this.callToGetData(
                    //   this.props.dashboardData.selectedDate,
                    //   this.props.dashboardData.selectedDate
                    // );
                    // console.log("res = ", res);
                })
                .catch((err) => {
                    console.log("err = ", err);
                });
        }
    }, [isVisible])

    return <Fragment>

        <div className={classes.stepper1} id="stepper">
            <div className={classes.modalTitle}>

                <LeftIconModal onClick={() => {
                    dispatch(setStoreData({
                        setp: 2
                    }));
                }} />
            </div>

            <div className={classes.step} style={{
                marginTop: '10px'
            }}>

                <div className={classes.quote}>
                    <div style={{
                        position: 'absolute',
                        left: '10px',
                        top: 0,
                        zIndex: 0
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="164" height="92" viewBox="0 0 164 92" fill="none">
                            <g clip-path="url(#clip0_581_5540)">
                                <path d="M43.2 29.8H46C53.6 29.8 60.4 32.7333 66.4 38.6C72.5333 44.3333 75.6 51.5333 75.6 60.2C75.6 68.7333 72.5333 75.8 66.4 81.4C60.4 86.8667 52.9333 89.6 44 89.6C34.2667 89.6 26.1333 85.7333 19.6 78C13.2 70.1333 10 59 10 44.6C10 31.4 12.8 19.0667 18.4 7.6C24.1333 -4 31.0667 -13.1333 39.2 -19.8C47.4667 -26.6 54.8 -30 61.2 -30C65.4667 -30 68.8 -28.7333 71.2 -26.2C73.6 -23.6667 74.8 -20.4 74.8 -16.4C74.8 -10.5333 72.2 -6.2 67 -3.4C59.9333 0.466667 54.3333 5 50.2 10.2C46.0667 15.4 43.7333 21.9333 43.2 29.8ZM131.6 29.8H134.2C141.667 29.8 148.4 32.7333 154.4 38.6C160.533 44.3333 163.6 51.5333 163.6 60.2C163.6 68.6 160.533 75.6667 154.4 81.4C148.4 87.1333 140.933 90 132 90C122.133 90 114 86 107.6 78C101.2 70 98 58.8667 98 44.6C98 34.3333 99.6667 24.6667 103 15.6C106.333 6.53334 110.6 -1.33333 115.8 -8C121.133 -14.8 126.8 -20.1333 132.8 -24C138.933 -28 144.4 -30 149.2 -30C153.6 -30 156.933 -28.7333 159.2 -26.2C161.6 -23.8 162.8 -20.5333 162.8 -16.4C162.8 -10.4 160.2 -6.06667 155 -3.4C147.667 0.733335 142.133 5.33334 138.4 10.4C134.667 15.3333 132.4 21.8 131.6 29.8Z" fill="#AC6CFF" />
                            </g>
                            <defs>
                                <clipPath id="clip0_581_5540">
                                    <rect width="164" height="92" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className={classes.dataContent}>
                        <div className={classes.frame4516}>
                        <img src={imageURLRender(selectedPlan?.url)} alt={selectedPlan?.questionCategory}/>
                            <Typography sx={{
                                ...GeneralSX({
                                    color: palette.common.white,
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: "100%",
                                    letterSpacing: '-0.18px'
                                })
                            }}>{selectedPlan?.questionCategory}</Typography>
                        </div>
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.common.white,
                                fontSize: 18,
                                fontWeight: 500,
                                lineHeight: "145%",
                                letterSpacing: '-0.18px'
                            }),
                            textAlign: 'center'
                        }}>{planQuotesData[0] && planQuotesData[0]?.title}</Typography>
                    </div>
                </div>
            </div>
            <Typography sx={{
                ...GeneralSX({
                    color: palette.common.mainBlack,
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "170%",
                    letterSpacing: '-0.16px'
                }),
                whiteSpace: 'pre-line'
            }}>{planQuotesData[0] && planQuotesData[0]?.description}</Typography>
            <div className={classes.inviteBlock} ref={listInnerRef}>
                <Typography sx={{
                    ...GeneralSX({
                        color: palette.common.mainBlack,
                        fontSize: 18,
                        fontWeight: 500,
                        lineHeight: "145%",
                        letterSpacing: '-0.18px'
                    }),
                    textAlign: 'center'
                }}>Share with someone who can benefit from this</Typography>
                <div className={classes.CTAButtonGroup} ref={listInnerRef}>
                    {[{
                        title: 'Mail',
                        icon: <Mail />
                    },/* {
                        title: 'Copy Link'
                    }, */].map(it => <div className={classes.CTAButton} /* onClick={() => setClickedmail(true)} */>
                        <div className={classes.CTAIcon}>{it.icon}</div>
                        <Typography
                            variant="caption"
                            component="div"
                            className={classes.CTAText}
                        >
                            {it.title}
                        </Typography>
                    </div>)}
                </div>
            </div>
        </div>
        {/* <div className={classes.blurOverlay}></div>
        <Button variant="contained" className={classes.modalBottomButton1} onClick={() => {
            dispatch(setStoreData({
                setp: setp + 1
            }));
        }}>
            <Typography sx={{
                ...GeneralSX({
                    color: palette.common.white,
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "100%",
                    letterSpacing: '-0.16px'
                })
            }}>Start actions</Typography>
        </Button> */}
    </Fragment>
}

export default Step3PlanDetails

const Tag = ({ xp }) => {
    return <div className={classes.tag}>
        <Avatar alt="Natacha" sx={{ width: '12px', height: '12px' }} src={XP} />
        <Typography sx={{
            ...GeneralSX({
                color: palette.primary.main,
                fontSize: 12,
                fontWeight: 400,
                lineHeight: "100%",
                letterSpacing: '-0.12px'
            })
        }}>{xp}</Typography>
    </div>
}

function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])


    useEffect(() => {
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}
