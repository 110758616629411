import styled from "@emotion/styled";
import classes from "./StepFour.module.css";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Search from "../../../assets/Svg/Search.svg";
function Stepfour() {
  const Name = [
    { shortName: "AL", name: "Anastasia Louis" },
    { shortName: "AL", name: "Anastasia Louis" },
    { shortName: "GK", name: "George Kent" },
    { shortName: "GK", name: "George Kent" },
    { shortName: "NL", name: "Nicola Tesla" },
    { shortName: "NL", name: "Nicola Tesla" },
    { shortName: "NL", name: "Nicola Tesla" },
    { shortName: "NL", name: "Nicola Tesla" },
    { shortName: "AL", name: "Anastasia Louis" },
    { shortName: "AL", name: "Anastasia Louis" },
    { shortName: "AL", name: "Anastasia Louis" },
    { shortName: "AL", name: "Anastasia Louis" },
    { shortName: "AL", name: "Anastasia Louis" },
    { shortName: "AL", name: "Anastasia Louis" },
  ];
  const InputField = styled(TextField)`
  width:100%;
    height: 40px;
    background: #F9F9F9;;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-input {
      font-size: 16px;
      color: #190d1a;
      ::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #434243;
      }
    }
  `;
  return (
    <>
      <Box className={classes.mainWrapper}>
        <Box className={classes.InnerWrapper}>
          <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
            <Typography className={classes.heading} variant="h4">
              Please select someone <br /> from the list to view their
              perspective on your self-discovery
            </Typography>
          </Box>
          <Box className={classes.SearchWrapper}>
            <InputField
              size="small"
              fullWidth
              type="text"
              placeholder="Type in a name"
              id="outlined-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Search} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Grid
            container
            rowSpacing={1.5}
            columnSpacing={1.5}
            sx={{ marginTop: "2px" }}
          >
            {Name?.map((data, i) => {
              return (
                <Grid item lg={6} md={5.9} sm={12} xs={12} key={i}>
                  <Box
                    className={classes.nameWrapper}
                    sx={{
                      display: "flex",
                      border: "1px solid pink",
                      padding: "16px",
                    }}
                  >
                    <Box className={classes.ShortNamewrapper}>
                      <Typography variant="span">
                        {data.name.charAt(0)}
                        {data.name.split(" ")[1].charAt(0)}
                      </Typography>
                    </Box>
                    <Typography className={classes.NameClass} variant="body1">
                      {data.name}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
export default Stepfour;
