import { useTheme } from "@emotion/react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import typography, { pxToRem } from "../../theme/typography";
import classes from "./GoalList.module.css";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import GoalGroup from "../../assets/Svg/GoalGroup.svg";
import GoalCalibrate from "../../assets/Svg/GoalCalibrate.svg";
import GoalDecrease from "../../assets/Svg/GoalDecrease.svg";
import AcademicIcon from "../../assets/Svg/AcademicIcon.svg";
import PlusLargeIcon from "../../assets/Svg/PlusLargeIcon.svg";
import Archive from "../../assets/Svg/Archive.svg";
import { useLocation, useNavigate } from "react-router-dom";

import { PersonalChart } from "../../components/PersonalChartGoal/PersonalChart";
import CalanderGoal from "../../components/CalanderGoal/CalanderGoal";
import { FriendChart } from "../../components/FriendChartGoal/FriendChart";
import {
    ResultAllTestDiamention,
    editPreSelectedGrowth,
    getGoals,
    goalArchive,
    updateHabitActionCalender,
} from "../../services/auth";
import CircularProgressGoal, { CircularProgressGoalNew } from "../../components/CircularProgressBar/CircularProgressBarGoal";
import moment from "moment";
import {
    CustomTypography,
    RightSideContentWrapper,
    SchedualdateWrapper,
    SchedualdateWrapperNew,
} from "../../components/GoalComponent/GoalCustomDiv";
import ActionAccordian from "../../components/GoalAccordian/ActionAccordian";
import GoalNotFound from "../GoalNotFound/GoalNotFound";
import HabitAccordian from "../../components/GoalAccordian/HabitAccordian";
import GrowthAreaAccordian from "../../components/GoalAccordian/GrowthAreaAccordian";
import AddNewGoal from "../../components/AddNewGoal/AddNewGoal";
import {
    updateGoalID,
    updateSuggestedGrowthAreaState,
} from "../../Store/Reducers/goalGrowthArea";
import { useDispatch, useSelector } from "react-redux";
import {
    updateActionData,
    updateAllActionData,
    updateIsAddMore,
    updateisAllCompeletedAction,
    updateisCompeletedAction,
} from "../../Store/Reducers/goalActionData";
import {
    updateCompeletedHabit,
    updateHabitAddMore,
    updatehabitAllCalenders,
    updatehabitAllCalendersComplete,
    updatehabitCalenders,
    updatehabitCalendersComplete,
    updateHabitData,
} from "../../Store/Reducers/goalHabitData";
import ButtonGroup, { ButtonGroupNew } from "../../components/GoalAddMoreDialog/ButtonGroupAddMore/ButtonGroup";
import CompletedGoalDialog from "../../components/CompletedArchiveDialog/CompletedDialog";
import ArchiveGoalDialog from "../../components/CompletedArchiveDialog/ArchieveDialog";
import { imageURLRender } from "../../utils";
import { setGoalNotificationMessage, updateAddGoalCategory, updateAddGoalStep, updategetGoal, updateGoalData } from "../../Store/Reducers/goalDataSlice";
import AddNewgoalModal, { ConfirmationClose } from "./AddGoalModal";
import CloseSmalIcon from "../../assets/Svg/CloseSmalIcon.svg";
import useCustomNotification from "../../Hooks/useCustomNotification";
function GoalList() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const growthAreaDataSlice = useSelector(
        (state) => state.growthAreaDataSlice
    );
    const { state, pathname } = useLocation();
    console.log("state", state);
    const ActiondataSlice = useSelector(
        (state) => state.ActiondataSlice
    );
    const HabitDataSlice = useSelector(
        (state) => state.HabitDataSlice
    );
    const {
        getgoal,
        isAddNewGoal,
        isGoalArchive,
        isCompletedGoal,
        preSelectedState,
        allPreSelectedState,
        isCalenderOpen,
        isGoalGet,
        tbdDate,
        goalName,
        schedualDate,
        othresArray,
        PersonalDataArray,
        goalScore,
        selectedQuestionCategoriesId,
        completedGrowthAreaState,
        allCompletedGrowthAreaState,
        isAddMoreGrowthAreaOpen,
        isCompletedArchiveOpen,
        isArchieveOpen,
        expanded,
        notificationmesage
    } = useSelector(
        (state) => state.goalData
    );
    const setExpanded = (val) => {
        dispatch(updateGoalData({
            expanded: val
        }))
    }
    const setisAddNewGoal = (val) => {
        dispatch(updateGoalData({
            isAddNewGoal: val
        }))
    }
    const setIsGoalArchive = (val) => {
        dispatch(updateGoalData({
            isGoalArchive: val
        }))
    }
    const setIsCompletedGoal = val => {
        dispatch(updateGoalData({
            isCompletedGoal: val
        }))
    }
    const setPreSelectedState = (val) => {
        dispatch(updateGoalData({
            preSelectedState: [...val].slice(0, 3),
            allPreSelectedState: val
        }))
    }
    useEffect(() => {
        const user = localStorage.getItem("userDetail");
        const archive = false;
        const status = "IN_PROGRESS";
        ResultAllTestDiamention(user)
            .then((data) => {
                console.log("data.allPercentage", data.allPercentage);
                if (data.allPercentage && data.allPercentage.length) {
                    const unLockResults = [...data.allPercentage].filter(it => it.unlock)
                    if (unLockResults && unLockResults.length) {
                        getGoals(user, archive, status)
                            .then((res) => {
                                dispatch(updategetGoal(res?.data))
                                dispatch(updateGoalData({
                                    resultNotUnlocked: false,
                                    testNotCompleted: false,
                                }))
                                if (res?.data[0]) {
                                    const {
                                        questionCategory,
                                        questionCategoryId,
                                        actions,
                                        actionRecommended = [],
                                        growthRecommended = [],
                                        actionsComplete,
                                        habits,
                                        mySelf,
                                        others,
                                        url,
                                        preSelectedGrowth,
                                        preSelectedGrowthComplete,
                                        actionsArchived,
                                        habitsArchived,
                                        _id,
                                        increase,
                                        habitsComplete,
                                        tbd,
                                        habitCalenders = [],
                                        habitCalendersComplete = [],
                                        subCategory_id, category_id
                                    } = res?.data[0];
                                    handleChange(
                                        0,
                                        questionCategory,
                                        mySelf,
                                        others,
                                        questionCategoryId,
                                        _id,
                                        preSelectedGrowth,
                                        preSelectedGrowthComplete,
                                        actions,
                                        actionsComplete,
                                        habits,
                                        habitsComplete,
                                        tbd,
                                        habitCalenders,
                                        habitCalendersComplete
                                    )(null, true)
                                }

                                const cat = localStorage.getItem("cat")
                                if (cat) {
                                    const newCat = JSON.parse(cat)
                                    console.log("????", res?.data)
                                    const foundGoal = res?.data.findIndex(it => it.questionCategoryId === newCat.questionCategoryId)
                                    console.log("foundGoal =====", foundGoal);
                                    if (foundGoal < 0) {
                                        dispatch(updateAddGoalCategory(newCat))
                                        dispatch(updateAddGoalStep(2))
                                        dispatch(updateGoalData({
                                            isAskOtherModalOpen: true
                                        }))
                                        localStorage.removeItem("cat")
                                    }
                                    if (foundGoal > -1) {
                                        dispatch(setGoalNotificationMessage("Goal is already created"))
                                        setCustomNotification(true)
                                    }
                                }
                            })
                    } else {
                        dispatch(updateGoalData({
                            resultNotUnlocked: true,
                            testNotCompleted: false,
                        }))
                    }
                } else {
                    dispatch(updateGoalData({
                        testNotCompleted: true,
                    }))
                }
            })

    }, [])
    useEffect(() => {
        if (
            !getgoal.length ||
            isAddNewGoal ||
            isCompletedGoal ||
            isGoalArchive ||
            ActiondataSlice?.isAddmoreAction ||
            HabitDataSlice?.isAddmoreHabit
        ) {
            const user = localStorage.getItem("userDetail");
            const archive = false;
            const status = "IN_PROGRESS";
            getGoals(user, archive, status)
                .then((res) => {
                    dispatch(updategetGoal(res?.data))
                    setIsGoalGet(true);
                    setIsGoalArchive(false);
                    setisAddNewGoal(false);
                    setIsCompletedGoal(false);
                    const tempPreselectedData = res?.data?.filter(
                        (item) => item._id === growthAreaDataSlice?.goalID
                    );
                    setCompletedGrowthAreaState(
                        tempPreselectedData[0]?.preSelectedGrowthComplete
                    );
                    setPreSelectedState(tempPreselectedData[0]?.preSelectedGrowth);
                    const newActions = [...(tempPreselectedData[0]?.actions || [])].map(it => {
                        const newObject = { ...(it || {}) }
                        if (newObject && newObject.scheduled_type === "DEFAULT") {
                            newObject.executionDate = moment().format()
                            newObject.executionDateEnd = moment().add(30, 'minutes').format()
                        }
                        return newObject
                    })
                    dispatch(updateActionData([...newActions].splice(0, 3)));
                    dispatch(updateAllActionData(newActions));
                    dispatch(
                        updateisCompeletedAction([...tempPreselectedData[0]?.actionsComplete].splice(0, 3))
                    );
                    dispatch(updateisAllCompeletedAction(tempPreselectedData[0]?.actionsComplete))
                    dispatch(updateHabitData(tempPreselectedData[0]?.habits));
                    dispatch(updatehabitCalenders([...tempPreselectedData[0]?.habitCalenders].splice(0, 3)));
                    dispatch(updatehabitAllCalenders(tempPreselectedData[0]?.habitCalenders))
                    dispatch(updatehabitCalendersComplete([...tempPreselectedData[0]?.habitCalendersComplete].splice(0, 3)));
                    dispatch(updatehabitAllCalendersComplete(tempPreselectedData[0]?.habitCalendersComplete));
                    dispatch(
                        updateCompeletedHabit(tempPreselectedData[0]?.habitsComplete)
                    );
                    dispatch(updateIsAddMore(false));
                    dispatch(updateHabitAddMore(false));
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => { });
        }
    }, [
        isAddNewGoal,
        isCompletedGoal,
        isGoalArchive,
        ActiondataSlice?.isAddmoreAction,
        HabitDataSlice?.isAddmoreHabit,
    ]);
    const setIsCalenderOpen = (val) => {
        dispatch(updateGoalData({
            isCalenderOpen: val
        }))
    }
    const setIsGoalGet = (val) => {
        dispatch(updateGoalData({
            isGoalGet: val
        }))
    }
    const setTbdDate = (val) => {
        dispatch(updateGoalData({
            tbdDate: val
        }))
    }
    const setGoalName = (val) => {
        dispatch(updateGoalData({
            goalName: val
        }))
    }
    const setschedualDate = (val) => {
        dispatch(updateGoalData({
            schedualDate: val
        }))
    }
    const setOthresArray = (val) => {
        dispatch(updateGoalData({
            othresArray: val
        }))
    }
    const setPersonalDataArray = (val) => {
        dispatch(updateGoalData({
            PersonalDataArray: val
        }))
    }
    const setgoalScore = (val) => {
        dispatch(updateGoalData({
            goalScore: val
        }))
    }
    const setSelectedQuestionCategoriesId = (val) => {
        dispatch(updateGoalData({
            selectedQuestionCategoriesId: val
        }))
    }
    const setIsAskOtherModalOpen = (val) => {
        dispatch(updateGoalData({
            isAskOtherModalOpen: val
        }))
    }
    const { NotificationContentJSX, customNotification, setCustomNotification } =
        useCustomNotification();
    const setCompletedGrowthAreaState = val => dispatch(updateGoalData({
        completedGrowthAreaState: [...val].slice(0, 3),
        allCompletedGrowthAreaState: val
    }))
    const setisAddMoreGrowthAreaOpen = val => dispatch(updateGoalData({
        isAddMoreGrowthAreaOpen: val
    }))
    const setIsCompletedArchiveOpen = val => dispatch(updateGoalData({
        isCompletedArchiveOpen: val
    }))
    const setisArchieveOpen = val => dispatch(updateGoalData({
        isArchieveOpen: val
    }))
    const smallDeviceMatches = useMediaQuery("(max-width:500px)");

    const selectGrowthAreaHandler = (data) => {
        const completed = allPreSelectedState.filter((item) => item._id === data._id);
        const tempCompeleted = [
            ...allCompletedGrowthAreaState,
            {
                type: completed[0].type,
                status: "COMPLETE",
                _id: completed[0]._id,
                text: completed[0].text,
            },
        ];
        const uniquePreselected = duplicateGrowthAreaRemoveHandler(tempCompeleted);
        setCompletedGrowthAreaState(uniquePreselected);
        const tempSelectedGrowthArea = allPreSelectedState?.filter(
            (item) => item._id !== data._id
        );
        setPreSelectedState(tempSelectedGrowthArea);
        const user = localStorage.getItem("userDetail");
        let selectedToCompletedGrowth = {
            uid: user,
            goalId: growthAreaDataSlice?.goalID,
            preSelectedGrowth: tempSelectedGrowthArea,
            preSelectedGrowthComplete: tempCompeleted,
        };
        editPreSelectedGrowth(selectedToCompletedGrowth)
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const completeDataHandler = (questionCategory) => {
        const user = localStorage.getItem("userDetail");
        setIsCompletedGoal(true);
        const archiveData = {
            id: selectedQuestionCategoriesId,
            archive: false,
            uid: user,
            status: "COMPLETE",
        };
        console.log("archiveData", archiveData, questionCategory);
        goalArchive(archiveData)
            .then((res) => {
                // console.log(res);
                dispatch(updateGoalData({
                    // isAskOtherModalOpen: false,
                    oprnCongratsModal: true,
                    completeModal: true,
                    createdgoalName: questionCategory
                }))
                setIsCompletedArchiveOpen(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setExpanded(false);
    };
    const archiveDataHandler = () => {
        const user = localStorage.getItem("userDetail");
        setIsGoalArchive(true);
        let archiveData = {
            id: selectedQuestionCategoriesId,
            archive: true,
            uid: user,
            status: "IN_PROGRESS",
        };
        goalArchive(archiveData)
            .then((res) => {
                // console.log(res);
                setisArchieveOpen(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setExpanded(false);
    };
    const completedGrowthAreaHandler = (data) => {
        const selectedArea = allCompletedGrowthAreaState.filter(
            (item) => item._id === data._id
        );

        const tempSelected = [
            ...allPreSelectedState,
            {
                type: selectedArea[0].type,
                status: "IN_PROGRESS",
                _id: selectedArea[0]._id,
                text: selectedArea[0].text,
            },
        ];
        setPreSelectedState(tempSelected);

        const user = localStorage.getItem("userDetail");
        const tempCompleted = allCompletedGrowthAreaState?.filter(
            (item) => item._id !== data._id
        );
        setCompletedGrowthAreaState(tempCompleted);

        let selectedToCompletedGrowth = {
            uid: user,
            goalId: growthAreaDataSlice?.goalID,
            preSelectedGrowthComplete: tempCompleted,
            preSelectedGrowth: tempSelected,
        };
        editPreSelectedGrowth(selectedToCompletedGrowth)
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const duplicateGrowthAreaRemoveHandler = (arr) => {
        const res = {};
        arr?.forEach((obj) => {
            const key = `${obj.text}`;
            if (!res[key]) {
                res[key] = { ...obj, count: 0 };
            }
            res[key].count += 1;
        });
        return Object.values(res);
    };
    const handleChange =
        (
            panel,
            questionCategory,
            mySelf,
            others,
            questionCategoryId,
            id,
            preSelectedGrowth,
            preSelectedGrowthComplete,
            actions,
            actionsComplete,
            habits,
            habitsComplete,
            tbd,
            habitCalenders = [],
            habitCalendersComplete = []
        ) =>
            (event, isExpanded) => {
                console.log("preSelectedGrowthComplete =====", preSelectedGrowthComplete);
                setExpanded(isExpanded ? panel : false);
                setGoalName(questionCategory);
                setSelectedQuestionCategoriesId(questionCategoryId);
                dispatch(updateGoalID(id));
                setPreSelectedState(preSelectedGrowth);
                setCompletedGrowthAreaState(preSelectedGrowthComplete);
                console.log("actions", actions);
                const newActions = [...(actions || [])].map(it => {
                    const newObject = { ...(it || {}) }
                    if (newObject && newObject.scheduled_type === "DEFAULT") {
                        newObject.executionDate = moment().format()
                        newObject.executionDateEnd = moment().add(30, 'minutes').format()
                    }
                    return newObject
                })
                dispatch(updateActionData([...newActions].splice(0, 3)));
                dispatch(updateAllActionData(newActions));
                dispatch(updateHabitData(habits));
                dispatch(updateCompeletedHabit(habitsComplete));
                dispatch(updateisCompeletedAction([...actionsComplete].splice(0, 3)));
                dispatch(updateisAllCompeletedAction(actionsComplete))
                dispatch(updatehabitCalenders([...habitCalenders].splice(0, 3)));
                dispatch(updatehabitAllCalenders(habitCalenders))
                console.log("habitCalendersComplete", habitCalendersComplete);
                dispatch(updatehabitCalendersComplete([...habitCalendersComplete].splice(0, 3)));
                dispatch(updatehabitAllCalendersComplete(habitCalendersComplete));
                setTbdDate(tbd);

                const tempScore = mySelf.map((item) => {
                    return item.percent;
                });
                setgoalScore(tempScore);
                const personalArray = mySelf.map((item) => {
                    return {
                        y: item.percent,
                        name: moment(item?.date).format("DD MMM"),
                        userName: item?.name && item?.name[0] && item?.name[0]
                            .split(" ")
                            .map((i) => i.toUpperCase().charAt(0))
                            .join(""),
                    };
                });
                const friendArray = others.map((item) => {
                    return {
                        y: item.percent,
                        name: moment(item.date).format("DD MMM"),
                        userName: item?.name && item?.name[0] && item?.name[0]
                            .split(" ")
                            .map((i) => i.toUpperCase().charAt(0))
                            .join(""),
                    };
                });
                setOthresArray(friendArray);
                setPersonalDataArray(personalArray);
                // const suggestedGrowthArea = preSelectedGrowth?.filter(
                //   (item) => item.type === "SUGGESTED"
                // );
                // const suggestedCompletedGrowthArea = preSelectedGrowthComplete?.filter(
                //   (item) => item.type === "SUGGESTED"
                // );
                const tempSuggestedArea = [
                    ...preSelectedGrowth,
                    ...preSelectedGrowthComplete,
                ];
                dispatch(updateSuggestedGrowthAreaState(tempSuggestedArea));
            };
    const elementRef = useRef(null);

    console.log("customNotification", customNotification)
    return (
        <>

            <div className={classes.dashbordTitleContainer}>
                <div className={classes.dashbordTitle}>
                    <Typography variant="h4">Goals</Typography>
                    <img
                        src={Archive}
                        onClick={() => navigate("/goals/archive")}
                        alt="Archive"
                    />
                </div>
                <div className={classes.dashbordTitlebody}>
                    {matches ? (
                        <Typography variant="p" sx={{ color: "#434243" }}>
                            Goals allow to set measurable targets in various dimensions of
                            your personality. You can track your progress
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="p" sx={{ color: "#434243" }}>
                                Goals allow to set measurable targets in various dimensions of
                                your personality. You can track your progress
                            </Typography>
                            <Typography variant="p" sx={{ color: "#434243" }}>
                                and adjust the goals to stay on track towards self-improvement
                                and discovery.
                            </Typography>
                        </>
                    )}
                </div>
            </div>
            {!isGoalGet ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : getgoal.length === 0 ? (
                <GoalNotFound setisAddNewGoal={setisAddNewGoal} />
            ) : (
                <div
                    className={
                        matches
                            ? expanded === false
                                ? classes.smallMainWrapper
                                : classes.accodianOpensmallWrapper
                            : expanded === false
                                ? classes.mainWrapper
                                : classes.accodianOpenMainWrapper
                    }
                >
                    <Grid
                        container
                        spacing={1}
                    // sx={{
                    //   overflowY: "auto",
                    //   height: "490px"
                    // }}
                    >
                        {getgoal.map((data, index) => {
                            const {
                                questionCategory,
                                questionCategoryId,
                                actions,
                                actionRecommended = [],
                                growthRecommended = [],
                                actionsComplete,
                                habits,
                                mySelf,
                                others,
                                url,
                                preSelectedGrowth,
                                preSelectedGrowthComplete,
                                actionsArchived,
                                habitsArchived,
                                _id,
                                increase,
                                habitsComplete,
                                tbd,
                                habitCalenders = [],
                                habitCalendersComplete = [],
                                subCategory_id, category_id
                            } = data;
                            console.log("data ========", data);
                            const imageCompo = <div>
                                <img
                                    src={
                                        url === "/public/images/"
                                            ? AcademicIcon
                                            : imageURLRender(url)
                                    }
                                    style={{ margin: "16px auto", height: '58px', }}
                                    alt=""
                                />
                            </div>
                            return (
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                    <Accordion
                                        sx={{
                                            border: "1px solid #D0CED0",
                                            borderRadius: "16px !important",
                                        }}
                                        expanded={expanded === index}
                                        onChange={handleChange(
                                            index,
                                            questionCategory,
                                            mySelf,
                                            others,
                                            questionCategoryId,
                                            _id,
                                            preSelectedGrowth,
                                            preSelectedGrowthComplete,
                                            actions,
                                            actionsComplete,
                                            habits,
                                            habitsComplete,
                                            tbd,
                                            habitCalenders,
                                            habitCalendersComplete
                                        )}
                                    >
                                        <AccordionSummary
                                            expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
                                            className={classes.AccordianSummery}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            sx={{
                                                "& .Mui-expanded": {
                                                    margin: "0px 0px !important",
                                                },
                                            }}
                                        //
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <img
                                                        src={
                                                            url === "/public/images/"
                                                                ? AcademicIcon
                                                                : imageURLRender(url)
                                                        }
                                                        alt=""
                                                    />
                                                    <Typography
                                                        variant="body4"
                                                        sx={{
                                                            marginLeft: "12px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {questionCategory}
                                                    </Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        marginRight: increase === "Calibrate" ? "10px" : "15px",
                                                        alignItems: "center",
                                                        display: "flex",
                                                    }}
                                                >
                                                    {increase === "Decrease" ? (
                                                        <img src={GoalDecrease} alt="GoalGroup" />
                                                    ) : increase === "Calibrate" ? (
                                                        <img src={GoalCalibrate} alt="GoalGroup" height={"30px"} />
                                                    ) : (
                                                        <img src={GoalGroup} alt="GoalGroup" />
                                                    )}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            padding: 0,
                                            borderRadius: '16px'
                                        }}>
                                            <div>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '24px',
                                                    alignSelf: 'stretch',
                                                    background: '#FFF',
                                                    borderRadius: '16px'

                                                }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        padding: '20px',
                                                        margin: '0 24px',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-start',
                                                        gap: '16px',
                                                        alignSelf: 'stretch',
                                                        borderRadius: '16px',
                                                        background: '#F9F9F9',
                                                    }}>
                                                        <Typography sx={{
                                                            color: '#190D1A',
                                                            fontSize: `${pxToRem(16)}!important`,
                                                            fontWeight: 500,
                                                            lineHeight: '100%', /* 16px */
                                                            letterSpacing: '-0.16px',

                                                        }}>Scores</Typography>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'flex-end',
                                                            alignSelf: 'stretch',
                                                        }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'flex-start',
                                                                gap: '12px',
                                                            }}>
                                                                {goalScore.length === 0 ? (
                                                                    <div className={classes.dataNotFoundSubWrapper}>
                                                                        <Typography variant="body2">
                                                                            Archived Action not found
                                                                        </Typography>
                                                                    </div>
                                                                ) : (
                                                                    goalScore?.map((percent, i) => {
                                                                        return (
                                                                            <CircularProgressGoalNew
                                                                                key={i}
                                                                                value={Math.round(percent)}
                                                                                width={"32px"}
                                                                                height={"32px"}
                                                                            />
                                                                        );
                                                                    })
                                                                )}
                                                                {tbdDate?.map((data, i) => {
                                                                    const { date } = data;
                                                                    return (
                                                                        <SchedualdateWrapperNew key={i}>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                width: '28px',
                                                                                height: '26px',
                                                                                flexDirection: 'column',
                                                                                justifyContent: 'center',
                                                                                flexShrink: 0,
                                                                            }}>
                                                                                <Typography
                                                                                    // variant="small"
                                                                                    // className={classes.shedualdateText}
                                                                                    sx={{
                                                                                        color: '#9747FF',
                                                                                        textAlign: 'center',
                                                                                        fontSize: `${pxToRem(8)}!important`,
                                                                                        fontWeight: 400,
                                                                                        lineHeight: '120%', /* 9.6px */
                                                                                        letterSpacing: '-0.08px',
                                                                                    }}
                                                                                >
                                                                                    {moment(date).format("MMM Do")}
                                                                                </Typography>
                                                                            </Box>

                                                                        </SchedualdateWrapperNew>
                                                                    );
                                                                })}
                                                            </Box>
                                                            <Box sx={{
                                                                width: '16px',
                                                                height: '16px',

                                                            }} onClick={() => setIsCalenderOpen(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path d="M8.0006 2.34315V13.6569" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M2.34375 8H13.6575" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.divider}></Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        padding: '20px',
                                                        margin: '0 24px',
                                                        alignSelf: 'stretch',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        gap: '12px',
                                                        borderRadius: '20px',
                                                        background: '#F9F9F9',
                                                    }}>
                                                        <Typography sx={{
                                                            color: '#190D1A',
                                                            fontSize: `${pxToRem(16)}!important`,
                                                            fontWeight: 500,
                                                            lineHeight: '100%', /* 16px */
                                                            letterSpacing: '-0.16px',

                                                        }}>Personal and friend’s perspective graphs</Typography>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start',
                                                            gap: '16px',
                                                            alignSelf: 'stretch'
                                                        }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                padding: '16px',
                                                                flexDirection: 'column',
                                                                alignItems: 'flex-start',
                                                                gap: '16px',
                                                                borderRadius: '16px',
                                                                background: '#FFF',
                                                                alignSelf: 'stretch'
                                                            }}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    sx={{
                                                                        color: '#190D1A',
                                                                        fontSize: `${pxToRem(16)}!important`,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        fontWeight: 500,
                                                                        lineHeight: '100%', /* 16px */
                                                                        letterSpacing: '-0.16px',
                                                                    }}
                                                                >
                                                                    Personal
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignSelf: 'stretch',
                                                                        alignItems: 'flex-start',
                                                                        height: "400px",
                                                                        // width: " 400px",
                                                                    }}
                                                                    ref={elementRef}
                                                                    className="goalListFriendChart"
                                                                >
                                                                    <PersonalChart
                                                                        PersonalDataArray={PersonalDataArray}
                                                                        expanded={expanded}
                                                                        url={mySelf[0]?.imageUrl[0] ? mySelf[0]?.imageUrl[0] : '/public/images/'}
                                                                        width={elementRef.current?.getBoundingClientRect()?.width}
                                                                    />
                                                                </div>
                                                            </Box>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                padding: '16px',
                                                                flexDirection: 'column',
                                                                alignItems: 'flex-start',
                                                                gap: '16px',
                                                                borderRadius: '16px',
                                                                background: '#FFF',
                                                                alignSelf: 'stretch'
                                                            }}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    sx={{
                                                                        color: '#190D1A',
                                                                        fontSize: `${pxToRem(16)}!important`,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        fontWeight: 500,
                                                                        lineHeight: '100%', /* 16px */
                                                                        letterSpacing: '-0.16px',
                                                                    }}
                                                                >
                                                                    Friends
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignSelf: 'stretch',
                                                                        alignItems: 'flex-start',
                                                                        height: "400px",
                                                                        // width: " 400px",
                                                                    }}
                                                                    ref={elementRef}
                                                                    className="goalListFriendChart"
                                                                >
                                                                    <FriendChart data={data} othresArray={othresArray} others={others} width={elementRef.current?.getBoundingClientRect()?.width} />
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.divider}></Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        padding: '20px',
                                                        margin: '0 24px',
                                                        alignSelf: 'stretch',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        gap: '12px',
                                                        borderRadius: '20px',
                                                        background: '#F9F9F9',
                                                    }}>
                                                        <GrowthAreaAccordian
                                                            preSelectedGrowth={preSelectedState}
                                                            allPreSelectedState={allPreSelectedState}
                                                            growthRecommended={growthRecommended}
                                                            preSelectedGrowthComplete={completedGrowthAreaState}
                                                            allCompletedGrowthAreaState={allCompletedGrowthAreaState}
                                                            selectGrowthAreaHandler={selectGrowthAreaHandler}
                                                            completedGrowthAreaHandler={
                                                                completedGrowthAreaHandler
                                                            }
                                                            questionCategoryId={questionCategoryId}
                                                            selectedQuestionCategoriesId={
                                                                selectedQuestionCategoriesId
                                                            }
                                                            setPreSelectedState={setPreSelectedState}
                                                            setCompletedGrowthAreaState={
                                                                setCompletedGrowthAreaState
                                                            }
                                                            isAddMoreGrowthAreaOpen={isAddMoreGrowthAreaOpen}
                                                            setisAddMoreGrowthAreaOpen={
                                                                setisAddMoreGrowthAreaOpen
                                                            }
                                                            goalName={goalName}
                                                            expanded={expanded}
                                                            imageCompo={imageCompo}
                                                        />
                                                    </Box>
                                                    <Box className={classes.divider}></Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        padding: '20px',
                                                        margin: '0 24px',
                                                        alignSelf: 'stretch',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        gap: '12px',
                                                        borderRadius: '20px',
                                                        background: '#F9F9F9',
                                                    }}>
                                                        <ActionAccordian
                                                            actions={actions}
                                                            actionRecommended={actionRecommended}
                                                            actionsArchived={actionsArchived}
                                                            selectedQuestionCategoriesId={
                                                                selectedQuestionCategoriesId
                                                            }
                                                            goalName={goalName}
                                                            questionCategoryId={questionCategoryId}
                                                            expanded={expanded}
                                                            imageCompo={imageCompo}

                                                        />
                                                    </Box>
                                                    <Box className={classes.divider}></Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        padding: '20px',
                                                        margin: '0 24px',
                                                        alignSelf: 'stretch',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        gap: '12px',
                                                        borderRadius: '20px',
                                                        background: '#F9F9F9',
                                                    }}>
                                                        <HabitAccordian
                                                            habits={habits}
                                                            habitsArchived={habitsArchived}
                                                            goalName={goalName}
                                                            selectedQuestionCategoriesId={
                                                                selectedQuestionCategoriesId
                                                            }
                                                            imageCompo={imageCompo}

                                                        />
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        gap: '12px',
                                                        alignSelf: 'stretch',
                                                        margin: '0 24px 20px',
                                                        borderRadius: '20px',
                                                        background: '#FFF',
                                                    }}>
                                                        <ButtonGroupNew
                                                            nextButtonText="Complete goal"
                                                            previousButtonText="Archive goal"
                                                            isPrevDisable={false}
                                                            onClickNext={() => {
                                                                console.log("goalData allPreSelectedState", allPreSelectedState);
                                                                console.log("goalData  ActiondataSlice?.allactionData", ActiondataSlice?.allactionData);
                                                                console.log("goalData  HabitDataSlice?.allhabitCalenders", HabitDataSlice?.allhabitCalenders);
                                                                allPreSelectedState?.length === 0 &&
                                                                    ActiondataSlice?.allactionData?.length === 0 &&
                                                                    HabitDataSlice?.allhabitCalenders?.length === 0
                                                                    ? completeDataHandler(questionCategory)
                                                                    : setIsCompletedArchiveOpen(true);
                                                            }}
                                                            onClickPrevious={() => {
                                                                allPreSelectedState?.length === 0 &&
                                                                    ActiondataSlice?.allactionData?.length === 0 &&
                                                                    HabitDataSlice?.allhabitCalenders?.length === 0
                                                                    ? archiveDataHandler()
                                                                    : setisArchieveOpen(true);
                                                            }}
                                                        />
                                                    </Box>

                                                </Box>


                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <div
                        className={classes.createMoreGoalWrapper}
                        onClick={() => {
                            setIsAskOtherModalOpen(true);
                        }}
                    >
                        <img style={{ margin: "9px" }} src={PlusLargeIcon} alt="" />
                    </div>
                </div >
            )
            }
            <Dialog
                open={isCalenderOpen}
                onClose={() => {
                    setIsCalenderOpen(false);
                }}
                sx={{
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "388px",
                        height: "361px",
                        position: "unset",
                        padding: "20px",
                        borderRadius: "20px",
                        overflow: "hidden",
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CalanderGoal
                    goalName={goalName}
                    setschedualDate={setschedualDate}
                    setIsCalenderOpen={setIsCalenderOpen}
                    schedualDate={schedualDate}
                    selectedQuestionCategoriesId={selectedQuestionCategoriesId}
                    tbdDate={tbdDate}
                    setTbdDate={setTbdDate}
                />
            </Dialog>

            <AddNewgoalModal
                setCustomNotification={setCustomNotification} />
            {
                isCompletedArchiveOpen && (
                    <CompletedGoalDialog
                        isCompletedArchiveOpen={isCompletedArchiveOpen}
                        setIsCompletedArchiveOpen={setIsCompletedArchiveOpen}
                        preSelectedState={preSelectedState}
                        goalName={goalName}
                        setIsCompletedGoal={setIsCompletedGoal}
                        completeDataHandler={completeDataHandler}
                    />
                )
            }
            {
                isArchieveOpen && (
                    <ArchiveGoalDialog
                        setisArchieveOpen={setisArchieveOpen}
                        isArchieveOpen={isArchieveOpen}
                        preSelectedState={preSelectedState}
                        goalName={goalName}
                        setIsGoalArchive={setIsGoalArchive}
                        archiveDataHandler={archiveDataHandler}
                    />
                )
            }
            {
                customNotification && (
                    <NotificationContentJSX
                        message={notificationmesage}
                        isThumbIcon={false}
                        position="top"
                    />
                )
            }
        </>
    );
}
export default GoalList;
