import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { createRef, useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import classes from "./GoalList.module.css"
import WinningCel from "./../../assets/Svg/WinningCel.svg"
import { useDispatch, useSelector } from "react-redux";
import { updateGoalData } from "../../Store/Reducers/goalDataSlice";
const CelebrationModal = (props) => {
  const [value, setInputValue] = useState("");
  const textInput = createRef(null);
  const { oprnCongratsModal, createdgoalName, completeModal } = useSelector(
    (state) => state.goalData
  );
  const dispatch = useDispatch()
  const openCloseCelebrationModal = () => {
    dispatch(updateGoalData({
      oprnCongratsModal: false,
      createdgoalName: "",
      completeModal: false,
    }))
  }
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const confettiRef = useRef(null);
  useEffect(() => {
    setHeight(confettiRef?.current?.clientHeight);
    setWidth(confettiRef?.current?.clientWidth);
  }, []);

  return (
    <Dialog
      open={oprnCongratsModal}
      // open={true}
      onClose={() => {
        openCloseCelebrationModal(false);
      }}
      PaperProps={{
        style: {
          borderRadius: "20px",
          position: "unset",
          padding: "0",
          width: 'calc(100vw - 40%)',
          height: 'calc(100vh - 15%)'
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          // width: "100%",


        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ position: 'relative', height: '100%' }} ref={confettiRef}>
        <div style={{
          display: "flex", position: 'absolute', justifyContent: "space-between", right: '20px',
          top: '5px', cursor: 'pointer',
          zIndex: 1

        }} onClick={() => {
          openCloseCelebrationModal(false);
        }}>
          {/* <div
            className={classes.closeIcon}

          >
            x
          </div> */}
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <Confetti width={width} height={height} opacity={0.4} />
          <div className={classes.cel_title}>
            
            {/* <div className={classes.cel_title_icon}>
            <img src={

              WinningCel} alt="" />
          </div> */}
            <Typography
              variant="caption"
              component="div"
              className={classes.cel_info_text}
            >
              {completeModal ? `Congrats! You’ve completed your goal for ${createdgoalName}` : `Congrats! You’ve created a goal for ${createdgoalName}`}

            </Typography>
          </div>
          <div className={classes.cel_info}>

            <LoadingButton
              variant="contained"
              className={classes.cel_info_button}
              onClick={(e) => {
                openCloseCelebrationModal(false);
              }}
            >
              Great!
            </LoadingButton>
          </div>
        </div>

      </div>

    </Dialog>
  );
};

export default CelebrationModal;
