import { Box, Button, Dialog, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setStoreData } from "../../Store/Reducers/plans";
import classes from "./plans.module.css"
// import Step1Day from "./Step1Days";
// import Step2PlanInfo from "./Step2PlanInfo";
// import Step3PlanDetails from "./Step3PlanDetails";
// import { useEffect } from "react";
// import PlansServices from "../../../services/Plans";
// import Step3JournalEntry from "./Step3JournalEntry";
// import CelebrationModal from "./CelebrationModal";
// import useCustomNotification from "../../../Hooks/useCustomNotification";
import { GeneralSX } from "../../components/Text/Text";
import palette from "../../theme/palette";
import PlansServices from "../../services/Plans";
import { useEffect, useState } from "react";
const PlanModalConfirmation = ({ selectedPlan }) => {
    const { userData } = useSelector((state) => state.userProfile);
    const { planId, userPlansData = [] } = selectedPlan
    const { status = "active", _id } = (userPlansData[0] || {})
    const { viewPlanConfirmationModal, setp } = useSelector((state) => state.planData);
    const [alreadyExistPlan, setAlreadyExistPlan] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        if(!viewPlanConfirmationModal) {
            setAlreadyExistPlan(null)
        }
    }, [viewPlanConfirmationModal])
    const freezeUnfreeze = () => {

        if (alreadyExistPlan && alreadyExistPlan._id) {
            PlansServices.freeseUnfreezePlan({
                "uid": userData?.uid,
                "id": alreadyExistPlan._id,
                "status": "freeze"
            }).then(res => {
                PlansServices.freeseUnfreezePlan({
                    "uid": userData?.uid,
                    "id": _id,
                    "status": "active"
                }).then(res => {
                    let copyRes = { ...selectedPlan };
                    copyRes = {
                        ...copyRes,
                        userPlansData: [
                            {
                                ...(userPlansData[0] || {}),
                                status: "active"
                            }
                        ]
                    }
                    setAlreadyExistPlan(null)
                    dispatch(setStoreData({
                        selectedPlan: copyRes,
                        viewPlanConfirmationModal: false,
                        isForUnfreeze: false,
                        isForSecondFreeze: false
                    }));
                })
            })
        } else {
            PlansServices.freeseUnfreezePlan({
                "uid": userData?.uid,
                "id": _id,
                "status": status === "active" ? "freeze" : "active"
            }).then(res => {
                let copyRes = { ...selectedPlan };
                copyRes = {
                    ...copyRes,
                    userPlansData: [
                        {
                            ...(userPlansData[0] || {}),
                            status: status === "active" ? "freeze" : "active"
                        }
                    ]
                }
                setAlreadyExistPlan(null)
                dispatch(setStoreData({
                    selectedPlan: copyRes,
                    viewPlanConfirmationModal: false,
                    isForUnfreeze: false,
                    isForSecondFreeze: false
                }));
            }).catch(err => {
                console.log("err.response && err.response.data && err.response.data === ALREADY_EXISTS && err.response.data.data.length", err.response, err.response.data,err.response.code, err.response && err.response.data && err.response.data.code === "ALREADY_EXISTS" && err.response.data.data.length)
                if (err.response && err.response.data && err.response.data.code === "ALREADY_EXISTS" && err.response.data.data.length) {
                    setAlreadyExistPlan(err.response.data.data[0])
                }
                console.log("err", err)
            })
        }

    }
    console.log("alreadyExistPlan", alreadyExistPlan)
    const {plansData = []} = (alreadyExistPlan || {})
    return <><Dialog
        open={viewPlanConfirmationModal}
        onClose={() => {
            dispatch(setStoreData({
                viewPlanConfirmationModal: false,
                isForUnfreeze: false,
                isForSecondFreeze: false
            }));
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
            "& .MuiPaper-root": {
                maxWidth: "40%",
                borderRadius: "20px",
                position: "relative",
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '24px',
                flexShrink: 0,
                justifyContent: 'center'
            },
        }}
    >
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} gap={"8px"} alignSelf={"stretch"}>
            <Typography sx={{
                ...GeneralSX({
                    color: palette.common.mainBlack,
                    fontSize: 18,
                    fontWeight: 500,
                    lineHeight: "145%",
                    letterSpacing: '-0.18px'
                }),
                textAlign: 'center'
            }}>
                {alreadyExistPlan && alreadyExistPlan._id ? `You can only work on one plan at a time. Would you like to freeze ${plansData[0].name} and work on ${selectedPlan.name} instead?` : `You can always come back to this plan and continue your work. Do you want to ${status === "freeze" ? "unfreeze" : "freeze"} ${selectedPlan.name}?`}
            </Typography>
        </Box>

        <div className={classes.flexbuttonbox}>
            {alreadyExistPlan && alreadyExistPlan._id ? (
                <Button variant="outlined" className={classes.outlinedButton} sx={{
                    width: '100%'
                }} onClick={() => {
                    dispatch(setStoreData({
                        viewPlanConfirmationModal: false,
                        isForUnfreeze: false,
                        isForSecondFreeze: false
                    }));
                }}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.primary.main,
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "100%",
                            letterSpacing: '-0.16px'
                        })
                    }}>Cancel</Typography>
                </Button>
            ) : (
                <Button variant="outlined" className={classes.outlinedButton} sx={{
                    width: '100%'
                }} onClick={freezeUnfreeze}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.primary.main,
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "100%",
                            letterSpacing: '-0.16px'
                        })
                    }}>{status === "freeze" ? "Unfreeze" : "Freeze"}</Typography>
                </Button>
            )}
            {alreadyExistPlan && alreadyExistPlan._id ? (
                <Button variant="outlined" className={classes.buttonPlan} sx={{
                    width: '100%'
                }} onClick={freezeUnfreeze}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.common.white,
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "100%",
                            letterSpacing: '-0.16px'
                        })
                    }}>Freeze & work</Typography>
                </Button>
            ) : (
                <Button variant="outlined" className={classes.buttonPlan} sx={{
                    width: '100%'
                }} onClick={() => {
                    dispatch(setStoreData({
                        viewPlanConfirmationModal: false,
                        isForUnfreeze: false,
                        isForSecondFreeze: false
                    }));
                }}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.common.white,
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "100%",
                            letterSpacing: '-0.16px'
                        })
                    }}>Keep working</Typography>
                </Button>
            )}

        </div>
    </Dialog >

    </>
}

export default PlanModalConfirmation