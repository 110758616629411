import axios from "axios";
import { baseEndpoints } from "../config";

class PlansServices {
    static PLANS_TAGS = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLANS.TAGS}`, updateActionHabit)
            .then((response) => {
                return response;
            });
        return data;
    }

    static PLANS = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLANS.LIST}`, updateActionHabit)
            .then((response) => {
                return response;
            });
        return data;
    }

    static planActionPlanId = async (planActionObject) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLAN_ACTIN.ACTION_BYID}`, planActionObject)
            .then((response) => {
                return response;
            });
        return data;
    }

    static selectPlan = async (planActionObject) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLANS.SELECT_PLAN}`, planActionObject)
            .then((response) => {
                return response;
            });
        return data;
    }

    static updateJournal = async (journalObject) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLAN_ACTIN.UPDATE_JOURNAL}`, journalObject)
            .then((response) => {
                return response;
            });
        return data;
    }

    static updateActionStatus = async (actionObject) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLAN_ACTIN.UPDATE_STATUS}`, actionObject)
            .then((response) => {
                return response;
            });
        return data;
    }

    static planQuoteRead = async (obj) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLAN_ACTIN.QUOTE_READ}`, obj)
            .then((response) => {
                return response;
            });
        return data;
    }

    static freeseUnfreezePlan = async (obj) => {
        const { data } = await axios
            .post(`${baseEndpoints.PLANS.STATUS_UPDATE}`, obj)
            .then((response) => {
                return response;
            });
        return data;
    }
}

export default PlansServices