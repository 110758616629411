import { Chip } from "@mui/material"
import classes from "./ChipButton.module.css";
 const ChipSaveButton = (props) => {
    const { onSave, title = 'Save', style = {}, type = "outlined" } = props
    return (
        <Chip
            label={title}
            onClick={onSave}
            style={style}
            className={type === "contained" ? classes.backgroundChipContained  : classes.backgroundChip}
        />
    )
}

export default ChipSaveButton