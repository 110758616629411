import styled from "@emotion/styled";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import { SubmitButtonWithBackGround } from "../../../../components/Button/Button";
import ButtonGroup from "../../../../components/ButtonGroup/ButtonGroup";
import {
  CheckBoxInput,
  CheckBoxInputChecked,
  CheckBoxInputCheckedNew,
} from "../../../../components/Input/Input";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { FEEDBACKTYPE } from "../../../../Constant/Invite";
import useCustomCheckBox from "../../../../Hooks/useCustomCheckBox";
import typography from "../../../../theme/typography";
import classes from "./StepOne.module.css";
import HTMLTooltip from "../../../../components/ToolTipWithIcon/HTMLTooltip";
const StepOne = (props) => {
  const { firstCheckBox, secondCheckBox, handleChange } = useCustomCheckBox(
    FEEDBACKTYPE?.BASED_ON_AVERAGE_POPULATION,
    FEEDBACKTYPE?.BASED_ON_PERSONAL_STANDARDS,
    props?.inviteData?.feedbackType
  );

  // const [first, setfirst] = useState(second)
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;
  const updateMainState = () => {
    if (firstCheckBox) {
      props.updateInviteData(
        "feedbackType",
        FEEDBACKTYPE?.BASED_ON_AVERAGE_POPULATION
      );
    } else {
      props.updateInviteData(
        "feedbackType",
        FEEDBACKTYPE?.BASED_ON_PERSONAL_STANDARDS
      );
    }
    props.onNext();
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div className={classes.stepOneWrapper}>
      <ProgressBar progress={props.progress} />
      <div className={classes.middleContent}>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Invite other people to test for you
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", mt: "32px" }}
        >
          Select feedback type
        </Typography>
        <div className="option">
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={firstCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="Based on average population" sx={{
                '& .MuiOutlinedInput-input': {
                  textAlign: "center"
                }
              }} extraInput={
                <HTMLTooltip
                  title={"The invitee will be asked to rate you based on their understanding of the average population. Usually people interpret this as selecting the middle point to indicate that you are not especially low or high on a particular characteristic."}
                  placement="right"
                  showArrow={true}
                  sx={{
                    '& .MuiTooltip-tooltip': {
                      background: "#FFF",
                      color: "#000",
                      maxWidth: "300px"
                    }
                  }}
                />}/>}
              onChange={() => {
                handleChange(FEEDBACKTYPE?.BASED_ON_AVERAGE_POPULATION);
              }}
              checkedIcon={
                <CheckBoxInputCheckedNew placeholder="Based on average population" sx={{
                  '& .MuiOutlinedInput-input': {
                    textAlign: "center"
                  }
                }} extraInput={
                  <HTMLTooltip
                    title={"The invitee will be asked to rate you based on their understanding of the average population. Usually people interpret this as selecting the middle point to indicate that you are not especially low or high on a particular characteristic."}
                    placement="right"
                    showArrow={true}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        background: "#FFF",
                        color: "#000",
                        maxWidth: "300px"
                      }
                    }}
                  />}/>
              }
            />
          </Box>
          <Box className={classes.wrapper}>
            <CustomCheckBox
              checked={secondCheckBox}
              {...label}
              icon={<CheckBoxInput placeholder="Based on personal standards" sx={{
                '& .MuiOutlinedInput-input': {
                  textAlign: "center"
                }
              }} extraInput={
                <HTMLTooltip
                  title={"Instead of selecting the average as the middle point, the invitee will be asked to rate you on their understanding of the maximum score. Here, average could be rated as a low scale point."}
                  placement="right"
                  showArrow={true}
                  sx={{
                    '& .MuiTooltip-tooltip': {
                      background: "#FFF",
                      color: "#000",
                      maxWidth: "300px"
                    }
                  }}
                />} />}
              onChange={() => {
                handleChange(FEEDBACKTYPE?.BASED_ON_PERSONAL_STANDARDS);
              }}
              checkedIcon={
                <CheckBoxInputCheckedNew placeholder="Based on personal standards" sx={{
                  '& .MuiOutlinedInput-input': {
                    textAlign: "center !important"
                  }
                }} extraInput={
                  <HTMLTooltip
                    title={"Instead of selecting the average as the middle point, the invitee will be asked to rate you on their understanding of the maximum score. Here, average could be rated as a low scale point."}
                    placement="right"
                    showArrow={true}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        background: "#FFF",
                        color: "#000",
                        maxWidth: "300px"
                      }
                    }}
                  />}/>
              }
            />
          </Box>
        </div>
      </div>
      <div className={classes.bottomContainerWrapper}>
        {/* <ButtonGroup {...props} updateMainState={updateMainState} isNextDisable={!firstCheckBox && !secondCheckBox} isPreviousBtnDisable={true} /> */}
        <SubmitButtonWithBackGround
          buttonText="Next"
          onClick={updateMainState}
          isDisabled={!firstCheckBox && !secondCheckBox}
        />
      </div>
    </div>
  );
};

export default StepOne;
