import axios from "axios";
import { baseEndpoints } from "../config";

class JournalServices {
    static GetJournalData = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.JOURNAL_DATA}`, updateActionHabit)
            .then((response) => {
                return response;
            });
        return data;
    }

    static GetJournalFilter = async (updateActionHabit) => {
        const { data } = await axios
            .post(`${baseEndpoints.DASHBOARD.JOURNAL_FILTER_DATA}`, updateActionHabit)
            .then((response) => {
                return response;
            });
        return data;
    }
}

export default JournalServices