import React, { useState, useEffect } from "react";
import classes from "./InviateTestLayout.module.css";
import { Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import CloseIcon from "../../assets/Svg/CloseIcon.svg";
import Flag from "../../assets/Svg/Flag.svg";
import AlertModal from "../../components/AlertModal/AlertModal";
import InvitePage from "../../pages/InviteFlow/InvitePage/InvitePage";
import AuthOption from "../../pages/InviteFlow/AuthOption/AuthOption";
import NameOption from "../../pages/InviteFlow/NameOption/NameOption";
import GetName from "../../pages/InviteFlow/GetName/GetName";
import GetAge from "../../pages/InviteFlow/GetAge/GetAge";
import StartTest from "../../pages/InviteFlow/StartTest/StartTest";
import ResultPage from "../../pages/InviteFlow/ResultPage/ResultPage";
import TestPage from "../../pages/InviteFlow/InviteTestPage/InviteTestPage";
import ReflectiveQuestion from "../../pages/InviteFlow/ReflectiveQuestion/ReflectiveQuestion";
import GetGender from "../../pages/InviteFlow/GetGender/GetGender";
import CongratsPage from "../../pages/InviteFlow/CongratsPage/CongratsPage";
import Login from "../../pages/Login/Login";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getInvite } from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { updateInviteData } from "../../Store/Reducers/inviteData";
import { FEEDBACKTYPE } from "../../Constant/Invite";
import HTMLTooltip from "../../components/ToolTipWithIcon/HTMLTooltip";

const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  // display: "block",
  // paddingBlock: "2rem",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  height: "100vh",
  minHeight: "100vh",
  position: "relative",
}));

const CardContent = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: "40px 118px",
  // [theme.breakpoints.up("xl")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  [theme.breakpoints.down("md")]: {
    padding: "40px",
  },
  // background: 'yellow',
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 7rem)", // aahi 7rem aave
    with: "100%",
    padding: "12px",
  },
}));

const MainCard = styled(Box)(({ theme }) => ({
  background: "#fff",
  width: "692px",
  height: "740px",
  minWidth: "250px",
  borderRadius: "40px",
  // minHeight: "calc(100vh - 4rem)",
  position: "relative",
  [theme.breakpoints.up("xl")]: {
    width: "692px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "692px",
  },
  [theme.breakpoints.down("md")]: {
    width: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 7rem)", // aahi pan 7 rem aave
    width: "100%",
    borderRadius: "20px",
  },
}));

const CloseIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "20px",
  right: "20px",
  zIndex: "100",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    top: "7px",
    right: "10px",
  },
}));

const FlagIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "20px",
  right: "20px",
  zIndex: "100",
  // [theme.breakpoints.up("xl")]: {
  // },
  // [theme.breakpoints.down("lg")]: {
  // },
  [theme.breakpoints.down("md")]: {
    bottom: "0px",
    right: "7px",
  },
  // [theme.breakpoints.down("sm")]: {
  // },
}));

const InviateTestLayout = () => {
  const [openAlert, setOpenAlert] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  console.log("matches", matches);
  const dispatch = useDispatch();
  const {
    inviteDataSlice: { inviteData },
  } = useSelector((state) => state);
  useEffect(() => {
    const inviteID = localStorage.getItem("inviteId");
    console.log("inviteID = ", inviteID);
    getInvite({ inviteID })
      .then((inviteData) => {
        dispatch(updateInviteData(inviteData?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  // const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  // console.log("useMediaQuery",matches)

  // <InvitePage />
  // <AuthOption />
  // <NameOption />
  // <GetName />
  // <GetAge />

  // for desktop
  // <div className={classes.invitedPersonName}>
  //         <div className={classes.personName}>
  //           <Typography variant="small" color="#434243">
  //             Invited by
  //           </Typography>
  //           <Typography
  //             variant="body2"
  //             color="#190D1A"
  //             sx={{ marginTop: "8px" }}
  //           >
  //             Deni Cv
  //           </Typography>
  //         </div>
  //         <Divider className={classes.divider} />
  //         <div className={classes.feedBackTypeWrapper}>
  //           <Typography variant="small" color="#434243">
  //             Feedback type
  //           </Typography>
  //           <div className={classes.feedBackType}>
  //             <div className={classes.dot}></div>
  //             <Typography variant="body2" color="#9747FF">
  //               Average population
  //             </Typography>
  //           </div>
  //         </div>
  //       </div>

  // for mobile
  //   <div className={classes.invitedPersonNameMobileDevice}>
  //   <div className={classes.personNameMobile}>
  //     <Typography variant="small" color="#434243">
  //       Invited by
  //     </Typography>
  //     <Typography
  //       variant="body2"
  //       color="#190D1A"
  //       sx={{ marginTop: "8px" }}
  //     >
  //       Deni Cv
  //     </Typography>
  //   </div>
  //   <div className={classes.feedBackTypeWrapperMobile}>
  //     <Typography variant="small" color="#434243">
  //       Feedback type
  //     </Typography>
  //     <div className={classes.feedBackType}>
  //       <div className={classes.dot}></div>
  //       <Typography variant="body2" color="#9747FF">
  //         Average population
  //       </Typography>
  //     </div>
  //   </div>
  // </div>
  return (
    <>
      <RootStyle className={classes.main_wrapper}>
        {/* put for desktop here */}
        {matches ? (
          <div className={classes.invitedPersonNameMobileDevice}>
            <div className={classes.personNameMobile}>
              <Typography variant="small" color="#434243">
                Invited by
              </Typography>
              <Typography
                variant="body2"
                color="#190D1A"
                sx={{ marginTop: "8px" }}
              >
                {inviteData?.name ? inviteData?.name : "Guest"}
              </Typography>
            </div>
            <div className={classes.feedBackTypeWrapperMobile}>
              <div style={{ display: "grid", gridTemplateColumns: '0.6fr 0.4fr', width: "100%", alignItems: "center", justifyContent: "stretch" }}>
                <Typography
                  variant="small"
                  color="#434243"
                  sx={{ justifySelf: "end" }}
                >
                  Feedback type
                </Typography>
                <div style={{ position: "relative", justifySelf: "end" }}>
                  <HTMLTooltip
                    title={"The invitee will be asked to rate you based on their understanding of the average population. Usually people interpret this as selecting the middle point to indicate that you are not especially low or high on a particular characteristic."}
                    placement="right"
                    showArrow={true}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        background: "#FFF",
                        color: "#000",
                        maxWidth: "350px"
                      }
                    }}
                  />
                </div>

              </div>
              <div className={classes.feedBackType}>
                <div className={classes.dot}></div>
                <Typography variant="body2" color="#9747FF">
                  {inviteData?.feedbackType === FEEDBACKTYPE.BASED_ON_AVERAGE_POPULATION ? "Average population" : "Personal standards"}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.invitedPersonName}>
            <div className={classes.personName}>
              <Typography variant="small" color="#434243">
                Invited by
              </Typography>
              <Typography
                variant="body2"
                color="#190D1A"
                sx={{ marginTop: "8px" }}
              >
                {inviteData?.name ? inviteData?.name : "Guest"}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.feedBackTypeWrapper}>
            <div style={{ display: "grid", gridTemplateColumns: '0.6fr 0.4fr', width: "100%", alignItems: "center", justifyContent: "stretch" }}>
                <Typography
                  variant="small"
                  color="#434243"
                  sx={{ justifySelf: "start" }}
                >
                  Feedback type
                </Typography>
                <div style={{ position: "relative", justifySelf: "end" }}>
                  <HTMLTooltip
                    title={"The invitee will be asked to rate you based on their understanding of the average population. Usually people interpret this as selecting the middle point to indicate that you are not especially low or high on a particular characteristic."}
                    placement="right"
                    showArrow={true}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        background: "#FFF",
                        color: "#000",
                        maxWidth: "350px"
                      }
                    }}
                  />
                </div>

              </div>
              {/* <Typography variant="small" color="#434243">
                Feedback type
              </Typography> */}
              <div className={classes.feedBackType}>
                <div className={classes.dot}></div>
                <Typography variant="body2" color="#9747FF">
                  {inviteData?.feedbackType === FEEDBACKTYPE.BASED_ON_AVERAGE_POPULATION ? "Average population" : "Personal standards"}
                </Typography>
              </div>
            </div>
          </div>
        )}
        {/*
        <CloseIconWrapper onClick={handleClickOpen}>
          <img src={CloseIcon} alt="" />
        </CloseIconWrapper>
        <FlagIconWrapper>
          <img src={Flag} alt="" />
        </FlagIconWrapper> */}

        {/* put for mobile here */}
        <MainCard
        // sx={{
        //   minWidth: 250,
        //   borderRadius: "40px",
        //   // marginInline: "auto",
        // }}
        >
          <CardContent>
            {/* <AuthOption /> */}
            {/* <InvitePage /> */}
            {/* <NameOption /> */}
            {/*  <GetName />  */}
            {/* <GetAge /> */}
            {/* <ResultPage /> */}
            {/* <TestPage /> */}
            {/* <ReflectiveQuestion /> */}
            {/* <StartTest /> */}
            {/* <GetGender /> */}
            {/* <CongratsPage /> */}
            {/* <Login /> */}
            <Outlet />
          </CardContent>
        </MainCard>
        <AlertModal open={openAlert} handleClose={handleClose} />
      </RootStyle>
    </>
  );
};

export default InviateTestLayout;
