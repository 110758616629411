import { Avatar, Box, Button, Checkbox, Chip, Dialog, FormControlLabel, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { AllDiamentionByTest, ResultAllTestDiamention, questionCategoryCreateFavorite, questionCategoryDeleteFavorite, unlockGAAction } from "../../services/auth";
import { pxToRem } from "../../theme/typography";
import { GeneralSX } from "../../components/Text/Text";
import classes from './GoalFavouriteModal.module.css'
import clsx from "clsx";
import XP from "../../assets/Svg/XP.svg";
import RadioIcon from "../../assets/Svg/RadioIcon.svg";
import RadioCheckedIcon from "../../assets/Svg/RadioCheckedIcon.svg";
import parse from "html-react-parser";
import { randomXP } from "../../utils";
import Coin from "../../assets/Svg/Coin.svg";
import { useDispatch } from "react-redux";
import { updateUserWalletCoins } from "../../Store/Reducers/userProfileSlice";
import useNotifications from "../../Hooks/useNotifications";

const GoalFavouriteModal = ({ visible, onClose, isGrowth = true, isCreate = true, questionCategoryId, goalName }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [foundResult, setFoundResult] = useState({})
    const [growthAreaActionData, setGrwothAreaActionData] = useState([])
    const [growthFavorites, setGrwothAreaFavouriutes] = useState([])
    const [AllgrowthFavorites, setAllGrwothAreaFavouriutes] = useState([])
    const [actionFavorites, setActionFavouriutes] = useState([])
    const [AllactionFavorites, setAllActionFavouriutes] = useState([])
    const [growthAreaData, setgrowthAreaData] = useState([])
    const [progressData, setprogressData] = useState([])
    const [tempActionArea, settempActionArea] = useState([])
    const [tempSelectedAction, settempSelectedAction] = useState([])
    const [tempDeletedAction, settempDeletedAction] = useState([])
    const [tempGrowhtArea, settempGrowhtArea] = useState([])
    const [tempSelectedGrowthArea, settempSelectedGrowthArea] = useState([])
    const [tempDeletedGrowthArea, settempDeletedGrowthArea] = useState([])
    const [gacount, setgacount] = useState([])
    const [actionCount, setActionCount] = useState([])
    const { Notification, updateNotificationState } = useNotifications(false);
    console.log("foundResult", foundResult);
    useEffect(() => {
        setLoading(true)
        allDimentionByTest()
    }, [])
    function renderGrowthFavorites(growthArea, growthFav, enable) {
        growthArea.filter(function (o1) {
            growthFav.map((item, index) => {
                if (index === enable) {
                    item.some(function (o2) {
                        if (o1.text === o2.point) {
                            return (o1.isChecked = true);
                        } else {
                            return (o1.isChecked = false);
                        }
                    });
                }
                return item
            });
        });
    }

    function renderActionFavorites(actionArea, actionFavorites, enable) {
        actionArea.filter(function (o1) {
            actionFavorites.map((item, index) => {
                if (index === enable) {
                    item.some(function (o2) {
                        if (o1.text === o2.point) {
                            return (o1.isChecked = true);
                        } else {
                            return (o1.isChecked = false);
                        }
                    });
                }
            });
        });
    }
    const UnlockGAActionType = (type) => {
        const newfoundResult = { ...foundResult }

        const object = {
            uid: localStorage.getItem("userDetail"),
            questionCategoryId: newfoundResult.questionCategoryId,
            percent: newfoundResult.data[0].percent,
            type: type,
        };
        UnlockGAAction(type, object);
    };
    const updateModalData = (data) => {
        if (data.growth) {
            const modelInfo = { ...foundResult };
            console.log("modelInfo", modelInfo);
            if (
                modelInfo.data[0].unlockgrowthandactions &&
                modelInfo.data[0].unlockgrowthandactions[data.idx]
            ) {
                modelInfo.data[0].unlockgrowthandactions[0] = {
                    ...modelInfo.data[0].unlockgrowthandactions[0],
                    growth: modelInfo.data[0].unlockgrowthandactions[0].growth + 3,
                };
            }
            setFoundResult(modelInfo)
            setgacount(gacount + 3)
        }
        if (data.action) {
            const modelInfo = { ...foundResult };
            console.log("modelInfo", modelInfo);
            if (
                modelInfo.data[0].unlockgrowthandactions &&
                modelInfo.data[0].unlockgrowthandactions[data.idx]
            ) {
                modelInfo.data[0].unlockgrowthandactions[0] = {
                    ...modelInfo.data[0].unlockgrowthandactions[0],
                    action: modelInfo.data[0].unlockgrowthandactions[0].action + 3,
                };
            }
            setFoundResult(modelInfo)
            setActionCount(actionCount + 3)
        }
    }
    const UnlockGAAction = (type, object) => {
        unlockGAAction(object).then(data => {
            if (type === "GROWTH") {
                updateModalData({
                    growth: true,
                    idx: 0
                })
                dispatch(updateUserWalletCoins())

                // const modelInfo = {...modalData};
                // console.log("modelInfo", modelInfo);
                // modelInfo.data[0].unlockgrowthandactions[0] = {
                //   ...modelInfo.data[0].unlockgrowthandactions[0],
                //   growth: modelInfo.data[0].unlockgrowthandactions[0].growth + 3
                // }
                // setModalData(modelInfo)
                // dispatch(updateResultState({
                //   actionCount: growthCount + 3
                // }))
            }
            if (type === "ACTION") {

                dispatch(updateModalData({
                    action: true,
                    idx: 0
                }))
                dispatch(updateUserWalletCoins())
                // dispatch(updateResultState({
                //   actionCount: actionCount + 3
                // }))
                // const modelInfo = {...modalData};
                // modelInfo.data[0].unlockgrowthandactions[0] = {
                //   ...modelInfo.data[0].unlockgrowthandactions[0],
                //   action: modelInfo.data[0].unlockgrowthandactions[0].action + 3
                // }
                // setModalData(modelInfo)
            }
        }).catch(err => {
            if (err.response.data.description) {
                updateNotificationState(err.response.data.description);
                setTimeout(() => {
                    updateNotificationState("", false);
                }, 2000);
                // setCustomNotification(err.response.data.description);
                // setCustomNotification(true);

            }
        }).finally(() => {

        })
    }
    const handleChangeProgressArea = (data) => {
        const newProgressData = [...progressData]
        const newtempActionArea = [...tempActionArea]
        const newfoundResult = { ...foundResult }
        let newactionFavorites = [...actionFavorites]
        let newtempSelectedAction = [...tempSelectedAction]
        let newtempDeletedAction = [...tempDeletedAction]
        if (!data.isChecked) {

            const foundIndexVal = newProgressData.findIndex(item => item.text === data.value)
            if (foundIndexVal > -1) {
                newProgressData[foundIndexVal].isChecked = true;
                setprogressData(newProgressData)
                const findActionArea = newtempActionArea.find(
                    (ele) =>
                        ele.point === data.value /* && ele.uniqueId === state.selectedScore */
                );
                console.log("findActionArea ===1", findActionArea);
                if (findActionArea) {
                    newtempActionArea.map((data) => {
                        if (data.point === data.value && !data.isChecked) {
                            data.isChecked = true;
                        }
                        return data;
                    });
                } else {
                    newtempActionArea.push({
                        uniqueId: newProgressData[foundIndexVal].uniqueId,
                        point: newProgressData[foundIndexVal].text,
                        isChecked: newProgressData[foundIndexVal].isChecked,
                    });
                }
            }
            newtempSelectedAction = [
                ...newtempSelectedAction,
                { point: data.value },
            ]
            newactionFavorites = newactionFavorites.map(it => {
                it = [
                    ...it,
                    { point: data.value },
                ]
                return it
            })
            newfoundResult.data = newfoundResult.data.map(it => {
                it.actionFavorites = [
                    ...it.actionFavorites,
                    { uniqueId: Math.random(), point: data.value },
                ]
                return it
            })
        } else {
            const foundIndexVal = newProgressData.findIndex(item => item.text === data.value)
            if (foundIndexVal > -1) {
                newProgressData[foundIndexVal].isChecked = false
                const findActionArea = newtempActionArea.find(
                    (ele) =>
                        ele.point === data.value /* && ele.uniqueId === state.selectedScore */
                );
                console.log("findActionArea", findActionArea);
                if (findActionArea) {
                    newtempActionArea.map((data) => {
                        if (data.point === data.value && data.isChecked) {
                            data.isChecked = false;
                        }
                        return data;
                    });
                } else {
                    newtempActionArea.push({
                        uniqueId: newProgressData[foundIndexVal].uniqueId,
                        point: newProgressData[foundIndexVal].text,
                        isChecked: newProgressData[foundIndexVal].isChecked,
                    });
                }
            }
            newtempDeletedAction = [
                ...newtempDeletedAction,
                { point: data.value },
            ]
            newactionFavorites = newactionFavorites.map(it => {
                it = it.filter(ite => ite.point !== data.value)
                return it
            })
            newfoundResult.data = newfoundResult.data.map(it => {
                it.actionFavorites = it.actionFavorites.filter(ite1 => ite1.point !== data.value)
                return it
            })
        }
        setprogressData(newProgressData)
        settempActionArea(newtempActionArea)
        setFoundResult(newfoundResult)
        setActionFavouriutes(newactionFavorites)
        settempSelectedAction(newtempSelectedAction)
        settempDeletedAction(newtempDeletedAction)
    }
    const handleChangeGrowthArea = (data) => {
        let newgrowthAreaData = [...growthAreaData]
        const newfoundResult = { ...foundResult }
        let newtempGrowhtArea = [...tempGrowhtArea]
        let newtempSelectedGrowthArea = [...tempSelectedGrowthArea]
        let newgrowthFavorites = [...growthFavorites]
        let newtempDeletedGrowthArea = [...tempDeletedGrowthArea]
        if (!data.isChecked) {
            const tempGrowthAreaCreate = [...newgrowthAreaData].map((item, index) => {
                const newItem = { ...item }
                if (newItem.text === data.value) {
                    newItem.isChecked = true;
                    const findGrowthArea = newtempGrowhtArea.find(
                        (ele) =>
                            ele.point === data.value/*  && ele.uniqueId === state.selectedScore */
                    );
                    console.log("true findGrowthArea ===", findGrowthArea);
                    if (findGrowthArea) {
                        newtempGrowhtArea = newtempGrowhtArea.map((data) => {
                            if (data.point === data.value && !data.isChecked) {
                                data.isChecked = true;
                            }
                            return data;
                        });
                    } else {
                        newtempGrowhtArea = [
                            ...newtempGrowhtArea,
                            {
                                uniqueId: newItem.uniqueId,
                                point: newItem.text,
                                isChecked: newItem.isChecked,
                            }
                        ]
                    }
                }
                return newItem;
            });
            newgrowthAreaData = tempGrowthAreaCreate
            newtempSelectedGrowthArea = [
                ...newtempSelectedGrowthArea,
                { uniqueId: Math.random(), point: data.value },
            ]
            newgrowthFavorites = newgrowthFavorites.map(it => {
                it = [
                    ...it,
                    { uniqueId: Math.random(), point: data.value },

                ]
                return it
            })
            newfoundResult.data = newfoundResult.data.map(it => {
                it.growthFavorites = [
                    ...it.growthFavorites,
                    { uniqueId: Math.random(), point: data.value },
                ]
                return it
            })
        } else {
            const tempGrowthAreaCreate = [...newgrowthAreaData].map((item) => {
                const newItem = { ...item }

                if (newItem.text === data.value) {
                    newItem.isChecked = false;
                    const findGrowthArea = newtempGrowhtArea.find(
                        (ele) =>
                            ele.point === data.value/*  && ele.uniqueId === state.selectedScore */
                    );
                    console.log("findGrowthArea ===", findGrowthArea);
                    if (findGrowthArea) {
                        newtempGrowhtArea.map((data) => {
                            if (data.point === data.value && data.isChecked) {
                                data.isChecked = false;
                            }
                            return data;
                        });
                    } else {
                        newtempGrowhtArea = [
                            ...newtempGrowhtArea,
                            {
                                uniqueId: newItem.uniqueId,
                                point: newItem.text,
                                isChecked: newItem.isChecked,
                            }
                        ]
                    }
                }
                return newItem;
            });
            newgrowthAreaData = tempGrowthAreaCreate
            newtempDeletedGrowthArea = [
                ...newtempDeletedGrowthArea,
                { point: data.value },
            ]
            newgrowthFavorites = newgrowthFavorites.map(it => {
                it = it.filter(ite => ite.point !== data.value)
                return it
            })
            newfoundResult.data = newfoundResult.data.map(it => {
                it.growthFavorites = it.growthFavorites.filter(ite1 => ite1.point !== data.value)
                return it
            })
        }
        setgrowthAreaData(newgrowthAreaData)
        setFoundResult(newfoundResult)
        settempGrowhtArea(newtempGrowhtArea)
        settempSelectedGrowthArea(newtempSelectedGrowthArea)
        setGrwothAreaFavouriutes(newgrowthFavorites)
        settempDeletedGrowthArea(newtempDeletedGrowthArea)
    }
    const handleChangeCreateProgressArea = (event, data, randomXPObject = {}) => {
        if (!data.isChecked) {
            handleChangeProgressArea({
                isChecked: data.isChecked,
                value: event.target.value,
            })
            const createGrowthAreaData = {
                type: "ACTION",
                point: event.target.value,
                questionCategoryId: questionCategoryId,
                uid: localStorage.getItem("userDetail"),
                level: randomXPObject?.level,
            };
            questionCategoryCreateFavorite(createGrowthAreaData)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            handleChangeProgressArea({
                isChecked: data.isChecked,
                value: event.target.value,
            })
            const deleteGrowthAreaData = {
                type: "ACTION",
                point: event.target.value,
                questionCategoryId: questionCategoryId,
                uid: localStorage.getItem("userDetail"),
                level: randomXPObject?.level,
            };
            questionCategoryDeleteFavorite(deleteGrowthAreaData)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handleChangeCreateGrowthArea = (event, data) => {
        console.log("data", data);
        if (data) {
            if (!data.isChecked) {
                handleChangeGrowthArea({
                    isChecked: data.isChecked,
                    value: event.target.value,
                })
                const createGrowthAreaData = {
                    type: "GROWTH",
                    point: event.target.value,
                    questionCategoryId: questionCategoryId,
                    uid: localStorage.getItem("userDetail"),
                };
                questionCategoryCreateFavorite(createGrowthAreaData)
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                handleChangeGrowthArea({
                    isChecked: data.isChecked,
                    value: event.target.value,
                })
                const deleteGrowthAreaData = {
                    type: "GROWTH",
                    point: event.target.value,
                    questionCategoryId: questionCategoryId,
                    uid: localStorage.getItem("userDetail"),
                };
                questionCategoryDeleteFavorite(deleteGrowthAreaData)
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };
    const allDimentionByTest = () => {


        const user = localStorage.getItem("userDetail");
        let uid = user;
        // AllDiamentionByTest(uid)
        //     .then((data) => {
        //         console.log("AllDiamentionByTest data", data);
        //         console.log("AllDiamentionByTest questionCategoryId", questionCategoryId);
        //         // setResultAllDiamention(data?.allPercentage);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
        //     .finally(() => {
        //         setLoading(false);
        //     });
        ResultAllTestDiamention(user)
            .then((respData) => {
                const found = respData?.allPercentage?.find(it => it.questionCategoryId === questionCategoryId)
                setFoundResult(found)
                const { data = [] } = found
                const growthAreaActionData = found?.data?.map((item, index) => parse(item.descriptionDetails))
                setGrwothAreaActionData(growthAreaActionData)
                const enable = found?.data.length - 1
                const growthFavorites = found?.data?.map((item) => item.growthFavorites)
                console.log("growthFavorites setting in store from click", growthFavorites);
                const actionFavorites = found?.data?.map((item) => item.actionFavorites)
                console.log("AllDiamentionByTest data", growthFavorites, actionFavorites, growthAreaActionData);
                setGrwothAreaFavouriutes(growthFavorites)
                setActionFavouriutes(actionFavorites)
                const handleTestData = () => {
                    let tempGrowthArea = [];
                    let tempProgress = [];
                    if (growthAreaActionData?.length) {
                        growthAreaActionData?.map((data, index) => {
                            if (index === enable) {
                                data.map((item) =>
                                    item.key === "1"
                                        ? item?.props?.children?.map((ele) => {
                                            tempGrowthArea.push({
                                                text: ele.props.children,
                                                isChecked: false,
                                            });
                                        })
                                        : null
                                );
                            }
                        });
                        growthAreaActionData?.map((data, index) => {
                            if (index === enable) {
                                data.map((item) =>
                                    item.key === "3"
                                        ? item?.props?.children?.map((ele) => {
                                            tempProgress.push({
                                                text: ele.props.children,
                                                isChecked: false,
                                                randomXPObject: randomXP(),
                                            });
                                        })
                                        : null
                                );
                            }
                        });
                        // growthAreaActionData?.map((data, index) => {
                        //     if (index === enable) {
                        //         data.map((item) =>
                        //             item.key === "0" ? dispatch(updateResultState({
                        //                 growthAreaQsn: item?.props?.children,
                        //             })) : ""
                        //         );
                        //     }
                        //     return data
                        // });
                        // growthAreaActionData?.map((data, index) => {
                        //     if (index === enable) {
                        //         data.map((item) =>
                        //             item.key === "2" ? dispatch(updateResultState({
                        //                 progressQsn: item?.props?.children,
                        //             })) : ""
                        //         );
                        //     }
                        //     return data
                        // });
                        renderGrowthFavorites(tempGrowthArea, [...growthFavorites], enable);
                        renderActionFavorites(tempProgress, [...actionFavorites], enable);
                        // dispatch(updateResultState({
                        //     growthAreaData: tempGrowthArea,
                        //     progressData: tempProgress
                        // }))
                        setgrowthAreaData(tempGrowthArea)
                        setprogressData(tempProgress)
                        if (
                            data[enable]?.unlockgrowthandactions &&
                            data[enable]?.unlockgrowthandactions[0]
                        ) {
                            setgacount(data[enable]?.unlockgrowthandactions[0].growth)
                            setActionCount(data[enable]?.unlockgrowthandactions[0].action)
                        }
                    }
                };
                handleTestData()
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        if (isGrowth) {

        } else {

        }
    }, [isGrowth ? gacount : actionCount])
    const smallDeviceMatches = useMediaQuery("(max-width:500px)");
    const closeButtonSX = isCreate ? {
        position: 'absolute',
        left: '32px',
        bottom: '18px',
        width: '16px',
        height: '16px',
        cursor: 'pointer'
    } : {
        position: 'absolute',
        right: '32px',
        bottom: '18px',
        width: '16px',
        height: '16px',
        cursor: 'pointer'
    }
    return <><Dialog
        open={visible}
        // open={true}
        onClose={onClose}
        PaperProps={{
            style: {
                borderRadius: "32px",
                position: "relative",
                padding: "0",
                width: 'calc(100vw - 40%)',
                height: 'calc(100vh - 15%)',
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flexShrink: 0,

            },
        }}
        sx={{
            "& .MuiPaper-root": {
                // width: "100%",


            },
            '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: '640px',
                    borderRadius: `32px`,
                    margin: `${smallDeviceMatches && "10px"}`,

                    position: 'relative',
                }
            }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Box className={clsx("flex", "flexColumn", "alignStart", "alignStrech")} sx={{
            gap: '20px',
        }}>
            <Box className={clsx("flex", "justifyCenter", "alignCenter", "alignStrech")} sx={{
                padding: '20px 32px',
                borderBottom: '1px solid #F3F3F3',
                background: '#FFF',
                position: 'relative'
            }}>
                {isCreate ? <Typography component={"span"} sx={GeneralSX({
                    fontWeight: 500
                })}>Go back to creating a goal</Typography> : `Add ${isGrowth ? "growth areas" : "actions"} to ${goalName}`}
                <Box sx={closeButtonSX} onClick={onClose}>
                    {isCreate ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M9.6001 11.1998L6.4001 7.9998L9.6001 4.7998" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    ) : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M12 4L4 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4 4L12 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>}

                </Box>
            </Box>
        </Box>
        <Box className={clsx("flex", "flexColumn", "alignCenter", "alignStrech")} sx={{
            padding: '32px',
            gap: '24px',
            background: '#FFF',
        }}>
            <Box className={clsx("flex", "flexColumn", "alignStart", "alignStrech")} sx={{
                padding: '16px',
                gap: '16px',
                borderRadius: '16px',
                background: '#F9F9F9',
            }}>
                <Box className={clsx("flex", "alignStart")} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}>
                    <Typography component={"span"} sx={{ ...GeneralSX() }}>Available {isGrowth ? "growth areas" : "actions"} for</Typography> &nbsp; <Typography component={"span"} sx={{ ...GeneralSX({ color: '#9747FF' }) }}>{foundResult?.questionCategory}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '12px',
                    alignSelf: 'stretch',
                    overflowY: 'scroll',
                    maxHeight: `calc(100vh - ${([...(isGrowth ? growthAreaData : progressData)].length > (isGrowth ? gacount : actionCount)) ? '430px' : '400px'})`
                }}>
                    {(isGrowth ? growthAreaData : progressData).map((it, index) => {
                        if (index < (isGrowth ? gacount : actionCount)) {
                            return <Selectionitem it={it} isGrowth={isGrowth} handleChangeCreateGrowthArea={handleChangeCreateGrowthArea} handleChangeCreateProgressArea={handleChangeCreateProgressArea} />
                        }
                        return null
                    })}

                </Box>
                {[...(isGrowth ? growthAreaData : progressData)].length > (isGrowth ? gacount : actionCount) ? (
                    <Button
                        variant="filled"
                        size="small"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "8px 12px",
                            gap: "4px",
                            marginTop: "11px",

                            /* Main/Purple-Tertiary */

                            background: "#F7F2FE",
                            borderRadius: "16px",
                        }}
                        onClick={() => UnlockGAActionType(isGrowth ? "GROWTH" : "ACTION")}
                    >
                        <span
                            style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "100%",
                                /* identical to box height, or 12px */

                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "-0.01em",

                                /* Main/Purple */

                                color: "#9747FF",
                            }}
                        >
                            Unlock 3 more for 20
                        </span>
                        <img
                            className={classes.CoinButton}
                            src={Coin}
                            alt="coin"
                            style={{
                                height: "12px",
                                margin: 0,
                            }}
                        />
                    </Button>
                ) : null}
            </Box>
        </Box>
        <Box sx={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            height: '110px',
            background: '#FFF',
            filter: 'blur(47px)',

        }}>

        </Box>
        <Box sx={{
            padding: '32px',
            position: 'absolute',
            bottom: 0,
            width: '100%',
        }}>
            <Button variant="contained" fullWidth sx={{
                display: 'flex',
                padding: '19px 20px',
                justifyContent: 'center',
                alignSelf: 'stretch',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '12px',
            }} onClick={onClose}> <Typography component={"span"} sx={{ ...GeneralSX({ color: '#FFF', fontSize: 18, letterSpacing: '-0.18px' }) }}>Save</Typography></Button>
        </Box>

    </Dialog>
        {Notification}
    </>
}

export default GoalFavouriteModal

const Selectionitem = ({ it, it: { text, isChecked, randomXPObject, }, isGrowth, handleChangeCreateGrowthArea, handleChangeCreateProgressArea }) => {
    console.log("isChecked", isChecked);
    return <Box sx={{
        display: 'flex',
        padding: '15px 16px',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        borderRadius: '16px',
        background: '#FFF',
        cursor: "pointer"
    }}>
        {isGrowth ? (
            <FormControlLabel
                key={text + (randomXPObject?.xp || '')}
                value={text}
                control={
                    <Checkbox
                        sx={{
                            padding: "6px 11px",
                            "& .MuiFormControlLabel-root": {
                                alignItems: "flex-start",
                            },
                        }}
                        checkedIcon={
                            <img
                                style={{ width: "14px", height: "13px" }}
                                src={RadioCheckedIcon}
                                alt=""
                            />
                        }
                        icon={
                            <img
                                style={{ width: "14px", height: "13px" }}
                                src={RadioIcon}
                                alt=""
                            />
                        }
                        checked={isChecked}
                        disabled={
                            // selectedInviteDataSlice?.isSelectedSocialUser && true
                            false
                        }
                        onChange={(e) => {
                            handleChangeCreateGrowthArea(e, it);
                        }}
                    />
                }
                className={classes.RadioOptionText}
                label={it.text}
            />
        ) : <FormControlLabel
            key={text + (randomXPObject?.xp || '')}
            value={it.text}
            sx={{
                "& .MuiFormControlLabel-root": {
                    alignItems: "flex-start",
                },
                "& .MuiFormControlLabel-label": {
                    width: "100%",
                },
            }}
            control={
                <Checkbox
                    sx={{
                        padding: "6px 11px",
                    }}
                    checkedIcon={
                        <img
                            style={{ width: "14px", height: "13px" }}
                            src={RadioCheckedIcon}
                            alt=""
                        />
                    }
                    icon={
                        <img
                            style={{ width: "14px", height: "13px" }}
                            src={RadioIcon}
                            alt=""
                        />
                    }
                    checked={it.isChecked}
                    disabled={
                        // selectedInviteDataSlice?.isSelectedSocialUser && true
                        false
                    }
                    onChange={(e) => {
                        handleChangeCreateProgressArea(e, it, randomXPObject);
                    }}
                />
            }
            className={classes.RadioOptionText}
            label={
                <div className={classes.actionLable}>
                    {it.text}
                    <Chip
                        label={randomXPObject.xp}
                        avatar={<Avatar alt="Natacha" src={XP} sx={{
                            height: '12px',
                            width: '12px',
                        }} />}
                        className={classes.backgroundChip}
                        sx={{
                            '& .MuiChip-label': {
                                paddingX: 1
                            }
                        }}
                    />
                </div>
            }
        />}
        {/* <Box sx={{
            width: '20px',
            height: '20px',
            flexShrink: 0,
        }}>
            {isChecked ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M15.8921 4.99643C15.5516 4.65576 15.1473 4.38552 14.7023 4.20115C14.2574 4.01677 13.7804 3.92187 13.2988 3.92188C12.8171 3.92187 12.3402 4.01677 11.8952 4.20115C11.4502 4.38552 11.0459 4.65576 10.7054 4.99643L9.99878 5.70309L9.29211 4.99643C8.60431 4.30863 7.67147 3.92223 6.69878 3.92223C5.72609 3.92223 4.79324 4.30863 4.10544 4.99643C3.41765 5.68422 3.03125 6.61707 3.03125 7.58976C3.03125 8.56245 3.41765 9.4953 4.10544 10.1831L4.81211 10.8898L9.99878 16.0764L15.1854 10.8898L15.8921 10.1831C16.2328 9.84259 16.503 9.4383 16.6874 8.99333C16.8718 8.54836 16.9667 8.07142 16.9667 7.58976C16.9667 7.1081 16.8718 6.63116 16.6874 6.18619C16.503 5.74122 16.2328 5.33693 15.8921 4.99643Z" fill="#EF8B6E" />
            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M15.8921 4.99643C15.5516 4.65576 15.1473 4.38552 14.7023 4.20115C14.2574 4.01677 13.7804 3.92187 13.2988 3.92188C12.8171 3.92187 12.3402 4.01677 11.8952 4.20115C11.4502 4.38552 11.0459 4.65576 10.7054 4.99643L9.99878 5.70309L9.29211 4.99643C8.60431 4.30863 7.67147 3.92223 6.69878 3.92223C5.72609 3.92223 4.79324 4.30863 4.10544 4.99643C3.41765 5.68422 3.03125 6.61707 3.03125 7.58976C3.03125 8.56245 3.41765 9.4953 4.10544 10.1831L4.81211 10.8898L9.99878 16.0764L15.1854 10.8898L15.8921 10.1831C16.2328 9.84259 16.503 9.4383 16.6874 8.99333C16.8718 8.54836 16.9667 8.07142 16.9667 7.58976C16.9667 7.1081 16.8718 6.63116 16.6874 6.18619C16.503 5.74122 16.2328 5.33693 15.8921 4.99643V4.99643Z" stroke="#B4B0B4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>}

        </Box>
        <Typography component={"span"} sx={{ ...GeneralSX({ lineHeight: '145%' }) }}>{text}</Typography>
        {!isGrowth && (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginLeft: 'auto'
            }}>
                <Chip
                    label={randomXPObject.xp}
                    avatar={<Avatar alt="Natacha" src={XP} />}
                    className={classes.backgroundChip}
                />
            </Box>
        )} */}

    </Box >
}