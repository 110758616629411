import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header/Header";
import ProfileHeader from "../../../components/Settings/Profile/ProfileHeader";
import { SettingNavigationBar } from "../../../components/Settings/SettingNavigationBar/SettingNavigationBar";
import { RightSideContentWrapper } from "../../../components/Settings/StyledElement/StyledDiv";
import { PROFILEINFO_TYPE } from "../../../Constant/SettingConstant";
import { getUserData } from "../../../services/auth";
import { userProfileUpdate } from "../../../Store/Reducers/userProfileSlice";
import classes from "./profile.module.css";
import ProfileAccountInfo from "./ProfileAccountInfo";
import ProfilePersonalInfo from "./ProfilePersonalInfo";

function SettingProfile() {
  const distpatch = useDispatch();

  const { userData } = useSelector((state) => state.userProfile);
  const [currentDate, setCurrentDate] = useState(userData?.dob);

  const [accountInfoData, setAccountInfoData] = useState({
    name: userData.name ? userData.name : "",
    surName: userData.surName ? userData.surName : "",
    userName: userData.userName ? userData.userName : "",
    user_email: userData.user_email ? userData.user_email : "",
    dob: userData?.dob ? userData?.dob : "dd/mm/yyyy",
    city: userData.city ? userData.city : "",
    bio: userData.bio ? userData.bio : "",
    facebook: userData.facebook ? userData.facebook : "",
    instagram: userData.instagram ? userData.instagram : "",
    twitter: userData.twitter ? userData.twitter : "",
    tiktok: userData.tiktok ? userData.tiktok : "",
    motivates: userData.motivates,
    imageUrl: userData.imageUrl ? userData.imageUrl : null,
    gender: userData.gender ? userData.gender : "",
    languages: userData.languages ? userData.languages : "",
    country: userData.country ? userData.country : "",
    ethnicity: userData.ethnicity ? userData.ethnicity : "",
    occupation: userData.occupation ? userData.occupation : "",
    industry: userData.industry ? userData.industry : "",
    marital_status: userData.marital_status
      ? userData.marital_status
      : "",
    children: userData.children ? userData.children : "",
    goals: userData.goals ? userData.goals : "",
    political_preference: userData.political_preference
      ? userData.political_preference
      : "",
    motivates: userData.motivates ? userData.motivates : "",
  });
  useEffect(() => {
   
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [togglePersonalAccountInfo, setPersonalAccountInfo] = useState(
    PROFILEINFO_TYPE.ACCOUNT_INFO
  );

  return (
    <RightSideContentWrapper>
      <Header />
      <Grid container spacing={1.5}>
        <Grid item xs={12} lg={9} md={9}>
          <div className={classes.dashbordTitleContainer}>
            {togglePersonalAccountInfo === PROFILEINFO_TYPE.ACCOUNT_INFO ? (
              <>
                <ProfileHeader
                  setPersonalAccountInfo={setPersonalAccountInfo}
                />
                <ProfileAccountInfo
                  accountInfoData={accountInfoData}
                  setAccountInfoData={setAccountInfoData}
                  currentDate={currentDate}
                  userData={userData}
                  setCurrentDate={setCurrentDate}
                />
              </>
            ) : (
              <>
                <ProfileHeader
                  setPersonalAccountInfo={setPersonalAccountInfo}
                />
                <ProfilePersonalInfo
                  accountInfoData={accountInfoData}
                  setAccountInfoData={setAccountInfoData}
                  currentDate={currentDate}
                  userData={userData}
                  setCurrentDate={setCurrentDate}
                />
              </>
            )}
          </div>
        </Grid>
        {!matches && (
          <Grid item xs={12} lg={3} md={3}>
            <div
              style={{ padding: "12px 0px" }}
              className={classes.dashbordTitleContainer}
            >
              <SettingNavigationBar />
            </div>
          </Grid>
        )}
      </Grid>
    </RightSideContentWrapper>
  );
}

export default SettingProfile;
