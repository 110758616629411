import { Button, Grid } from "@mui/material";
import { typography } from "@mui/system";
import React from "react";
import classes from './ButtonGroup.module.css'
function ButtonGroup({ onClickNext, onClickPrevious, isNextDisable, nextButtonText, previousButtonText, isPrevDisable }) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.buttonWrapper}
      rowSpacing="12px"
      columnSpacing="12px"
    >
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Button
          className={classes.previousButton}
          sx={{ ...typography.subtitle1 }}
          variant="outlined"
          onClick={() => {
            onClickPrevious()
          }}
          disabled={
            isPrevDisable
          }
        >
          {previousButtonText}
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
      >
        <Button
          className={classes.nextButton}
          sx={{ ...typography.subtitle1 }}
          variant="outlined"
          onClick={() => {
            onClickNext()
          }}
          disabled={
            isNextDisable
          }
        >
          {nextButtonText}
        </Button>
      </Grid>
    </Grid>
  );
}

export default ButtonGroup;
export function ButtonGroupNew({ onClickNext, onClickPrevious, isNextDisable, nextButtonText, previousButtonText, isPrevDisable }) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.buttonWrapper}
      rowSpacing="12px"
      columnSpacing="12px"
    >
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Button
          className={classes.previousButtonNew}
          // sx={{ ...typography.subtitle1 }}
          variant="outlined"
          onClick={() => {
            onClickPrevious()
          }}
          disabled={
            isPrevDisable
          }
        >
          {previousButtonText}
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
      >
        <Button
          className={classes.nextButtonNew}
          // sx={{ ...typography.subtitle1 }}
          variant="outlined"
          onClick={() => {
            onClickNext()
          }}
          disabled={
            isNextDisable
          }
        >
          {nextButtonText}
        </Button>
      </Grid>
    </Grid>
  );
}