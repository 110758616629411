import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Typography,
  useMediaQuery,
  Chip,
  Avatar,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import XP from "../../assets/Svg/XP.svg";
import ExpandMore from "../../assets/Svg/ExpandMore.svg";
import CheckboxEndIcon from "../../assets/Svg/CheckboxEndIcon.svg";
import UncheckCheckbox from "../../assets/Svg/UncheckCheckbox.svg";
import CheckedCheckbox from "../../assets/Svg/CheckedCheckbox.svg";
import PlusLargeIcon from "../../assets/Svg/PlusLargeIcon.svg";
import tripleDot from "../../assets/Svg/tripleDot.svg";
import classes from "./Accordian.module.css";
import { CustomShedualButton } from "../GoalComponent/GoalButton";
import styled from "@emotion/styled";
import HabitDialog from "../GoalAddMoreDialog/HabitDialog/HabitDialog";
import {
  goalCompleteHabitAction,
  goalCreateHabitAction,
  goalDeleteHabitAction,
  goalDuplicateHabitAction,
  goalUpdateHabitAction,
  updateHabitActionCalender,
} from "../../services/auth";
import {
  DuplicateSnackbar,
  EditActionSnakebar,
  EditRemoveSnackbar,
} from "../GoalComponent/GoalDialog";
import useCustomNotification from "../../Hooks/useCustomNotification";
import EditGoal from "../EditRemoveGoal/EditGoal/EditGoal";
import EditAction from "../EditRemoveGoal/EditAction/EditAction";
import {
  updateCompeletedHabit,
  updateHabitAddMore,
  updatehabitAllCalenders,
  updatehabitAllCalendersComplete,
  updatehabitCalenders,
  updatehabitCalendersComplete,
  updateHabitData,
  updateIsSchedule,
} from "../../Store/Reducers/goalHabitData";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import EditHabit from "../EditRemoveGoal/EditHabit/EditHabit";
import DuplicateGrowthArea from "../EditRemoveGoal/DuplicateGrowthArea/DuplicateGrowthArea";
import useCustomNotificationActionHabit from "../../Hooks/useCustomNotiFicationActionHabit";
import SuggestedGrowthArea from "../GrowthDialog/SuggestedGrowthArea";
import moment from "moment";
import { FindAndGetXPLevel } from "../../utils";
import GoalTimePicker from "../EditRemoveGoal/GoalTimePicker/GoalTimePicker";
import { updateUserCions } from "../../Store/Reducers/userProfileSlice";
import ChipSaveButton from "../ChipSaveButton";
import HTMLTooltip from "../ToolTipWithIcon/HTMLTooltip";
function HabitAccordian({ goalName, selectedQuestionCategoriesId, imageCompo }) {
  const { HabitDataSlice, growthAreaDataSlice, userProfile: { userData = {} } } = useSelector((state) => state);
  const [habitAccordian, sethabitAccordian] = useState("panel");
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [isHabitOpen, setIsHabitOpen] = useState(false);
  const [habitIds, setHabitIds] = useState("");
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false);
  const [isEditActionOpen, setisEditActionOpen] = useState(false);
  const [selectedHabitData, setSelectedHabitData] = useState({});
  const [isCompoletedMenuOpen, setIsCompoletedMenuOpen] = useState(false);
  const [isHabitScheduleOpen, setIsHabitScheduleOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const dispatch = useDispatch();
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const {
    NotificationContentActionHabitJSX,
    customNotificationActionHabit,
    setCustomNotificationActionHabit,
  } = useCustomNotificationActionHabit();
  const habitAccordianHandler = (panel) => (event, isExpanded) => {
    sethabitAccordian(isExpanded ? panel : false);
  };
  const addMoreHabitHandler = (habits) => {
    const addMoreHabitState = {
      uid: localStorage.getItem("userDetail"),
      questionCategoryId: selectedQuestionCategoriesId,
      type: "HABIT",
      habit: [habits],
    };
    // dispatch(
    //   updateHabitData([
    //     ...HabitDataSlice.habitData,
    //     {
    //       days: habits?.days,
    //       habitDetail: habits?.habitDetail,
    //       habitName: habits?.habitName,
    //       habitSchedule: habits?.habitSchedule,
    //       habitScheduleType: habits?.habitScheduleType,
    //       // habitId: uuidv4(),
    //       status: "IN-PROGRESS",
    //     },
    //   ])
    // );
    goalCreateHabitAction(addMoreHabitState)
      .then((res) => {
        console.log(res);
        dispatch(updateHabitAddMore(true));

      })
      .catch((err) => {
        console.log(err);
      });
    setIsHabitOpen(false);
  };
  const removeHabitHandler = () => {
    const tempHabiData = HabitDataSlice?.habitData.filter(
      (item) => item.habitId !== selectedHabitData.habitId
    );
    dispatch(updateHabitData(tempHabiData));
    setIsEditMenuOpen(false);
    const removeUserdata = {
      // ids: [selectedHabitData?.habitId],
      habitId: selectedHabitData?.habitId,
      uid: localStorage.getItem("userDetail"),
      archive: true,
    };
    goalCompleteHabitAction(removeUserdata)
      .then((res) => {
        dispatch(updateHabitAddMore(true))
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const unCheckToCheckHandler = (data) => {
    const user = localStorage.getItem("userDetail");
    const goalId = data?.habitId;
    console.log("data", data);
    const findData = HabitDataSlice.allhabitCalendersComplete.find(
      (o) => o.habitName === data.habitName
    );
    if (findData) {
      let tempHabitCompelete = [];
      HabitDataSlice.allhabitCalendersComplete?.forEach((element) => {
        tempHabitCompelete.push({
          habitName: element.habitName,
          count:
            data.habitName === element.habitName
              ? element.count + 1
              : element.count,
          _id: { habitName: element.habitName },
          ...data,
          calenderStatus: "COMPLETE",
          status: "COMPLETE"
        });
      });

      dispatch(updatehabitCalendersComplete([...tempHabitCompelete].splice(0, 3)));
      dispatch(updatehabitAllCalendersComplete(tempHabitCompelete));
    } else {
      const tempHabitCompelete = [
        ...HabitDataSlice.allhabitCalendersComplete,
        {
          ...data, calenderStatus: "COMPLETE",
          status: "COMPLETE",
        },

      ];
      dispatch(updatehabitCalendersComplete([...tempHabitCompelete].splice(0, 3)));
      dispatch(updatehabitAllCalendersComplete(tempHabitCompelete));
    }
    const tempHabit = HabitDataSlice?.allhabitCalenders.filter(
      (item) => item._id !== data._id
    );
    dispatch(updatehabitCalenders([...tempHabit].splice(0, 3)));
    dispatch(updatehabitAllCalenders(tempHabit))
    // const updatedhabitState = {
    //   uid: user,
    //   id: goalId,
    //   type: "HABIT",
    //   habit: tempHabitDetail,
    // };
    // goalUpdateHabitAction(updatedhabitState)
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});
    makeAPICall(data, goalId)
  };
  const makeAPICall = (data, goalId) => {
    console.log("data", data);
    let createHabitData = {}
    if (data.calenderStatus === "IN_PROGRESS") {
      createHabitData = {
        uid: localStorage.getItem("userDetail"),
        id: data._id,
        type: "HABIT",
        habit: {
          scheduled_type: data.scheduled_type,
          executionDate: data.executionDate,
          executionDateEnd: data.executionDateEnd,
          calenderStatus: "COMPLETE",
          status: "COMPLETE",
          xpChange: "INCREASE"
        },
        xpChange: "INCREASE"
      };
    } else {
      createHabitData = {
        uid: localStorage.getItem("userDetail"),
        id: data._id,
        type: "HABIT",
        habit: {
          scheduled_type: data.scheduled_type,
          executionDate: data.executionDate,
          executionDateEnd: data.executionDateEnd,
          calenderStatus: "IN_PROGRESS",
          status: "IN_PROGRESS",
          xpChange: "DECREASE"
        },
        xpChange: "DECREASE"
      };
    }
    console.log("createHabitData ========", createHabitData);
    if (Object.keys(createHabitData).length > 0) {
      updateHabitActionCalender(createHabitData)
        .then((res) => {
          // this.callToGetData(
          //   this.state.selectedDate,
          //   this.state.selectedDate
          // );
          const xp = FindAndGetXPLevel(data.level, false)
          updateProfileXp(xp, createHabitData.habit.status !== "IN_PROGRESS")
          console.log("res = ", res);
        })
        .catch((err) => {
          console.log("err = ", err);
        });
    }
  }
  const updateProfileXp = (xp, add) => {
    const updatedXp = !add ? (userData.xp - xp) : (userData.xp + xp)
    dispatch(updateUserCions(updatedXp))
  }
  const checkToUncheckHandler = (data) => {
    const user = localStorage.getItem("userDetail");
    const goalId = data?._id;
    const tempHabitDetail = {
      ...data,
      calenderStatus: "IN_PROGRESS",
      status: "IN_PROGRESS",
    };
    const tempHabit = [...HabitDataSlice.allhabitCalenders, tempHabitDetail];
    dispatch(updatehabitCalenders([...tempHabit].splice(0, 3)));
    dispatch(updatehabitAllCalenders(tempHabit))
    const updatedhabitState = {
      uid: user,
      id: goalId,
      type: "HABIT",
      habit: tempHabitDetail,
    };
    if (data.count > 1) {
      let tempHabitComplete = [];
      HabitDataSlice.allhabitCalendersComplete.forEach((element) => {
        tempHabitComplete.push({
          habitName: element.habitName,
          count:
            data?._id === element?._id
              ? element.count - 1
              : element.count,
          _id: { habitName: element.habitName },
          ...data,
          calenderStatus: "IN_PROGRESS",
          status: "IN_PROGRESS",
        });
      });
      dispatch(updatehabitAllCalendersComplete(tempHabitComplete));
      dispatch(updatehabitCalendersComplete([...tempHabitComplete].splice(0, 3)));
    } else {
      const tempHabitCompelete = HabitDataSlice?.allhabitCalendersComplete.filter(
        (item) => item._id !== data._id
      );
      dispatch(updatehabitAllCalendersComplete(tempHabitCompelete));
      dispatch(updatehabitCalendersComplete([...tempHabitCompelete].splice(0, 3)));
    }
    // goalUpdateHabitAction(updatedhabitState)
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});
    makeAPICall(data, goalId)

  };
  const editHabitHandler = () => {
    setisEditActionOpen(true);
    setIsEditMenuOpen(false);
  };
  const editHabitTextHandler = () => {
    console.log("selectedHabitData", selectedHabitData);
    const user = localStorage.getItem("userDetail");
    const goalId = selectedHabitData?.habitId;
    const newObj = { ...selectedHabitData }
    if (newObj?.goalStartDate) {
      newObj.goalStartDate = moment(newObj.goalStartDate).format("MM-DD-YYYY")
    }
    if (newObj?.goalEndDate) {
      newObj.goalEndDate = moment(newObj.goalEndDate).format("MM-DD-YYYY")
    }
    const updatedHabitState = {
      uid: user,
      id: goalId,
      type: "HABIT",
      habit: newObj,
    };
    let tempHabit = [];
    HabitDataSlice?.allhabitCalenders?.map((item) => {
      return tempHabit.push({
        level: item.level,
        habitName:
          item.habitId === selectedHabitData.habitId
            ? selectedHabitData.habitName
            : item.habitName,
        habitDetail:
          item.habitId === selectedHabitData.habitId
            ? selectedHabitData.habitDetail
            : item.habitDetail,
        status: "IN_PROGRESS",
        habitId: item.habitId,
        days:
          item.habitId === selectedHabitData.habitId && selectedHabitData.days
            ? selectedHabitData.days
            : item.days,
        habitSchedule:
          item.habitId === selectedHabitData.habitId &&
            selectedHabitData.habitSchedule
            ? selectedHabitData.habitSchedule
            : item.habitSchedule,
        habitScheduleType:
          item.habitId === selectedHabitData.habitId &&
            selectedHabitData.habitScheduleType
            ? selectedHabitData.habitScheduleType
            : item.habitScheduleType,
        goalStartDate: selectedHabitData?.goalStartDate,
        goalEndDate: selectedHabitData?.goalEndDate,
        calenderStatus: selectedHabitData.calenderStatus,
        _id: selectedHabitData._id,
        executionDate
          : selectedHabitData.executionDate,
        executionDateEnd
          : selectedHabitData.executionDateEnd,
        scheduled_type
          : selectedHabitData.scheduled_type
      });
    });
    dispatch(updatehabitCalenders([...tempHabit].splice(0, 3)));
    dispatch(updatehabitAllCalenders(tempHabit))
    setisEditActionOpen(false);
    goalUpdateHabitAction(updatedHabitState)
      .then((res) => {
        console.log(res);
        setisEditActionOpen(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
  };
  const duplicateHabitHandler = () => {
    let duplicateActiondata = {
      uid: localStorage.getItem("userDetail"),
      questionCategoryId: selectedQuestionCategoriesId,
      type: "HABIT",
      habit: {
        habitName: selectedHabitData.habitName,
      },
    };
    let tempHabitDataInProgress = {
      habitName: selectedHabitData.habitName,
      days: selectedHabitData?.habitId[0]?.days,
      habitDetail: selectedHabitData?.habitId[0]?.habitDetail,
      habitId: selectedHabitData?.habitId[0]?.habitId,
      habitSchedule: selectedHabitData?.habitId[0]?.habitSchedule,
      habitScheduleType: selectedHabitData?.habitId[0]?.habitScheduleType,
      status: "IN_PROGRESS",
    };
    dispatch(
      updateHabitData([...HabitDataSlice?.habitData, tempHabitDataInProgress])
    );
    goalDuplicateHabitAction(duplicateActiondata)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsCompoletedMenuOpen(false);
  };
  const habitScheduleClosehandler = () => {
    const user = localStorage.getItem("userDetail");
    console.log("selectedHabitData", selectedHabitData);
    const tempScheduledata = {
      habitDetail: selectedHabitData?.habitDetail,
      habitId: selectedHabitData?.habitId,
      habitName: selectedHabitData?.habitName,
      habitSchedule:
        selectedHabitData?.habitScheduleType === "MONTHLY"
          ? selectedHabitData?.habitSchedule
          : false,
      habitScheduleType: selectedHabitData?.habitScheduleType,
      days:
        selectedHabitData?.habitScheduleType === "WEEKLY"
          ? selectedHabitData?.days
          : [],
      "goalStartDate": selectedHabitData?.startDate ? moment(selectedHabitData?.startDate).format("MM-DD-YYYY") : null,
      "goalEndDate": selectedHabitData?.enddate ? moment(selectedHabitData?.enddate).format("MM-DD-YYYY") : null,
    };
    const goalId = selectedHabitData?.habitId;
    const updatedHabitState = {
      uid: user,
      id: goalId,
      type: "HABIT",
      habit: tempScheduledata,
    };
    let tempHabit = [];
    HabitDataSlice?.habitData?.map((item) => {
      return tempHabit.push({
        habitName:
          item.habitId === selectedHabitData.habitId
            ? selectedHabitData.habitName
            : item.habitName,
        habitDetail:
          item.habitId === selectedHabitData.habitId
            ? selectedHabitData.habitDetail
            : item.habitDetail,
        status: "IN_PROGRESS",
        habitId: item.habitId,
        days:
          item.habitId === selectedHabitData.habitId
            ? tempScheduledata.days
            : [],
        habitSchedule:
          item.habitId === selectedHabitData.habitId
            ? tempScheduledata.habitSchedule
            : item.habitSchedule,
        habitScheduleType:
          item.habitId === selectedHabitData.habitId
            ? tempScheduledata.habitScheduleType
            : item.habitScheduleType,
      });
    });
    console.log("updatedHabitState", updatedHabitState);
    dispatch(updateHabitData(tempHabit));
    goalUpdateHabitAction(updatedHabitState)
      .then((res) => {
        console.log(res);
        // dispatch(updateIsSchedule(true));
        setisEditActionOpen(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
    setIsHabitScheduleOpen(false);
  };
  const smallDeviceMatches = useMediaQuery("(max-width:500px)");
  const CustomAddNewGoalDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        width: 100%;
        max-width: 640px;
        border-radius: ${smallDeviceMatches ? "20px" : "40px"};
        margin: ${smallDeviceMatches && "10px"};
        position: unset;
      }
    }
  `;
  useEffect(() => {
    setselectedDate(selectedHabitData?.habitSchedule &&
      selectedHabitData?.habitSchedule?.split("-")[1])
    setSelectedDaystate(selectedHabitData?.days)
  }, [selectedHabitData])
  const [selectedDate, setselectedDate] = useState(
    selectedHabitData?.habitSchedule &&
    selectedHabitData?.habitSchedule?.split("-")[1]
  );
  const [selectedDaystate, setSelectedDaystate] = useState(
    selectedHabitData?.days
  );
  const setselectedDayHandler = (day) => {
    const findData = selectedDaystate.find((o) => o === day);
    if (findData) {
      setSelectedDaystate((current) => current.filter((data) => data !== day));
    } else {
      setSelectedDaystate([...selectedDaystate, day]);
    }
  };
  const dayModalCloseHandler = () => {
    setSelectedHabitData({
      habitName: selectedHabitData.habitName,
      habitDetail: selectedHabitData.habitDetail,
      habitId: selectedHabitData.habitId,
      habitSchedule: `${moment().format(
        "MM"
      )}-${selectedDate}-${moment().format("YYYY")}`,
      days: selectedDaystate,
      habitScheduleType: selectedHabitData?.habitScheduleType,
    });
    setIsDayPickerOpen(false);
  };
  return (
    <>
      <Accordion
        expanded={habitAccordian === "panel"}
        onChange={habitAccordianHandler("panel")}
        sx={{
          backgroundColor: "transparent",
          display: 'flex',
          padding: '0',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'stretch',
          width: '100%',
          '& .MuiCollapse-root': {
            width: '100%'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<img src={ExpandMore} alt="ExpandMore" />}
          className={classes.subAccordianSummery}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            backgroundColor: "transparent",
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            alignSelf: 'stretch',

            "& .Mui-expanded": {
              margin: "0px 0px !important",
            },
            "& .MuiAccordionSummary-content": {
              margin: "0px 0px",
            },
          }}
        //
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                Habits
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.GrowthAccordianDetailContainer} sx={{
          width: '100%'
        }}>
          <Box className={classes.GrowthAccordianDetailContainerBox} sx={{
            gap: '12px'
          }}>
            <div className={classes.selectedWrapper}>
              <Typography variant="body2" sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                Selected
                <HTMLTooltip
                  showArrow
                  placement={"right"}
                  title={`The 'Selected' list combines the habits you’ve personally created or any liked habits you added`}
                  sx={{
                    '& .MuiTooltip-tooltip': {
                      background: "#FFF",
                      color: "#000",
                      maxWidth: "350px"
                    }
                  }}
                />
              </Typography>
              <CustomShedualButton
                style={{
                  maxWidth: "91px",
                  background: "#F9F9F9",
                  color: "#190D1A",
                }}
                onClick={() => {
                  setIsHabitOpen(true);
                }}
              >
                Add more +
              </CustomShedualButton>
            </div>
            <Box style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '12px',
              alignSelf: 'stretch',

            }}>
              {HabitDataSlice?.habitCalenders?.map((data, index) => {
                const { habitId, habitName, habitDetail, level } = data;
                const foundXp = FindAndGetXPLevel(level, false)
                return (
                  <div className={classes.customRadioActionGroup}>
                    {foundXp && (
                      <div className={classes.schedualWrapper}>
                        <Chip
                          label={foundXp}
                          avatar={<Avatar alt="Natacha" sx={{ width: '20px', height: '20px' }} src={XP} />}
                          className={classes.backgroundChip}
                        />
                      </div>
                    )}
                    <div className={classes.customRadioActionAction} key={index}>

                      <div className={classes.actionNameWrapper}>
                        <img
                          src={UncheckCheckbox}
                          onClick={() => {
                            unCheckToCheckHandler(data);
                          }}
                          alt="CheckboxStartIcon"
                        />
                        <Typography
                          variant=" body2"
                          className={classes.checkBoxText}
                        >
                          {" "}
                          {habitName}
                        </Typography>
                      </div>
                      <div className={classes.schedualWrapper}>
                        <div style={{ width: "100%" }}>
                          {/* <CustomShedualButton
                        onClick={() => {
                          setIsHabitScheduleOpen(true);
                          setSelectedHabitData(data);
                          setTimeout(() => {
                            setIsDayPickerOpen(true);
                          }, 200);
                        }}
                      >
                        Schedule
                      </CustomShedualButton> */}
                        </div>
                        <img
                          style={{ width: "14px" }}
                          src={CheckboxEndIcon}
                          alt="CheckboxEndIcon"
                          onClick={() => {
                            setCustomNotification(true);
                            setHabitIds(index);
                          }}
                        />
                        <img
                          style={{
                            height: "18px",
                          }}
                          src={tripleDot}
                          onClick={() => {
                            setHabitIds(index);
                            setIsEditMenuOpen(true);
                            setSelectedHabitData({
                              ...data,
                              originalEndFDate: data.goalEndDate
                            });
                          }}
                          alt="ExpandMore"
                        />
                        {habitIds === index && (
                          <>
                            <EditRemoveSnackbar
                              open={isEditMenuOpen}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              onClose={() => {
                                setIsEditMenuOpen(false);
                              }}
                              action={
                                <EditGoal
                                  editGrowthAreaTextHandler={editHabitHandler}
                                  removeGrowthAreaHandler={removeHabitHandler}
                                  completeGrowthAreaHandler={removeHabitHandler}
                                />
                              }
                            ></EditRemoveSnackbar>
                            <EditActionSnakebar
                              open={isEditActionOpen}
                              onClose={() => {
                                editHabitTextHandler();
                              }}
                              action={
                                <EditHabit
                                  selectedHabitData={selectedHabitData}
                                  setSelectedHabitData={setSelectedHabitData}
                                  setIsHabitScheduleOpen={setIsHabitScheduleOpen}
                                  editHabitTextHandler={editHabitTextHandler}
                                />
                              }
                            ></EditActionSnakebar>
                            {customNotification && (
                              <NotificationContentJSX
                                message={habitDetail}
                                isThumbIcon={false}
                                position="top"
                              />
                            )}
                          </>
                        )}
                      </div>

                    </div>
                  </div>
                );
              })}
              {HabitDataSlice?.habitCalenders?.length > 0 && HabitDataSlice?.allhabitCalenders?.length > 0 && (HabitDataSlice?.habitCalenders?.length < HabitDataSlice?.allhabitCalenders?.length) && <ChipSaveButton
                title="View more"
                onSave={() => {
                  dispatch(updatehabitCalenders([...HabitDataSlice?.allhabitCalenders].slice(0, HabitDataSlice?.habitCalenders?.length + 3)))
                }}

              />}
            </Box>
          </Box>
          {HabitDataSlice?.habitCalendersComplete?.length > 0 && (
            <Box className={classes.GrowthAccordianDetailContainerBox} sx={{
              gap: showCompleted ? '12px' : 0
            }}>
              <Box className={classes.selectedWrapper} sx={{ cursor: "pointer" }} onClick={() => setShowCompleted(!showCompleted)} >
                <Typography variant="body2" sx={{  color: "#434243 !important", cursor: "pointer" }} onClick={() => setShowCompleted(!showCompleted)} className={classes.recommendedClass}>
                  Completed

                </Typography>
                <img src={ExpandMore} alt="ExpandMore" style={{
                  transform: `rotate(${showCompleted ? '180deg' : '0deg'})`
                }} />
              </Box>
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',

              }}>
                {showCompleted && HabitDataSlice?.habitCalendersComplete?.length === 0 ? (
                  <div className={classes.dataNotFoundSubWrapper}>
                    <Typography variant="body2">
                      Completed habbit not found
                    </Typography>
                  </div>
                ) : (
                  showCompleted && HabitDataSlice?.habitCalendersComplete?.map((data, index) => {
                    const { habitId, _id, habitName, level, count } = data;
                    return (
                      <div className={classes.customRadioActionGroup}>

                        <div className={classes.customRadioActionAction} key={index}>
                          <div className={classes.actionNameWrapper}>
                            {/* <img src={CheckedCheckbox} alt="CheckboxStartIcon" /> */}
                            {count > 1 ? (
                              <div className={classes.duplicateTextWrapper}>
                                <img
                                  src={CheckedCheckbox}
                                  className={classes.checkBoxIcon}
                                  alt=""
                                  onClick={() => {
                                    checkToUncheckHandler(data);
                                  }}
                                />
                                <Typography variant="body3">{count}x</Typography>
                              </div>
                            ) : (
                              <img
                                src={CheckedCheckbox}
                                className={classes.checkBoxIcon}
                                alt=""
                                onClick={() => {
                                  checkToUncheckHandler(data);
                                }}
                              />
                            )}
                            <Typography
                              variant=" body2"
                              className={classes.checkBoxChekedText}
                            >
                              {" "}
                              {habitName}
                            </Typography>
                          </div>
                          <div className={classes.schedualWrapper}>
                            <img
                              style={{ width: "14px" }}
                              src={CheckboxEndIcon}
                              alt="CheckboxEndIcon"
                              onClick={() => {
                                setCustomNotificationActionHabit(true);
                                setHabitIds(index);
                              }}
                            />
                            <img
                              style={{
                                height: "18px",
                              }}
                              src={tripleDot}
                              onClick={() => {
                                setHabitIds(index);
                                setIsCompoletedMenuOpen(true);
                                setSelectedHabitData(data);
                              }}
                              alt="ExpandMore"
                            />
                            {habitIds === index && isCompoletedMenuOpen && (
                              <DuplicateSnackbar
                                open={isCompoletedMenuOpen}
                                onClose={() => {
                                  setIsCompoletedMenuOpen(false);
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                action={
                                  <DuplicateGrowthArea
                                    duplicateGrowthAreaHandler={duplicateHabitHandler}
                                  />
                                }
                              ></DuplicateSnackbar>
                            )}
                            {customNotificationActionHabit && habitIds === index && (
                              <NotificationContentActionHabitJSX
                                message={habitId?.[0].habitDetail}
                                isThumbIcon={false}
                                position="top"
                              />
                            )}
                          </div>

                        </div>
                      </div>

                    );
                  })
                )}
                {showCompleted && HabitDataSlice?.habitCalendersComplete?.length > 0 && HabitDataSlice?.allhabitCalendersComplete?.length && (HabitDataSlice?.habitCalendersComplete?.length < HabitDataSlice?.allhabitCalendersComplete?.length) && <ChipSaveButton
                  title="View more"
                  onSave={() => {
                    dispatch(updatehabitCalendersComplete([...HabitDataSlice?.allhabitCalendersComplete].slice(0, HabitDataSlice?.habitCalendersComplete?.length + 3)))
                  }}

                />}
              </Box>

            </Box>
          )}

        </AccordionDetails>
        {isHabitOpen && (
          <CustomAddNewGoalDialog
            open={isHabitOpen}
            onClose={() => {
              setIsHabitOpen(false);
            }}
          >
            <HabitDialog
              selectedQuestionCategoriesId={selectedQuestionCategoriesId}
              goalName={goalName}
              setIsHabitOpen={setIsHabitOpen}
              addMoreHabitHandler={addMoreHabitHandler}
              imageCompo={imageCompo}
            ></HabitDialog>
          </CustomAddNewGoalDialog>
        )}
        {/* {isHabitScheduleOpen && (
          <HabitScheduleEdit
            isHabitScheduleOpen={isHabitScheduleOpen}
            habitScheduleClosehandler={habitScheduleClosehandler}
            setSelectedHabitData={setSelectedHabitData}
            selectedHabitData={selectedHabitData}
          />
        )} */}
        {isDayPickerOpen && (
          <GoalTimePicker
            isDayPickerOpen={isDayPickerOpen}
            dayModalCloseHandler={dayModalCloseHandler}
            setselectedDayHandler={setselectedDayHandler}
            selectedDaystate={selectedDaystate}
            selectedHabitData={selectedHabitData}
            disabledSelection={true}
          />
        )}
        {isHabitOpen && (
          <SuggestedGrowthArea
            suggestedGrowthArea={growthAreaDataSlice?.suggestedGrowthAreaState}
          />
        )}
      </Accordion>
    </>
  );
}
export default HabitAccordian;
