import { useEffect } from "react"
import PlansServices from "../../services/Plans"
import { useDispatch, useSelector } from "react-redux";
import { setStoreData } from "../../Store/Reducers/plans";
import clsx from "clsx";
import classes from "./plans.module.css"
import { Typography } from "@mui/material";
import { GeneralSX } from "../../components/Text/Text";

const defaultPlanTags = [
    {
        type: "MY_PLANS",
        lable: "My plan",
        isAll: false,
        showIcon: false
    },
    {
        type: "FOR_YOU",
        lable: "For you",
        isAll: false,
        showIcon: true
    },
    {
        type: "ALL",
        lable: "All",
        isAll: true,
        showIcon: false
    }
]
let isLareadyCalled = false
const PlanTabs = ({ disableButton, setDisableButton }) => {
    const { userData } = useSelector((state) => state.userProfile);
    const { activeTabObject, planTags, plansLoading } = useSelector((state) => state.planData);
    const dispatch = useDispatch()
    useEffect(() => {
        !isLareadyCalled && getPlanTags()
    }, [userData?.uid])
    const getPlanTags = () => {
        if (userData?.uid) {
            isLareadyCalled = true
            PlansServices.PLANS_TAGS({
                "uid": userData?.uid,
            }).then(data => {
                isLareadyCalled = false
                dispatch(setStoreData({
                    planTags: data?.planData || []
                }))
                console.log("data ===", data)
            }).catch(err => {
                console.log("err")
            })
        }

    }
    console.log("disableButton", disableButton)
    return <div className={classes.planTabs}>
        {defaultPlanTags.map(it => {
            const selected = ["MY_PLANS", "FOR_YOU", "ALL"].includes(activeTabObject.type) && activeTabObject.lable === it.lable
            return <div className={clsx(classes.choiceItem, selected ? classes.activeTab : "")} onClick={() => {
                if (!plansLoading || !disableButton) {
                    setDisableButton(true)
                    dispatch(setStoreData({
                        activeTabObject: it
                    }))
                }
               
            }}>
                {it.showIcon && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M6.00039 3.20001C6.88797 4.67633 8.12408 5.91243 9.60039 6.80001C8.12408 7.68759 6.88797 8.9237 6.00039 10.4C5.11281 8.9237 3.87671 7.68759 2.40039 6.80001C3.87671 5.91243 5.11281 4.67633 6.00039 3.20001Z" fill={selected ? "white" : "#B4B0B4"} />
                        <path d="M12.0004 3.20001C12.3949 3.85615 12.9443 4.40553 13.6004 4.80001C12.9443 5.19449 12.3949 5.74387 12.0004 6.40001C11.6059 5.74387 11.0565 5.19449 10.4004 4.80001C11.0565 4.40553 11.6059 3.85615 12.0004 3.20001Z" fill={selected ? "white" : "#B4B0B4"} />
                        <path d="M10 8.79999C10.4931 9.62016 11.1798 10.3069 12 10.8C11.1798 11.2931 10.4931 11.9798 10 12.8C9.5069 11.9798 8.82017 11.2931 8 10.8C8.82017 10.3069 9.5069 9.62016 10 8.79999Z" fill={selected ? "white" : "#B4B0B4"} />
                    </svg>
                )}
                <Typography sx={{
                    ...GeneralSX({
                        color: selected ? '#FFF' : "#B4B0B4",
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: "100%",
                        letterSpacing: '-0.14px'
                    })
                }}>{it.lable}</Typography>
            </div>
        })}
        {planTags.map(it => {
            const { _id: { questionCategory, questionCategoryId } } = it
            const selected = ["SPECIFIC"].includes(activeTabObject.type) && activeTabObject.questionCategoryId === questionCategoryId
            return <div className={clsx(classes.choiceItem, selected ? classes.activeTab : "")} onClick={() => {
                if (!plansLoading || !disableButton) {
                    setDisableButton(true)
                    dispatch(setStoreData({
                        activeTabObject: {
                            type: "SPECIFIC",
                            questionCategory, questionCategoryId
                        }
                    }))
                }
            }}>
                <Typography sx={{
                    ...GeneralSX({
                        color: selected ? '#FFF' : "#B4B0B4",
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: "100%",
                        letterSpacing: '-0.14px'
                    })
                }}>{questionCategory}</Typography>
            </div>
        })}
    </div>
}

export default PlanTabs