import { Box, Button, CircularProgress, Dialog, Grid, Typography } from "@mui/material";

import classes from "./TestItem.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateResultState } from "../../Store/Reducers/ResultAllDiamention";
import { useState } from "react";
import PaymentService from "../../services/Payments";
import { ReactComponent as StoreDimension } from "../../assets/Svg/StoreDimension.svg"
import { pxToRem } from "../../theme/typography";
const ConfirmationModalPayment = props => {
    const { userData } = useSelector((state) => state.userProfile);
    const [generatingPaymentLoader, setgeneratingPaymentLoader] = useState(false)
    const resultAllDiamention = useSelector(
        (state) => state.resultAllDiamention
    );
    const {
        visibleConfirmationModal,
        selectedItemForPurchase
    } = resultAllDiamention
    const dispatch = useDispatch()

    console.log("selectedItemForPurchase", selectedItemForPurchase)
    const createInvoice = () => {
        setgeneratingPaymentLoader(true)
        const objForServer = {
            "user_email": userData?.user_email,
            "uid": userData?.uid,
            redirect_url: window.location.origin + '/results-payment'
        }
        if (selectedItemForPurchase.questionCategoryId) {
            objForServer["type"] = "SHOP_DIMENSION"
            objForServer["questionCategoryId"] = selectedItemForPurchase.questionCategoryId
            objForServer["questionCategory"] = selectedItemForPurchase.questionCategory
        }
        PaymentService.CREATE_INVOICE(objForServer)
            .then((res) => {
                localStorage.setItem("selectedItem", res.paymentLink.id)
                localStorage.setItem("selectedItemForPurchase", JSON.stringify(selectedItemForPurchase))
                setgeneratingPaymentLoader(false)
                visibleConfirmationModalAction(false)
                if (res.paymentLink.url) {
                    window.open(res.paymentLink.url, "_self")
                }
                // setSubcategories(res.subCategoryData)
                // setAllSubcategories(res.subCategoryData)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            });
    }
    const getSymboleAndAmountForBuy = (item) => {
        const obj = {
            symbol: '',
            amount: 0,
            coin: false,
            diamond: false,
        }
        if (item.price && item.price.id) {
            obj.symbol = '$'
            obj.amount = item.price.unit_amount / 100
        }
        if (item.buyWith === "amt") {
            obj.symbol = '$'
            obj.amount = item.buyFor
        }
        if (item.buyWith === "coins") {
            obj.symbol = null
            obj.coin = true
            obj.amount = item.buyFor
        }
        return obj
    }

    const renderItemName = () => {
        if (selectedItemForPurchase.category_id || selectedItemForPurchase.questionCategory) {
            return "You are getting immediate access to \"" + (selectedItemForPurchase.category_id && selectedItemForPurchase.category ? selectedItemForPurchase.category : selectedItemForPurchase.questionCategory ? selectedItemForPurchase.questionCategory : '') + "\""
        }
        if ((selectedItemForPurchase.buyFor && selectedItemForPurchase.buyWith) || (selectedItemForPurchase.price && selectedItemForPurchase.price.id)) {
            const { amount, coin, diamond, symbol, } = getSymboleAndAmountForBuy(selectedItemForPurchase)
            return <>
                You are purchasing to {selectedItemForPurchase.name} for {(!coin && !diamond ? symbol : '') + amount}
                {coin && ' Coins'}
                {diamond && ' Diamonds'}
            </>
        }
    }
    console.log(selectedItemForPurchase);
    const visibleConfirmationModalAction = (visible, item = {}) => {
        dispatch(updateResultState({
            selectedItemForPurchase: item,
            visibleConfirmationModal: visible
        }))
    }
    return <Dialog
        open={visibleConfirmationModal}
        onClose={() => {
            visibleConfirmationModalAction(false);
        }}
        sx={{
            "& .MuiPaper-root": {
                width: "420px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderRadius: "24px",
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Box sx={{
            display: 'flex',
            padding: '0px 20px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            alignSelf: 'stretch',

        }}>
            <StoreDimension />
        </Box>
        <Box sx={{
            display: 'flex',
            padding: '0px 20px 20px 20px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '20px',
            alignSelf: 'stretch',
            borderBottom: '1px solid #F3F3F3',

        }}>
            <div className={classes.confirmationModalcardTitle}>
                {renderItemName()}

            </div>
            {generatingPaymentLoader && <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </div>}
            {!generatingPaymentLoader && <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
            }}>
                <Button sx={{
                    display: 'flex',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    flex: '1 0 0',
                    fontSize: '16px !important',
                    fontWeight: 400,
                    lineHeight: '100%', /* 16px */
                    letterSpacing: '-0.16px',

                    border: '1px solid #9747FF',
                    borderRadius: '100px'
                }} variant="outlined" onClick={() => visibleConfirmationModalAction(false)}>Cancel</Button>

                <Button sx={{
                    display: 'flex',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    fontSize: '16px !important',
                    fontWeight: 400,
                    lineHeight: '100%', /* 16px */
                    letterSpacing: '-0.16px',
                    flex: '1 0 0',
                    border: '1px solid #9747FF',
                    borderRadius: '100px'
                }} variant="contained" onClick={() => {
                    createInvoice()

                }}>Purchase for ${selectedItemForPurchase.data && selectedItemForPurchase.data[0] && selectedItemForPurchase.data[0].price}</Button>
            </Box>}
        </Box>
        <Box sx={{
            display: 'flex',
            padding: '20px',
            alignItems: 'flex-start',
            gap: '12px',
            alignSelf: 'stretch',

        }}>
            <Typography sx={{
                color: '#767476',
                fontSize: `${pxToRem(14)}!important`,
                fontWeight: 400,
                lineHeight: '140%', /* 19.6px */
                letterSpacing: '-0.14px',

            }}>Please note that you’ll be redirected to complete the transaction for this dimension</Typography>
        </Box>


    </Dialog>
}

export default ConfirmationModalPayment