import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Confetti from 'react-confetti'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubmitButtonWithBackGround } from "../../../components/Button/Button";
import { WrapperDiv } from "../StyledComponent";
import classes from "./OnbordingDone.module.css";

const OnbordingDone = () => {
  const {
    inviteDataSlice: { inviteData },
  } = useSelector((state) => state);
    const navigate = useNavigate()
  const onNext = () =>{
    const inviteID = localStorage.getItem("inviteId")
    navigate(`/invite/${inviteID}/userinfo`)
  }
  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)
  const confettiRef = useRef(null)
  useEffect(() => {
    setHeight(confettiRef?.current?.clientHeight)
    setWidth(confettiRef?.current?.clientWidth)
  }, [])
  
  return (
    <WrapperDiv ref={confettiRef}>
      <Confetti
          width={width}
          height={height}
        />
      <div></div>
      <div>
        <Box className={classes.wrapper}>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            Welcome to {window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery"}!
          </Typography>
        </Box>
        <Box sx={{ marginTop: "12px" }}>
          <Typography
            variant="subtitle1"
            color="#434243"
            sx={{ textAlign: "center", lineHeight: "145%" }}
          >
            Your journey to your self-discovery starts right here with us!
          </Typography>
        </Box>
      </div>
      <Box className={classes.wrapper} sx={{ marginTop: "32px" }}>
        <SubmitButtonWithBackGround
          buttonText={`Continue testing for ${inviteData?.name ? inviteData?.name : "guest"}`}
            onClick={onNext}
          isDisabled={false}
          isLoading={false}
        />
      </Box>
    </WrapperDiv>
  );
};

export default OnbordingDone;
