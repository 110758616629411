import Header from "../../components/Header/Header"
import PlanData from "./PlanData"
import { RightSideContentWrapper } from "./plans.styles"

const PlansPage = () => {
    return <RightSideContentWrapper>
        <Header />
        <PlanData />
    </RightSideContentWrapper>
}

export default PlansPage