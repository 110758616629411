import React, { useState } from "react";
import classes from "./StepTwo.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import CheckedIcon from "../../../assets/Svg/Right.svg";
import { Box, Typography } from "@mui/material";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { imageURLRender } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
import HTMLTooltip from "../../../components/ToolTipWithIcon/HTMLTooltip";

function StepTwo({ onNext, onPrevious, getGoalDetail, goalDetail, handleClose, selectedDiamenstion, selectedDiamenstionImage }) {
  const optimiseDiamension = ["Increase", "Decrease", "Calibrate"];
  const [Selectedoptimise, setSelectedoptimise] = useState("");
  const updateMainState = () => {
    if (Selectedoptimise === "") {
      getGoalDetail("increase", goalDetail?.increase);
    } else {
      getGoalDetail("increase", Selectedoptimise);
    }
  };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} />
      <div className={classes.stepTwoWrapper}>
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step></Step>
          <Step></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Select growth areas{" "}
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div className={classes.stepTwoSubWrapper}>
          <img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" />
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            You have selected{" "}
            <span style={{ color: "#9747FF", textAlign: "center" }}>
              {" "}
              {selectedDiamenstion}
            </span>{" "}
          </Typography>
          <Typography
            variant="body4"
            sx={{ margin: "16px 0px", textAlign: "center" }}
          >
            What would you like to do with selected dimension?
          </Typography>
          <Box className={classes.radioWrapper}>
            {optimiseDiamension?.map((item) => {
              return (
                <div
                  key={item}
                  onClick={() => {
                    setSelectedoptimise(item);
                    getGoalDetail("increase", "");
                  }}
                  className={
                    item === Selectedoptimise || goalDetail.increase === item
                      ? classes.checkedCustomRadio
                      : classes.customRadio
                  }
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        (item === Selectedoptimise ||
                          goalDetail.increase === item) &&
                        "#9747FF",
                    }}
                    className={classes.checkBoxText}
                  >
                    {item}{" "}
                  </Typography>
                  {item !== Selectedoptimise && item === "Calibrate" && <div style={{ position: "absolute", right: "50px" }}>
                    <HTMLTooltip
                      title={"Positive traits like courage, when miss-calibrated, can become less beneficial and even harmful. At optimal levels, courage allows us to take risks, face our fears, and tackle challenges head-on. It's a crucial trait for personal growth and achieving goals. However, when overused, it can lead to reckless behavior, ignoring reasonable risks, or failing to consider the potential consequences of our actions."}
                      placement="right"
                      showArrow={true}
                      sx={{
                        '& .MuiTooltip-tooltip': {
                          background: "#FFF",
                          color: "#000",
                          maxWidth: "350px"
                        }
                      }}
                    />
                  </div>}
                  {(item === Selectedoptimise ||
                    goalDetail.increase === item) && (
                      <img
                        src={CheckedIcon}
                        style={{ position: "absolute", right: "16px" }}
                        alt=""
                      />
                    )}


                </div>
              );
            })}
          </Box>

        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px", padding: '0 40px' }}>
          <ButtonGroup
            onNext={onNext}
            onPrevious={onPrevious}
            updateMainState={updateMainState}
            isNextDisable={
              Selectedoptimise === ""
                ? goalDetail?.increase === undefined
                  ? true
                  : false
                : goalDetail?.increase === undefined
                  ? true
                  : false
            }
          />
        </div>
      </div>
    </>

  );
}

export default StepTwo;
