import { Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import {
  DATE_PICKER,
  optionGroups,
  RANGEPICKERTYPE,
  TIME_PICKER,
} from "../../../Constant/GoalConstant";
import { CalanderDialog } from "../../GoalComponent/GoalDialog";
import classes from "./GoalDateTime.module.css";
import Clock from "../../../assets/Svg/Clock.svg";
import DatePicker from "../../DatePicker/DatePicker";
import Picker from "react-mobile-picker-scroll";
import { useDispatch, useSelector } from "react-redux";
import useCustomNotification from "../../../Hooks/useCustomNotification";
import ChipSaveButton from "../../ChipSaveButton";
function GoalDateTimePicker({
  editActionTextHandler,
  isSchedule,
  setIsSchedule,
  setCustomNotificationColourPick,
}) {
  const { ActiondataSlice } = useSelector((state) => state);
  const [timePicker, setTimePicker] = useState(TIME_PICKER.TIME_PICKER_START);
  const [datePicker, setDatePicker] = useState(DATE_PICKER.DATE_PICKER_START);
  const [isValid, setIsValid] = useState("");
  const tempStartDate =
    ActiondataSlice?.scheduleState.executionDateEnd?.split("T");
  console.log(tempStartDate[0]);
  const [startDate, setStartDate] = useState(new Date(tempStartDate[0]));

  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  const [startTimevalueGroups, setStartTimevalueGroups] = useState({
    hour: moment(new Date(ActiondataSlice?.scheduleState.executionDate))
      .format("HH"),
    minutus: moment(new Date(ActiondataSlice?.scheduleState.executionDate))
      .format("mm"),
    AMPM: moment
      .utc(new Date(ActiondataSlice?.scheduleState.executionDate))
      .format("A"),
  });
  const [endTimeValuegroup, setEndTimeValuegroup] = useState({
    hour: moment(new Date(ActiondataSlice?.scheduleState.executionDateEnd))
      .format("HH"),
    minutus: moment(new Date(ActiondataSlice?.scheduleState.executionDateEnd))
      .format("mm"),
    AMPM: moment
      .utc(new Date(ActiondataSlice?.scheduleState.executionDateEnd))
      .format("A"),
  });
  const [rangePickerType, setRangePickerType] = useState(
    RANGEPICKERTYPE?.DATE_RANGE_PICKER
  );

  const handleChange = (name, value) => {
    console.log("value", value, name)
    if (timePicker === TIME_PICKER.TIME_PICKER_START) {
      setStartTimevalueGroups({
        ...startTimevalueGroups,
        [name]: value,
      });
      const executionDate = moment(startDate, "yyyy-MM-DDT").set({
        hour: startTimevalueGroups.hour,
        minutes: startTimevalueGroups.minutus
      }).add(30, 'minutes').format();
      setEndTimeValuegroup({
        hour: moment(new Date(executionDate))
          .format("HH"),
        minutus: moment(new Date(executionDate))
          .format("mm"),
        AMPM: moment
          .utc(new Date(executionDate))
          .format("A"),
      })
    } else {
      setIsValid(false);
      setEndTimeValuegroup({
        ...endTimeValuegroup,
        [name]: value,
      });
    }
  };
  console.log("endTimeValuegroup", endTimeValuegroup);
  const modalClosehandler = () => {
    const convetedStartDate = moment(startDate).format("yyyy-MM-DDT");
    const convetedEndDate = moment(startDate).format("yyyy-MM-DDT");

    const executionDate = moment(convetedStartDate, "yyyy-MM-DDT").set({
      hour: startTimevalueGroups.hour,
      minutes: startTimevalueGroups.minutus
    }).format();
    const executionEnddate = moment(convetedEndDate, "yyyy-MM-DDT").set({
      hour: endTimeValuegroup.hour,
      minutes: endTimeValuegroup.minutus
    }).format();
    if (
      moment(new Date(executionDate)) <
      moment(new Date(executionEnddate))
    ) {
      setIsSchedule(false);
      editActionTextHandler(executionDate, executionEnddate);
    } else {
      console.log("helo");
      setIsSchedule(true);
      setIsValid(true);
      setCustomNotificationColourPick(true);
    }
  };
  return (
    <>
      <CalanderDialog
        open={isSchedule}
        onClose={modalClosehandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.calanderWrapper}>
          <div className={classes.DateAndTimewrapper}>
            <img src={Clock} />
            <Typography variant="body2" sx={{ color: "#190D1A" }}>
              Set duration
            </Typography>
          </div>
          <div style={{ width: "100%" }}>
            <Typography variant="small">Starts</Typography>
            <div className={classes.dateTimeRangePickerWrapper}>
              <div className={classes.dateTimeRangePickerSubWrapper}>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      rangePickerType === RANGEPICKERTYPE.DATE_RANGE_PICKER ?
                        "#9747FF" : "black",
                  }}
                  onClick={() => {
                    setRangePickerType(RANGEPICKERTYPE.DATE_RANGE_PICKER);
                    setDatePicker(DATE_PICKER.DATE_PICKER_START);
                  }}
                >
                  {" "}
                  {moment(startDate).format("DD MMM YYYY")}
                </Typography>
              </div>
              <div className={classes.dateTimeRangePickerSubWrapper}>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      rangePickerType === RANGEPICKERTYPE.TIME_RANGE_PICKER ?
                        "#9747FF" : "black",
                  }}
                  onClick={() => {
                    setRangePickerType(RANGEPICKERTYPE.TIME_RANGE_PICKER);
                    setTimePicker(TIME_PICKER.TIME_PICKER_START);
                    setDatePicker(DATE_PICKER.DATE_PICKER_START);
                  }}
                >
                  {`${startTimevalueGroups.hour}:${startTimevalueGroups.minutus}`}
                </Typography>
              </div>
            </div>
          </div>
          {rangePickerType === RANGEPICKERTYPE.DATE_RANGE_PICKER ? (
            <DatePicker currentDate={startDate} setCurrentDate={setStartDate} />
          ) : (
            <Picker
              optionGroups={optionGroups}
              valueGroups={
                timePicker === TIME_PICKER.TIME_PICKER_START
                  ? startTimevalueGroups
                  : endTimeValuegroup
              }
              onChange={handleChange}
            />
          )}

          <div style={{ width: "100%" }}>
            <Typography variant="small">Ends</Typography>
            <div className={classes.dateTimeRangePickerWrapper}>
              <div className={classes.dateTimeRangePickerSubWrapper}>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      rangePickerType === RANGEPICKERTYPE.DATE_RANGE_PICKER ?
                        "#9747FF" : 'black',
                  }}
                  onClick={() => {
                    setRangePickerType(RANGEPICKERTYPE.DATE_RANGE_PICKER);
                    setDatePicker(DATE_PICKER.DATE_PICKER_END);
                  }}
                >
                  {moment(startDate).format("DD MMM YYYY")}
                </Typography>
              </div>
              <div className={classes.dateTimeRangePickerSubWrapper}>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      rangePickerType === RANGEPICKERTYPE.TIME_RANGE_PICKER ?
                        "#9747FF" : 'black',
                  }}
                  onClick={() => {
                    setRangePickerType(RANGEPICKERTYPE.TIME_RANGE_PICKER);
                    setTimePicker(TIME_PICKER.TIME_PICKER_END);
                    setDatePicker(DATE_PICKER.DATE_PICKER_END);
                  }}
                >
                  {`${endTimeValuegroup.hour}:${endTimeValuegroup.minutus}`}
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "15px", fontSize: "14px" }}>
            <Typography variant="p" >
              {isValid && "Start Time should be less than End Time"}
            </Typography>
          </div>
          <ChipSaveButton
            title="Save"
            onSave={modalClosehandler}
          />
        </div>
      </CalanderDialog>
    </>
  );
}
export default GoalDateTimePicker;
