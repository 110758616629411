import axios from "axios";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../config/firebase";
import { baseEndpoints } from "./config";

export const socialMediaAuth = async (provider) => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      console.log("user", user);
      return user;
    })
    .catch((err) => {
      console.log(16, err);
      return err;
    });
};

export const createUser = async (userData) => {
  const { data } = await axios
    .post(`${baseEndpoints.createUser}`, userData)
    .then((response) => {
      return response;
    });
  return data;
};

export const userLogin = async (body) => {
  const { data } = await axios
    .post(`${baseEndpoints.userLogin}`, body)
    .then((response) => {
      return response;
    });
  return data;
};

export const updateUserData = async (userData) => {
  const { data } = await axios
    .post(`${baseEndpoints.updateUser}`, userData)
    .then((response) => {
      return response;
    });
  return data;
};

export const updateUserSignupData = async (userData) => {
  const { data } = await axios
    .post(`${baseEndpoints.updateSignupUser}`, userData)
    .then((response) => {
      return response;
    });
  return data;
};

export const getUserData = async (user) => {
  const { data } = await axios
    .post(`${baseEndpoints.getUser}`, { uid: user })
    .then((response) => {
      return response;
    });
  return data;
};

export const forgotPassword = async (userEmail) => {
  const { data } = await axios
    .post(`${baseEndpoints.forgotPassword}`, { user_email: userEmail })
    .then((response) => {
      return response;
    });
  return data;
};

export const updatePassword = async (userData) => {
  const { data } = await axios
    .post(`${baseEndpoints.updatePassword}`, {
      user_email: userData.user_email,
      newPassword: userData.newPassword,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const termsAndConditionContent = async () => {
  const { data } = await axios
    .post(`${baseEndpoints.termsAndCondition}`)
    .then((response) => {
      return response;
    });
  return data;
};

export const categoryList = async (values) => {
  const { data } = await axios
    .post(`${baseEndpoints.categoryList}`, values)
    .then((response) => {
      return response;
    });
  return data;
};

export const categoryListResponse = async (values) => {
  const { data } = await axios
    .post(`${baseEndpoints.categoryListResponse}`, {
      uid: values
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const categoryPage = async ({ category_id, uid }, controller) => {
  const { data } = await axios
    .post(`${baseEndpoints.categoryPage}`, { category_id, uid }, { signal: controller})
    .then((response) => {
      return response;
    });
  return data;
};
export const categoryPageNew = async ({ category_id, uid }) => {
  const { data } = await axios
    .post(`${baseEndpoints.categoryPageNew}`, { category_id, uid })
    .then((response) => {
      return response;
    });
  return data;
};
export const subcategoriesByInvite = async ({ category_id, uid }) => {
  const { data } = await axios
    .post(`${baseEndpoints.subCategoriesByInvite}`, { category_id, uid })
    .then((response) => {
      return response;
    });
  return data;
};

export const getQuestionsList = async ({ subCategory_id, uid }) => {
  const { data } = await axios
    .post(`${baseEndpoints.getAllQuestions}`, {
      subCategory_id,
      uid,
      multiple: false,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const storeAnswer = async ({ uid, answerId, questionId }) => {
  const body = {
    questionId,
    answerId: [answerId],
    multipleAnswers: false,
    uid,
  };
  const { data } = await axios
    .post(`${baseEndpoints.storeAnswer}`, body)
    .then((response) => {
      return response;
    });
  return data;
};

export const getTestDimensions = async (subCategory_id) => {
  const { data } = await axios
    .post(`${baseEndpoints.getDimensions}`, { subCategory_id })
    .then((response) => {
      return response;
    });
  return data;
};

export const getAllTestScores = async ({ uid, subCategory_id }) => {
  const { data } = await axios
    .post(`${baseEndpoints.getAllTestScores}`, { uid, subCategory_id })
    .then((response) => {
      return response;
    });
  return data;
};

export const archivedAnswer = async ({ uid, subCategory_id }) => {
  const { data } = await axios
    .post(`${baseEndpoints.archivedAnswer}`, { uid, subCategory_id })
    .then((response) => {
      return response;
    });
  return data;
};

export const inviteOther = async (inviteData) => {
  const { data } = await axios
    .post(`${baseEndpoints.inviteOther}`, inviteData)
    .then((response) => {
      return response;
    });
  return data;
};

export const inviteTestQuestionList = async ({ uid, inviteID }) => {
  const { data } = await axios
    .post(`${baseEndpoints.inviteTestQuestionList}`, { uid, inviteID })
    .then((response) => {
      return response;
    });
  return data;
};

export const inviteTestAnswerList = async ({
  uid,
  answerId,
  questionId,
  inviteID,
}) => {
  const body = {
    questionId,
    answerId: [answerId],
    multipleAnswers: false,
    uid,
    inviteID,
  };
  const { data } = await axios
    .post(`${baseEndpoints.inviteTestAnswerList}`, body)
    .then((response) => {
      return response;
    });
  return data;
};

export const inviteTestReflectiveQuestionList = async ({ uid, inviteID }) => {
  const { data } = await axios
    .post(`${baseEndpoints.inviteTestReflectiveQuestionList}`, {
      uid,
      inviteID,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const inviteTestReflectiveAnswer = async ({
  uid,
  inviteID,
  inviteQuestionID,
  answer,
}) => {
  const { data } = await axios
    .post(`${baseEndpoints.inviteTestReflectiveAnswer}`, {
      uid,
      inviteID,
      inviteQuestionID,
      answer,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const anonymousUpdate = async (anonymousData) => {
  const { data } = await axios
    .post(`${baseEndpoints.anonymousUpdateUserData}`, anonymousData)
    .then((response) => {
      return response;
    });
  return data;
};

export const getInvite = async ({ inviteID }) => {
  const { data } = await axios
    .post(`${baseEndpoints.getInvite}`, { inviteID })
    .then((response) => {
      return response;
    });
  return data;
};

export const inviteRegister = async ({ uid, inviteId, anonymousStatus }) => {
  const { data } = await axios
    .post(`${baseEndpoints.inviteRegister}`, {
      uid,
      inviteID: inviteId,
      anonymousStatus,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const archiveInviteTestAnswer = async ({
  uid,
  inviteID,
  subCategory_id,
}) => {
  const { data } = await axios
    .post(`${baseEndpoints.archiveInviteTestAnswer}`, {
      uid,
      inviteID,
      subCategory_id,
    })
    .then((response) => {
      return response;
    });
  return data;
};
export const ResultAllTestDiamention = async (user) => {
  const { data } = await axios
    .post(`${baseEndpoints.resultAllTestDiamention}`, { uid: user })
    .then((response) => {
      return response;
    });
  return data;
};
export const ResultAllTestDiamentionNew = async (user, categoryId) => {
  const { data } = await axios
    .post(`${baseEndpoints.resultAllTestDiamentionNew}`, { uid: user, category_id: categoryId, })
    .then((response) => {
      return response;
    });
  return data;
};
export const SocialUserList = async (uid, category_id, subCategory_id) => {
  const { data } = await axios
    .post(`${baseEndpoints.userListBYTest}`, {
      uid,
      category_id,
      subCategory_id,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const AllDiamentionByTest = async (uid, category_id, subCategory_id) => {
  const { data } = await axios
    .post(`${baseEndpoints.allDiamentionByTest}`, {
      uid,
      category_id,
      subCategory_id,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const InviteUserList = async (uid) => {
  const { data } = await axios
    .post(`${baseEndpoints.inviteUserList}`, {
      uid,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const AllDiamentionByInvite = async (uid, ownerId) => {
  const { data } = await axios
    .post(`${baseEndpoints.allDiamentionByInvite}`, {
      uid,
      ownerUid: localStorage.getItem("userDetail")
    })
    .then((response) => {
      return response;
    });
  return data;
};
export const InviteQuetionList = async (uid, inviteID) => {
  const { data } = await axios
    .post(`${baseEndpoints.inviteResultQuetionList}`, {
      uid,
      inviteID,
    })
    .then((response) => {
      return response;
    });
  return data;
};
export const feedbackDiamentionCreate = async (feedbackData) => {
  const { data } = await axios
    .post(`${baseEndpoints.feedbackDiamention}`, feedbackData)
    .then((response) => {
      return response;
    });
  return data;
};

export const AllDiamentionAverageInvite = async (
  uid,
  type,
  from,
  to,
  feedbackType
) => {
  const { data } = await axios
    .post(`${baseEndpoints.allDiamentionAverageInvite}`, {
      uid,
      type,
      from,
      to,
      feedbackType,
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const AllDiamentionByTestInvite = async (
  uid,
  category_id,
  subCategory_id
) => {
  const { data } = await axios
    .post(`${baseEndpoints.allDiamentionByTestInvite}`, {
      uid,
      category_id,
      subCategory_id,
      ownerUid: localStorage.getItem("userDetail")
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const allDiamentionAverageByInviteUser = async (
  uid,
  type,
  from,
  to,
  feedbackType,
  questionCategoryId
) => {
  const { data } = await axios
    .post(`${baseEndpoints.allDiamentionAverageByInviteUser}`, {
      uid,
      type,
      from,
      to,
      feedbackType,
      questionCategoryId,
    })
    .then((response) => {
      return response;
    });
  return data;
};

// goal api
export const getGoals = async (uid, archive, status) => {
  const { data } = await axios
    .post(`${baseEndpoints.getGoals}`, { uid, archive, status })
    .then((response) => {
      return response;
    });
  return data;
};
export const goalsAllDiamension = async (uid) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalAllDiamension}`, { uid })
    .then((response) => {
      return response;
    });
  return data;
};

export const goalCreate = async (createGoalData) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalCreate}`, createGoalData)
    .then((response) => {
      return response;
    });
  return data;
};
export const goalGetPreSelectedGrowth = async (uid, questionCategoryId) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalGetPreSelectedGrowth}`, {
      uid,
      questionCategoryId,
    })
    .then((response) => {
      return response;
    });
  return data;
};
export const GoalFaverouteDiamention = async (uid, type) => {
  const { data } = await axios
    .post(`${baseEndpoints.resultAllTestDiamention}`, { uid, type })
    .then((response) => {
      return response;
    });
  return data;
};

export const createGoalHabitAction = async (habitActionData) => {
  const { data } = await axios
    .post(`${baseEndpoints.createGoalHabitAction}`, habitActionData)
    .then((response) => {
      return response;
    });
  return data;
};
export const editPreSelectedGrowth = async (selectedToCompletedGrowth) => {
  const { data } = await axios
    .post(`${baseEndpoints.editPreSelectedGrowth}`, selectedToCompletedGrowth)
    .then((response) => {
      return response;
    });
  return data;
};
export const removeGrowthArea = async (removeGrowthAreaData) => {
  const { data } = await axios
    .post(`${baseEndpoints.removeGrowthArea}`, removeGrowthAreaData)
    .then((response) => {
      return response;
    });
  return data;
};
export const goalCreateHabitAction = async (createActionHabit) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalCreateHabitAction}`, createActionHabit)
    .then((response) => {
      return response;
    });
  return data;
};
export const goalUpdateHabitAction = async (updateActionHabit) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalUpdateHabitAction}`, updateActionHabit)
    .then((response) => {
      return response;
    });
  return data;
};
export const goalDeleteHabitAction = async (removeActionHabit) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalDeleteHabitAction}`, removeActionHabit)
    .then((response) => {
      return response;
    });
  return data;
};

export const goalCompleteHabitAction = async (removeActionHabit) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalDeleteCompleteAction}`, removeActionHabit)
    .then((response) => {
      return response;
    });
  return data;
};

export const goalDuplicateHabitAction = async (duplicateData) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalDuplicateHabitAction}`, duplicateData)
    .then((response) => {
      return response;
    });
  return data;
};

export const goalArchive = async (archiveData) => {
  const { data } = await axios
    .post(`${baseEndpoints.goalArchive}`, archiveData)
    .then((response) => {
      return response;
    });
  return data;
};

export const futureTestCreate = async (futureTestData) => {
  const { data } = await axios
    .post(`${baseEndpoints.futureTestCreate}`, futureTestData)
    .then((response) => {
      return response;
    });
  return data;
};

export const questionCategoryCreateFavorite = async (favoriteData) => {
  const { data } = await axios
    .post(`${baseEndpoints.questionCategoryCreateFavorite}`, favoriteData)
    .then((response) => {
      return response;
    });
  return data;
};
export const questionCategoryDeleteFavorite = async (favoriteData) => {
  const { data } = await axios
    .post(`${baseEndpoints.questionCategoryDeleteFavorite}`, favoriteData)
    .then((response) => {
      return response;
    });
  return data;
};

export const unlockGAAction = async (values) => {
  const { data } = await axios
    .post(`${baseEndpoints.unlockGAAction}`, values)
    .then((response) => {
      return response;
    });
  return data;
}

export const deleteUserAccount = async (values) => {
  const { data } = await axios
    .post(`${baseEndpoints.deleteUser}`, values)
    .then((response) => {
      return response;
    });
  return data;
}

export const getGrowthData = async (growthData) => {
  const { data } = await axios
    .post(`${baseEndpoints.getGrowthData}`, growthData)
    .then((response) => {
      return response;
    });
  return data;
};

export const growthCalenderCreate = async (growthData) => {
  const { data } = await axios
    .post(`${baseEndpoints.growthCalenderCreate}`, growthData)
    .then((response) => {
      return response;
    });
  return data;
};
export const growthCalenderGetCalenderData = async (growthCalenderGetData) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.growthCalenderGetCalenderData}`,
      growthCalenderGetData
    )
    .then((response) => {
      return response;
    });
  return data;
};

export const growthCalenderUpdate = async (updateData) => {
  const { data } = await axios
    .post(`${baseEndpoints.growthCalenderUpdate}`, updateData)
    .then((response) => {
      return response;
    });
  return data;
};

export const getTopicList = async (updateData) => {
  const { data } = await axios
    .post(`${baseEndpoints.topiclist}`, updateData)
    .then((response) => {
      return response;
    });
  return data;
};

export const updateHabitActionCalender = async (updateActionData) => {
  const { data } = await axios
    .post(`${baseEndpoints.updateHabitActionCalender}`, updateActionData)
    .then((response) => {
      return response;
    });
  return data;
};

export const generateAnoName = async (updateActionData) => {
  const { data } = await axios
    .post(`${baseEndpoints.generateAnoName}`, updateActionData)
    .then((response) => {
      return response;
    });
  return data;
};

export const engagementStatus = async (updateActionData) => {
  const { data } = await axios
    .post(`${baseEndpoints.engagementStatus}`, updateActionData)
    .then((response) => {
      return response;
    });
  return data;
};

export const featureRequestData = async (featurData) => {
  const { data } = await axios
    .post(`${baseEndpoints.featureCreateData}`, featurData)
    .then((response) => {
      return response;
    });
  return data;
};

export const reportBugData = async (bugData) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.bugCreate}?uid=${bugData.uid}&description=${bugData.description}&bugType=${bugData.bugType}&user_email=${bugData.user_email}`,
      bugData.photo
    )
    .then((response) => {
      return response;
    });
  return data;
};

export const giveFeedbackData = async (feedbackData) => {
  const { data } = await axios
    .post(`${baseEndpoints.giveFeedback}`, feedbackData)
    .then((response) => {
      return response;
    });
  return data;
};

export const upadateProfileImage = async (profile, photo) => {
  const { data } = await axios
    .post(`${baseEndpoints.updateImage}?uid=${profile.uid}`, photo)
    .then((responce) => {
      return responce;
    });
  return data;
};

export const deleteProfileImage = async (uid) => {
  const { data } = await axios
    .post(`${baseEndpoints.deleteProfile}`, uid)
    .then((response) => {
      return response;
    });
  return data;
};

export const setNewPasscode = async (passcode) => {
  const { data } = await axios
    .post(`${baseEndpoints.passcode}`, passcode)
    .then((response) => {
      return response;
    });
  return data;
};
