import { Fragment, createRef, useEffect, useRef, useState } from "react"
import { LoadingButton } from "@mui/lab";
import classes from "./plaanModal.module.css"
import { Avatar, Box, Typography, } from "@mui/material"
import { GeneralSX } from "../../../components/Text/Text"
import { useDispatch, useSelector } from "react-redux"
import { setStoreData, updateJournalOfPlanAction } from "../../../Store/Reducers/plans"
import Confetti from "react-confetti";
import XP from "../../../assets/Svg/XP.svg";
import PlansServices from "../../../services/Plans";
import WinningCel from "./../../../assets/Svg/WinningCel.svg"
import palette from "../../../theme/palette";
const CelebrationModal = () => {
    const dispatch = useDispatch()
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const confettiRef = useRef(null);
    const { setp, selectedAction, selectedPlan } = useSelector((state) => state.planData);
    useEffect(() => {
        setHeight(confettiRef?.current?.clientHeight);
        setWidth(confettiRef?.current?.clientWidth);
    }, []);

    return <Fragment>
        <div style={{ width: '100%', height: '100%', display: 'flex', alignSelf: 'stretch' }} ref={confettiRef}>
            <div className={classes.cel_title}>
                <Confetti width={width} height={height} opacity={0.4} />
            </div>
            <div className={classes.cel_info}>
                <div className={classes.cel_info1}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.common.mainBlack,
                            fontSize: 40,
                            fontWeight: 600,
                            lineHeight: "120%",
                            letterSpacing: '-0.4px',

                        }),
                        zIndex: 2,
                        textAlign: 'center'
                    }}>You’ve just completed Day {selectedAction?._id?.dayNumber} of {selectedPlan.name}</Typography>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.common.mainBlack,
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "150%",
                            letterSpacing: '-0.16px',

                        }),
                        zIndex: 2,
                        textAlign: 'center'

                    }}>Come back tomorrow for new action</Typography>
                </div>
                <div className={classes.cel_info1}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: '#767476',
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "150%",
                            letterSpacing: '-0.16px',

                        }),
                        zIndex: 2,
                        textAlign: 'center'

                    }}>You’ve earned</Typography>
                    <Tag xp={450} />
                </div>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} position={"absolute"} bottom={'40px'} alignSelf={"stretch"} width={"100%"}>
                    <LoadingButton
                        variant="contained"
                        className={classes.cel_info_button}
                        onClick={(e) => {
                            dispatch(setStoreData({
                                viewPlanModal: false,
                                setp: 1,
                            }));
                            // openCloseCelebrationModal(false);
                        }}
                    >
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.common.white,
                                fontSize: 16,
                                fontWeight: 400,
                                lineHeight: "100%",
                                letterSpacing: '-0.16px',

                            }),
                            zIndex: 2,
                            textAlign: 'center'
                        }}>Go back home</Typography>
                    </LoadingButton>
                </Box>
            </div>

        </div>

    </Fragment>
}

export default CelebrationModal

const Tag = ({ xp }) => {
    return <div className={classes.tag1}>
        <div className={classes.tag1Inner}>
            <Avatar alt="Natacha" sx={{ width: '16px', height: '16px' }} src={XP} />
            <Typography sx={{
                ...GeneralSX({
                    color: palette.primary.main,
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: '-0.16px'
                })
            }}>{xp}</Typography>
        </div>

    </div>
}