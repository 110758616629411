import React, { memo, useEffect, useState } from "react";

import {
  AllDiamentionByInvite,
  categoryList,
  categoryListResponse,
  feedbackDiamentionCreate,
  getUserData,
  ResultAllTestDiamention,
  unlockGAAction,
} from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { storeTestCategoriesList } from "../../Store/Reducers/testCategoriesList";

import { storeResultAllDiamention, updateModalData, updateResultState } from "../../Store/Reducers/ResultAllDiamention";
import { TYPEOFRESULTS } from "../../Constant/Invite";

import CategorySubcategoryService from "../../services/CategorySubcategory";
import { updateUserWalletCoins, userProfileUpdate } from "../../Store/Reducers/userProfileSlice";
import useCustomNotification from "../../Hooks/useCustomNotification";
import ConfirmationModal from "./Modal/ConfirmatioinModal";
import PersonalResultComponent from "./personalResultContainer";
import useNotifications from "../../Hooks/useNotifications";
import ResultTitle from "./resultTitle";
import ConfirmationModalPayment from "./ConfirmatioinModal";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentService from "../../services/Payments";
import UnlockSuccessFullModal from "./UnlockSuccessfullModal";
const TestResultData = () => {
  const { resultAllDiamention, testCategoriesList } = useSelector(
    (state) => state
  );
  const { selectedInviteDataSlice, averageByInviteUserSlice } = useSelector(
    (state) => state
  );
  const {
    generatingPaymentLoader = false,
    selectedItem = {},
    visibleConfirmation = false,
    spacificPersonInviteId = '',
    QuetionCatagoriesId = "",
    finalirrelevantdata = [],
    irreleventRating = "",
    isCatagories = true,
    notificationMessage = "",
  } = resultAllDiamention

  const dispatch = useDispatch();
  const setResultAllDiamention = (val) => dispatch(updateResultState({
    ResultDiamention: val
  }))
  const setGeneratingPaymentLoader = val => dispatch(updateResultState({
    generatingPaymentLoader: val
  }))
  const setSelectedItem = val => dispatch(updateResultState({
    selectedItem: val
  }))
  const setConfirmationVisible = val => dispatch(updateResultState({
    visibleConfirmation: val
  }))

  const setTypeofResult = val => dispatch(updateResultState({
    typeofResult: val
  }))

  const setIsResultLoading = val => dispatch(updateResultState({
    isResultLoading: val
  }))

  const setFinalinaccuratedata = val => dispatch(updateResultState({
    finalinaccuratedata: val
  }))

  const setinaccurateRatting = val => dispatch(updateResultState({
    inaccurateRatting: val
  }))

  const setIsHandleCloseCall = val => dispatch(updateResultState({
    isHandleCloseCall: val
  }))
  const setIsCatagories = val => dispatch(updateResultState({
    isCatagories: val
  }))
  const setNotificationMessage = val => dispatch(updateResultState({
    notificationMessage: val
  }))
  const { NotificationContentJSX, customNotification, setCustomNotification } =
    useCustomNotification();
  const { Notification, updateNotificationState } = useNotifications(false);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.userProfile);
  const [unlockSuccessfullModalVisible, setUnlockSuccessfullModalVisible] = useState(false)
  const [paymentId, setSelectedId] = useState(null)
  const [loadingPaymentStatus, setLoadingPaymentStatus] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [selectedItemPurchase, setSelectedItemPurchase] = useState({})
  useEffect(() => {
    if (pathname.includes('results-payment')) {
      const selectedItemLocal = localStorage.getItem("selectedItem")
      if (selectedItemLocal) {
        setSelectedId(selectedItemLocal)
        const internalLoop = () => {
          if (userData?.uid && selectedItemLocal) {
            getPaymentStatus(selectedItemLocal)
            if (localStorage.getItem("selectedItemForPurchase") && Object.keys(JSON.parse(localStorage.getItem("selectedItemForPurchase"))).length) {
              setSelectedItemPurchase(JSON.parse(localStorage.getItem("selectedItemForPurchase")))
            }
            !unlockSuccessfullModalVisible && setUnlockSuccessfullModalVisible(true)
            // localStorage.removeItem("selectedItem")
            // localStorage.removeItem("selected_item")
          } else {
            setTimeout(() => {
              internalLoop()
            }, 1000);
          }
        }
        internalLoop()
      } else {
        navigate('/results')
      }
    }
  }, [paymentId])
  const getPaymentStatus = (id) => {
    setLoadingPaymentStatus(true)
    const objForServer = {
      "user_email": userData?.user_email,
      "uid": userData?.uid,
      "id": id
    }
    PaymentService.PAYMENT_STATUS(objForServer)
      .then(async (res) => {
        if (["paid",].includes(res.data.payment_status)) {
          setPaymentSuccess(true)

          if (res.data.metadata.type === "SHOP_VIRTUAL_CURRENCY") {
            getUserData(userData?.uid)
              .then((response) => {
                dispatch(userProfileUpdate(response.data));
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
              });

          }
          if (res.data.metadata.type === "SHOP_TEST") {
          }
          if (res.data.metadata.type === "SHOP_DIMENSION") {
          }
        } else {
          setPaymentSuccess(false)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingPaymentStatus(false)
      });
  }
  // api calling for feedback
  const feedBackDataHandler = (ratingInaccurate, inaccurateData) => {
    setinaccurateRatting(ratingInaccurate);
    setFinalinaccuratedata(inaccurateData);
    const user = localStorage.getItem("userDetail");
    let FinalFeedBackData = {
      uid: user,
      ratingIrrelevant: irreleventRating,
      inaccurate: inaccurateData,
      ratingInaccurate: ratingInaccurate,
      questionCategoryId: QuetionCatagoriesId,
      irrelevant: finalirrelevantdata,
    };

    feedbackDiamentionCreate(FinalFeedBackData)
      .then((response) => {
        console.log("response>>>>>>", response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDimentions = (showLoader = true) => {
    setIsResultLoading(showLoader);
    const user = localStorage.getItem("userDetail");
    // if(ResultAllDiamention.length === 0){
    ResultAllTestDiamention(user)
      .then((data) => {
        setResultAllDiamention(data?.allPercentage);
        dispatch(storeResultAllDiamention(data?.allPercentage));
        setIsHandleCloseCall(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsResultLoading(false);
      });
  }
  useEffect(() => {
    setIsResultLoading(true);
    if (
      selectedInviteDataSlice?.isSelectedSocialUser &&
      selectedInviteDataSlice?.selectedinviteData?.inviteUserUid
    ) {
      AllDiamentionByInvite(
        selectedInviteDataSlice?.selectedinviteData?.inviteUserUid
      )
        .then((data) => {
          setResultAllDiamention(data?.allPercentage);
          dispatch(storeResultAllDiamention(data?.allPercentage));
          setIsHandleCloseCall(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsResultLoading(true);
        });
    } else {
      getDimentions(false)
    }
    // }
    if (averageByInviteUserSlice.isSocialResultNavigate) {
      setTypeofResult(TYPEOFRESULTS.SOCIAL);
    }
    updateUserData()
  }, [spacificPersonInviteId, selectedInviteDataSlice]);


  // const { isLoading: isCatagories } = useQuery(
  //   "result-test-categorys",
  //   fetchTestCatagories,
  //   {
  //     onSuccess: (data) => {
  //       dispatch(storeTestCategoriesList(data?.categoryData));
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const fetchTestCategories = (runLoader = true) => {
    setIsCatagories(runLoader);
    const user = localStorage.getItem("userDetail");
    categoryListResponse(user)
      .then((res) => {
        console.log(res);
        dispatch(storeTestCategoriesList(res?.categoryData));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsCatagories(false);
      });
  }

  const UnlockGAAction = (type, object) => {
    unlockGAAction(object).then(data => {
      if (type === "GROWTH") {
        dispatch(updateModalData({
          growth: true,
          idx: 0
        }))
        dispatch(updateUserWalletCoins())

        // const modelInfo = {...modalData};
        // console.log("modelInfo", modelInfo);
        // modelInfo.data[0].unlockgrowthandactions[0] = {
        //   ...modelInfo.data[0].unlockgrowthandactions[0],
        //   growth: modelInfo.data[0].unlockgrowthandactions[0].growth + 3
        // }
        // setModalData(modelInfo)
        // dispatch(updateResultState({
        //   actionCount: growthCount + 3
        // }))
      }
      if (type === "ACTION") {

        dispatch(updateModalData({
          action: true,
          idx: 0
        }))
        dispatch(updateUserWalletCoins())
        // dispatch(updateResultState({
        //   actionCount: actionCount + 3
        // }))
        // const modelInfo = {...modalData};
        // modelInfo.data[0].unlockgrowthandactions[0] = {
        //   ...modelInfo.data[0].unlockgrowthandactions[0],
        //   action: modelInfo.data[0].unlockgrowthandactions[0].action + 3
        // }
        // setModalData(modelInfo)
      }
    }).catch(err => {
      if (err.response.data.description) {
        updateNotificationState(err.response.data.description);
        setTimeout(() => {
          updateNotificationState("", false);
        }, 2000);
        // setCustomNotification(err.response.data.description);
        // setCustomNotification(true);

      }
    }).finally(() => {

    })
  }

  useEffect(() => {
    // if (testCategoriesList?.testCategoriesList.length === 0) {
    fetchTestCategories()
    // }
    if (testCategoriesList?.testCategoriesList.length && isCatagories) {
      setIsCatagories(false);
    }
  }, []);

  const updateUserData = () => {
    const user = localStorage.getItem("userDetail");
    if (user) {
      getUserData(user)
        .then((response) => {
          console.log(response.data);
          // navigate("/onbording");
          dispatch(userProfileUpdate(response.data));
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }
  const unlockCard = (e, questionCategoryId, makeAPI = false) => {
    e.stopPropagation();
    e.preventDefault();
    if (makeAPI) {
      setGeneratingPaymentLoader(true)
      const uid = localStorage.getItem("userDetail");
      CategorySubcategoryService.UNLOCK_DIMANETION({
        uid,
        "questionCategoryId": questionCategoryId
      }).then((data) => {
        getDimentions(false)
        updateUserData()
        if (data) {
          // navigate(`/test/${subCategory_id}`, {
          //   state: {
          //     subCategory,
          //     description,
          //     subCategory_desc,
          //     category_desc,
          //     category,
          //     categoryId: category_id,
          //     subCategoryId: subCategory_id,
          //     imageUrl,
          //   },
          // });
        }
      })
        .catch((error) => {
          if (error?.response?.data?.description) {
            setNotificationMessage(error?.response?.data?.description)
            // setCustomNotification(error?.response?.data?.description);
            setCustomNotification(true);
          }
        }).finally(() => {
          setGeneratingPaymentLoader(false)
          setSelectedItem({})
          setConfirmationVisible(false)
        });
    } else {
      const foundAllSelectedcard = resultAllDiamention?.ResultDiamention.find(it => it.questionCategoryId === questionCategoryId)
      if (foundAllSelectedcard) {
        setSelectedItem(foundAllSelectedcard)
        setConfirmationVisible(true)
      }
    }


  }

  return (
    <>
      {!isCatagories && (
        <ResultTitle />
      )}
      {
        <PersonalResultComponent
          fetchTestCategories={fetchTestCategories}
          getDimentions={getDimentions}
          unlockCard={unlockCard}
          UnlockGAAction={UnlockGAAction}
          Notification={Notification}
          updateNotificationState={updateNotificationState}
          feedBackDataHandler={feedBackDataHandler} />
      }
      {customNotification && (
        <NotificationContentJSX
          message={notificationMessage}
          isThumbIcon={false}
          position="top"
        />
      )}
      {visibleConfirmation && <ConfirmationModal
        visibleConfirmation={visibleConfirmation}
        setConfirmationVisible={setConfirmationVisible}
        selectedItem={selectedItem}
        // getSymboleAndAmountForBuy={getSymboleAndAmountForBuy}
        // unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
        // setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
        // setTestModalVisible={setTestModalVisible}
        // setDiamondModalVisible={setDiamondModalVisible}
        createInvoice={unlockCard}
        generatingPaymentLoader={generatingPaymentLoader}
      />}
      <ConfirmationModalPayment />
      {/* {Notification}  */}
      {unlockSuccessfullModalVisible && <UnlockSuccessFullModal
        selectedItem={selectedItem}
        paymentSuccess={paymentSuccess}
        unlockSuccessfullModalVisible={unlockSuccessfullModalVisible}
        setUnlockSuccessfullModalVisible={setUnlockSuccessfullModalVisible}
        loadingPaymentStatus={loadingPaymentStatus}
      />}
    </>
  );
};
export default memo(TestResultData);
