import { createSlice } from "@reduxjs/toolkit";
import { SELECTLAYOUTBTN, TYPEOFRESULTS } from "../../Constant/Invite";
const initialState = {
  ResultDiamention: [],
  generatingPaymentLoader: false,
  selectedItem: {},
  visibleConfirmation: false,
  selectLayoutBtn: SELECTLAYOUTBTN.GRID,
  typeofResult: TYPEOFRESULTS.PERSONAL,
  modalStep: false,
  sendmodalStep: false,
  congatesStep: false,
  isResultLoading: false,
  isAskOtherModalOpen: false,
  modalData: {},
  spacificPersonInviteId: "",
  specificInviterName: "",
  QuetionCatagoriesId: "",
  finalirrelevantdata: [],
  finalinaccuratedata: [],
  irreleventRating: "",
  inaccurateRatting: "",
  feedBackData: {},
  selectedGrowthArr: [],
  selectedActionArr: [],
  deletedGrowthArr: [],
  deletedActionArr: [],
  finalSelectedGrowthArea: [],
  isHandleCloseCall: false,
  isCatagories: true,
  notificationMessage: "",
  actionCount: 0,
  growthCount: 0,
  tempSelectedGrowthArea: [],
  tempSelectedAction: [],
  tempGrowhtArea: [],
  tempActionArea: [],
  tempDeletedGrowthArea: [],
  tempDeletedAction: [],
  enable: -1,
  selectedScore: null,
  growthAreaActionData: null,
  growthFavorites: [],
  actionFavorites: [],
  growthAreaData: [],
  growthAreaQsn: "",
  progressQsn: "",
  progressData: [],
  // --- inner test
  resultTestDimention: [],
  isAskOtherModalOpenSocial: false,
  isTestBookMark: false,
  isLoading: false,
  testtypeofResult: TYPEOFRESULTS.PERSONAL,
  dimensionsBtnData: { index: 8, text: "View all" },
  ResultAllDiamentionByInvite: [],
  reflectiveQuestion: [],
  subcategories: [],
  selectedSubCategories: [],
  testselectLayoutBtn: SELECTLAYOUTBTN.GRID,
  selectedUserInviteId: "",
  selectedinviteId: "",
  inviteName: "",
  testQuetionCatagoriesId: "",
  testfinalirrelevantdata: [],
  testirreleventRating: "",
  selectedPersonal: false,
  selectedItemForPurchase: {},
  visibleConfirmationModal: false
};
export const ResultDiamentionAll = createSlice({
  name: "ResultDiamentionAll",
  initialState,
  reducers: {
    storeResultAllDiamention: (state, action) => {
      state.ResultDiamention = action.payload;
    },
    updateResultState: (state, action) => {
      if(Object.keys(action.payload).includes("resultTestDimention")) {
        state.resultTestDimention = action.payload.resultTestDimention
      }
      if(Object.keys(action.payload).includes("selectedItemForPurchase")) {
        state.selectedItemForPurchase = action.payload.selectedItemForPurchase
      }
      if(Object.keys(action.payload).includes("visibleConfirmationModal")) {
        state.visibleConfirmationModal = action.payload.visibleConfirmationModal
      }
      if(Object.keys(action.payload).includes("selectedScore")) {
        state.selectedScore = action.payload.selectedScore
      }
      if(Object.keys(action.payload).includes("growthAreaActionData")) {
        state.growthAreaActionData = action.payload.growthAreaActionData
      }
      if(Object.keys(action.payload).includes("growthFavorites")) {
        state.growthFavorites = action.payload.growthFavorites
      }
      if(Object.keys(action.payload).includes("actionFavorites")) {
        state.actionFavorites = action.payload.actionFavorites
      }
      if(Object.keys(action.payload).includes("growthAreaData")) {
        console.log("updating state growthAreaData", action.payload.growthAreaData);
        state.growthAreaData = action.payload.growthAreaData
      }
      if(Object.keys(action.payload).includes("growthAreaQsn")) {
        state.growthAreaQsn = action.payload.growthAreaQsn
      }
      if(Object.keys(action.payload).includes("progressQsn")) {
        state.progressQsn = action.payload.progressQsn
      }
      if(Object.keys(action.payload).includes("progressData")) {
        console.log("updating state progressData", action.payload.progressData);
        state.progressData = action.payload.progressData
      }
      if(Object.keys(action.payload).includes("tempDeletedGrowthArea")) {
        state.tempDeletedGrowthArea = action.payload.tempDeletedGrowthArea
      }
      if(Object.keys(action.payload).includes("tempDeletedAction")) {
        state.tempDeletedAction = action.payload.tempDeletedAction
      }
      if(Object.keys(action.payload).includes("tempSelectedGrowthArea")) {
        state.tempSelectedGrowthArea = action.payload.tempSelectedGrowthArea
      }
      if(Object.keys(action.payload).includes("tempSelectedAction")) {
        state.tempSelectedAction = action.payload.tempSelectedAction
      }
      if(Object.keys(action.payload).includes("tempGrowhtArea")) {
        state.tempGrowhtArea = action.payload.tempGrowhtArea
      }
      if(Object.keys(action.payload).includes("tempActionArea")) {
        state.tempActionArea = action.payload.tempActionArea
      }
      if (Object.keys(action.payload).includes("actionCount")) {
        state.actionCount = action.payload.actionCount;
      }
      if (Object.keys(action.payload).includes("growthCount")) {
        state.growthCount = action.payload.growthCount;
      }
      if (Object.keys(action.payload).includes("generatingPaymentLoader")) {
        state.generatingPaymentLoader = action.payload.generatingPaymentLoader;
      }
      if (Object.keys(action.payload).includes("selectedItem")) {
        state.selectedItem = action.payload.selectedItem;
      }
      if (Object.keys(action.payload).includes("visibleConfirmation")) {
        state.visibleConfirmation = action.payload.visibleConfirmation;
      }
      if (Object.keys(action.payload).includes("selectLayoutBtn")) {
        state.selectLayoutBtn = action.payload.selectLayoutBtn;
      }
      if (Object.keys(action.payload).includes("typeofResult")) {
        state.typeofResult = action.payload.typeofResult;
      }
      if (Object.keys(action.payload).includes("modalStep")) {
        state.modalStep = action.payload.modalStep;
      }
      if (Object.keys(action.payload).includes("sendmodalStep")) {
        state.sendmodalStep = action.payload.sendmodalStep;
      }
      if (Object.keys(action.payload).includes("congatesStep")) {
        state.congatesStep = action.payload.congatesStep;
      }
      if (Object.keys(action.payload).includes("isResultLoading")) {
        state.isResultLoading = action.payload.isResultLoading;
      }
      if (Object.keys(action.payload).includes("isAskOtherModalOpen")) {
        state.isAskOtherModalOpen = action.payload.isAskOtherModalOpen;
      }
      if (Object.keys(action.payload).includes("modalData")) {
        state.modalData = action.payload.modalData;
      }
      if (Object.keys(action.payload).includes("spacificPersonInviteId")) {
        state.spacificPersonInviteId = action.payload.spacificPersonInviteId;
      }
      if (Object.keys(action.payload).includes("specificInviterName")) {
        state.specificInviterName = action.payload.specificInviterName;
      }
      if (Object.keys(action.payload).includes("QuetionCatagoriesId")) {
        state.QuetionCatagoriesId = action.payload.QuetionCatagoriesId;
      }
      if (Object.keys(action.payload).includes("finalirrelevantdata")) {
        state.finalirrelevantdata = action.payload.finalirrelevantdata;
      }
      if (Object.keys(action.payload).includes("finalinaccuratedata")) {
        state.finalinaccuratedata = action.payload.finalinaccuratedata;
      }
      if (Object.keys(action.payload).includes("irreleventRating")) {
        state.irreleventRating = action.payload.irreleventRating;
      }
      if (Object.keys(action.payload).includes("inaccurateRatting")) {
        state.inaccurateRatting = action.payload.inaccurateRatting;
      }
      if (Object.keys(action.payload).includes("feedBackData")) {
        state.feedBackData = action.payload.feedBackData;
      }
      if (Object.keys(action.payload).includes("selectedGrowthArr")) {
        state.selectedGrowthArr = action.payload.selectedGrowthArr;
      }
      if (Object.keys(action.payload).includes("selectedActionArr")) {
        state.selectedActionArr = action.payload.selectedActionArr;
      }
      if (Object.keys(action.payload).includes("deletedGrowthArr")) {
        state.deletedGrowthArr = action.payload.deletedGrowthArr;
      }
      if (Object.keys(action.payload).includes("deletedActionArr")) {
        state.deletedActionArr = action.payload.deletedActionArr;
      }
      if (Object.keys(action.payload).includes("finalSelectedGrowthArea")) {
        state.finalSelectedGrowthArea = action.payload.finalSelectedGrowthArea;
      }
      if (Object.keys(action.payload).includes("isHandleCloseCall")) {
        state.isHandleCloseCall = action.payload.isHandleCloseCall;
      }
      if (Object.keys(action.payload).includes("isCatagories")) {
        state.isCatagories = action.payload.isCatagories;
      }
      if (Object.keys(action.payload).includes("notificationMessage")) {
        state.notificationMessage = action.payload.notificationMessage;
      }
      if (Object.keys(action.payload).includes("enable")) {
        state.enable = action.payload.enable;
      }
      if(Object.keys(action.payload).includes("resultTestDimention")) {
        state.resultTestDimention = action.payload.resultTestDimention
      }
      if(Object.keys(action.payload).includes("isAskOtherModalOpenSocial")) {
        state.isAskOtherModalOpenSocial = action.payload.isAskOtherModalOpenSocial
      }
      if(Object.keys(action.payload).includes("isTestBookMark")) {
        state.isTestBookMark = action.payload.isTestBookMark
      }
      if(Object.keys(action.payload).includes("isLoading")) {
        state.isLoading = action.payload.isLoading
      }
      if(Object.keys(action.payload).includes("testtypeofResult")) {
        state.testtypeofResult = action.payload.testtypeofResult
      }
      if(Object.keys(action.payload).includes("dimensionsBtnData")) {
        state.dimensionsBtnData = action.payload.dimensionsBtnData
      }
      if(Object.keys(action.payload).includes("ResultAllDiamentionByInvite")) {
        state.ResultAllDiamentionByInvite = action.payload.ResultAllDiamentionByInvite
      }
      if(Object.keys(action.payload).includes("reflectiveQuestion")) {
        state.reflectiveQuestion = action.payload.reflectiveQuestion
      }
      if(Object.keys(action.payload).includes("subcategories")) {
        state.subcategories = action.payload.subcategories
      }
      if(Object.keys(action.payload).includes("selectedSubCategories")) {
        state.selectedSubCategories = action.payload.selectedSubCategories
      }
      if(Object.keys(action.payload).includes("testselectLayoutBtn")) {
        state.testselectLayoutBtn = action.payload.testselectLayoutBtn
      }
      if(Object.keys(action.payload).includes("selectedUserInviteId")) {
        state.selectedUserInviteId = action.payload.selectedUserInviteId
      }
      if(Object.keys(action.payload).includes("selectedinviteId")) {
        state.selectedinviteId = action.payload.selectedinviteId
      }
      if(Object.keys(action.payload).includes("inviteName")) {
        state.inviteName = action.payload.inviteName
      }
      if(Object.keys(action.payload).includes("testQuetionCatagoriesId")) {
        state.testQuetionCatagoriesId = action.payload.testQuetionCatagoriesId
      }
      if(Object.keys(action.payload).includes("testfinalirrelevantdata")) {
        state.testfinalirrelevantdata = action.payload.testfinalirrelevantdata
      }
      if(Object.keys(action.payload).includes("testirreleventRating")) {
        state.testirreleventRating = action.payload.testirreleventRating
      }
      if(Object.keys(action.payload).includes("selectedPersonal")) {
        state.selectedPersonal = action.payload.selectedPersonal
      }
    },
    updateResultDataSetData: (state, action) => {
      if (action.payload.questionCategoryId) {
        const foundCategoryIndex = state.ResultDiamention.findIndex(it => it.questionCategoryId === action.payload.questionCategoryId)
        if(foundCategoryIndex > -1) {
          state.ResultDiamention[foundCategoryIndex] = {
            ...state.ResultDiamention[foundCategoryIndex],
            data: action.payload.finalSelectedGrowthArea
          }
        }
      }
    },
    updateModalData: (state, action) => {
      if (action.payload.growth) {
        const modelInfo = { ...state.modalData };
        console.log("modelInfo", modelInfo);
        if (
          modelInfo.data[state.enable].unlockgrowthandactions &&
          modelInfo.data[state.enable].unlockgrowthandactions[action.payload.idx]
        ) {
          modelInfo.data[state.enable].unlockgrowthandactions[0] = {
            ...modelInfo.data[state.enable].unlockgrowthandactions[0],
            growth: modelInfo.data[state.enable].unlockgrowthandactions[0].growth + 3,
          };
        }
        state.growthCount = state.growthCount + 3;
      }
      if (action.payload.action) {
        const modelInfo = { ...state.modalData };
        console.log("modelInfo", modelInfo);
        if (
          modelInfo.data[state.enable].unlockgrowthandactions &&
          modelInfo.data[state.enable].unlockgrowthandactions[action.payload.idx]
        ) {
          modelInfo.data[state.enable].unlockgrowthandactions[0] = {
            ...modelInfo.data[state.enable].unlockgrowthandactions[0],
            action: modelInfo.data[state.enable].unlockgrowthandactions[0].action + 3,
          };
        }
        state.actionCount = state.actionCount + 3;
      }
    },
    handleChangeGrowthArea: (state, action) => {
      if (!action.payload.isChecked) {
        const tempGrowthAreaCreate = [...state.growthAreaData].map((item, index) => {
          const newItem = {...item}
          if (newItem.text === action.payload.value) {
            console.log("state.selectedScore", JSON.stringify(state.tempGrowhtArea), state.selectedScore);

            newItem.isChecked = true;
            const findGrowthArea = state.tempGrowhtArea.find(
              (ele) =>
                ele.point === action.payload.value/*  && ele.uniqueId === state.selectedScore */
            );
            console.log("true findGrowthArea ===", findGrowthArea);
            if (findGrowthArea) {
              state.tempGrowhtArea = state.tempGrowhtArea.map((data) => {
                if (data.point === action.payload.value && !data.isChecked) {
                  data.isChecked = true;
                }
                return data;
              });
            } else {
              state.tempGrowhtArea = [
                ...state.tempGrowhtArea,
                {
                  uniqueId: newItem.uniqueId,
                  point: newItem.text,
                  isChecked: newItem.isChecked,
                }
              ]
            }
          }
          return newItem;
        });
        state.growthAreaData = tempGrowthAreaCreate
        state.tempSelectedGrowthArea = [
          ...state.tempSelectedGrowthArea,
          { uniqueId: state.selectedScore, point: action.payload.value },
        ]
        state.growthFavorites = state.growthFavorites.map(it => {
          it = [
            ...it,
            { uniqueId: state.selectedScore, point: action.payload.value },

          ]
          return it
        })
        state.modalData.data = state.modalData.data.map(it => {
          it.growthFavorites = [
            ...it.growthFavorites,
            { uniqueId: state.selectedScore, point: action.payload.value },
          ]
          return it
        })
      } else {
        const tempGrowthAreaCreate = [...state.growthAreaData].map((item) => {
          const newItem = {...item}
  
          if (newItem.text === action.payload.value) {
            newItem.isChecked = false;
            console.log("state.selectedScore", state.selectedScore);
            const findGrowthArea = state.tempGrowhtArea.find(
              (ele) =>
                ele.point === action.payload.value/*  && ele.uniqueId === state.selectedScore */
            );
            console.log("findGrowthArea ===", findGrowthArea);
            if (findGrowthArea) {
              state.tempGrowhtArea.map((data) => {
                if (data.point === action.payload.value && data.isChecked) {
                  data.isChecked = false;
                }
                return data;
              });
            } else {
              state.tempGrowhtArea = [
                ...state.tempGrowhtArea,
                {
                  uniqueId: newItem.uniqueId,
                  point: newItem.text,
                  isChecked: newItem.isChecked,
                }
              ]
            }
          }
          return newItem;
        });
        state.growthAreaData = tempGrowthAreaCreate
        state.tempDeletedGrowthArea = [
          ...state.tempDeletedGrowthArea,
          { point: action.payload.value },
        ]
        state.growthFavorites = state.growthFavorites.map(it => {
          it = it.filter(ite => ite.point !== action.payload.value)
          return it
        })
        state.modalData.data = state.modalData.data.map(it => {
          it.growthFavorites = it.growthFavorites.filter(ite1 => ite1.point !== action.payload.value)
          return it
        })
      }
    },
    handleChangeProgressArea: ( state, action) => {
      if (!action.payload.isChecked) {
        const foundIndexVal = state.progressData.findIndex(item => item.text === action.payload.value)
        if(foundIndexVal > -1) {
          state.progressData[foundIndexVal].isChecked = true;
          const findActionArea = state.tempActionArea.find(
            (ele) =>
              ele.point === action.payload.value /* && ele.uniqueId === state.selectedScore */
          );
          console.log("findActionArea ===1", findActionArea);
          if (findActionArea) {
            state.tempActionArea.map((data) => {
              if (data.point === action.payload.value && !data.isChecked) {
                data.isChecked = true;
              }
              return data;
            });
          } else {
            state.tempActionArea.push({
              uniqueId: state.progressData[foundIndexVal].uniqueId,
              point: state.progressData[foundIndexVal].text,
              isChecked: state.progressData[foundIndexVal].isChecked,
            });
          }
        }
        state.tempSelectedAction = [
          ...state.tempSelectedAction,
          { point: action.payload.value },
        ]
        state.actionFavorites = state.actionFavorites.map(it => {
          it = [
            ...it,
            { point: action.payload.value },
          ]
          return it
        })
        state.modalData.data = state.modalData.data.map(it => {
          it.actionFavorites = [
            ...it.actionFavorites,
            { uniqueId: state.selectedScore, point: action.payload.value },
          ]
          return it
        })
      } else {
        const foundIndexVal = state.progressData.findIndex(item => item.text === action.payload.value)
        if(foundIndexVal > -1) {
          state.progressData[foundIndexVal].isChecked = false
          const findActionArea = state.tempActionArea.find(
            (ele) =>
              ele.point === action.payload.value /* && ele.uniqueId === state.selectedScore */
          );
          console.log("findActionArea", findActionArea);
          if (findActionArea) {
            state.tempActionArea.map((data) => {
              if (data.point === action.payload.value && data.isChecked) {
                data.isChecked = false;
              }
              return data;
            });
          } else {
            state.tempActionArea.push({
              uniqueId: state.progressData[foundIndexVal].uniqueId,
              point: state.progressData[foundIndexVal].text,
              isChecked: state.progressData[foundIndexVal].isChecked,
            });
          }
        }
        state.tempDeletedAction = [
          ...state.tempDeletedAction,
          { point: action.payload.value },
        ]
        state.actionFavorites = state.actionFavorites.map(it => {
          it = it.filter(ite => ite.point !== action.payload.value)
          return it
        })
        state.modalData.data = state.modalData.data.map(it => {
          it.actionFavorites = it.actionFavorites.filter(ite1 => ite1.point !== action.payload.value)
          return it
        })
      }
    },
    updateResultDimention: (state, action) => {
      const found = state?.ResultDiamention.findIndex(it => it.questionCategoryId === action.payload.questionCategoryId)
      if(found > -1) {
        state.ResultDiamention[found].data = action.payload.data
      }
    },
    updateTestResultDimention: (state, action) => {
      if(state.testtypeofResult === TYPEOFRESULTS.PERSONAL) {
        const found = state?.resultTestDimention.findIndex(it => it.questionCategoryId === action.payload.questionCategoryId)
        if(found > -1) {
          state.resultTestDimention[found].data = action.payload.data
        }
      }
      if(state.testtypeofResult === TYPEOFRESULTS.SOCIAL) {
        const found = state?.ResultAllDiamentionByInvite.findIndex(it => it.questionCategoryId === action.payload.questionCategoryId)
        if(found > -1) {
          state.ResultAllDiamentionByInvite[found].data = action.payload.data
        }
      }

    }
  },
});
// Action creators are generated for each case reducer function
export const { storeResultAllDiamention, updateResultState, updateModalData, updateResultDataSetData, handleChangeGrowthArea, handleChangeProgressArea, updateResultDimention, updateTestResultDimention } =
  ResultDiamentionAll.actions;
export default ResultDiamentionAll.reducer;
