import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setStoreData } from "../../../Store/Reducers/plans";
import Step1Day from "./Step1Days";
import { CrossIconModal } from "../plans.styles";
import Step2PlanInfo from "./Step2PlanInfo";
import Step3PlanDetails from "./Step3PlanDetails";
import { useEffect, useState } from "react";
import PlansServices from "../../../services/Plans";
import Step3JournalEntry from "./Step3JournalEntry";
import CelebrationModal from "./CelebrationModal";
import useCustomNotification from "../../../Hooks/useCustomNotification";
const PlanModal = ({ selectedPlan }) => {
    const { userData } = useSelector((state) => state.userProfile);
    const { planId, planSelected } = selectedPlan
    const { viewPlanModal, setp } = useSelector((state) => state.planData);
    const dispatch = useDispatch()
    const [notificationMessage, setNotificationMessage] = useState('')
    const [showThumbIcon, setShowThumbIcon] = useState(true)
    const { NotificationContentJSX, customNotification, setCustomNotification } = useCustomNotification()

    return <><Dialog
        open={viewPlanModal}
        onClose={() => {
            dispatch(setStoreData({
                viewPlanModal: false,
                setp: 1,
            }));
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "720px",
                height: "calc(100vh - 100px)",
                borderRadius: "32px",
                position: "relative",
                padding: setp === 99 ? "0px" : '40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '24px',
                flexShrink: 0,
            },
        }}
    >
        <CrossIconModal onClick={() => dispatch(setStoreData({
            viewPlanModal: false,
            setp: 1
        }))} />
        {setp === 1 && <Step1Day setCustomNotification={setCustomNotification} setNotificationMessage={setNotificationMessage} setShowThumbIcon={setShowThumbIcon}/>}
        {setp === 2 && <Step2PlanInfo />}
        {setp === 4 && <Step3PlanDetails />}
        {setp === 3 && <Step3JournalEntry setCustomNotification={setCustomNotification} setNotificationMessage={setNotificationMessage} setShowThumbIcon={setShowThumbIcon} />}
        {setp === 99 && <CelebrationModal />}

    </Dialog>
        {customNotification && <NotificationContentJSX message={notificationMessage} isThumbIcon={showThumbIcon} position="top" customStyle={{
            zIndex: 10000
        }} />}
    </>
}

export default PlanModal