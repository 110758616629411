import { Box, Button, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { ReactComponent as StoreTest } from "../../../assets/Svg/StoreTest.svg"
import classes from "./Confirmation.module.css";
import { pxToRem } from "../../../theme/typography";
const ConfirmationModal = props => {
    const {
        visibleConfirmation,
        setConfirmationVisible,
        selectedItem,
        createInvoice,
        generatingPaymentLoader
    } = props

    const renderItemName = () => {
        if (selectedItem.questionCategory) {
            return "You are getting immediate access to \"" + (selectedItem.questionCategory ? selectedItem.questionCategory : '') + "\""
        }
        if (selectedItem.category_id) {
            return "You are getting immediate access to \"" + (selectedItem.category_id && selectedItem.category ? selectedItem.subCategory : '') + "\""
        }
    }
    console.log(selectedItem);
    return <Dialog
        open={visibleConfirmation}
        onClose={() => {
            setConfirmationVisible(false);
        }}
        // maxWidth={'420px'}
        sx={{
            "& .MuiPaper-root": {
                width: "420px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderRadius: "24px",
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Box sx={{
            display: 'flex',
            padding: '0px 20px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            alignSelf: 'stretch',

        }}>
            <StoreTest />
        </Box>
        <Box sx={{
            display: 'flex',
            padding: '0px 20px 20px 20px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '20px',
            alignSelf: 'stretch',
            borderBottom: '1px solid #F3F3F3',

        }}>
            <div className={classes.confirmationModalcardTitle}>
                {renderItemName()}
            </div>
            {generatingPaymentLoader && <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </div>}
            {!generatingPaymentLoader && <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
            }}>
                <Button sx={{
                    display: 'flex',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    flex: '1 0 0',
                    fontSize: '16px !important',
                    fontWeight: 400,
                    lineHeight: '100%', /* 16px */
                    letterSpacing: '-0.16px',

                    border: '1px solid #9747FF',
                    borderRadius: '100px'
                }} variant="outlined" onClick={() => setConfirmationVisible(false)}>Cancel</Button>

                <Button sx={{
                    display: 'flex',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    fontSize: '16px !important',
                    fontWeight: 400,
                    lineHeight: '100%', /* 16px */
                    letterSpacing: '-0.16px',
                    flex: '1 0 0',
                    border: '1px solid #9747FF',
                    borderRadius: '100px'
                }} variant="contained" onClick={(e) => {
                    createInvoice(e, selectedItem.questionCategoryId, true)
                }}>Purchase for ${selectedItem?.price}</Button>
            </Box>}
        </Box>
        <Box sx={{
            display: 'flex',
            padding: '20px',
            alignItems: 'flex-start',
            gap: '12px',
            alignSelf: 'stretch',
            
        }}>
            <Typography sx={{
                color:  '#767476',
                fontSize: `${pxToRem(14)}!important`,
                fontWeight: 400,
                lineHeight: '140%', /* 19.6px */
                letterSpacing: '-0.14px',
                
            }}>Please note that you’ll be redirected to complete the transaction for this test</Typography>
        </Box>


    </Dialog>
}

export default ConfirmationModal