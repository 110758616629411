import styled from "@emotion/styled";
import { Box } from "@mui/system";
import React, { useImperativeHandle, useState } from "react";
import StepOne from "../../pages/AskOtherPage/WholeTest/StepOne/StepOne";
import classes from "./AskOther.module.css";
import CLoseIcon from "../../assets/Svg/CloseIcon.svg";
import CloseIconBlack from "../../assets/Svg/CloseIconBlack.svg";
import StepTwo from "../../pages/AskOtherPage/WholeTest/StepTwo/StepTwo";
import StepThree from "../../pages/AskOtherPage/WholeTest/StepThree/StepThree";
import SpecificDimensionStepThree from "../../pages/AskOtherPage/SpecificDimension/SpecificDimensionStepThree/SpecificDimensionStepThree";
import StepFour from "../../pages/AskOtherPage/WholeTest/StepFour/StepFour";
import StepFive from "../../pages/AskOtherPage/WholeTest/StepFive/StepFive";
import { useSelector } from "react-redux";
import {
  FEEDBACKTYPE,
  LINKTYPE,
  REFLECTIVEQUESTION,
  TESTTYPE,
} from "../../Constant/Invite";
import AlertModal from "../../components/AlertModal/AlertModal";
import ConfirmationModalClose from "./ConfirmationClose";

// const stepsForWholeTest = {
//   1: StepOne,
//   2: StepTwo,
//   3: StepThree,
//   4: StepFour,
//   5: StepFive,
// };

// const stepsForSpecificDimension = {
//   1: StepOne,
//   2: StepTwo,
//   3: SpecificDimensionStepThree,
//   4: StepThree,
//   5: StepFour,
//   6: StepFive,
// };

//  ************************************************************
const stepsForWholeTestWithoutQuestion = {
  1: StepOne,
  2: StepTwo,
  3: StepThree,
  4: StepFive,
};

const stepsForWholeTestWithQuestion = {
  1: StepOne,
  2: StepTwo,
  3: StepThree,
  4: StepFour,
  5: StepFive,
};

const stepsForSpecificDimensionWithoutQuestion = {
  1: StepOne,
  2: StepTwo,
  3: SpecificDimensionStepThree,
  4: StepThree,
  5: StepFive,
};

const stepsForSpecificDimensionWithQuestion = {
  1: StepOne,
  2: StepTwo,
  3: SpecificDimensionStepThree,
  4: StepThree,
  5: StepFour,
  6: StepFive,
};

const AskOther = React.forwardRef(({
  onClose,
  dimensions,
  isAskOtherModalOpen,
  setIsAskOtherModalOpen,
  setIsAskOtherModalOpenSocial,
}, ref) => {
  const [step, setStep] = useState(1);
  const [inviteData, setInviteData] = useState({
    feedbackType: "",
    testType: "",
    reflectiveQuestion: "",
    questionCategoryIds: [], // dimension
    questions: [],
    inviteDetails: "", // desc
    invites: [], // email aahi mokalvu
    // linkType: "",
    linkType: LINKTYPE.MULTIPLE,
  });
  const [openConfirmationModal, closeConformation ] = useState(false)
  const updateInviteData = (key, value) => {
    setInviteData({
      ...inviteData,
      [key]: value,
    });
  };

  const { testType, reflectiveQuestion } = inviteData;

  const Step =
    testType === TESTTYPE?.WHOLE_TEST
      ? reflectiveQuestion === REFLECTIVEQUESTION?.YES
        ? stepsForWholeTestWithQuestion[step]
        : stepsForWholeTestWithoutQuestion[step]
      : reflectiveQuestion === REFLECTIVEQUESTION?.YES
        ? stepsForSpecificDimensionWithQuestion[step]
        : stepsForSpecificDimensionWithoutQuestion[step];

  const onNext = () => {
    if (testType === TESTTYPE?.WHOLE_TEST) {
      if (reflectiveQuestion === REFLECTIVEQUESTION?.YES) {
        if (step < Object.keys(stepsForWholeTestWithQuestion).length) {
          setStep((prev) => prev + 1);
        }
      } else {
        if (step < Object.keys(stepsForWholeTestWithoutQuestion).length) {
          setStep((prev) => prev + 1);
        }
      }
    } else {
      if (reflectiveQuestion === REFLECTIVEQUESTION?.YES) {
        if (step < Object.keys(stepsForSpecificDimensionWithQuestion).length) {
          setStep((prev) => prev + 1);
        }
      } else {
        if (
          step < Object.keys(stepsForSpecificDimensionWithoutQuestion).length
        ) {
          setStep((prev) => prev + 1);
        }
      }
    }
  };
  useImperativeHandle(ref, () => ({
    closeConforma: val => {
      if(localStorage.getItem("showConfirmation") === "false") {
        setIsAskOtherModalOpen(false)
        localStorage.removeItem("showConfirmation")
      } else {
        closeConformation(val)
      }
      console.log("called fror confirmation")
    }
  }), [step])
  const onPrevious = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };
  const MainCard = styled(Box)(({ theme }) => ({
    background: "#fff",
    width: "692px",
    height: "740px",
    // minHeight: "calc(100vh - 4rem)",
    // position: "relative",
    [theme.breakpoints.up("xl")]: {
      width: "692px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "692px",
    },
    [theme.breakpoints.down("md")]: {
      width: "600px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 6rem)",
      width: "100%",
    },
  }));

  const CardContent = styled("div")(({ theme }) => ({
    width: "100%",
    // minHeight: "calc(100vh - 4rem)",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "3rem 7.4rem",
    // gap: '16px',
    // position: "relative",
    [theme.breakpoints.up("xl")]: {
      padding: "3rem 7.4rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "3rem 7.4rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "3rem 7.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      with: "100%",
      padding: "2rem",
    },
  }));

  const handleClickOpen = () => {
    console.log("localStorage.getItem(\"showConfirmation\")", localStorage.getItem("showConfirmation"));
    if(localStorage.getItem("showConfirmation") === "false") {
      setIsAskOtherModalOpen(false)
      localStorage.removeItem("showConfirmation")
    } else {
      closeConformation(true)
    }
  };

  const handleClose = () => {
    closeConformation(false)

  };
  return (
    <MainCard
      sx={{
        minWidth: 250,
        borderRadius: "40px",
        marginInline: "auto",
      }}
    >
      <CardContent>
        <Step
          onNext={onNext}
          onPrevious={onPrevious}
          dimensions={dimensions}
          progress={
            step *
            (100 /
              (Object.keys(
                testType === TESTTYPE?.WHOLE_TEST
                  ? reflectiveQuestion === REFLECTIVEQUESTION?.YES
                    ? stepsForWholeTestWithQuestion
                    : stepsForWholeTestWithoutQuestion
                  : reflectiveQuestion === REFLECTIVEQUESTION?.YES
                    ? stepsForSpecificDimensionWithQuestion
                    : stepsForSpecificDimensionWithoutQuestion
              ).length -
                1))
          }
          updateInviteData={updateInviteData}
          inviteData={inviteData}
          onModalClose={onClose}
        />
        {/* <StepTwo /> */}
        {/* <StepThree /> */}
        {/* <StepFour /> */}
        {/* <SpecificDimensionStepThree /> */}
        {/* <StepFive /> */}
        <div
          className={classes.closeIcon}
          style={{
            width: "32px",
            height: "32px",
            background: "white",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          // onClick={onClose}
          onClick={handleClickOpen}
        >
          <img src={CloseIconBlack} alt="" />
        </div>
        {openConfirmationModal && <ConfirmationModalClose openConfirmationModal={openConfirmationModal} closeConformation={closeConformation} setIsAskOtherModalOpenSocial={setIsAskOtherModalOpenSocial}/>}
      </CardContent>
    </MainCard>
  );
});

export default AskOther;
