import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup";
import classes from "./StepFive.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import {
  CustomCheckBox,
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { CustomShedualButton } from "../../../components/GoalComponent/GoalButton";
import { find } from "lodash";
import { FindAndGetXPLevel, imageURLRender, randomXP, XPLevels } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
import HTMLTooltip from "../../../components/ToolTipWithIcon/HTMLTooltip";
import { ReactComponent as Sparkles } from "../../../assets/Svg/Sparkles.svg";
import { CheckBoxInputCheckedGoalNew, CheckBoxInputGoalNew } from "../../../components/Input/Input";
import { TeriteryButtonChip, TeriteryButtonContained } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { pxToRem } from "../../../theme/typography";
import { goalGetPreSelectedGrowth } from "../../../services/auth";
import GoalFavouriteModal from "../../GoalListPage/GoalFavouriteModal";
import { XPIcon } from "../../../components/XPIcon";
function StepFive({
  onNext,
  onPrevious,
  getGoalDetail,
  goalDetail,
  selectedDiamenstion,
  selectedDiamenstionImage,
  handleClose,
}) {
  const navigate = useNavigate()
  useEffect(() => {
    // nextDataHandler();
  }, []);
  const [growthVisible, setGrowthVisible] = useState(false)
  const goalGetPreSelectedActions = () => {
    let user = localStorage.getItem("userDetail");
    let questionCategoryId = goalDetail?.questionCategoryId;
    goalGetPreSelectedGrowth(user, questionCategoryId)
      .then((res) => {
        const newObj = []
        if (res?.action && res?.action?.length) {
          for (let index = 0; index < res?.action.length; index++) {
            const randomXPFound = () => randomXP()

            const element = res?.action[index];
            newObj.push({
              ...element,
              level: randomXPFound().xp
            })
          }
        }
        console.log("res?.data", newObj);
        setgoalActions(newObj);
        fill();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisGoalGrowth(true);
      });
  }
  useEffect(() => {

    if (!goalActions?.length) {
      goalGetPreSelectedActions()
    }
  }, []);
  const findIcon = (level) => {

  }
  // function
  const growthArea = goalDetail?.action?.filter(
    (data) => data.type == "SUGGESTED"
  );
  const selfgrowthAreas = goalDetail?.action?.filter(
    (data) => data.type == "SELF"
  );
  const fill = () => {
    if (goalActions) {
      console.log("selfgrowthAreas", selfgrowthAreas);
      setPreselectedState(growthArea);
      setActionState(selfgrowthAreas || []);
    }
  };
  const [preselectedState, setPreselectedState] = useState([]);
  const [goalActions, setgoalActions] = useState([]);
  const [isGoalGrowth, setisGoalGrowth] = useState(false);
  const [actionState, setActionState] = useState([
  ]);
  console.log("actionState", actionState);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...actionState];
    tempList[index][name] = value;
    setActionState(tempList);
  };
  const savedAtIndex = (i) => {
    const newInputs = [...actionState];
    newInputs[i].saved = true
    setActionState(newInputs)
  }
  const removeAtIndex = (i) => {
    const newInputs = [...actionState];
    newInputs.splice(i, 1)
    setActionState(newInputs)
  }
  const handleChangeAction = (value, index) => {
    const tempList = [...actionState];
    tempList[index]["level"] = value;
    setActionState(tempList);
  }
  const addMoreHandler = () => {
    if (
      actionState[actionState.length - 1].actionName !== "" &&
      actionState[actionState.length - 1].actionDetail !== ""
    ) {
      setActionState([
        ...actionState,
        {
          actionName: "",
          actionDetail: "",
        },
      ]);
    }
  };
  const addFirstInput = () => setActionState([{
    actionName: "",
    actionDetail: "",
    level: "", id: 1, saved: false
  }]);
  const nextDataHandler = () => {
    if (goalDetail?.action) {
      setActionState(goalDetail.action);
    }
  };
  const updateMainState = () => {
    let tempArr = [];
    preselectedState?.forEach((data) => {
      tempArr.push({
        actionName: data.text,
        actionDetail: data.actionDetail,
        level: data.level, type: "SUGGESTED", index: data.index
      });
    });
    actionState?.forEach((data) => {
      tempArr.push({
        actionName: data.actionName,
        actionDetail: "",
        level: data.level, type: "SELF"
      });
    });
    getGoalDetail("action", tempArr);
  };
  const growthAreaHandler = (index, item) => {
    console.log("item", item);
    const findData = preselectedState?.find((o) => o.index === index);
    if (findData) {
      setPreselectedState((current) =>
        current.filter((data) => data.index !== index)
      );
    } else {
      setPreselectedState([...(preselectedState || []), { index: index, text: item.point, level: FindAndGetXPLevel(item.level, "name") }]);
    }
  };
  const endOrnament = (item, isWithoutSelected = false) => {
    return <InputAdornment position="end">
      <Box display={"flex"} alignItems={"center"} gap={"8px"} marginRight={isWithoutSelected ? '16px' : 0}>
        <Box sx={{
          display: 'flex',
          padding: '4px 8px',
          alignItems: 'center',
          gap: '4px',
          borderRadius: '100px',
          background: '#F7F2FE',
        }}>
          <Box display={"flex"} alignItems={"center"} width={"12px"} height={"12px"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <circle cx="6" cy="6" r="6" fill="#9747FF" />
              <circle cx="5.99871" cy="6.00005" r="5.2" fill="#F7F2FE" />
              <path d="M3.25053 3.75L4.31221 5.51001H4.34687L5.40855 3.75H6.18423L4.80188 6L6.19289 8.25H5.41289L4.34687 6.51416H4.31221L3.2462 8.25H2.46619L3.88104 6L2.47485 3.75H3.25053Z" fill="#9747FF" />
              <path d="M6.84832 8.25V3.75H8.43C8.77523 3.75 9.06123 3.81372 9.28801 3.94116C9.51479 4.0686 9.68452 4.24292 9.79718 4.46411C9.90985 4.68384 9.96619 4.9314 9.96619 5.20679C9.96619 5.48364 9.90913 5.73267 9.79502 5.95386C9.68235 6.17358 9.5119 6.3479 9.28368 6.47681C9.0569 6.60425 8.77162 6.66797 8.42784 6.66797H7.34016V6.09229H8.36717C8.58528 6.09229 8.76223 6.0542 8.89801 5.97803C9.03379 5.90039 9.13346 5.79492 9.19701 5.66162C9.26057 5.52832 9.29235 5.37671 9.29235 5.20679C9.29235 5.03687 9.26057 4.88599 9.19701 4.75415C9.13346 4.62231 9.03307 4.51904 8.89584 4.44434C8.76006 4.36963 8.58095 4.33228 8.3585 4.33228H7.51783V8.25H6.84832Z" fill="#9747FF" />
            </svg>
          </Box>
          <Typography sx={{
            color: '#9747FF',
            fontSize: `${pxToRem(12)}!important`,
            fontWeight: 400,
            lineHeight: '100%',
            letterSpacing: '-0.12px'
          }}>{item.level}</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          padding: '4px',
          alignItems: 'center',
          gap: '4px',
          borderRadius: '100px',
          background: '#F7F2FE',
          '& path': {
            fill: "#9747FF"
          }
        }} >
          {FindAndGetXPLevel(item.level, "icon")}
        </Box>
      </Box>
    </InputAdornment>
  }
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} skipButton={<Typography variant="h5" sx={{
        textAlign: "right", fontSize: '0.75rem !important', position: 'absolute',
        left: '20px', cursor: 'pointer'
      }} onClick={onNext}>
        <span style={{ color: "#9747FF" }}>{"Skip Action"}</span>{" "}
      </Typography>} />
      <div className={classes.StepFiveWrapper}>
        {" "}
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Select growth areas{" "}
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div className={classes.StepFiveSubWrapper}>

          <div><img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" /></div>
          <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            {/* <Typography
              variant="body4"
              sx={{ margin: "16px 0px", textAlign: "center", justifySelf: "end" }}
            >
              Choose preselected growth area or type in your own
            </Typography> */}
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Add action/s to{" "}
              <span style={{ color: "#9747FF" }}>
                {" "}
                {selectedDiamenstion}
              </span>{" "}
            </Typography>
            <div style={{ position: "absolute", right: "0" }}>
              <HTMLTooltip
                title={"Actions are one-time acts contributing to your character development goals."}
                placement="right"
                showArrow={true}
                sx={{
                  '& .MuiTooltip-tooltip': {
                    background: "#FFF",
                    color: "#000",
                    maxWidth: "350px"
                  }
                }}
              />
            </div>

          </div>
          {!isGoalGrowth ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : goalActions && goalActions.length ? (
            <Box sx={{
              display: 'flex',
              padding: '16px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              alignSelf: 'stretch',
              borderRadius: '16px',
              background: '#F9F9F9',
              marginTop: '24px'
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '4px',
                alignSelf: 'stretch',
              }}>
                <Sparkles /> <Typography sx={{
                  color: '#9747FF',
                  fontSize: `${pxToRem(14)}!important`,
                  fontWeight: 400,
                  lineHeight: '100%'
                }}>Recommended</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',

              }}>
                {goalActions?.map((item, index) => {
                  return (
                    <CustomCheckBox
                      key={index}
                      // sx={{ margin: "6px 0px" }}
                      icon={<CheckBoxInputGoalNew placeholder={item?.point} inputProps={{
                        endAdornment: endOrnament(item, true)
                      }} />}
                      checkedIcon={
                        <CheckBoxInputCheckedGoalNew placeholder={item?.point} inputProps={{
                          endAdornment: endOrnament(item)
                        }} />
                      }
                      onChange={() => {
                        growthAreaHandler(index, item);
                      }}
                      checked={preselectedState?.find((o) => o.index === index) ? true : false}
                    />
                  );
                })}
              </Box>
              <TeriteryButtonContained onClick={() => setGrowthVisible(true)}>Add more actions</TeriteryButtonContained>

            </Box>
          ) : <Box sx={{
            display: 'flex',
            padding: '16px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch',
            borderRadius: '16px',
            background: '#F7F2FE',
            marginTop: '24px'

          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '12px',
              alignSelf: 'stretch',
            }}>
              <div>
                <Sparkles height={'20px'} width={"20px"} />
              </div>
              <Typography sx={{
                color: '#9747FF',
                fontSize: `${pxToRem(16)}!important`,
                fontWeight: 400,
                lineHeight: '150%',
                letterSpacing: '-0.16px'
              }}>You haven’t liked any actions for this dimension in the results. Click the ‘open results’, like interesting expert-crafted actions, and add them to this goal.</Typography>
            </Box>
            <TeriteryButtonContained onClick={() => setGrowthVisible(true)}>Open results</TeriteryButtonContained>
          </Box>}
          {actionState && actionState.length <= 0 && (
            <div style={{ width: "100%", margin: "16px 0" }}>
              <TeriteryButtonChip
                // style={{ maxWidth: "91px" }}
                onClick={addFirstInput}
              >
                Add action manually
              </TeriteryButtonChip>
            </div>
          )}
          {actionState && actionState.length > 0 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '24px',
              flexShrink: 0,
              width: '100%',
              marginTop: '24px',
              marginBottom: '24px',
            }}>
              {actionState?.map((item, i) => {
                return <Box sx={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '16px',
                  alignSelf: 'stretch',
                  borderRadius: '16px',
                  background: '#F9F9F9',
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between'
                  }}>
                    <Typography component="span" sx={{
                      color: "#190D1A",
                      fontSize: `${pxToRem(14)} !important`,
                      fontWeight: 400,
                      lineHeight: '100%'
                    }}>Action #{i + 1}</Typography>
                    <span onClick={() => removeAtIndex(i)} style={{
                      cursor: "pointer"
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M12 4L4 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4 4L12 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </Box>
                  <TextField
                    key={i}
                    size="small"
                    className={classes.goalActionInput}
                    sx={{
                      // marginBottom: "12px",
                      background: '#FFF',
                      borderRadius: '16px',
                      '& .MuiOutlinedInput-root': {
                        paddingTop: '18px',
                        "&.Mui-focused .MuiOutlinedInput-input": {
                          color: '#9747FF'
                        },
                        "&.Mui-focused .MuiInputLabel-root": {
                          color: '#767476'
                        }
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                        top: 0,
                        '& legend': {
                          height: 0,
                          width: 0
                        }
                      },
                      '& label': {
                        color: '#767476',
                        top: '18px',
                        left: '-3px',
                        "&.Mui-focused": {
                          color: '#767476'
                        }
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                        borderRadius: "16px",
                        background: "#fff"
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      // color: '#767476'
                    }}

                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    label="Name action"
                    name="actionName"
                    value={item.actionName}
                    onChange={(e) => handleInputChange(e, i)}
                    type="text"
                    placeholder="Text"
                    id="outlined-start-adornment"
                  />
                  <TextField
                    size="small"
                    className={classes.goalActionInput}
                    sx={{
                      background: '#FFF',
                      borderRadius: '16px',
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    name="actionDetail"
                    value={item.actionDetail}
                    onChange={(e) => handleInputChange(e, i)}
                    type="text"
                    placeholder="Add why this action"
                    id="outlined-start-adornment"
                  />
                  <Box sx={{
                    display: 'flex',
                    padding: '16px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '8px',
                    alignSelf: 'stretch',
                    borderRadius: '16px',
                    background: '#FFF',

                  }}>
                    <Typography variant="subtitle1" sx={{
                      color: '#767476',
                      fontSize: `${pxToRem(10)}!important`,
                      fontWeight: 400,
                      lineHeight: '100%', /* 10px */
                      letterSpacing: '-0.1px',

                    }}>
                      Select Difficulty
                    </Typography>
                    <div className={classes.customActionLevelGroup}>
                      <ToggleButtonGroup
                        color="primary"
                        value={item.level}
                        className={classes.customActionLevelGroup}
                        exclusive
                        onChange={(e, val) => {
                          handleChangeAction(val, i)
                        }}
                        size="small"
                        aria-label="Platform"
                      >
                        {XPLevels.map(it => (
                          <ToggleButton value={it.level} className={item.level === it.level ? classes.selectedtoggleButton : classes.toggleButton}> {
                            item.level === it.level ? <>
                              <Box sx={{
                                display: 'flex',
                                paddingRight: '8px',
                                alignItems: 'center',
                                gap: '6px',
                                borderRight: '1px solid #F3F3F3',
                              }}>
                                {it.icon} <span className="toggle-text">{it.level}</span>
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                paddingLeft: '8px',
                                alignItems: 'center',
                                gap: '4px',
                              }}>
                                <span className="toggle-text">{it.xp}</span>
                                <XPIcon />

                              </Box>
                            </> : <>
                              {it.icon} <span className="toggle-text">{it.level}</span>
                            </>
                          } </ToggleButton>

                        ))}
                      </ToggleButtonGroup>
                    </div>
                  </Box>
                  {i === actionState?.length - 1 && (
                    <TeriteryButtonContained disabled={!(item.actionName && item.actionDetail)} onClick={() => {
                      if (item.saved) {
                        addMoreHandler()
                      } else {
                        savedAtIndex(i)
                      }
                    }}>{item.saved ? 'Add more' : 'Save'}</TeriteryButtonContained>
                  )}
                </Box>
              })}
            </Box>
          )}

        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px", padding: "0 40px" }}>
          <ButtonGroup
            onNext={onNext}
            onPrevious={onPrevious}
            updateMainState={updateMainState}
            isNextDisable={actionState && actionState.length > 0 &&
              (actionState[actionState.length - 1].actionName === "" ||
                actionState[actionState.length - 1].actionDetail === "" ||
                !actionState[actionState.length - 1].level)
            }
          />
        </div>
      </div >
      {growthVisible && <GoalFavouriteModal visible={growthVisible} onClose={() => {
        goalGetPreSelectedActions()
        setGrowthVisible(false);
      }} isGrowth={false} questionCategoryId={goalDetail?.questionCategoryId} />}
    </>

  );
}
export default StepFive;
