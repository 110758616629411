import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  planTags: [],
  activeTabObject: {
    type: "MY_PLANS",
    lable: "My plan",
    isAll: false,
  },
  plansTable: [],
  selectedPlan: {},
  viewPlanModal: false,
  setp: 1,
  planActions: [],
  selectedAction: {},
  selectedJournalAction: {},
  visibleJournalModal: false,
  grwothNotFound: false,
  testNotCompleted: false,
  resultNotUnlocked: false,
  plansLoading: false,
  viewPlanConfirmationModal: false,
  isForSecondFreeze: false,
  isForUnfreeze: false,

};
export const PlanData = createSlice({
  name: "PlanData",
  initialState,
  reducers: {
    setStoreData: (state, action) => {
      if (Object.keys(action.payload).includes("planTags")) {
        state.planTags = action.payload.planTags;
      }
      if (Object.keys(action.payload).includes("viewPlanConfirmationModal")) {
        state.viewPlanConfirmationModal = action.payload.viewPlanConfirmationModal;
      }
      if (Object.keys(action.payload).includes("isForSecondFreeze")) {
        state.isForSecondFreeze = action.payload.isForSecondFreeze;
      }
      if (Object.keys(action.payload).includes("plansLoading")) {
        state.plansLoading = action.payload.plansLoading;
      }
      if (Object.keys(action.payload).includes("grwothNotFound")) {
        state.grwothNotFound = action.payload.grwothNotFound;
      }
      if (Object.keys(action.payload).includes("testNotCompleted")) {
        state.testNotCompleted = action.payload.testNotCompleted;
      }
      if (Object.keys(action.payload).includes("resultNotUnlocked")) {
        state.resultNotUnlocked = action.payload.resultNotUnlocked;
      }
      if (Object.keys(action.payload).includes("selectedJournalAction")) {
        state.selectedJournalAction = action.payload.selectedJournalAction;
      }
      if (Object.keys(action.payload).includes("visibleJournalModal")) {
        state.visibleJournalModal = action.payload.visibleJournalModal;
      }
      if (Object.keys(action.payload).includes("activeTabObject")) {
        state.activeTabObject = action.payload.activeTabObject;
      }
      if (Object.keys(action.payload).includes("selectedAction")) {
        state.selectedAction = action.payload.selectedAction;
      }
      if (Object.keys(action.payload).includes("plansTable")) {
        state.plansTable = action.payload.plansTable;
      }
      if (Object.keys(action.payload).includes("selectedPlan")) {
        state.selectedPlan = action.payload.selectedPlan;
      }
      if (Object.keys(action.payload).includes("viewPlanModal")) {
        state.viewPlanModal = action.payload.viewPlanModal;
      }
      if (Object.keys(action.payload).includes("planActions")) {
        state.planActions = action.payload.planActions;
      }
      if (Object.keys(action.payload).includes("setp")) {
        state.setp = action.payload.setp;
      }
    },
    updateStatusOfAction: (state, action) => {
      const newselectedActionsActions = [...state.selectedAction.actions];
      const foundIndex = newselectedActionsActions.findIndex(
        (it) => it.userPlanActionId === action.payload.userPlanActionId
      );
      if (foundIndex > -1) {
        newselectedActionsActions[foundIndex].status = action.payload.status;
      }
      const newplanActions = [...state.planActions];
      const foundplanIndex = newplanActions.findIndex(
        (it) => it._id.dayNumber === state.selectedAction._id.dayNumber
      );
      if (foundplanIndex > -1) {
        const foundIndex = newplanActions[foundplanIndex].actions.findIndex(
          (it) => it.userPlanActionId === action.payload.userPlanActionId
        );
        if (foundIndex > -1) {
          newplanActions[foundplanIndex].actions[foundIndex].status =
            action.payload.status;
        }
      }
      state.selectedAction = {
        ...state.selectedAction,
        actions: newselectedActionsActions,
      };
      state.planActions = newplanActions;
      if (newselectedActionsActions && newselectedActionsActions?.length) {
        const withoutJournalActions = [...newselectedActionsActions].filter(
          (it) => it.status === "IN_PROGRESS"
        );
        console.log("withoutJournalActions", withoutJournalActions);
        if (state.setp === 2 && !withoutJournalActions.length) {
          state.setp = 99;
        }
      }
    },
    updateQuoteRead: (state, action) => {
      const newselectedActionsActions = {...state.selectedAction};
      newselectedActionsActions.planQuotesData[0].userplanquoteData = newselectedActionsActions.planQuotesData;
      state.selectedAction = {
        ...state.selectedAction,
        planQuotesData: newselectedActionsActions.planQuotesData
      };

      const newplanActions = [...state.planActions];
      const foundplanIndex = newplanActions.findIndex(
        (it) => it._id.dayNumber === state.selectedAction._id.dayNumber
      );
      if (foundplanIndex > -1) {
          newplanActions[foundplanIndex].planQuotesData =
          newselectedActionsActions.planQuotesData;
      }
      state.planActions = newplanActions;
    },
    updateJournalOfPlanAction: (state, action) => {
      const newselectedActionsActions = [...state.selectedAction.actions];
      const foundIndex = newselectedActionsActions.findIndex(
        (it) =>
          it.userPlanActionId === state.selectedJournalAction.userPlanActionId
      );
      if (foundIndex > -1) {
        console.log(
          "newselectedActionsActions[foundIndex]",
          newselectedActionsActions[foundIndex]
        );
        newselectedActionsActions[foundIndex].journal = action.payload.value;
      }
      const newplanActions = [...state.planActions];
      const foundplanIndex = newplanActions.findIndex(
        (it) => it._id.dayNumber === state.selectedAction._id.dayNumber
      );
      if (foundplanIndex > -1) {
        const foundIndex = newplanActions[foundplanIndex].actions.findIndex(
          (it) =>
            it.userPlanActionId === state.selectedJournalAction.userPlanActionId
        );
        if (foundIndex > -1) {
          newplanActions[foundplanIndex].actions[foundIndex].journal =
            action.payload.value;
        }
      }
      state.selectedAction = {
        ...state.selectedAction,
        actions: newselectedActionsActions,
        
      };
      state.selectedJournalAction = {
        ...state.selectedJournalAction,
        journal:action.payload.value,
      }
      state.planActions = newplanActions;
    },
    resetStoreState: () => {
      return initialState;
    },
  },
});

export const {
  setStoreData,
  resetStoreState,
  updateJournalOfPlanAction,
  updateStatusOfAction,
  updateQuoteRead,
} = PlanData.actions;
export default PlanData.reducer;
