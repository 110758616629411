import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  journals: [],
  clickedButtonObject: {},
  visibleModal: false,
  selectedSearchJournal: null,
  questionCategoryData: []
};
export const JournalsData = createSlice({
  name: "JournalsData",
  initialState,
  reducers: {
    setJournalStoreData: (state, action) => {
      if (Object.keys(action.payload).includes("journals")) {
        state.journals = action.payload.journals;
      }
      if (Object.keys(action.payload).includes("clickedButtonObject")) {
        state.clickedButtonObject = action.payload.clickedButtonObject;
      }
      if (Object.keys(action.payload).includes("visibleModal")) {
        state.visibleModal = action.payload.visibleModal;
      }
      if (Object.keys(action.payload).includes("selectedSearchJournal")) {
        state.selectedSearchJournal = action.payload.selectedSearchJournal;
      }
      if (Object.keys(action.payload).includes("questionCategoryData")) {
        state.questionCategoryData = action.payload.questionCategoryData;
      }
    },
    resetJournalState: () => {
      return initialState;
    },
  },
});

export const {
  setJournalStoreData,
  resetJournalState,
} = JournalsData.actions;
export default JournalsData.reducer;
