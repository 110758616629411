import {
  Checkbox,
  CircularProgress,
  IconButton,
  Snackbar,
  styled,
} from "@mui/material";
import classes from "./InviteSignup.module.css";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Text from "../../../components/Text/Text";
import Input from "../../../components/Input/Input";
import {
  LinkButton,
  LinkButtonForTermsAndCondition,
  LinkButtonWithText,
  SubmitButton,
} from "../../../components/Button/Button";
import google from "../../../assets/images/google.png";
import facebook from "../../../assets/images/fb.png";
import apple from "../../../assets/images/apple.png";
import {
  appleProvider,
  facebookProvider,
  googleProvider,
} from "../../../config/authMethods";
import { createUser, socialMediaAuth,termsAndConditionContent } from "../../../services/auth";
import { isValidEmail, isValidPassword } from "../../../validations/Email/Email";
import { useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { auth } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import useNotifications from "../../../Hooks/useNotifications";
import checkedIcon from "../../../../src/assets/images/Vector.png";
import Google from "../../../assets/Svg/Google.svg"
import Facebook from "../../../assets/Svg/Facebook.svg"
import Apple from "../../../assets/Svg/Apple.svg"

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function InviteSignup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsAndConditionUrl, settermsAndConditionUrl] = useState("")
  const inviteID = localStorage.getItem("inviteId")

  const { Notification, updateNotificationState, action } =
    useNotifications(false);
  let navigate = useNavigate();

  useEffect(()=>{
    termsAndConditionContent().then((data)=>{
      console.log(data)
      settermsAndConditionUrl(data?.policyAndConditionsData?.conditionsUrl)
    }).catch((err)=>{
      console.log(err)
    })
  },[])

  const createNewUser = async (result) => {
    const inviteID = localStorage.getItem("inviteId")
    const userDataRes = {
      uid: result?.user?.uid ? result?.user?.uid : result?.uid,
      user_email: result?.user?.email ? result?.user?.email : result?.email,
      inviteID: inviteID,
      "ipAddress": localStorage.getItem("ipAddress")
    };
    setIsLoading(true);
    createUser(userDataRes)
      .then((data) => {
        console.log(data);
        localStorage.setItem("userDetail", data.data.uid);
        localStorage.setItem("userEmail", data.data.user_email);
        updateNotificationState(data.message);
        navigate(`/invite/${inviteID}/onbording`);
      })
      .catch((error) => {
        console.log(12, error.response.data.description);
        if (error.response.data.description === "Uid is already registered") {
          updateNotificationState("User is alredy registered");
          // setErrorEmail("User is alredy registered")
          // navigate("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnClick = async (provider) => {
    try {
      const result = await socialMediaAuth(provider);
      if (!result.uid) {
        throw new Error(result.message);
      } else {
        createNewUser(result);
      }
    } catch (error) {
      if(!error.message.includes("auth/popup-closed-by-user")){
        updateNotificationState( error?.message);
      }
      setIsLoading(false);
    }
  };

  const emailAuth = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        console.log(result);
        localStorage.setItem("allUserDetails", JSON.stringify(result));
        return result;
      })
      .catch((err) => {
        setIsLoading(false);
        // updateNotificationState("User is alredy registered");
        setErrorEmail("User is alredy registered");
        setPassword("");
        console.log("----------", err);
        return err;
      });
  };

  const firebaseLogin = async () => {
    // setShowLoader(true);
    try {
      const result = await emailAuth(email, password);
      console.log("res 82", result);
      if (result.user) {
        createNewUser(result);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(">>>", error);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    firebaseLogin();
  };

  console.log(termsAndConditions);

  const CheckboxIcon = () => {
    return (
      <div
        style={{
          border: "1px solid #E5D5FC",
          borderRadius: "8px",
          padding: "11px",
        }}
      ></div>
    );
  };

  const CheckboxCheckedIcon = () => {
    return (
      <div
        style={{
          border: "1px solid #E5D5FC",
          borderRadius: "8px",
          padding: "2px",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <img src={checkedIcon} alt="" />
      </div>
    );
  };

  const signinWithApple = async () => {
    // signInWithRedirect(auth, appleProvider)
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const user = result.user;
        console.log(result);
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        if (!user.uid) {
          throw new Error(result.message);
        }
        createNewUser(result);
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        console.log(error, credential);
        setErrorPassword("Invalid credentials");
        setErrorEmail("Invalid credentials");
        setIsLoading(false);
      });
  };

  const TermsAndCondition = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "-2px",
    marginLeft: "3px",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",

    [theme.breakpoints.up("xl")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "-2px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-4px",
    },
  }));
  return (
    <>
      <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px",mt: '60px' }}>
        <Text textValue="Create an account" variant="h2" />
      </Box>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Email"
          value={email}
          onChange={setEmail}
          validationFunction={isValidEmail}
          type="text"
          errorMessage={errorEmail}
          setErrorMessage={setErrorEmail}
        />
      </Box>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Password"
          value={password}
          onChange={setPassword}
          validationFunction={isValidPassword}
          type="password"
          errorMessage={errorPassword}
          setErrorMessage={setErrorPassword}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <SubmitButton
          buttonText="Sign up"
          isDisabled={
            !email ||
            !password ||
            errorEmail ||
            errorPassword ||
            !termsAndConditions
          }
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </Box>
      <TermsAndCondition className={classes.terms_and_condition}>
        <Checkbox
          {...label}
          disableRipple
          sx={{ pl: "0", width: "20px", height: "20px" }}
          checked={termsAndConditions}
          onChange={(e) => setTermsAndConditions(e.target.checked)}
          icon={<CheckboxIcon />}
          checkedIcon={<CheckboxCheckedIcon />}
        />
        <span style={{ color: "#434243", paddingLeft: "12px" }}>
          By creating an account you are agree to our{" "}
          <LinkButtonForTermsAndCondition
            termsAndConditionUrl={termsAndConditionUrl}
            buttonText="Terms & Conditions"
            color="#000000"
            textDecoration="underline"
          />
        </span>
      </TermsAndCondition>
      <Box className={`${classes.wrapper} ${classes.orText}`}>or</Box>
      <Box className={classes.wrapper} sx={{ gap: "12px", padding: "16px" }}>
        <Box
          className={classes.imgIcon}
          onClick={() => handleOnClick(googleProvider)}
        >
          <img src={Google} alt="" />
        </Box>

        <Box className={classes.imgIcon} onClick={() => signinWithApple()}>
          <img src={Apple} alt="" />
        </Box>

        <Box
          className={classes.imgIcon}
          onClick={() => handleOnClick(facebookProvider)}
        >
          <img src={Facebook} alt="" />
        </Box>
      </Box>
      <Box className={classes.wrapper} sx={{mb: '60px'}}>
        <LinkButtonWithText
          text="Already a user?"
          buttonText="Log in"
          navigateUrl={`/invite/${inviteID}/login`}
        />
      </Box>
      {Notification}
    </>
  );
}
