import { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import JournalData from "./journalData"
import { RightSideContentWrapper } from "./journalStyles"
import { ResultAllTestDiamention, getGoals } from "../../services/auth"

const JournalsPage = () => {
    
    return <RightSideContentWrapper>
        <Header />
        <JournalData />
    </RightSideContentWrapper>
}

export default JournalsPage