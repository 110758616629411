import { Button, Typography } from "@mui/material";
import classes from "./shareview.module.css"
import typography, { pxToRem } from "../../theme/typography";
import ShareViewImage from "./../../assets/Svg/ShareViewImage.svg"
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
const ShareReview = () => {
    return <div className={classes.shareViewConteiner}>
        <div className={classes.frame3}>
            <div className={classes.frame1}>
                <div style={{
                    display: "flex"
                }}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: `${pxToRem(28)} !important`,
                            color: "#190D1A"
                        }}>Help</Typography>
                    &nbsp;
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: `${pxToRem(28)} !important`,
                            color: "#190D1A"
                        }}>[User's Name]</Typography>
                    &nbsp;

                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: `${pxToRem(28)} !important`,
                            color: "#190D1A"
                        }}>💚</Typography>

                </div>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: `${pxToRem(16)} !important`,
                        color: "#190D1A",
                        lineHeight: "150%"
                    }}>Your friend, [User's Name], is on a personal growth journey with the {window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery (SSD)"} app. They value your perspective and have invited you to offer feedback on their self-improvement efforts.
                    <br />
                    <br />
                    In SSD, users like [User's Name] take scientifically-backed personality tests and work on growth areas. Your insights can provide valuable guidance and clarity on their path.<br /> <br />
                    We appreciate your willingness to support [User's Name] in this transformative process. Your unique perspective is invaluable.<br /><br />
                    With warmth, <br />
                    <i>Team {window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery"}</i>
                    <br /> <br />
                    Here is what [User's Name] had to say: </Typography>
            </div>
            <div className={classes.frame2}><Typography
                sx={{
                    fontWeight: 400,
                    fontSize: `${pxToRem(16)} !important`,
                    color: "#190D1A",
                    lineHeight: "150%"
                }}>Chris, it’s Robby! I’m trying out this new crazy app that helps on your journey to self-discovery. It has a bunch of cool features and inviting friends is one of them. Well, closer to why I’m sending this hahaha. Could you pls pass a test for me? All you need is to just answer the questions, considering if I act that way. It would be freaking helpful!!
                <br /> <br />
                Best,
                <br />Robby</Typography> </div>

        </div>
        <div className={classes.frame3745}>
            <div className={classes.rectangle}> </div>
        </div>
        <div className={classes.frame3744}>
            <div style={{
                display: "flex"
            }}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: `${pxToRem(18)} !important`,
                        color: "#190D1A",
                        lineHeight: "145%"
                    }}>[User's Name]</Typography>
                &nbsp;
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: `${pxToRem(18)} !important`,
                        color: "#190D1A",
                        lineHeight: "145%"
                    }}>invited you to take ‘Well-being literacy’ test</Typography>
            </div>
            <div className={classes.cards}>
                <div className={classes.tit}>
                    <div className={classes.image} style={{
                        justifyContent: 'center',
                        backgroundImage: `url(${ShareViewImage})`
                    }}></div>
                    <div className={classes.tagComponent}>
                            <div className={classes.coinIconWrapper}>
                                <img src={Coin} alt="" />
                                <Typography variant="body3" color="#9747FF">
                                    {20}
                                </Typography>
                            </div>
                            <div className={`${classes.coinIconWrapper} ${classes.ml4}`}>
                                <img src={DiamondCoin} alt="" />
                                <Typography variant="body3" color="#9747FF">
                                    {3}
                                </Typography>
                            </div>
                            <div className={`${classes.timeIcon} ${classes.ml4}`}>
                                <Typography variant="body3" color="#9747FF">
                                    {15} min
                                </Typography>
                            </div>
                    </div>
                    <div className={classes.desktop}>
                        <div className={classes.desktopText}>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: `${pxToRem(18)} !important`,
                                    color: "#190D1A",
                                    lineHeight: "140%"
                                }}>Well-being literacy</Typography>
                        </div>
                        <div className={classes.desktopText1}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: `${pxToRem(14)} !important`,
                                    color: "#767476",
                                    lineHeight: "140%"
                                }}>Lorem ipsum dolor sit amet consectetur. Imperdiet penatibus gravida urna fermentum cursus lectus vitae. In lacus urna tortor accumsan. Faucibus platea at ullamcorper risus aenean</Typography>
                        </div>
                    </div>
                </div>
                <Button
                    className={classes.createGoalsBtn}
                    sx={{ ...typography.subtitle1, marginTop: "20px" }}
                    variant="contained"
                >
                    Start test
                </Button>
            </div>
        </div>
    </div>;
}

export default ShareReview