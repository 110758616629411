import { createSlice } from "@reduxjs/toolkit";
import { GOALTYPE } from "../../Constant/GoalConstant";
const initialState = {
  getgoal: [],
  expanded: true,
  isAddNewGoal: false,
  isGoalArchive: false,
  isCompletedGoal: false,
  preSelectedState: [],
  allPreSelectedState: [],
  isCalenderOpen: false,
  isGoalGet: false,
  tbdDate: [],
  goalName: "",
  schedualDate: [],
  othresArray: [],
  PersonalDataArray: [],
  goalScore: [],
  selectedQuestionCategoriesId: "",
  isAskOtherModalOpen: false,
  completedGrowthAreaState: [],
  allCompletedGrowthAreaState: [],
  isAddMoreGrowthAreaOpen: false,
  isCompletedArchiveOpen: false,
  isArchieveOpen: false,
  openConfirmationModal: false,
  step: 1,
  isLastStep: false,
  typeGoal: GOALTYPE.ALL_DIAMENSTIONGOAL,
  selectedDiamenstion: "",
  goalDetail: {},
  goalAllDiamension: [],
  isGoalDialmension: false,
  questionCategoryId: "",
  addGoalStep: 1,
  addGoalCtageory: {},
  notificationmesage: "",
  testNotCompleted: false,
  resultNotUnlocked: false,
  oprnCongratsModal: false,
  createdgoalName: "",
  completeModal: false
  //   isActionShedualOpen:false,
  //   scheduleState:{}
};
export const GoalData = createSlice({
  name: "GoalData",
  initialState,
  reducers: {
    updategetGoal: (state, action) => {
      state.getgoal = action.payload;
    },
    updateAddGoalStep: (state, action) => {
      state.addGoalStep = action.payload
    },
    updateAddGoalCategory: (state, action) => {
      state.addGoalCtageory = action.payload
    },
    updateGoalData: (state, action) => {
      if(Object.keys(action.payload).includes("completeModal")) {
        state.completeModal = action.payload.completeModal
      }
      if(Object.keys(action.payload).includes("createdgoalName")) {
        state.createdgoalName = action.payload.createdgoalName
      }
      if(Object.keys(action.payload).includes("oprnCongratsModal")) {
        state.oprnCongratsModal = action.payload.oprnCongratsModal
      }
      if(Object.keys(action.payload).includes("goalAllDiamension")) {
        state.goalAllDiamension = action.payload.goalAllDiamension
      }
      if(Object.keys(action.payload).includes("isGoalDialmension")) {
        state.isGoalDialmension = action.payload.isGoalDialmension
      }
      if(Object.keys(action.payload).includes("testNotCompleted")) {
        state.testNotCompleted = action.payload.testNotCompleted
      }
      if(Object.keys(action.payload).includes("resultNotUnlocked")) {
        state.resultNotUnlocked = action.payload.resultNotUnlocked
      }
      if(Object.keys(action.payload).includes("questionCategoryId")) {
        state.questionCategoryId = action.payload.questionCategoryId
      }
      if (Object.keys(action.payload).includes("expanded")) {
        state.expanded = action.payload.expanded;
      }
      if (Object.keys(action.payload).includes("step")) {
        state.step = action.payload.step;
      }
      if (Object.keys(action.payload).includes("isLastStep")) {
        state.isLastStep = action.payload.isLastStep;
      }
      if (Object.keys(action.payload).includes("typeGoal")) {
        state.typeGoal = action.payload.typeGoal;
      }
      if (Object.keys(action.payload).includes("selectedDiamenstion")) {
        state.selectedDiamenstion = action.payload.selectedDiamenstion;
      }
      if (Object.keys(action.payload).includes("goalDetail")) {
        state.goalDetail = action.payload.goalDetail;
      }
      if (Object.keys(action.payload).includes("openConfirmationModal")) {
        state.openConfirmationModal = action.payload.openConfirmationModal;
      }
      if (Object.keys(action.payload).includes("isAddNewGoal")) {
        state.isAddNewGoal = action.payload.isAddNewGoal;
      }
      if (Object.keys(action.payload).includes("isGoalArchive")) {
        state.isGoalArchive = action.payload.isGoalArchive;
      }
      if (Object.keys(action.payload).includes("isCompletedGoal")) {
        state.isCompletedGoal = action.payload.isCompletedGoal;
      }
      if (Object.keys(action.payload).includes("preSelectedState")) {
        state.preSelectedState = action.payload.preSelectedState;
      }
      if (Object.keys(action.payload).includes("allPreSelectedState")) {
        state.allPreSelectedState = action.payload.allPreSelectedState;
      }
      if (Object.keys(action.payload).includes("isCalenderOpen")) {
        state.isCalenderOpen = action.payload.isCalenderOpen;
      }
      if (Object.keys(action.payload).includes("isGoalGet")) {
        state.isGoalGet = action.payload.isGoalGet;
      }
      if (Object.keys(action.payload).includes("tbdDate")) {
        state.tbdDate = action.payload.tbdDate;
      }
      if (Object.keys(action.payload).includes("goalName")) {
        state.goalName = action.payload.goalName;
      }
      if (Object.keys(action.payload).includes("schedualDate")) {
        state.schedualDate = action.payload.schedualDate;
      }
      if (Object.keys(action.payload).includes("othresArray")) {
        state.othresArray = action.payload.othresArray;
      }
      if (Object.keys(action.payload).includes("PersonalDataArray")) {
        state.PersonalDataArray = action.payload.PersonalDataArray;
      }
      if (Object.keys(action.payload).includes("goalScore")) {
        state.goalScore = action.payload.goalScore;
      }
      if (
        Object.keys(action.payload).includes("selectedQuestionCategoriesId")
      ) {
        state.selectedQuestionCategoriesId =
          action.payload.selectedQuestionCategoriesId;
      }
      if (Object.keys(action.payload).includes("isAskOtherModalOpen")) {
        state.isAskOtherModalOpen = action.payload.isAskOtherModalOpen;
      }
      if (Object.keys(action.payload).includes("completedGrowthAreaState")) {
        state.completedGrowthAreaState =
          action.payload.completedGrowthAreaState;
      }
      if (Object.keys(action.payload).includes("allCompletedGrowthAreaState")) {
        state.allCompletedGrowthAreaState =
          action.payload.allCompletedGrowthAreaState;
      }
      // 
      if (Object.keys(action.payload).includes("isAddMoreGrowthAreaOpen")) {
        state.isAddMoreGrowthAreaOpen = action.payload.isAddMoreGrowthAreaOpen;
      }
      if (Object.keys(action.payload).includes("isCompletedArchiveOpen")) {
        state.isCompletedArchiveOpen = action.payload.isCompletedArchiveOpen;
      }
      if (Object.keys(action.payload).includes("isArchieveOpen")) {
        state.isArchieveOpen = action.payload.isArchieveOpen;
      }
    },
    setGoalNotificationMessage: (state, action) => {
      state.notificationmesage = action.payload
    },
    resetGoalState: () => {
      return initialState;
    },
  },
});

export const { updategetGoal, updateGoalData, resetGoalState, updateAddGoalStep, updateAddGoalCategory, setGoalNotificationMessage } =
  GoalData.actions;
export default GoalData.reducer;
