import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
const initialState = {
  currentMonth: [],
  startDay: moment().startOf("month").format(),
  endDay: moment().endOf("month").format(),
  selectedDate: moment().format(),
  openJournalModal: false,
  openJournalItem: {},
  savingJournal: false,
  quote: {},
  completedXP: 0,
  goalXp: 0,
  openNotification: false,
  notificationMessage: "",
  openInviteModal: false,
  loadingButton: false,
  visibleCelebrationmodal: false,
  visibleEditGoalModal: false,
  userLevelDetails: {
    level: "",
    start: 0,
    end: 0,
  },
  dailyGoalLevel: [],
  selectedActionIds: [],
  selectedAHabitIds: [],
  buttonLoading: false,
  showStories: false,
  showYoutube: false,
  grwothNotFound: false,
  testNotCompleted: false,
  resultNotUnlocked: false,
  contentLoading: true,
  scheduleData: [],
  unSchedule: [],
  upcomingResponse: [],
  AllscheduleData: [],
  AllunSchedule: [],
  AllupcomingResponse: [],
  openToday: true,
  openUpcoming: true,
  openUnschedule: true,
  // action schedule
  isScheduleAction: false,
  scheduleActionState: {},
  actionIdDialog: "",
  isSchedulePopup: false,
  actionState: {
    actionName: "",
    actionDetail: "",
    executionDate: "",
    executionDateEnd: "",
    actionId: "",
    scheduled_type: "DEFAULT",
  },
  oldDateData: [],
  userPlanActionsData: [],
  openPlanJournalModal: false,
  openPlanJournalItem: {},
  openPlanJournalModalPlanId: undefined,
  isAskOtherModalOpenAction: false,
  viewPlanReadModal: false,
  planReadItem: {}
};
export const DashboardData = createSlice({
  name: "DashboardData",
  initialState,
  reducers: {
    updateDashStartEnd: (state, action) => {
      state.startDay = action.payload.startDay;
      state.endDay = action.payload.endDay;
    },
    updateSetShowStories: (state, action) => {
      state.showStories = action.payload;
    },
    updateSetShowYoutube: (state, action) => {
      state.showYoutube = action.payload;
    },
    openCloseInviteModal: (state, action) => {
      state.openInviteModal = action.payload;
    },
    updateDashButtonLoading: (state, action) => {
      state.buttonLoading = action.payload;
    },
    updateDashGoalXP: (state, action) => {
      state.goalXp = action.payload;
    },
    openCloseEditGoalModal: (state, action) => {
      state.visibleEditGoalModal = action.payload;
    },
    openCloseJournalModal: (state, action) => {
      state.openJournalModal = action.payload.openJournalModal;
      state.openJournalItem = action.payload.openJournalItem;
    },
    openCloseCelebrationModal: (state, action) => {
      state.visibleCelebrationmodal = action.payload;
    },
    updateStatusOfActionDashboard: (state, action) => {
      const foundPlan = [...state.userPlanActionsData].findIndex(it => it._id.planId === action.payload.planId)
      if(foundPlan > -1) {
        const copyOfPlans = [...state.userPlanActionsData]
        const foundIndex = copyOfPlans[foundPlan].actions.findIndex(
          (it) => it.userPlanActionId === action.payload.userPlanActionId
        );
        if (foundIndex > -1) {
          copyOfPlans[foundPlan].actions[foundIndex].status = action.payload.status;
        }
        state.userPlanActionsData = copyOfPlans
      }
    },
    updateJournalOfPlanActionDashboard: (state, action) => {
      const foundPlan = [...state.userPlanActionsData].findIndex(it => it._id.planId === action.payload.planId)
      if(foundPlan > -1) {
        const copyOfPlans = [...state.userPlanActionsData]
        const foundIndex = copyOfPlans[foundPlan].actions.findIndex(
          (it) => it.userPlanActionId === action.payload.userPlanActionId
        );
        if (foundIndex > -1) {
          copyOfPlans[foundPlan].actions[foundIndex].journal = action.payload.journal;
        }
        state.userPlanActionsData = copyOfPlans
      }
    },
    updateQuoteReadDashboard: (state, action) => {
      const foundPlan = [...state.userPlanActionsData].findIndex(it => it._id.planId === action.payload.planId)
      if(foundPlan > -1) {
        const copyOfPlans = [...state.userPlanActionsData]
        const foundIndex = copyOfPlans[foundPlan].planQuotesData.findIndex(
          (it) => it.quoteId === action.payload.quoteId
        );
        if (foundIndex > -1) {
          copyOfPlans[foundPlan].planQuotesData[foundIndex].userplanquoteData = [action.payload.userplanquoteData];
        }
        state.userPlanActionsData = copyOfPlans
      }
    },
    updateScheduleAtIndex: (state, action) => {
      if (action.payload.type === "Upcoming") {
        const foundIndex = [...state.upcomingResponse].findIndex((it) => {
          if (action.payload.actionId)
            return it.actionId === action.payload.actionId;
          if (action.payload.habitId) return it._id === action.payload.habitId;
        });
        console.log("foundIndex", foundIndex);
        if (foundIndex > -1) {
          if (action.payload.actionId) {
            state.upcomingResponse = state.upcomingResponse.map((it) => {
              if (it.actionId === action.payload.actionId) {
                it = {
                  ...it,
                  ...action.payload.obj,
                };
              }
              return it;
            });
          }
          if (action.payload.habitId) {
            state.upcomingResponse = state.upcomingResponse.map((it) => {
              if (it._id === action.payload.habitId) {
                it = {
                  ...it,
                  ...action.payload.obj,
                };
              }
              return it;
            });
          }
          Object.keys(action.payload.obj).map((it) => {
            // state.scheduleData[foundIndex][it] = action.payload.obj[it]
          });
        }
      } else if (action.payload.type === "Unscheduled") {
        const foundIndex = [...state.unSchedule].findIndex((it) => {
          if (action.payload.actionId)
            return it.actionId === action.payload.actionId;
          if (action.payload.habitId) return it._id === action.payload.habitId;
        });
        console.log("foundIndex", foundIndex);
        if (foundIndex > -1) {
          if (action.payload.actionId) {
            state.unSchedule = state.unSchedule.map((it) => {
              if (it.actionId === action.payload.actionId) {
                it = {
                  ...it,
                  ...action.payload.obj,
                };
              }
              return it;
            });
          }
          if (action.payload.habitId) {
            state.unSchedule = state.unSchedule.map((it) => {
              if (it._id === action.payload.habitId) {
                it = {
                  ...it,
                  ...action.payload.obj,
                };
              }
              return it;
            });
          }
          Object.keys(action.payload.obj).map((it) => {
            // state.scheduleData[foundIndex][it] = action.payload.obj[it]
          });
        }
      } else {
        const foundIndex = [...state.scheduleData].findIndex((it) => {
          if (action.payload.actionId)
            return it.actionId === action.payload.actionId;
          if (action.payload.habitId) return it._id === action.payload.habitId;
        });
        console.log("foundIndex", foundIndex);
        if (foundIndex > -1) {
          if (action.payload.actionId) {
            state.scheduleData = state.scheduleData.map((it) => {
              if (it.actionId === action.payload.actionId) {
                it = {
                  ...it,
                  ...action.payload.obj,
                };
              }
              return it;
            });
          }
          if (action.payload.habitId) {
            state.scheduleData = state.scheduleData.map((it) => {
              if (it._id === action.payload.habitId) {
                it = {
                  ...it,
                  ...action.payload.obj,
                };
              }
              return it;
            });
          }
          Object.keys(action.payload.obj).map((it) => {
            // state.scheduleData[foundIndex][it] = action.payload.obj[it]
          });
        }
      }
    },
    setDahData: (state, action) => {
      console.log("action.payload ", action.payload);
      if (Object.keys(action.payload).includes("viewPlanReadModal")) {
        state.viewPlanReadModal = action.payload.viewPlanReadModal;
      }
      if (Object.keys(action.payload).includes("planReadItem")) {
        state.planReadItem = action.payload.planReadItem;
      }
      if (Object.keys(action.payload).includes("currentMonth")) {
        state.currentMonth = action.payload.currentMonth;
      }
      if (Object.keys(action.payload).includes("actionIdDialog")) {
        state.actionIdDialog = action.payload.actionIdDialog;
      }
      if (Object.keys(action.payload).includes("userPlanActionsData")) {
        state.userPlanActionsData = action.payload.userPlanActionsData;
      }
      if (Object.keys(action.payload).includes("isAskOtherModalOpenAction")) {
        state.isAskOtherModalOpenAction =
          action.payload.isAskOtherModalOpenAction;
      }
      if (Object.keys(action.payload).includes("oldDateData")) {
        state.oldDateData = action.payload.oldDateData;
      }
      if (Object.keys(action.payload).includes("actionState")) {
        state.actionState = action.payload.actionState;
      }
      if (Object.keys(action.payload).includes("isSchedulePopup")) {
        state.isSchedulePopup = action.payload.isSchedulePopup;
      }
      if (Object.keys(action.payload).includes("scheduleActionState")) {
        state.scheduleActionState = action.payload.scheduleActionState;
      }
      if (Object.keys(action.payload).includes("isScheduleAction")) {
        state.isScheduleAction = action.payload.isScheduleAction;
      }
      if (Object.keys(action.payload).includes("AllscheduleData")) {
        state.AllscheduleData = action.payload.AllscheduleData;
      }
      if (Object.keys(action.payload).includes("AllunSchedule")) {
        state.AllunSchedule = action.payload.AllunSchedule;
      }
      if (Object.keys(action.payload).includes("AllupcomingResponse")) {
        state.AllupcomingResponse = action.payload.AllupcomingResponse;
      }
      if (Object.keys(action.payload).includes("upcomingResponse")) {
        state.upcomingResponse = action.payload.upcomingResponse;
      }
      if (Object.keys(action.payload).includes("unSchedule")) {
        state.unSchedule = action.payload.unSchedule;
      }
      if (Object.keys(action.payload).includes("openToday")) {
        state.openToday = action.payload.openToday;
      }
      if (Object.keys(action.payload).includes("openUpcoming")) {
        state.openUpcoming = action.payload.openUpcoming;
      }
      if (Object.keys(action.payload).includes("openUnschedule")) {
        state.openUnschedule = action.payload.openUnschedule;
      }
      if (Object.keys(action.payload).includes("contentLoading")) {
        state.contentLoading = action.payload.contentLoading;
      }
      if (Object.keys(action.payload).includes("startDay")) {
        state.startDay = action.payload.startDay;
      }
      if (Object.keys(action.payload).includes("grwothNotFound")) {
        state.grwothNotFound = action.payload.grwothNotFound;
      }
      if (Object.keys(action.payload).includes("testNotCompleted")) {
        state.testNotCompleted = action.payload.testNotCompleted;
      }
      if (Object.keys(action.payload).includes("resultNotUnlocked")) {
        state.resultNotUnlocked = action.payload.resultNotUnlocked;
      }
      if (Object.keys(action.payload).includes("endDay")) {
        state.endDay = action.payload.endDay;
      }
      if (Object.keys(action.payload).includes("selectedDate")) {
        state.selectedDate = action.payload.selectedDate;
      }
      if (Object.keys(action.payload).includes("scheduleData")) {
        state.scheduleData = action.payload.scheduleData;
      }
      if (Object.keys(action.payload).includes("openJournalModal")) {
        state.openJournalModal = action.payload.openJournalModal;
      }
      if (Object.keys(action.payload).includes("openJournalItem")) {
        state.openJournalItem = action.payload.openJournalItem;
      }
      if (Object.keys(action.payload).includes("openPlanJournalModal")) {
        state.openPlanJournalModal = action.payload.openPlanJournalModal;
      }
      if (Object.keys(action.payload).includes("openPlanJournalModalPlanId")) {
        state.openPlanJournalModalPlanId = action.payload.openPlanJournalModalPlanId;
      }
      if (Object.keys(action.payload).includes("openPlanJournalItem")) {
        state.openPlanJournalItem = action.payload.openPlanJournalItem;
      }
      if (Object.keys(action.payload).includes("savingJournal")) {
        state.savingJournal = action.payload.savingJournal;
      }
      if (Object.keys(action.payload).includes("quote")) {
        if (!Object.keys(state.quote).length) {
          state.quote = action.payload.quote;
        }
      }
      if (Object.keys(action.payload).includes("completedXP")) {
        state.completedXP = action.payload.completedXP;
      }
      if (Object.keys(action.payload).includes("goalXp")) {
        state.goalXp = action.payload.goalXp;
      }
      if (Object.keys(action.payload).includes("openNotification")) {
        state.openNotification = action.payload.openNotification;
      }
      if (Object.keys(action.payload).includes("notificationMessage")) {
        state.notificationMessage = action.payload.notificationMessage;
      }
      if (Object.keys(action.payload).includes("openInviteModal")) {
        state.openInviteModal = action.payload.openInviteModal;
      }
      if (Object.keys(action.payload).includes("loadingButton")) {
        state.loadingButton = action.payload.loadingButton;
      }
      if (Object.keys(action.payload).includes("visibleCelebrationmodal")) {
        state.visibleCelebrationmodal = action.payload.visibleCelebrationmodal;
      }
      if (Object.keys(action.payload).includes("visibleEditGoalModal")) {
        state.visibleEditGoalModal = action.payload.visibleEditGoalModal;
      }
      if (Object.keys(action.payload).includes("userLevelDetails")) {
        state.userLevelDetails = action.payload.userLevelDetails;
      }
      if (Object.keys(action.payload).includes("dailyGoalLevel")) {
        state.dailyGoalLevel = action.payload.dailyGoalLevel;
      }
      if (Object.keys(action.payload).includes("buttonLoading")) {
        state.buttonLoading = action.payload.buttonLoading;
      }
    },
    resetDashboardState: () => {
      return initialState;
    },
  },
});

export const {
  updateDashStartEnd,
  openCloseInviteModal,
  updateDashButtonLoading,
  updateDashGoalXP,
  openCloseEditGoalModal,
  openCloseJournalModal,
  openCloseCelebrationModal,
  setDahData,
  resetDashboardState,
  updateScheduleAtIndex,
  updateSetShowStories,
  updateSetShowYoutube,
  updateStatusOfActionDashboard,
  updateJournalOfPlanActionDashboard,
  updateQuoteReadDashboard
} = DashboardData.actions;
export default DashboardData.reducer;
