import React, { useState } from "react";
import classes from "./TestPageLayout.module.css";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Card } from "@mui/material";
import CloseIcon from "../../assets/Svg/CloseIcon.svg";
import CloseIconBlack from "../../assets/Svg/CloseIconBlack.svg";
import Flag from "../../assets/Svg/Flag.svg";
import AlertModal from "../../components/AlertModal/AlertModal";

const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  // display: "block",
  // paddingBlock: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  minHeight: "100vh",
  position: 'relative'
}));

const CardContent = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: "40px",
  // [theme.breakpoints.up("xl")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.down("md")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // background: 'yellow',
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    with: "100%",
    padding: "40px",
  },
}));

const CardContent2 = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  padding: "40px",
  // [theme.breakpoints.up("xl")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.down("md")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // background: 'yellow',
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    with: "100%",
    padding: "40px",
  },
}));

const MainCard = styled(Box)(({ theme }) => ({
  background: "#fff",
  width: "692px",
  height: "700px",
  // minHeight: "calc(100vh - 4rem)",
  position: "relative",
  [theme.breakpoints.up("xl")]: {
    width: "692px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "692px",
  },
  [theme.breakpoints.down("md")]: {
    width: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    width: "100%",
  },
}));
const BrandWrapper = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px',
  gap: '6px',

  position: 'absolute',
  width: '219px',
  height: '38px',
  // left: '611px',
  top: '12px',

  background: '#9747FF',
  borderRadius: '12px',
}))
const CloseIconWrapper = styled("div")(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  zIndex: '100',
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    top: '7px',
    right: '10px'
  },
}));

const FlagIconWrapper = styled("div")(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  zIndex: '100',
  // [theme.breakpoints.up("xl")]: {
  // },
  // [theme.breakpoints.down("lg")]: {
  // },
  [theme.breakpoints.down("md")]: {
    bottom: '0px',
    right: '7px',
  },
  // [theme.breakpoints.down("sm")]: {
  // },
}));

const BranchnameText = styled("span")(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '100%',
  /* identical to box height, or 14px */

  letterSpacing: '-0.01em',

  /* Main/White */

  color: '#FFFFFF',
}))

const TestPageLayout = ({ showCloseIcon = true, showFlag = true, showBrand = false }) => {
  const [openAlert, setOpenAlert] = useState(false);

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };
  return (
    <>
      <RootStyle className={classes.main_wrapper}>
        {showBrand ? (
          <BrandWrapper >
            <BranchnameText>
            {window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery"}
            </BranchnameText>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
              gap: '10px',

              width: '24px',
              height: '24px',

              /* Main/White */

              background: '#FFFFFF',
              borderRadius: '100px',
            }}>
              <span style={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '100%',
                /* identical to box height, or 14px */

                letterSpacing: '-0.01em',

                color: '#9747FF',
              }}>
                β
              </span>
            </div></BrandWrapper>
        ) : null}
        {showCloseIcon ? (
          <CloseIconWrapper onClick={handleClickOpen} style={{ width: "32px", height: "32px", background: "white", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={CloseIconBlack} alt="" />
          </CloseIconWrapper>
        ) : null}
        {showFlag ? (
          <FlagIconWrapper >
            <img src={Flag} alt="" />
          </FlagIconWrapper>
        ) : null}

        <MainCard
          sx={{
            minWidth: 250,
            borderRadius: "40px",
            marginTop: showBrand ? '50px' : '0',
            height: showBrand ? '690px' : '700px',
            // marginInline: "auto",
          }}
        >
          {showBrand ? (
            <CardContent2>
              <Outlet />
            </CardContent2>
          ) : (
            <CardContent>
              <Outlet />
            </CardContent>
          )}

        </MainCard>
        <AlertModal open={openAlert} handleClose={handleClose} />
      </RootStyle>
    </>
  );
};

export default TestPageLayout;
