import { Box } from "@mui/material"
import QuoteIcon from "./../../assets/Svg/quoteIcon.svg"
import QuoteFlourishIcon from "./../../assets/Svg/quoteFlourishIcon.svg"

import classes from "./quote-share.module.css"
const QuoteTitle = ({ title = ``, name = '' }) => {
    return (
        <Box className={window.florishApp ?classes.flourishquote :classes.quote} sx={{ backgroundImage: `url(${window.florishApp ? QuoteFlourishIcon:QuoteIcon}) !important`, backgroundRepeat: 'no-repeat !important', backgroundPositionX: '20px !important' }}>
            {/* <img src={QuoteIcon} alt="" /> */}
            <div className={classes.quoteDataContent}>
                <span className={classes.quoteDataContentText}>
                    {title}
                </span>
                <span className={classes.quoteDataContentTextName}>
                    {name}
                </span>
            </div>
        </Box>
    )
}

export default QuoteTitle