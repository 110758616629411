import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import PlansServices from "../../services/Plans";
import { setStoreData } from "../../Store/Reducers/plans";
import { CircularProgress, Typography } from "@mui/material";
import { GeneralSX } from "../../components/Text/Text";
import classes from "./plans.module.css"
import clsx from "clsx";
import palette from "../../theme/palette";
import { imageURLRender } from "../../utils";
import PlanFallback from "../../assets/Svg/PlanFallback.svg"
import PlanLow from "../../assets/Svg/PlanLow.svg"
import PlanMid from "../../assets/Svg/PlanMid.svg"
import PlanHigh from "../../assets/Svg/PlanHigh.svg"
import { useNavigate } from "react-router-dom";
import { XP } from "../PlanDetails/plans.styles";
import EmptyStateBox from "./plansEmptyBox";
import { ResultAllTestDiamention, getGoals } from "../../services/auth";
import HTMLTooltip from "../../components/ToolTipWithIcon/HTMLTooltip";
let isLareadyCalled = false
const PlanTable = ({ setDisableButton }) => {
    const [isLoading, setIsLoading] = useState(true)
    const { userData } = useSelector((state) => state.userProfile);
    const { activeTabObject, plansTable, grwothNotFound, resultNotUnlocked, testNotCompleted } = useSelector((state) => state.planData);
    const dispatch = useDispatch()
    useEffect(() => {
        !isLareadyCalled && getPlanData()
    }, [activeTabObject, userData?.uid])
    useEffect(() => {
        const archive = false;
        const status = "IN_PROGRESS";
        const user = localStorage.getItem("userDetail");
        setDisableButton(true)
        ResultAllTestDiamention(user)
            .then((data) => {
                if (data.allPercentage && data.allPercentage.length) {
                    const unLockResults = [...data.allPercentage].filter(it => it.unlock)
                    if (unLockResults && unLockResults.length) {
                        getGoals(user, archive, status)
                            .then((response) => {
                                if (response.data && response.data.length) {


                                } else {
                                    dispatch(setStoreData({
                                        grwothNotFound: true,
                                        contentLoading: false,
                                        testNotCompleted: false,
                                        resultNotUnlocked: false
                                    }))
                                }
                                console.log("getGoals response ========", response);
                            })
                    } else {
                        dispatch(setStoreData({
                            resultNotUnlocked: true,
                            contentLoading: false,
                            testNotCompleted: false,
                            grwothNotFound: false,
                        }))
                    }

                } else {
                    /*  */
                    dispatch(setStoreData({
                        testNotCompleted: true,
                        contentLoading: false,
                        resultNotUnlocked: false,
                        grwothNotFound: false,

                    }))
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            });
    }, [])
    console.log()
    const getPlanData = () => {
        if (userData?.uid) {
            setDisableButton(true)
            dispatch(setStoreData({
                plansLoading: true
            }))
            setIsLoading(true)
            isLareadyCalled = true
            PlansServices.PLANS({
                "uid": userData?.uid,
                "type": activeTabObject.type,
                "questionCategoryId": activeTabObject.questionCategoryId,
            }).then(data => {
                isLareadyCalled = false
                dispatch(setStoreData({
                    plansTable: [...data?.planData] || []
                }))
                setIsLoading(false)
                console.log("data ===", data)
            }).catch(err => {
               
                console.log("err")
            }).finally(() => {
                setDisableButton(false)
                dispatch(setStoreData({
                    plansLoading: false
                }))
            })
        }

    }
    return <>
        <Typography sx={{
            ...GeneralSX({
                color: "#190D1A",
                fontSize: 40,
                fontWeight: 600,
                lineHeight: "120%",
                letterSpacing: '-0.4px'
            })
        }}>{activeTabObject.type === "MY_PLANS" ? "Based on your selection" : activeTabObject.type === "FOR_YOU" ? "Based on your goals" : "Based on your unlocked dimensions"}</Typography>

        {isLoading ? (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "calc(100vh - 280px)",
                }}
            >
                <CircularProgress />
            </div>
        ) : plansTable.length > 0 ? <div className={classes.planList}>{plansTable.map(item => {
            return ["FOR_YOU", "ALL"].includes(activeTabObject.type) ? <CustomPlanBox {...item} /> : <PlanBox {...item} />
        })}</div> : (grwothNotFound || resultNotUnlocked || testNotCompleted || (activeTabObject.type === "MY_PLANS" && plansTable.length <= 0)) && <EmptyStateBox activeTab={activeTabObject.type} grwothNotFound={grwothNotFound} resultNotUnlocked={resultNotUnlocked} testNotCompleted={testNotCompleted} />}

    </>
}

export default PlanTable

const CustomPlanBox = (item) => {
    console.log("item", item)
    const dispatch = useDispatch()
    const havePlans = item.plansData && item.plansData.length > 0
    return <div className={havePlans ? classes.plan : classes.planDisabled} key={item._id} onClick={() => {
        // navigate('/plans/' + item.planId)
        havePlans && dispatch(setStoreData({
            activeTabObject: {
                type: "SPECIFIC",
                questionCategory: item.questionCategory, questionCategoryId: item.questionCategoryId
            }
        }))
    }}>
        <div className={classes.frame4426}>
            <div className={classes.frame4424}>
                {!havePlans && <div className={classes.frame4392}>
                    <div className={clsx(classes.frame4391, classes.borderBgPrimary)}>
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.primary.main,
                                fontSize: 10,
                                fontWeight: 500,
                                lineHeight: "100%",
                                letterSpacing: '-0.1px'
                            })
                        }}>No plans yet</Typography>
                    </div>
                </div>}
                <div className={classes.frame4390}>
                    <HTMLTooltip
                        title={item.questionCategory}
                        placement="top"
                        showArrow={true}
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                background: "#FFF",
                                color: "#000",
                                maxWidth: "350px"
                            }
                        }}
                        customIcon={<Typography className="text-1line" sx={{
                            ...GeneralSX({
                                color: palette.common.mainBlack,
                                fontSize: 18,
                                fontWeight: 600,
                                lineHeight: "116%",
                                letterSpacing: '-0.18px'
                            })
                        }}>{item.questionCategory}</Typography>}
                    />

                    <HTMLTooltip
                        title={item.description}
                        placement="top"
                        showArrow={true}
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                background: "#FFF",
                                color: "#000",
                                maxWidth: "350px"
                            }
                        }}
                        customIcon={<Typography className={!havePlans ? "text-3lines" : "text-4lines"} sx={{
                            ...GeneralSX({
                                color: palette.common.darkGray,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "145%",
                                letterSpacing: '-0.14px'
                            })
                        }}>{item.description}</Typography>}
                    />

                </div>
            </div>
            <div className={havePlans ? classes.imagePlan :classes.imagePlanWithout}>
                <img
                    //src={SmallEngagement} 
                    src={imageURLRender(item?.url)}
                    alt=""
                    onError={({ currentTarget }) => {
                        // currentTarget.onerror = null; // prevents looping
                        currentTarget.src = PlanFallback;
                    }}
                />


            </div>
        </div>
    </div>
}

const PlanBox = (item) => {
    console.log("==== item", item)
    const navigate = useNavigate()
    const isFreezed = item?.userPlansData && item?.userPlansData[0] && item?.userPlansData[0]?.status === "freeze"
    return <div className={!isFreezed ? classes.plan : classes.planDisabled} key={item._id} onClick={() => {
        navigate('/plans/' + item.planId)
    }}>
        <div className={classes.frame4426}>
            <div className={classes.frame4424}>
                <div className={!isFreezed ? classes.frame4392 : classes.frame4392Disabled}>
                    <div className={classes.frame4384}>
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.common.white,
                                fontSize: 10,
                                fontWeight: 500,
                                lineHeight: "100%",
                                letterSpacing: '-0.1px'
                            })
                        }}>{item.questionCategory}</Typography>
                    </div>
                    <div className={clsx(classes.frame4391, classes.borderBgPrimary)}>
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.primary.main,
                                fontSize: 10,
                                fontWeight: 500,
                                lineHeight: "100%",
                                letterSpacing: '-0.1px'
                            })
                        }}>{item.weekDuration} weeks</Typography>
                    </div>
                    <div className={clsx(classes.frame43921, classes.borderBgPrimary)}>
                        <XP />
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.primary.main,
                                fontSize: 10,
                                fontWeight: 500,
                                lineHeight: "100%",
                                letterSpacing: '-0.1px'
                            })
                        }}>{item.xpLevel}</Typography>
                    </div>
                </div>
                <div className={classes.frame4390}>
                    <HTMLTooltip
                        title={item.name}
                        placement="top"
                        showArrow={true}
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                background: "#FFF",
                                color: "#000",
                                maxWidth: "350px"
                            }
                        }}
                        customIcon={<Typography className="text-1line" sx={{
                            ...GeneralSX({
                                color: palette.common.mainBlack,
                                fontSize: 18,
                                fontWeight: 600,
                                lineHeight: "116%",
                                letterSpacing: '-0.18px'
                            })
                        }}>{item.name}</Typography>}
                    />

                    <HTMLTooltip
                        title={item.shortDescription}
                        placement="top"
                        showArrow={true}
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                background: "#FFF",
                                color: "#000",
                                maxWidth: "350px"
                            }
                        }}
                        customIcon={<Typography className="text-3lines" sx={{
                            ...GeneralSX({
                                color: palette.common.darkGray,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "145%",
                                letterSpacing: '-0.14px'
                            })
                        }}>{item.shortDescription}</Typography>}
                    />

                </div>
            </div>
            <div className={!isFreezed ? classes.imagePlan :classes.imagePlanWithout}>
                {item.planLevel === "Low" ? (
                    <img
                        //src={SmallEngagement} 
                        src={PlanLow}
                        alt=""
                        style={{
                            objectFit: "cover",
                            borderRadius: "0px 20px 20px 0px"
                        }}
                        onError={({ currentTarget }) => {
                            // currentTarget.onerror = null; // prevents looping
                            currentTarget.src = PlanFallback;
                        }}
                    />
                ) : item.planLevel === "Moderate" ? (
                    <img
                        //src={SmallEngagement} 
                        src={PlanMid}
                        alt=""
                        style={{
                            objectFit: "cover",
                            borderRadius: "0px 20px 20px 0px"
                        }}
                        onError={({ currentTarget }) => {
                            // currentTarget.onerror = null; // prevents looping
                            currentTarget.src = PlanFallback;
                        }}
                    />
                ) : item.planLevel === "High" ? (
                    <img
                        //src={SmallEngagement} 
                        src={PlanHigh}
                        alt=""
                        style={{
                            objectFit: "cover",
                            borderRadius: "0px 20px 20px 0px"
                        }}
                        onError={({ currentTarget }) => {
                            // currentTarget.onerror = null; // prevents looping
                            currentTarget.src = PlanFallback;
                        }}
                    />
                ) : <img
                    //src={SmallEngagement} 
                    src={imageURLRender(item?.imageURL)}
                    alt=""
                    onError={({ currentTarget }) => {
                        // currentTarget.onerror = null; // prevents looping
                        currentTarget.src = PlanFallback;
                    }}
                />}



            </div>
        </div>
    </div>
}