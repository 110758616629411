import React, { useState } from "react";
import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import ExpandMore from "../../../assets/Svg/ExpandMore.svg";
import classes from "./notificationDetails.module.css";
import Swich from "../../../assets/Svg/Swich.svg";
import LeftArrowIcom from "../../../assets/Svg/LeftArrowIcon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import SwichToggle from "./SwichToggle";
import CustomizedSwitches from "./SwichToggle";
import { useSelector } from "react-redux";
import { engagementStatus } from "../../../services/auth";

function NotificationsDetailes() {
  const navigate = useNavigate()
const theme = useTheme();
const { userData } = useSelector((state) => state.userProfile);
const matches = useMediaQuery(theme.breakpoints.down("md"));
 const [selectedImage, setSelectedImage] = useState(null);
 const [emailChecked, setEmailChecked] = useState(userData.engagementMail);
 const [pushChecked, setPushChecked] = useState(userData.engagementPush);
 const [notificationChecked, setNotificationChecked] = useState(userData.engagementMail || userData.engagementPush);
  // const [lable, setLable] = useState([
  //   "Reminders",
  //   "Updates from your community",
  // ]);
  // const [layout, setLayout] = useState([
  //   "These notifications remind you of Updates on your discovery",
  // ]);
 
  const backToSideNavigate = () => {
    navigate("/settings/settingnavigationbar");
  };
  const makeAPiCall = (obj) => {
    const objForServer ={
      uid: localStorage.getItem("userDetail"),
      ...obj
    }
    engagementStatus(objForServer).then(res => {
      console.log("res", res)

    }).catch(err => {
      console.log("err", err)
    })
  }
  const changeNotification = (val) => {
    console.log("val", val, val.target.checked);
    setEmailChecked(val.target.checked)
    setPushChecked(val.target.checked)
    setNotificationChecked(val.target.checked)
    makeAPiCall({
      engagementMail: val.target.checked,
      engagementPush: val.target.checked
    })
  }
  const changeEmail = (val) => {
    setEmailChecked(val.target.checked)
    if(!pushChecked && !val.target.checked) {
      setNotificationChecked(false)
    }
    if(!pushChecked && val.target.checked) {
      setNotificationChecked(true)
    }
    makeAPiCall({
      engagementMail: val.target.checked,
      engagementPush: pushChecked
    })
  }
  const changePush = (val) => {
    setPushChecked(val.target.checked)
    if(!emailChecked && !val.target.checked) {
      setNotificationChecked(false)
    }
    if(!emailChecked && val.target.checked) {
      setNotificationChecked(true)
    }
    makeAPiCall({
      engagementMail: emailChecked,
      engagementPush: val.target.checked
    })
  }
  return (
    <>
      {matches ? (
        <Typography variant="h5">
          <img
            className={classes.backbutton}
            onClick={backToSideNavigate}
            alt="..."
            src={
              selectedImage ? URL.createObjectURL(selectedImage) : LeftArrowIcom
            }
          />
          Notifications
        </Typography>
      ) : (
        <Typography variant="h4">Notifications</Typography>
      )}
      <div>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<img src={ExpandMore} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid>
              <Typography variant="h5">
                Reminders <br />
              </Typography>
              <Typography className={classes.push_mail} variant="p">
                Push, email
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.notification_title} variant="lable">
              These notifications remind you of updates on your discovery
            </Typography>
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Allow notifications from {window.florishApp ? "FlourishLabs" : "SSD"}
              </Typography>
              <div>
                <CustomizedSwitches checked={notificationChecked} onChange={changeNotification}/>
              </div>
            </Grid>
            <Typography className={classes.notification_title} variant="lable">
              Where to receive
            </Typography>
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Email
              </Typography>
              <div>
                <CustomizedSwitches checked={emailChecked} onChange={changeEmail}/>
              </div>
            </Grid>
            <hr className={classes.line} />
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Push
              </Typography>
              <div>
                <CustomizedSwitches checked={pushChecked} onChange={changePush}/>
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<img src={ExpandMore} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid>
              <Typography variant="h5">
                Updates from your community <br />
              </Typography>
              <Typography className={classes.push_mail} variant="p">
                {" "}
                Push, email
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.notification_title} variant="lable">
              These notifications Updates you on how your friends are
              interacting with SSD and you
            </Typography>
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Allow notifications from SSD
              </Typography>
              <div>
                <CustomizedSwitches />
              </div>
            </Grid>
            <Typography className={classes.notification_title} variant="lable">
              Where to receive
            </Typography>
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Email
              </Typography>
              <div>
                <CustomizedSwitches />
              </div>
            </Grid>
            <hr className={classes.line} />
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Push
              </Typography>
              <div>
                <CustomizedSwitches />
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<img src={ExpandMore} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Grid>
              <Typography variant="h5">
                Annoouncements
                <br />
              </Typography>
              <Typography className={classes.push_mail} variant="p">
                Push, email
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.notification_title} variant="lable">
              These notifications send you Updates on our marketing campaigns
              and product news
            </Typography>
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Allow notifications from SSD
              </Typography>
              <div>
                <CustomizedSwitches />
              </div>
            </Grid>
            <Typography className={classes.notification_title} variant="lable">
              Where to receive
            </Typography>
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Email
              </Typography>
              <div>
                <CustomizedSwitches />
              </div>
            </Grid>
            <hr className={classes.line} />
            <Grid className={classes.layout}>
              <Typography className={classes.layout_lable} variant="p">
                Push
              </Typography>
              <div>
                <CustomizedSwitches />
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>  */}
      </div>
    </>
  );
}

export default NotificationsDetailes;
