import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    overflowY: "auto",
    position: 'relative',
    height: "100%",
}));

export const InputField = styled(TextField)`
    height: 56px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-input {
      font-size: 16px;
      color: #190d1a;
      ::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #434243;
      }
    }
  `;