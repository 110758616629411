import { CircularProgress, IconButton } from "@mui/material";
import classes from "./InviteLogin.module.css";
import Box from "@mui/material/Box";
import Input from "../../../components/Input/Input";
import Text from "../../../components/Text/Text";
import {
  LinkButton,
  LinkButtonWithText,
  SubmitButton,
} from "../../../components/Button/Button";
import google from "../../../assets/images/google.png";
import facebook from "../../../assets/images/fb.png";
import apple from "../../../assets/images/apple.png";
import {
  appleProvider,
  facebookProvider,
  googleProvider,
} from "../../../config/authMethods";
import { socialMediaAuth, userLogin } from "../../../services/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { isValidEmail, isValidPassword } from "../../../validations/Email/Email";
import {
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../../config/firebase";
import useNotifications from "../../../Hooks/useNotifications";
import { useSelector } from "react-redux";
import Google from "../../../assets/Svg/Google.svg"
import Facebook from "../../../assets/Svg/Facebook.svg"
import Apple from "../../../assets/Svg/Apple.svg"
import { WrapperDiv } from "../StyledComponent";

export default function InviteLogin() {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inviteID = localStorage.getItem("inviteId")

  const { userData } = useSelector((state) => state.userProfile);
  console.log(33, userData);

  const { Notification, updateNotificationState } = useNotifications(false);

  const loginUser = async (uid) => {
    const inviteID = localStorage.getItem("inviteId")
    setIsLoading(true);
    const body = {
      uid: uid,
      inviteID: inviteID,
      "ipAddress": localStorage.getItem("ipAddress")
    };

    localStorage.setItem("userDetail", uid);

    userLogin(body)
      .then((data) => {
        console.log(48, data);
        updateNotificationState(data.message);
        navigate(`/invite/${inviteID}/done`);
      })
      .catch((error) => {
        updateNotificationState(error.response.data.description);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnClick = async (provider) => {
    try {
      const result = await socialMediaAuth(provider);
      if (!result.uid) {
        throw new Error(result.message);
      } else {
        localStorage.setItem("userDetail", result?.uid);
        localStorage.setItem("userEmail", result?.email); // store this in the store
        loginUser(result?.uid);
      }
    } catch (error) {
      if (!error.message.includes("auth/popup-closed-by-user")) {
        updateNotificationState(error?.message);
      }
      setIsLoading(false);
    }
  };

  const emailAuth = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => {
        console.log("----------", err.message);
        // user not registered
        if (err.message.includes("auth/wrong-password")) {
          setErrorPassword("Invalid credentials");
        } else if (err.message.includes("auth/user-not-found")) {
          setErrorEmail("User with such email isn’t found");
        }
        setIsLoading(false);
        // return err
      });
  };

  const firebaseLogin = async (email, password) => {
    try {
      const result = await emailAuth(email, password);
      console.log(result);
      if (result?.user) {
        loginUser(result?.user?.uid);
      }
    } catch (error) {
      updateNotificationState("User not found");
      setIsLoading(false);
      console.log(">>>", error);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    firebaseLogin(email, password);
  };

  const signinWithApple = async () => {
    // signInWithRedirect(auth, appleProvider)
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const user = result.user;
        console.log(result.user.uid);
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        if (result?.user) {
          loginUser(result.user.uid);
        }
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        console.log(error, credential);
        setErrorPassword("Invalid credentials");
        setErrorEmail("Invalid credentials");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px",mt: "60px" }}>
        <Text textValue="Welcome back" variant="h2" />
      </Box>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Email or username"
          value={email}
          onChange={setEmail}
          validationFunction={isValidEmail}
          type="text"
          errorMessage={errorEmail}
          setErrorMessage={setErrorEmail}
        />
      </Box>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Password"
          value={password}
          onChange={setPassword}
          validationFunction={isValidPassword}
          type="password"
          errorMessage={errorPassword}
          setErrorMessage={setErrorPassword}
          height="56px"
        />
      </Box>
      <Box className={classes.forgotPassword_btn_wrapper}>
        <LinkButton
          buttonText="Forgot password?"
          color="#434243"
          navigateUrl="/forgotpassword"
        />
      </Box>
      <Box sx={{ width: "100%", paddingBottom: "16px" }}>
        <SubmitButton
          buttonText="Log In"
          isDisabled={!email || !password || errorEmail || errorPassword}
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </Box>
      <Box className={`${classes.wrapper} ${classes.orText}`}>or</Box>
      <Box className={classes.wrapper} sx={{ gap: "12px", padding: "16px" }}>
        <Box
          className={classes.imgIcon}
          onClick={() => handleOnClick(googleProvider)}
        >
          <img src={Google} alt="" />
        </Box>

        <Box className={classes.imgIcon} onClick={() => signinWithApple()}>
          <img src={Apple} alt="" />
        </Box>

        <Box
          className={classes.imgIcon}
          onClick={() => handleOnClick(facebookProvider)}
        >
          <img src={Facebook} alt="" />
        </Box>
      </Box>
      <Box className={classes.wrapper} sx={{mb: "60px"}}>
        <LinkButtonWithText
          text="Not a user yet?"
          buttonText="Sign up"
          navigateUrl={`/invite/${inviteID}/signup`}
        />
      </Box>
      {Notification}
    </>
  );
}
