// local 82.180
// live 92.205
// export const baseUrl = "http://92.205.28.214:3000/api/v1/";
export const baseUrl = `https://apip.scientific-selfdiscovery.com/api/v1/`;
// export const baseUrl = `http://16.171.161.64:3000/api/v1/`;
// export const baseUrl = "https://personalityapp.onrender.com/api/v1/";
// export const baseUrl = "http://82.180.160.159:3001/api/v1/";
export const IMAGE_BASE_URL = "https://apip.scientific-selfdiscovery.com";
// export const IMAGE_BASE_URL = "http://16.171.161.64:3000";
// export const IMAGE_BASE_URL = "http://92.205.28.214:3000";

export const baseEndpoints = {
  createUser: `${baseUrl}app/create-user`,
  userLogin: `${baseUrl}app/user-login`,
  updateUser: `${baseUrl}app/update-profile`,
  updateSignupUser: `${baseUrl}app/update-signup-user`,
  deleteUser: `${baseUrl}app/delete`,
  forgotPassword: `${baseUrl}app/forgot-password`,
  updatePassword: `${baseUrl}app/update-password`,
  getUser: `${baseUrl}app/get-user-profile`,
  termsAndCondition: `${baseUrl}policy/list`,
  categoryList: `${baseUrl}category/list`,
  categoryListResponse: `${baseUrl}category/list-response`,
  categoryPage: `${baseUrl}sub-category/list`,
  categoryPageNew: `${baseUrl}sub-category/list-response`,
  getAllQuestions: `${baseUrl}question/list`,
  storeAnswer: `${baseUrl}question/answer`,
  getDimensions: `${baseUrl}question-category/list`,
  getAllTestScores: `${baseUrl}result/score-test`,
  archivedAnswer: `${baseUrl}question/archive-answer`,
  inviteOther: `${baseUrl}invite/create`,
  inviteTestQuestionList: `${baseUrl}invite-test-question/list`,
  inviteTestAnswerList: `${baseUrl}invite-test-question/answer`,
  inviteTestReflectiveQuestionList: `${baseUrl}invite-question/get`,
  inviteTestReflectiveAnswer: `${baseUrl}invite-question/answer`,
  anonymousUpdateUserData: `${baseUrl}app/anonymous-update`,
  getInvite: `${baseUrl}invite/get`,
  inviteRegister: `${baseUrl}app/invite-register`,
  archiveInviteTestAnswer: `${baseUrl}invite-test-question/archive-answer`,
  resultAllTestDiamention: `${baseUrl}result/all-dimensions`,
  resultAllTestDiamentionNew: `${baseUrl}sub-category/list-response`,
  userListBYTest: `${baseUrl}invite/users-list-by-test`,
  allDiamentionByTest: `${baseUrl}result/all-dimensions-by-test`,
  inviteUserList: `${baseUrl}invite/users-list`,
  allDiamentionByInvite: `${baseUrl}result/all-dimensions-invite`,
  inviteResultQuetionList: `${baseUrl}invite-question/get-list`,
  feedbackDiamention: `${baseUrl}feedback-dimension/create`,
  allDiamentionAverageInvite: `${baseUrl}result/all-dimensions-average-invite`,
  allDiamentionByTestInvite: `${baseUrl}result/all-dimensions-by-test-invite`,
  subCategoriesByInvite: `${baseUrl}sub-category/list-invite`,
  allDiamentionAverageByInviteUser: `${baseUrl}result/all-dimensions-average-invite-by-user`,
  getGoals: `${baseUrl}goal/get-goals-new`,
  goalCreate: `${baseUrl}goal/create`,
  goalAllDiamension: `${baseUrl}result/all-dimensions-goals`,
  goalGetPreSelectedGrowth: `${baseUrl}goal/get-pre-selected`,
  createGoalHabitAction: `${baseUrl}goal/create-habit-action`,
  editPreSelectedGrowth: `${baseUrl}goal/edit-pre-selected-growth-user`,
  removeGrowthArea: `${baseUrl}goal/remove-pre-selected-growth-user`,
  goalCreateHabitAction: `${baseUrl}goal/create-habit-action`,
  goalUpdateHabitAction: `${baseUrl}goal/update-habit-action-data`,
  goalDeleteHabitAction: `${baseUrl}goal/delete-habit-action`,
  goalDeleteCompleteAction: `${baseUrl}goal/archive-habit-calender`,
  goalDuplicateHabitAction: `${baseUrl}goal/duplicate-habit-action`,
  goalArchive: `${baseUrl}goal/archive`,
  futureTestCreate: `${baseUrl}future-test/create`,
  questionCategoryCreateFavorite: `${baseUrl}question-category/create-favorite`,
  questionCategoryDeleteFavorite: `${baseUrl}question-category/delete-favorite`,
  unlockGAAction: `${baseUrl}unlock/ga-action`,
  getGrowthData: `${baseUrl}growth-calender/get-growth-data`,
  growthCalenderCreate: `${baseUrl}growth-calender/create`,
  growthCalenderGetCalenderData: `${baseUrl}growth-calender/get-calender-data`,
  growthCalenderUpdate: `${baseUrl}growth-calender/update`,
  updateHabitActionCalender: `${baseUrl}growth-calender/update-habit-action-calender`,
  generateAnoName: `${baseUrl}app/anonymous-name`,
  engagementStatus: `${baseUrl}app/engagement-status`,
  featureCreateData: `${baseUrl}feature/create`,
  feadbackRequest: `${baseUrl}create`,
  bugCreate: `${baseUrl}bug/create`,
  updateImage: `${baseUrl}app/update-profile-image`,
  giveFeedback: `${baseUrl}feedback/create`,
  deleteProfile: `${baseUrl}app/delete-profile-image`,
  passcode: `${baseUrl}passcode/verify`,
  WAIT_LIST: {
    CREATE: `${baseUrl}wait-list/create`
  },
  PLANS: {
    TAGS: `${baseUrl}plan/get-plan-user-tags`,
    LIST: `${baseUrl}plan/get-plan-user`,
    SELECT_PLAN: `${baseUrl}plan/select-plan`,
    STATUS_UPDATE: `${baseUrl}plan/update-status`
  },
  PLAN_ACTIN: {
    ACTION_BYID: `${baseUrl}plan-action/plan-action-by-planId`,
    UPDATE_JOURNAL: `${baseUrl}plan-action/update-journal`,
    UPDATE_STATUS: `${baseUrl}plan-action/update-plan-action`,
    QUOTE_READ: `${baseUrl}plan-quote/read`,
  },
  topiclist: `${baseUrl}topic/list`,
  PAYMENT: {
    GET_PRODUCTS: `${baseUrl}payment/get-products`,
    CREATE_INVOICE: `${baseUrl}payment/create-invoice`,
    PAYMENT_STATUS: `${baseUrl}payment/check-session-status`,
    FETCH_INVOICES: `${baseUrl}payment/fetch-invoice`,
  },
  UNLOCK_TEST: {
    UNLOCK: `${baseUrl}unlock/test`
  },
  UNLOCK_DIMENTION: {
    UNLOCK: `${baseUrl}unlock/dimension`
  },
  CATEGORY_SUBCATEGORY: {
    ALL_SUBCAT: `${baseUrl}sub-category/list-all`
  },
  DASHBOARD: {
    LIST_DATA: `${baseUrl}dashboard/get-calender-data`,
    JOURNAL_DATA: `${baseUrl}dashboard/get-journal-data`,
    JOURNAL_FILTER_DATA: `${baseUrl}dashboard/get-journal-data-filter`,
    UPDATE_JOURNAL: `${baseUrl}goal/update-habit-action-journal`,
    SHARE_QUOTE: `${baseUrl}track-quote/share`,
    GET_BY_ID: `${baseUrl}track-quote/get-by-id`,
    REFERE_INVITE: `${baseUrl}refer/send`,
    UPDATE_DAILY_GOAL: `${baseUrl}app/updateDailyGoal`,
  },
  NOTIFICATION: {
    LIST: `${baseUrl}notification/get-notification`,
    READ: `${baseUrl}notification/read-notification`,
  }
};
// export const baseEndpoints = {
//   createUser: `https://personalityapp.herokuapp.com/api/v1/app/create-user`,
//   userLogin: `https://personalityapp.herokuapp.com/api/v1/app/user-login`,
//   updateUser: `https://personalityapp.herokuapp.com/api/v1/app/update-profile`,
//   forgotPassword: `https://personalityapp.herokuapp.com/api/v1/app/forgot-password`,
//   updatePassword: `https://persmobapp.herokuapp.com/api/v1/app/update-password`,
//   getUser: `https://personalityapp.herokuapp.com/api/v1/app/get-user-profile`,
//   termsAndCondition: `https://personalityapp.herokuapp.com/api/v1/policy/list`,
//   categoryList: `https://personalityapp.herokuapp.com/api/v1/category/list`,
//   categoryPage: `https://personalityapp.herokuapp.com/api/v1/sub-category/list`,
//   getAllQuestions: `https://personalityapp.herokuapp.com/api/v1/question/list`,
//   storeAnswer: `https://personalityapp.herokuapp.com/api/v1/question/answer`,
//   getDimensions: `https://personalityapp.herokuapp.com/api/v1/question-category/list`,
//   getAllTestScores: `https://personalityapp.herokuapp.com/api/v1/result/score-test`,
//   archivedAnswer: `https://personalityapp.herokuapp.com/api/v1/question/archive-answer`,
//   inviteTestQuestionList: `https://personalityapp.herokuapp.com/api/v1/invite-test-question/list`,
//   inviteTestAnswerList: `https://personalityapp.herokuapp.com/api/v1/invite-test-question/answer`
// };
