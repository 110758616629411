import { configureStore } from "@reduxjs/toolkit";
import averageByInviteUserSlice from "./Reducers/averageByInviteUser";
import categoriesIdSlice from "./Reducers/categoriesId";
import DashboardData from "./Reducers/dashboard";
import dimensions from "./Reducers/dimensions";
import getCalenderDataSlice from "./Reducers/getCalendarData";
import ActiondataSlice from "./Reducers/goalActionData";
import GoalData from "./Reducers/goalDataSlice";
import growthAreaDataSlice from "./Reducers/goalGrowthArea";
import GetAllGrowthData from "./Reducers/goalGrowthCalendar";
import HabitDataSlice from "./Reducers/goalHabitData";
import HeaderData from "./Reducers/headerData";
import inviteDataSlice from "./Reducers/inviteData";
import onbordingReducer from "./Reducers/onbordingSlice";
import questionsListSlice from "./Reducers/questionsListSlice";
import ResultDiamentionAll from "./Reducers/ResultAllDiamention";
import selectedInviteDataSlice from "./Reducers/selectedInviteData";
import storeData from "./Reducers/storeData";
import testCategoriesSlice from "./Reducers/testCategories";
import testCategoriesListSlice from "./Reducers/testCategoriesList";
import userProfileSlice from "./Reducers/userProfileSlice";
import PlanData from "./Reducers/plans";
import JournalsData from "./Reducers/journals";

export const store = configureStore({
  reducer: {
    onbording: onbordingReducer,
    userProfile: userProfileSlice,
    questionsList: questionsListSlice,
    testCategoriesList: testCategoriesListSlice,
    testCategories: testCategoriesSlice,
    dimensions: dimensions,
    inviteDataSlice: inviteDataSlice,
    selectedInviteDataSlice: selectedInviteDataSlice,
    resultAllDiamention: ResultDiamentionAll,
    categoriesIdSlice: categoriesIdSlice,
    averageByInviteUserSlice: averageByInviteUserSlice,
    growthAreaDataSlice: growthAreaDataSlice, 
    ActiondataSlice: ActiondataSlice,
    HabitDataSlice: HabitDataSlice,
    getAllGrowthData: GetAllGrowthData,
    getCalenderDataSlice: getCalenderDataSlice,
    headerDataSlice: HeaderData,
    dashboardDataSlice: DashboardData,
    goalData: GoalData,
    storeData: storeData,
    planData: PlanData,
    journalData: JournalsData,
  },
  
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
