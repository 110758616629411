import React, { useState, useEffect } from "react";
import classes from "./ResultCategoryCard.module.css";
import Gift from "../../assets/Svg/Gift.svg";
import {
  Box,
  CircularProgress,
  Grid,
  Slider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { SubmitButtonOutline, UnlockButtonOutline } from "../Button/Button";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import useCustomNotification from "../../Hooks/useCustomNotification";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Filter from "../../assets/Svg/Filter.svg";

import { SELECTLAYOUTBTN } from "../../Constant/Invite";
import CircularProgressWithLabel from "../CircularProgressBar/CircularProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { storeTestCategoriesList } from "../../Store/Reducers/testCategoriesList";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { updateCategoryId } from "../../Store/Reducers/categoriesId";
import moment from "moment";
import CategorySubcategoryService from "../../services/CategorySubcategory";
import { userProfileUpdate } from "../../Store/Reducers/userProfileSlice";
import { imageURLRender, secondsToDhms } from "../../utils";
import HTMLTooltip from "../ToolTipWithIcon/HTMLTooltip";
import ChipSaveButton from "../ChipSaveButton";
const generateRandomNumber = (min, max) => {
  return parseFloat(Math.random() * (max - min + 1) + min).toFixed(2);
}
const TestQuestionSlider = styled(Slider)`
    // background: yellow;
    padding: 0px !important;
    & .MuiSlider-rail {
      background: linear-gradient(
        90deg,
        #d7e0fb 0%,
        #dcfaf0 20.28%,
        #e6f8de 46.45%,
        #fff8bd 69.19%,
        #fce9a5 100%
      );
      height: 12px;
    }

    & .MuiSlider-track {
      background: linear-gradient(
        90deg,
        #d7e0fb 0%,
        #dcfaf0 20.28%,
        #e6f8de 46.45%,
        #fff8bd 69.19%,
        #fce9a5 100%
      );
      height: 12px;
      border: none;
    }

    & .MuiSlider-mark {
      // left: 0% !important;

      // width: unset;
      // height: unset;
      // background: none;

      // border-radius: 50%;

      // background: #9747FF;

      //   background: none;
      width: 4px;
      height: 12px;
      left: 110px;
      top: 0px;
      background: #ffffff;
    }

    & .MuiSlider-markActive {
      // left: 0% !important;
      background: none;
      // background-image: url("../../assets/Svg/SliderMark.svg");
      & :nth-child(1) {
        background: ;
      }
    }

    & .MuiSlider-thumbColorPrimary {
      box-shadow: none;
      background: #e5d5fc;
      width: 20px;
      height: 20px;
      border: 2px solid #FFF;
    }
  `;
export default function ResultCategoryCard(props) {
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(props.dimensionUnlockIn);
  const [sec, seSeconds] = useState(0);
  // const [currentTime, setCurrentTime] = useState(moment());
  const [notificationMessage, setNotificationMessage] = useState('');
  const setTime = () => {
    const splittedValue = props.dimensionUnlockIn?.split(":")
    if (splittedValue && splittedValue[0] !== 'NaN') {
      const hms = props.dimensionUnlockIn;   // your input string
      const a = hms.split(':');
      const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      setCurrentTime(secondsToDhms(seconds - sec))
      seSeconds(sec + 1)
    }
  }
  useEffect(() => {

    const inertval = setInterval(() => {
      setTime()
    }, 1000);
    return () => clearInterval(inertval)
  })
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          {...props}
          sx={{ width: "36px !important", height: "36px !important" }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body3" component="div" sx={{ padding: "12px" }}>
            {`${Math.round(props.value)}`}
          </Typography>
        </Box>
      </Box>
    );
  }
  let showReport = false
  if (props.data && props.data[0]) {
    showReport = props.data[0].showReport
  }
  return (
    <div className={classes.maincontainerWrapper}>
      <div className={classes.mainWrapper}>
        {!showReport && <div className={classes.noreporttag}>No report yet</div>}
        <div className={classes.headerWrapper}>
          <div className={classes.headerTitleWrapper}>
            <div>
              <img
                src={
                  props.imageUrl === "/public/images/"
                    ? Gift
                    : imageURLRender(props.imageUrl)
                }
                alt=""
              />
            </div>
            <Typography variant="caption" sx={{ lineHeight: "26px" }}>{props.category}</Typography>
          </div>
          <div className={classes.contentWrapper}>
            <HTMLTooltip customIcon={<div>
              <Typography sx={{ color: "#434243", fontSize: "14px" }} className={props.unlock ? "text-4lines" : "text-3lines"}>
                {/* {props.preDescription} */}
                {props?.preDescription[0]}
              </Typography>
            </div>}
              showArrow
              placement={"top"}
              title={<Typography sx={{ color: "#434243", fontSize: "12px" }} >
                {/* {props.preDescription} */}
                {props?.preDescription[0]}
              </Typography>}
              sx={{
                '& .MuiTooltip-tooltip': {
                  background: "#FFF",
                  color: "#000",
                  maxWidth: "350px"
                }
              }}
            />

          </div>
        </div>
        {/* slider High low */}
        {props.selectLayoutBtn === SELECTLAYOUTBTN.GRID ? (
          !props.unlock && showReport ? (
            <Grid
              container
              spacing={1.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {<div className={classes.cardTimerBar}>
                  <div className={classes.procesingWrapper}>
                    <TestQuestionSlider
                      defaultValue={0}
                      // getAriaValueText={valuetext}
                      valueLabelDisplay="off"
                      step={33.33}
                      marks
                      min={0}
                      max={100}
                      value={props?.percentage}
                      // components={{ Input: SilderMarkComponent }}
                      onChange={(e) => {
                        // console.log("value", e.target.value);
                      }}
                    />
                    <div className={classes.ScoreHighLowWrapper}>
                      <div>
                        <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                          Low
                        </Typography>
                      </div>
                      <div>
                        <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                          Moderate
                        </Typography>
                      </div>
                      <div>
                        <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                          High
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container
                  spacing={1.5}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ zIndex: 100 }}>
                  <Grid item xs={12} sm={!props.unlock && !props.dimensionUnlock ? 9 : 12} md={!props.unlock && !props.dimensionUnlock ? 9 : 12} lg={!props.unlock && !props.dimensionUnlock ? 9 : 12} xl={!props.unlock && !props.dimensionUnlock ? 9 : 12}>
                    {!props.unlock && !props.dimensionUnlock ? <HTMLTooltip customIcon={<div style={{
                      marginRight: '2px'
                    }}>
                      <UnlockButtonOutline
                        onClick={!props.dimensionUnlock ? null : (e) => props?.unlockCard(e, props.questionCategoryId)}
                        buttonText={[
                          !props.dimensionUnlock ? (currentTime && props.dimensionUnlockIn !== currentTime ? currentTime : '') : "Unlock with 1",
                          !props.dimensionUnlock ? null : <img
                            style={{ marginLeft: "4px", width: "12px", height: " 12px" }}
                            src={DiamondCoin}
                          />,
                        ]}
                        extraSx={{
                          padding: '5px 0',
                        }}
                      />
                    </div>}
                      showArrow
                      placement={"top"}
                      title={`2-day waiting period between accessing new dimensions is designed to encourage thoughtful reflection and engagement with the content, allowing you to fully digest the insights and results from your previous dimension and apply any learnings or recommendations to your personal growth journey`}
                      sx={{
                        '& .MuiTooltip-tooltip': {
                          background: "#FFF",
                          color: "#000",
                          maxWidth: "350px"
                        },
                      }}
                    /> : !props.unlock && <UnlockButtonOutline
                      onClick={!props.dimensionUnlock ? null : (e) => props?.unlockCard(e, props.questionCategoryId)}
                      buttonText={[
                        !props.dimensionUnlock ? (currentTime && props.dimensionUnlockIn !== currentTime ? currentTime : '') : "Unlock with 1",
                        !props.dimensionUnlock ? null : <img
                          style={{ marginLeft: "4px", width: "12px", height: " 12px" }}
                          src={DiamondCoin}
                        />,
                      ]}

                    />}
                  </Grid>

                  {!props.unlock && !props.dimensionUnlock ? <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "right",
                  }}>
                    {/* {<ChipSaveButton
                      style={{
                        float: 'right'
                      }}
                      onSave={props?.visibleConfirmationModal}
                      title='Purchase'
                    />} */}
                    <HTMLTooltip
                      title={"If you feel ready and eager to move ahead more quickly, you can bypass the waiting periods through in-app purchases"}
                      placement="top"
                      showArrow={true}
                      sx={{
                        '& .MuiTooltip-tooltip': {
                          background: "#FFF",
                          color: "#000",
                          maxWidth: "350px"
                        }
                      }}
                      customIcon={<div
                        className={classes.retakeButtonWrapper}
                        onClick={props?.visibleConfirmationModal}
                      >
                        <Typography variant="body3" color="#FFF">
                          Purchase
                        </Typography>
                      </div>
                      }
                    />
                  </Grid> : null}
                </Grid>

                {/* <div
                  className={classes.retakeButtonWrapper}
                  style={{ textAlign: 'right'}}
                  onClick={(e) => props?.unlockCard(e, props.questionCategoryId)}
                >
                  <Typography variant="body3" color="#9747FF">
                    Unlock
                  </Typography>
                </div> */}
              </Grid>
            </Grid>
          ) : <div className={classes.procesingWrapper}>

            <TestQuestionSlider
              defaultValue={0}
              // getAriaValueText={valuetext}
              valueLabelDisplay="off"
              step={33.33}
              marks
              min={0}
              max={100}
              value={props?.percentage}
              // components={{ Input: SilderMarkComponent }}
              onChange={(e) => {
                // console.log("value", e.target.value);
              }}
            />
            <div className={classes.ScoreHighLowWrapper}>
              <div>
                <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                  Low
                </Typography>
              </div>
              <div>
                <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                  Moderate
                </Typography>
              </div>
              <div>
                <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                  High
                </Typography>
              </div>
            </div>
          </div>
        ) : null}
        {/* circular progress bar */}
        {props.selectLayoutBtn === SELECTLAYOUTBTN.COMPRISON ? (
          <div className={classes.testScores}>
            <div className={classes.testResultScore}>
              {/* !props.unlock ? null :  */props?.score?.map((data, i) => {
                return (
                  <CircularProgressWithLabel
                    key={i}
                    value={data.map((item) => item.percent)}
                  />
                );
              })}
            </div>
            {!props.unlock && showReport ? <UnlockButtonOutline
              onClick={!props.dimensionUnlock ? null : (e) => props?.unlockCard(e, props.questionCategoryId)}
              buttonText={[
                !props.dimensionUnlock ? (currentTime && props.dimensionUnlockIn !== currentTime ? currentTime : '') : "Unlock with 1",
                !props.dimensionUnlock ? null : <img
                  style={{ marginLeft: "4px", width: "12px", height: " 12px" }}
                  src={DiamondCoin}
                />,
              ]}
            /> : null
            }

          </div>
        ) : null}
      </div>
    </div>
  );
}
export function ResultReflectiveCardWhole(props) {
  return (
    <div className={classes.rQmainWrapper}>
      {props?.question && props.index === 0 && (
        <div className={classes.rqHeaderWrapper}>
          <div className={classes.userIconeWrapper}>
            <Typography
              sx={{
                fontSize: "10px",
                color: "#9747FF",
                height: "10px",
                widht: "16px",
                textAlign: "center",
              }}
            >
              {props?.inviteName
                .split(" ")
                .map((word) => word[0])
                .join(" ")}
            </Typography>
          </div>
          <Typography
            variant="body4"
            sx={{
              fontSize: "14px",
              margin: "0px",
              color: "#190D1A",
            }}
          >
            {props?.inviteName}
          </Typography>
        </div>
      )}

      <div>
        <Typography
          variant="body4"
          sx={{
            fontSize: "14px",
            margin: "0px",
            color: "#190D1A",
          }}
        >
          {props?.question}
        </Typography>
        <div style={{ marginTop: "8px" }}>
          <Typography variant="p" sx={{ color: "#434243", fontSize: "14px" }}>
            {props?.answer[0]?.answer}
          </Typography>
        </div>
      </div>
    </div>
  );
}
export function ResultReflectiveCard(props) {
  return (
    <div className={classes.rQmainWrapper}>
      {props?.question && (
        <div className={classes.rqHeaderWrapper}>
          <div className={classes.userIconeWrapper}>
            <Typography
              sx={{
                fontSize: "10px",
                color: "#9747FF",
                height: "10px",
                widht: "16px",
              }}
            >
              PR
            </Typography>
          </div>
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            {props?.inviteName}
          </Typography>
        </div>
      )}

      <div>
        <Typography variant="h6" sx={{ fontSize: " 14px " }}>
          {props?.question}
        </Typography>
        <Typography variant="p" sx={{ color: "#434243", fontSize: "14px" }}>
          {props?.answer[0].answer}
        </Typography>
      </div>
    </div>
  );
}
export function LongMenu({ setSelected }) {
  const options = ["Grid", "Comparison", "Graph"];
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={Filter} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={SELECTLAYOUTBTN.GRID}
            onClick={() => {
              if (option === "Grid") {
                setSelected(SELECTLAYOUTBTN.GRID);
              } else if (option === "Comparison") {
                setSelected(SELECTLAYOUTBTN.COMPRISON);
              } else {
                setSelected(SELECTLAYOUTBTN.GRAPH);
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
export const ResultCategoryCardWithImage = ({
  imageUrl,
  description,
  preDescription,
  score,
  subCategory,
  onClickHandler,
  unlock,
  testStatus,
}) => {
  if (testStatus === "SOON2") {
    return <div className={classes.subcard}>
      <div>
        <div className={classes.ImageoverlaySoonCard}>
          <span className={classes.questionImage1}>?</span>
          <span className={classes.questionImage2}>?</span>
          <span className={classes.questionImage3}>?</span>
          <div className={classes.sooncardBackground}>
            <div className={classes.ellipse1}></div>
            <div className={classes.ellipse2}></div>
            <div className={classes.ellipse3}></div>
            <div className={classes.ellipse4}></div>
          </div>
        </div>

        <div className={classes.testTitleDisableSoonCard}>
          {Array.from({ length: 5000 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#190D1A',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
        <div className={classes.testTitleDisableSoonCard1}>
          {Array.from({ length: 3500 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#434243',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
        <div className={classes.testTitleDisableSoonCard2}>
          {Array.from({ length: 3500 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#434243',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
        <div className={classes.testTitleDisableSoonCard3}>
          {Array.from({ length: 3500 }, (_, index) => index + 1).fill().map(it => {
            const randomTop = generateRandomNumber(0, 26)
            const randomLeft = generateRandomNumber(0, 500)
            return (
              <span key={it} style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                left: `${randomLeft}px`,
                top: `${randomTop}px`,

                /* Main/Black */

                background: '#434243',
                transform: 'rotate(-180deg)',
              }}>

              </span>
            )
          })}
        </div>
      </div>


    </div>
  }
  return (
    <div className={classes.subcard} onClick={unlock || (score && score.length) ? onClickHandler : null}>
      <div>
        <div className={classes.imageResultWrapper}>
          <div className={classes.Imageoverlay}>
            <img src={imageURLRender(imageUrl)} alt="no img" />
          </div>
          {testStatus !== "LIVE" && (
            <div className={classes.iconsWrapper}>
              <div className={classes.iconsLeftWrapperDisable}>
                <div className={classes.commingSoonicon}>
                  <Typography variant="body3" color="#9747FF">
                    {testStatus?.charAt(0)?.toUpperCase() +
                      testStatus?.slice(1)?.toLowerCase().replace("1", "")}
                  </Typography>
                </div>
              </div>
            </div>
          )}

        </div>
        <div className={classes.cardHeader}>
          <Typography variant="body4" color={testStatus === "LIVE" ? "black" : '#DAD6DA'}>{subCategory}</Typography>
          <Typography
            variant="body2"
            sx={{ mt: "8px", lineHeight: "145% !important", color: testStatus === "LIVE" ? "#434243" : '#DAD6DA' }}
            className="text-3lines"
          >
            {preDescription}
          </Typography>
        </div>
      </div>
      <div className={classes.cardProgressBar}>
        <div className={classes.testScores}>
          <div className={classes.subResultScore}>
            {score?.map((data) => {
              const { percent, archive } = data;
              return <CircularProgressWithLabel value={percent.toFixed()} />;
            })}
          </div>
        </div>
      </div>

    </div>
  );
};
export function TestcatagoriesComponent({ isFetching }) {
  const { testCategoriesList, categoriesIdSlice, testCategories } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [locationData, setlocationData] = useState(state);
  useEffect(() => {
    if (pathname === "/results") {
      setlocationData(null);
    }
  }, []);

  return (
    <Box sx={{ width: "100%", typography: "body1", gap: "12px" }}>
      <TabContext>
        <Box sx={{ borderColor: "divider" }}>
          {/* {isCatagories ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : ( */}
          <Tabs
            data-testid="test"
            sx={{
              "& div div": {
                // gap: "10px",
                width: "fit-content",
                borderRadius: "20px",
                border: " 1px solid #F3F3F3",
                height: "38px",
              },
            }}
            variant="scrollable"
          // value="All"
          >
            {/* <CustomTab label="All Dimensions" /> */}
            <Tab
              onClick={() => {
                navigate(`/results`, {
                  // state: {
                  //   category,
                  //   category_desc: description,
                  //   category_id,
                  // },
                });
              }}
              label="All Dimensions"
              value="All"
              sx={{
                borderRadius: "20px",
                fontSize: "12px !important",
                color: "#FFFFFF",
                maxHeight: "36px",
                minHeight: "36px",
                // backgroundColor: "#9747FF",
                backgroundColor: locationData === null ? "#9747FF" : "#FFFFFF",
                color: locationData === null ? "#FFFFFF" : "#190D1A",
              }}
            />
            {testCategoriesList?.testCategoriesList?.map((data) => {
              const { category, category_id } = data;
              return (
                <Tab
                  key={category_id}
                  disabled={isFetching}
                  onClick={() => {
                    localStorage.setItem("selctedCategoryId", category_id);
                    dispatch(updateCategoryId(category_id));
                    navigate(`/results/${category_id}`, {
                      state: {
                        category,
                        category_id,
                      },
                    });
                  }}
                  label={category}
                  className={classes.categoryListTab}
                  value="2"
                  sx={{
                    // borderRadius: "20px",
                    // border: "1px solid #9747FF",
                    fontSize: "12px !important",
                    fontWeight: "400",
                    maxHeight: "36px",
                    minHeight: "36px",
                    backgroundColor:
                      category_id === locationData?.category_id
                        ? "#9747FF"
                        : "#FFFFFF",
                    color:
                      category_id === locationData?.category_id
                        ? "#FFFFFF"
                        : "#190D1A",
                    borderRadius: "20px",
                  }}
                />
              );
            })}
          </Tabs>
          {/* )} */}
        </Box>
      </TabContext>
    </Box>
  );
}
