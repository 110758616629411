import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import XP from "../../../assets/Svg/XP.svg";
import CircularProgressWithLabel from "../../../components/CircularProgressBar/CircularProgressBar";
import RadioIcon from "../../../assets/Svg/RadioIcon.svg";
import RadioCheckedIcon from "../../../assets/Svg/RadioCheckedIcon.svg";
import { ReviewButton } from "../StyledStepper/styledStepper";
import classes from "./StepOne.module.css";
import Coin from "../../../assets/Svg/Coin.svg";
import close from "../../../assets/Svg/Close.svg";
import parse from "html-react-parser";
import {
  questionCategoryCreateFavorite,
  questionCategoryDeleteFavorite,
} from "../../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { randomXP } from "../../../utils";
import {
  handleChangeGrowthArea,
  handleChangeProgressArea,
  updateResultState,
} from "../../../Store/Reducers/ResultAllDiamention";
import { useNavigate } from "react-router-dom";
import {
  updateAddGoalCategory,
  updateAddGoalStep,
  updateGoalData,
} from "../../../Store/Reducers/goalDataSlice";
import HTMLTooltip from "../../../components/ToolTipWithIcon/HTMLTooltip";
export const StepOne = ({
  setmodalStep,
  // modalData,
  handleClose,
  UnlockGAAction,
  onHandleclose,
  // enable, setEnable,
  Notification,
}) => {
  const dispatch = useDispatch();
  const { selectedInviteDataSlice, resultAllDiamention } = useSelector(
    (state) => state
  );
  const {
    actionCount,
    growthCount,
    modalData,
    enable,
    selectedScore,
    growthAreaActionData,
    growthAreaData,
    growthAreaQsn,
    progressQsn,
    progressData,
    tempGrowhtArea,
    tempActionArea,
    growthFavorites,
    QuetionCatagoriesId,
    actionFavorites,
  } = resultAllDiamention;
  console.log("resultAllDiamention", resultAllDiamention);
  const setEnable = (val) => {
    dispatch(updateResultState(val));
  };
  const navigate = useNavigate();
  console.log("growthFavorites", growthFavorites);
  const [message, setMessage] = useState("");
  // const [selectedScore, setSelectedScore] = useState(
  //   modalData?.data?.length && modalData?.data[modalData?.data?.length - 1]?.uniqueId
  // );

  // const [growthAreaActionData, setGrowthAreaActionData] = useState(
  //   modalData?.data?.map((item, index) => parse(item.descriptionDetails))
  // );
  // const [growthFavorites, setGrowthFavorites] = useState(
  //   modalData?.data?.length && modalData?.data?.map((item) => {
  //     return item.growthFavorites;
  //   })
  // );
  // const [actionFavorites, setActionFavorites] = useState(
  //   modalData?.data?.length && modalData?.data?.map((item) => item.actionFavorites)
  // );
  // const [detailedDescription, setDetailedDescription] = useState("");
  // const [growthAreaData, setGrowthAreaData] = useState([]);
  // const [growthAreaQsn, setGrowthAreaQsn] = useState("");
  // const [progressQsn, setProgressQsn] = useState("");
  // const [progressData, setProgressData] = useState([]);

  const UnlockGAActionType = (type) => {
    const foundIndex = modalData.data.findIndex(
      (it) => it.uniqueId === selectedScore
    );
    if (foundIndex > -1) {
      const object = {
        uid: localStorage.getItem("userDetail"),
        questionCategoryId: modalData.questionCategoryId,
        percent: modalData.data[foundIndex].percent,
        type: type,
      };
      UnlockGAAction(type, object);
    }
  };
  const handleTestData = (inde) => {
    let tempGrowthArea = [];
    let tempProgress = [];
    if (growthAreaActionData?.length) {
      growthAreaActionData?.map((data, index) => {
        if (index === inde) {
          data.map((item) =>
            item.key === "1"
              ? item?.props?.children?.map((ele) => {
                  tempGrowthArea.push({
                    uniqueId: selectedScore,
                    text: ele.props.children,
                    isChecked: false,
                  });
                })
              : null
          );
        }
      });
      growthAreaActionData?.map((data, index) => {
        if (index === inde) {
          data.map((item) =>
            item.key === "3"
              ? item?.props?.children?.map((ele) => {
                  tempProgress.push({
                    uniqueId: selectedScore,
                    text: ele.props.children,
                    isChecked: false,
                    randomXPObject: randomXP(),
                  });
                })
              : null
          );
        }
      });
      growthAreaActionData?.map((data, index) => {
        if (index === inde) {
          data.map((item) =>
            item.key === "0"
              ? dispatch(
                  updateResultState({
                    growthAreaQsn: item?.props?.children,
                  })
                )
              : ""
          );
        }
        return data;
      });
      growthAreaActionData?.map((data, index) => {
        if (index === inde) {
          data.map((item) =>
            item.key === "2"
              ? dispatch(
                  updateResultState({
                    progressQsn: item?.props?.children,
                  })
                )
              : ""
          );
        }
        return data;
      });

      renderGrowthFavorites(tempGrowthArea, [...growthFavorites], inde);
      renderActionFavorites(tempProgress, [...actionFavorites], inde);
      dispatch(
        updateResultState({
          growthAreaData: tempGrowthArea,
          progressData: tempProgress,
        })
      );

      if (
        modalData?.data[inde]?.unlockgrowthandactions &&
        modalData?.data[inde]?.unlockgrowthandactions[0]
      ) {
        dispatch(
          updateResultState({
            growthCount:
              modalData?.data[inde]?.unlockgrowthandactions[0].growth,
            actionCount:
              modalData?.data[inde]?.unlockgrowthandactions[0].action,
          })
        );
      }
    }
  };
  const handleChangeCreateGrowthArea = (event, data) => {
    console.log("data", data);
    if (data) {
      if (!data.isChecked) {
        dispatch(
          handleChangeGrowthArea({
            isChecked: data.isChecked,
            value: event.target.value,
          })
        );
        const createGrowthAreaData = {
          type: "GROWTH",
          point: event.target.value,
          questionCategoryId: modalData?.questionCategoryId,
          uid: localStorage.getItem("userDetail"),
        };
        questionCategoryCreateFavorite(createGrowthAreaData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dispatch(
          handleChangeGrowthArea({
            isChecked: data.isChecked,
            value: event.target.value,
          })
        );
        const deleteGrowthAreaData = {
          type: "GROWTH",
          point: event.target.value,
          questionCategoryId: modalData?.questionCategoryId,
          uid: localStorage.getItem("userDetail"),
        };
        questionCategoryDeleteFavorite(deleteGrowthAreaData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const handleChangeCreateProgressArea = (event, data, randomXPObject = {}) => {
    if (!data.isChecked) {
      dispatch(
        handleChangeProgressArea({
          isChecked: data.isChecked,
          value: event.target.value,
        })
      );
      const createGrowthAreaData = {
        type: "ACTION",
        point: event.target.value,
        questionCategoryId: modalData?.questionCategoryId,
        uid: localStorage.getItem("userDetail"),
        level: randomXPObject?.level,
      };
      questionCategoryCreateFavorite(createGrowthAreaData)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(
        handleChangeProgressArea({
          isChecked: data.isChecked,
          value: event.target.value,
        })
      );
      const deleteGrowthAreaData = {
        type: "ACTION",
        point: event.target.value,
        questionCategoryId: modalData?.questionCategoryId,
        uid: localStorage.getItem("userDetail"),
        level: randomXPObject?.level,
      };
      questionCategoryDeleteFavorite(deleteGrowthAreaData)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function renderGrowthFavorites(growthArea, growthFav, inde) {
    growthArea.filter(function (o1) {
      growthFav.map((item, index) => {
        // if (index === inde) {
        item.some(function (o2) {
          if (o1.text === o2.point) {
            return (o1.isChecked = true);
          } else {
            return (o1.isChecked = false);
          }
        });
        // }
        return item;
      });
    });
  }

  function renderActionFavorites(actionArea, actionFavorites, inde) {
    actionArea.filter(function (o1) {
      actionFavorites.map((item, index) => {
        // if (index === inde) {
        item.some(function (o2) {
          if (o1.text === o2.point) {
            return (o1.isChecked = true);
          } else {
            return (o1.isChecked = false);
          }
        });
        // }
      });
    });
  }

  const circularProgressHandler = (index, uniqueId) => {
    setEnable({
      enable: index,
    });
    let temp = [...growthAreaData].map((data) => {
      const newData = { ...data };
      tempGrowhtArea.map((item) => {
        if (newData.uniqueId === item.uniqueId && newData.text === item.point) {
          return (newData.isChecked = item.isChecked);
        }
        return item;
      });
      tempActionArea.map((ele) => {
        if (ele.uniqueId === newData.uniqueId && ele.point === newData.text) {
          return (ele.isChecked = newData.isChecked);
        }
        return ele;
      });
      return newData;
    });
    dispatch(
      updateResultState({
        growthAreaData: temp,
        selectedScore: uniqueId,
      })
    );
    handleTestData(index);

    // updateNotificationState("State Changed");
    // setTimeout(() => {
    // updateNotificationState("", false);

    // }, 2000);
  };

  // useImperativeHandle(ref, () => ({
  //   onHandleclose,
  // }));
  console.log("growthAreaData", growthAreaData);
  return (
    <Box className={classes.mainWrapper}>
      <div onClick={onHandleclose}></div>
      <div
        className={classes.closeIconeWrapper}
        onClick={(e) => {
          onHandleclose(e);
          handleClose();
        }}
      >
        <img src={close} />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4" className={classes.ZestHeading}>
          {modalData?.questionCategory}
        </Typography>
        <div
          style={{
            display: "flex",
            gap: "7px",
            marginTop: "10px",
          }}
        >
          {modalData?.data?.length &&
            modalData?.data.map((item, index) =>
              item.score.map((data) => {
                return (
                  <Box
                    className={classes.circularprogressBar}
                    onClick={() =>
                      circularProgressHandler(index, item.uniqueId)
                    }
                  >
                    <CircularProgressWithLabel
                      value={data?.percent?.toFixed()}
                      colorCode={index === enable ? "#9747FF" : "#E5D5FC"}
                    />
                  </Box>
                );
              })
            )}
        </div>

        <Typography variant="body2" className={classes.ContentText}>
          {enable > -1 && modalData?.data[enable]?.detailedDescription[0] ? (
            /* modalData?.data[enable]?.detailedDescription[0]
                .split("\n\n")
                .map((it) => (
                  <>
                    <p style={{ height: "12px" }}></p>
                    <p>{it}</p>
                  </>
                )) */ <p
              dangerouslySetInnerHTML={{
                __html: modalData?.data[enable]?.detailedDescription[0].replace(
                  /\n/g,
                  "<br />"
                ),
              }}
            ></p>
          ) : null}
        </Typography>
      </Box>
      <Box className={classes.ContentWrapper}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.9fr 0.1fr",
            width: "100%",
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          <Typography className={classes.QauetionText}>
            {growthAreaQsn}
          </Typography>
          <div style={{ position: "relative", justifySelf: "end" }}>
            <HTMLTooltip
              title={
                "Growth Areas (GAs) are targeted, action-oriented questions designed to guide you in creating meaningful actions for your personal growth journey."
              }
              placement="bottom"
              showArrow={true}
              sx={{
                "& .MuiTooltip-tooltip": {
                  background: "#FFF",
                  color: "#000",
                  maxWidth: "350px",
                },
              }}
            />
          </div>
        </div>

        {growthAreaData?.map((data, index) => {
          if (index < growthCount) {
            return (
              <FormControlLabel
                key={index}
                value={data.text}
                control={
                  <Checkbox
                    sx={{
                      padding: "6px 11px",
                      "& .MuiFormControlLabel-root": {
                        alignItems: "flex-start",
                      },
                    }}
                    checkedIcon={
                      <img
                        style={{ width: "14px", height: "13px" }}
                        src={RadioCheckedIcon}
                        alt=""
                      />
                    }
                    icon={
                      <img
                        style={{ width: "14px", height: "13px" }}
                        src={RadioIcon}
                        alt=""
                      />
                    }
                    checked={data.isChecked}
                    disabled={
                      // selectedInviteDataSlice?.isSelectedSocialUser && true
                      false
                    }
                    onChange={(e) => {
                      handleChangeCreateGrowthArea(e, data);
                    }}
                  />
                }
                className={classes.RadioOptionText}
                label={data.text}
              />
            );
          }
        })}
        {growthAreaData.length > growthCount ? (
          <Button
            variant="filled"
            size="small"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 12px",
              gap: "4px",
              marginTop: "11px",

              /* Main/Purple-Tertiary */

              background: "#F7F2FE",
              borderRadius: "16px",
            }}
            onClick={() => UnlockGAActionType("GROWTH")}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "100%",
                /* identical to box height, or 12px */

                display: "flex",
                alignItems: "center",
                letterSpacing: "-0.01em",
                /* Main/Purple */

                color: "#9747FF",
              }}
            >
              Unlock 3 more for 20
            </span>
            <img
              className={classes.CoinButton}
              src={Coin}
              alt="coin"
              style={{
                height: "12px",
                margin: 0,
              }}
            />
          </Button>
        ) : null}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.9fr 0.1fr",
            width: "100%",
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          <Typography className={classes.QauetionText}>
            {progressQsn}
          </Typography>
          <div style={{ position: "relative", justifySelf: "end" }}>
            <HTMLTooltip
              title={
                "Actions are one-time acts contributing to your character development goals."
              }
              placement="bottom"
              showArrow={true}
              sx={{
                "& .MuiTooltip-tooltip": {
                  background: "#FFF",
                  color: "#000",
                  maxWidth: "350px",
                },
              }}
            />
          </div>
        </div>

        {[...progressData]?.map((data, index) => {
          const { randomXPObject } = data;
          if (index < actionCount) {
            return (
              <FormControlLabel
                key={index}
                value={data.text}
                sx={{
                  "& .MuiFormControlLabel-root": {
                    alignItems: "flex-start",
                  },
                  "& .MuiFormControlLabel-label": {
                    width: "100%",
                  },
                }}
                control={
                  <Checkbox
                    sx={{
                      padding: "6px 11px",
                    }}
                    checkedIcon={
                      <img
                        style={{ width: "14px", height: "13px" }}
                        src={RadioCheckedIcon}
                        alt=""
                      />
                    }
                    icon={
                      <img
                        style={{ width: "14px", height: "13px" }}
                        src={RadioIcon}
                        alt=""
                      />
                    }
                    checked={data.isChecked}
                    disabled={
                      // selectedInviteDataSlice?.isSelectedSocialUser && true
                      false
                    }
                    onChange={(e) => {
                      handleChangeCreateProgressArea(e, data, randomXPObject);
                    }}
                  />
                }
                className={classes.RadioOptionText}
                label={
                  <div className={classes.actionLable}>
                    {data.text}
                    <Chip
                      label={randomXPObject.xp}
                      avatar={<Avatar alt="Natacha" src={XP} />}
                      className={classes.backgroundChip}
                    />
                  </div>
                }
              />
            );
          }
        })}
        {[...progressData].length > actionCount ? (
          <Button
            variant="filled"
            size="small"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 12px",
              gap: "4px",
              marginTop: "11px",

              /* Main/Purple-Tertiary */

              background: "#F7F2FE",
              borderRadius: "16px",
            }}
            onClick={() => UnlockGAActionType("ACTION")}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "100%",
                /* identical to box height, or 12px */

                display: "flex",
                alignItems: "center",
                letterSpacing: "-0.01em",

                /* Main/Purple */

                color: "#9747FF",
              }}
            >
              Unlock 3 more for 20
            </span>
            <img
              className={classes.CoinButton}
              src={Coin}
              alt="coin"
              style={{
                height: "12px",
                margin: 0,
              }}
            />
          </Button>
        ) : null}
      </Box>
      <Divider sx={{ borderColor: "#F7F2FE", margin: "32px 0px" }} />
      <div
        style={{ marginTop: "-15px" }}
        onClick={() => {
          // dispatch(updateAddGoalCategory(resultAllDiamention?.ResultDiamention[0]))
          // dispatch(updateAddGoalStep(2))
          // dispatch(updateGoalData({
          //   isAskOtherModalOpen: true
          // }))
          try {
            dispatch(
              updateResultState({
                isAskOtherModalOpen: false,
              })
            );
            const objToSet = [...resultAllDiamention?.ResultDiamention].find(
              (it) => it.questionCategoryId === QuetionCatagoriesId
            );
            const newObj = { ...objToSet };
            console.log("newObj", newObj);
            newObj.questionCategoryImage = newObj.data[0].url;
            localStorage.setItem("cat", JSON.stringify(newObj));
            navigate("/goals");
          } catch (error) {
            console.log("error", error);
          }

          // setmodalStep(true); // this commented
          // setGrowthAreaDetail(growthAreaDetail)
          // selectedDataHandler();
        }}
      >
        <ReviewButton
        // nextButtonIcon={
        //   <img className={classes.CoinButton} src={Coin} alt="coin" />
        // }
        />
      </div>
      {Notification}
    </Box>
  );
};
