import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Header from "../../components/Header/Header";
import SearchBar from "../../components/SearchBar/SearchBar";
import {
  CircularProgress,
  Grid,
  Input,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import classes from "./TestCategoriesList.module.css";
import Search from "../../assets/Svg/Search.svg";
import debounce from "lodash.debounce";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { Box } from "@mui/system";
import { categoryList } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeTestCategoriesList } from "../../Store/Reducers/testCategoriesList";
import { useQuery } from "react-query";
import { baseUrl, IMAGE_BASE_URL } from "../../services/config";
import defaultImg from '../../assets/images/resizeCategory1662712270910_1662712270910.jpeg';
import { imageURLRender } from "../../utils";
import { updateSetShowYoutube } from "../../Store/Reducers/dashboard";
import HTMLTooltip from "../../components/ToolTipWithIcon/HTMLTooltip";

const TestCategoriesList = () => {
  const { testCategoriesList } = useSelector((state) => state);
  const [categorys, setCategorys] = useState(
    testCategoriesList?.testCategoriesList
  );
  const [searchResultArray, setSearchResultArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchTestCategories = () => {
    const user = localStorage.getItem("userDetail");
    return /* categorys.length ? { categoryData: categorys } :  */categoryList({ uid: user, type: "NORMAL" });
  };
  const { isLoading } = useQuery(
    "test-categorys",
    fetchTestCategories,
    {
      onSuccess: (data) => {
        if (JSON.stringify(categorys) !== JSON.stringify(data?.categoryData)) {
          setCategorys(data?.categoryData);
          dispatch(storeTestCategoriesList(data?.categoryData));
        }
      },
      onError: (error) => {
        console.log(error)
      },
      refetchOnWindowFocus: false
    }
  );
  const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    overflowY: "auto",
    height: "100vh",
  }));

  const InputField = styled(TextField)`
    height: 56px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-input {
      font-size: 16px;
      color: #190d1a;
      ::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #434243;
      }
    }
  `;

  function LinearProgressWithLabel(props) {
    return (
      <Box className={classes.progressBarWrapper}>
        <Box sx={{ width: "100%", mr: "15px" }}>
          <LinearProgress
            className={classes.progress}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box>
          <Typography variant="body3" color="#190D1A">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const SearchCategoryList = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      getCtagoryData()
    }
    // const filteredCategories = categorys.filter((category) => {
    //   return category.category
    //     .toLowerCase()
    //     .includes(e.target.value.toLowerCase());
    // });
    // setSearchResultArray(filteredCategories);
  };
  const getCtagoryData = (search) => {
    const user = localStorage.getItem("userDetail");
    categoryList({ uid: user, type: search && search.length ? "SEARCH" : "NORMAL", search }).then(data => {
      setCategorys(data?.categoryData);
      dispatch(storeTestCategoriesList(data?.categoryData));
    })
  }
  const { topicDataYoutubeLink } = useSelector(
    (state) => state.onbording
  );
  useEffect(() => {
    if (localStorage.getItem("newSignup") && topicDataYoutubeLink) {
      dispatch(updateSetShowYoutube(true))
      localStorage.removeItem("newSignup")
    }
    return () => {
      // debouncedChangeHandler.cancel();
    }
  }, []);
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value && e.target.value.trim() && e.target.value.length > 2) {
        getCtagoryData(e.target.value.trim())
      }
      if (!e.target.value) {
        getCtagoryData()
      }
    }
  }
  return (
    <RightSideContentWrapper>
      <Header />
      <div className={classes.dashbordTitle}>
        <Typography variant="h3">Test categories</Typography>
      </div>
      {/* <SearchBar /> */}
      <div className={classes.SearchBar}>
        <InputField
          size="small"
          fullWidth
          autoFocus={searchValue !== ""}
          type="text"
          value={searchValue}
          onChange={(e) => SearchCategoryList(e)}
          onKeyDown={keyPress}
          placeholder="Well-being, self-esteem..."
          id="outlined-start-adornment"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Search} alt="" />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Grid container spacing={1.5} className={classes.testListWrapper}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "calc(100vh - 228px)",
            }}
          >
            <CircularProgress />
          </div>
        ) : /* searchValue ? (
          searchResultArray?.map((data) => {
            const {
              category,
              description,
              percentage,
              category_id,
              preDescription,
              imageUrl
            } = data;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                xl={3}
                key={category_id}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("selctedCategoryId", category_id);
                  navigate(`/testcategory/${category_id}`, {
                    state: {
                      category,
                      category_desc: description,
                      category_id,
                    },
                  });
                }}
              >
                <div className={classes.card}>
                  <div>
                    <div className={classes.imageWrapper}>
                      <img src={imageURLRender(imageUrl)} alt="" />

                    </div>
                    <div className={classes.cardHeader}>
                      <Typography variant="body4">{category}</Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: "8px", lineHeight: "145% !important" }}
                        className="text-3lines"
                      >
                        {preDescription}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.cardProgressBar}>
                    <LinearProgressWithLabel value={percentage} />
                  </div>
                </div>
              </Grid>
            );
          })
        ) :  */(
            categorys?.map((data) => {
              const {
                category,
                description,
                percentage,
                category_id,
                preDescription,
                imageUrl,
                categoryStatus,
                recommendation,
                title
              } = data;
              console.log("data", data)
              const CardInnerContent = <div className={classes.card} style={recommendation ? { border: '2px solid #9747FF' } : {}}>
                <div>
                  <div className={classes.imageWrapper}>
                    <img src={imageURLRender(imageUrl)} alt="" />

                  </div>
                  {["SOON"].includes(categoryStatus) ? (
                    <div className={classes.iconsWrapper}>
                      <div className={classes.iconsLeftWrapperDisable}>
                        <div className={classes.commingSoonicon}>
                          <Typography variant="body3" color="#9747FF">
                            {categoryStatus && (categoryStatus?.charAt(0)?.toUpperCase() +
                              categoryStatus?.slice(1)?.toLowerCase().replace("1", ""))}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {recommendation ? (
                    <div className={classes.iconsWrapper} >
                      <div className={classes.iconsLeftWrapperDisable}>
                        <div className={classes.recommendedicon} style={{
                          border: '1px solid #9747FF'
                        }}>
                          <Typography variant="body3" color="#9747FF" sx={{
                            fontSize: '0.875rem',
                            fontWeight: 400
                          }}>
                            {"Recommended"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className={classes.cardHeader}>
                    <Typography variant="body4">{category}</Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: "6px", lineHeight: "145% !important" }}
                      className="text-3lines"
                    >
                      {preDescription}
                    </Typography>
                  </div>
                </div>
                <div className={classes.cardProgressBar}>
                  <LinearProgressWithLabel value={percentage} />
                </div>
              </div>
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  key={category_id}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    // if (categoryStatus !== "SOON") {
                    localStorage.setItem("selctedCategoryId", category_id);
                    navigate(`/testcategory/${category_id}`, {
                      state: {
                        category,
                        category_desc: description,
                        category_id,
                        title
                      },
                    });
                    // }
                  }}
                >
                  {["SOON"].includes(categoryStatus) ? (
                    <HTMLTooltip customIcon={CardInnerContent} placement="top" showArrow={true}
                      title={`This particular category is currently under development or in our team’s final stages of validation. We’re diligently working to ensure its accuracy, reliability, and relevance. Once it meets our rigorous standards, it will be made available to users. By indicating “soon,” we aim to keep you informed of upcoming content and exciting additions to enhance your experience.`}
                      sx={{
                        '& .MuiTooltip-tooltip': {
                          background: "#FFF",
                          color: "#000",
                          maxWidth: "400px"
                        }
                      }}
                    />
                  ) : CardInnerContent}
                </Grid>
              );
            })
          )}
        {/* {categorys.map((data) => {
          const { category, description, percentage, category_id } =
            data.categoryData;
          return (
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} key={category_id}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <Typography variant="body4">Personality</Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: "8px", lineHeight: "145% !important" }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Habitant at lectus congue
                  </Typography>
                </div>
                <div className={classes.cardProgressBar}>
                  <LinearProgressWithLabel value={50} />
                </div>
              </div>
            </Grid>
          );
        })} */}
        {/* <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <Typography variant="body4">Personality</Typography>
              <Typography
                variant="body2"
                sx={{ mt: "8px", lineHeight: "145% !important" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Habitant at lectus congue
              </Typography>
            </div>
            <div className={classes.cardProgressBar}>
              <LinearProgressWithLabel value={50} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <Typography variant="body4">Personality</Typography>
              <Typography
                variant="body2"
                sx={{ mt: "8px", lineHeight: "145% !important" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Habitant at lectus congue
              </Typography>
            </div>
            <div className={classes.cardProgressBar}>
              <LinearProgressWithLabel value={50} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <Typography variant="body4">Personality</Typography>
              <Typography
                variant="body2"
                sx={{ mt: "8px", lineHeight: "145% !important" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Habitant at lectus congue
              </Typography>
            </div>
            <div className={classes.cardProgressBar}>
              <LinearProgressWithLabel value={50} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <Typography variant="body4">Personality</Typography>
              <Typography
                variant="body2"
                sx={{ mt: "8px", lineHeight: "145% !important" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Habitant at lectus congue
              </Typography>
            </div>
            <div className={classes.cardProgressBar}>
              <LinearProgressWithLabel value={50} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <Typography variant="body4">Personality</Typography>
              <Typography
                variant="body2"
                sx={{ mt: "8px", lineHeight: "145% !important" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Habitant at lectus congue
              </Typography>
            </div>
            <div className={classes.cardProgressBar}>
              <LinearProgressWithLabel value={50} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <Typography variant="body4">Personality</Typography>
              <Typography
                variant="body2"
                sx={{ mt: "8px", lineHeight: "145% !important" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Habitant at lectus congue
              </Typography>
            </div>
            <div className={classes.cardProgressBar}>
              <LinearProgressWithLabel value={50} />
            </div>
          </div>
        </Grid> */}
      </Grid>
    </RightSideContentWrapper>
  );
};

export default TestCategoriesList;
