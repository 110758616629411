import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./HeaderNavigation.module.css";
import RightArrow from "../../assets/Svg/RightArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateIsSelectedUser,
  updateSelectedIndividualInvitId,
  updateSelectedInviteData,
} from "../../Store/Reducers/selectedInviteData";
import {
  updateisLearnMore,
  updateisSocialResultNavigate,
  updateLearnMoreQuestionCategories,
  updateSelectedQuestionCategories,
} from "../../Store/Reducers/averageByInviteUser";
import { updateHeaderNavigation } from "../../Store/Reducers/headerData";
let counter= 0
let isAlreadySet = false
let lastPath = ""
const setIsAlreadySet = val => isAlreadySet = val
const HeaderNavigation = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isAlreadySet, setIsAlreadySet] = useState(false)
  const [showNavigation, setShowNavigation] = useState(true)
  const { selectedInviteDataSlice, averageByInviteUserSlice, headerDataSlice, testCategories: {
    selectedSubcategoryData=  {},
    selectedCategoryData=  {},
    selectedSubCat = {}
  }  } = useSelector(
    (state) => state
  );
  const { selectedPlan } = useSelector((state) => state.planData);
  const { headerNavigation } = headerDataSlice
  const setheaderNavigation = (val) => dispatch(updateHeaderNavigation(val))
  // console.log(" state?.subCategory,", state?.subCategory);
  // console.log("header navigation page ",selectedInviteDataSlice)

  // console.log("headerNavigation", headerNavigation, pathname, state);
  // console.log(
  //   'pathname.includes("testcategory")',
  //   pathname.includes("testcategory")
  // );

  //   category: "Well-Being"
  // categoryId: "43uJxK8g5BJXaruL9fH3MR"
  // description: "fbf"
  // subCategory: "djifb"
  // subCategoryId: "r66fTCTPghpnvZUtGnWxoQ"
  useEffect(() => {
    if(lastPath !== pathname) {
      lastPath = pathname
      console.log("path name changed");
      setIsAlreadySet(false)
    }
    
  }, [pathname])
  const foundIDName = (name, route) => [...headerNavigation].find(it => it.name === name && it.path === route)
  useEffect(() => {
    counter = counter + 1
    console.log("state", state, counter);
    // const isCategory = headerNavigation.filter((item)=>item.name === state.category)
    // const isSubCategory = headerNavigation.filter((item)=>item.name === state.subCategory)
    if (pathname) {
      if (pathname.includes("/dashboard")) {
        if ([...headerNavigation].length > 1) {
          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
          ]);
        }

      }
      if (pathname.includes("/testcategory") && (!state || !state.category) && !pathname.includes("/testcategory/")) {
        if (!foundIDName("Test", "/testcategory") || headerNavigation.length !== 2) {
          setIsAlreadySet(true)

          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Test",
              path: "/testcategory",
              state: {}
            },
          ]);
        }

      }
      if (pathname.includes("/store")) {
        setShowNavigation(false)
        if (!foundIDName("Store", "/store") || !isAlreadySet) {
          setIsAlreadySet(true)

          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Store",
              path: "/store",
              state: {}
            },
          ]);
        }
      }
      if (pathname.includes("/plans")) {
        setShowNavigation(false)
        if (!foundIDName("Plans", "/plans") || !isAlreadySet) {
          setIsAlreadySet(true)

          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Plans",
              path: "/plans",
              state: {}
            },
          ]);
        }
      }
      if (pathname.includes("/journals")) {
        setShowNavigation(false)
        if (!foundIDName("Journals", "/journals") || !isAlreadySet) {
          setIsAlreadySet(true)

          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Journals",
              path: "/journals",
              state: {}
            },
          ]);
        }
      }
      if (!pathname.includes("/store")) {
        setShowNavigation(true)
      }
      if (pathname.includes("/settings")) {
        if (!foundIDName("Settings", "/settings") || !isAlreadySet) {
          setIsAlreadySet(true)

          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Settings",
              path: "/settings",
              state: {}
            },
          ]);
        }
      }
      if (pathname.includes("/results")) {
        if (selectedInviteDataSlice?.isSelectedSocialUser) {
          if (!foundIDName(`${selectedInviteDataSlice?.selectedinviteData?.name} Results`, "/results") || !isAlreadySet) {
            setIsAlreadySet(true)

            setheaderNavigation([
              {
                name: "Home",
                path: "/dashboard",
              },
              {
                name: "Results",
                path: "/results",
              },
              {
                name: `${selectedInviteDataSlice?.selectedinviteData?.name} Results`,
                path: "/results",
              },
            ]);
          }
        } else if (averageByInviteUserSlice?.isLearnMore) {
          if (!foundIDName(`${averageByInviteUserSlice?.learnMoreQuestioncategories}`, "/results") || !isAlreadySet) {
            setIsAlreadySet(true)
            setheaderNavigation([
              {
                name: "Home",
                path: "/dashboard",
                state: {}
              },
              {
                name: "Results",
                path: "/results",
                state: {}
              },
              {
                name: `${averageByInviteUserSlice?.learnMoreQuestioncategories}`,
                path: "/results",
                state: {}
              },
              // {
              //   name: state?.category,
              //   path: `/results/${state?.category_id}`,
              //   active: true,
              // },
            ]);
          }

        } else {
          if (!foundIDName(`Results`, "/results") || !isAlreadySet) {
            setheaderNavigation([
              {
                name: "Home",
                path: "/dashboard",
              },
              {
                name: "Results",
                path: "/results",
              },
            ]);
            setIsAlreadySet(true)
          }
        }
      }
      if (pathname.includes("/goals")) {
        if (!foundIDName(`Goals`, "/goals") || !isAlreadySet) {
          setIsAlreadySet(true)

          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
            },
            {
              name: "Goals",
              path: "/goals",
            },
          ]);
        }
        if (pathname.includes("/archive")) {
          if (!foundIDName(`Archive`, "/archive") || !isAlreadySet) {
            setIsAlreadySet(true)

            setheaderNavigation([
              {
                name: "Home",
                path: "/dashboard",
                state: {}
              },
              {
                name: "Goals",
                path: "/goals",
                state: {}
              },
              {
                name: "Archive",
                path: "/archive",
                state: {}
              },
            ]);
          }
        }
      }
    }
    if (state) {
      if (state.category && state?.subCategory) {
        if (pathname.includes("/testcategory/")) {
          if (!foundIDName(state?.category, `/testcategory/${state?.categoryId}`) || headerNavigation.length !== 3 || !isAlreadySet) {
            setIsAlreadySet(true)

            setheaderNavigation([
              {
                name: "Home",
                path: "/dashboard",
                state: {}
              },
              {
                name: "Test",
                path: "/testcategory",
                state: {}
              },
              {
                name: state && state?.category ? state?.category : selectedCategoryData.category ? selectedCategoryData.category : null,
                path: `/testcategory/${state?.categoryId}`,
                active: true,
                state: state
              },
            ]);
          }

        }
        console.log("pathname.includes(\"/test/\")", pathname.includes("/test/"));
        if (pathname.includes("/test/")) {
          console.log("selectedCategoryData called here ")
          if (!foundIDName(state?.subCategory ? state?.subCategory :selectedSubcategoryData?.subCategory , `/test/${state?.subCategoryId || selectedSubcategoryData?.subCategoryId}`) || !isAlreadySet) {
            setIsAlreadySet(true)

            const newState = {
              ...(state || selectedSubCat)
            }
            delete newState?.subCategory
            delete newState?.subCategoryId
            delete newState?.subCategory_desc
            console.log("subCategory_desc", newState);
            setheaderNavigation([
              {
                name: "Home",
                path: "/dashboard",
                state: {}
              },
              {
                name: "Test",
                path: "/testcategory",
                state: {}
              },
              {
                name: state && state?.category ? state?.category : selectedCategoryData.category ? selectedCategoryData.category : null,
                path: `/testcategory/${state?.categoryId}`,
                active: false,
                state: newState
              },
              {
                name: state?.subCategory,
                path: `/test/${state?.subCategoryId}`,
                active: true,
                state: state
              },
            ]);
          }

        }
      } else {
        console.log("pathname", pathname, headerNavigation.length, headerNavigation);
        if (state.category && pathname !== "/testcategory") {
          if (pathname.includes("testcategory")) {
            if (!foundIDName(state?.category, `/testcategory/${state?.categoryId}`) || headerNavigation.length !== 3) {
            setIsAlreadySet(true)

              setheaderNavigation([
                {
                  name: "Home",
                  path: "/dashboard",
                  state: {}
                },
                {
                  name: "Test",
                  path: "/testcategory",
                  state: {}
                },
                {
                  name: state?.category,
                  path: `/testcategory/${state?.categoryId}`,
                  active: true,
                  state: state
                },
              ]);
            }
          }
        }
      }
      //// Header Navigation Result

      if (state.category || state?.subCategory) {
        if (pathname.includes("/results/")) {
          if (selectedInviteDataSlice?.isSelectedSocialUser) {
            if (!foundIDName(`${selectedInviteDataSlice?.selectedinviteData?.name} Results`, `/results`) || !isAlreadySet) {
            setIsAlreadySet(true)

              setheaderNavigation([
                {
                  name: "Home",
                  path: "/dashboard",
                  state: {}

                },
                {
                  name: "Results",
                  path: "/results",
                  state: {}

                },
                {
                  name: `${selectedInviteDataSlice?.selectedinviteData?.name} Results`,
                  path: "/results",
                  state: {}

                },
                // {
                //   name: state?.category,
                //   path: `/results/${state?.category_id}`,
                //   active: true,
                // },
              ]);
            }
          } else {
            if (!foundIDName(`Results`, `/results`) || !isAlreadySet) {
            setIsAlreadySet(true)

              setheaderNavigation([
                {
                  name: "Home",
                  path: "/dashboard",
                  state: {}

                },
                {
                  name: "Results",
                  path: "/results",
                  state: {}

                },
                // {
                //   name: state?.category,
                //   path: `/results/${state?.category_id}`,
                //   active: true,
                // },
              ]);
            }
          }
        }
        if (pathname.includes("/resultsubcatagories/")) {
          if (selectedInviteDataSlice?.isSelectedSocialUser) {
            if (!foundIDName(state?.subCategory, `/results/${state?.subCategoryId}`) || !isAlreadySet) {
            setIsAlreadySet(true)

              setheaderNavigation([
                {
                  name: "Home",
                  path: "/dashboard",
                  state: {}

                },
                {
                  name: "Results",
                  path: "/results",
                  state: {}

                },
                {
                  name: `${selectedInviteDataSlice?.selectedinviteData?.name} Results`,
                  path: "/results",
                  state: state
                },
                {
                  name: state?.subCategory,
                  path: `/results/${state?.subCategoryId}`,
                  active: true,
                  state: state
                },
              ]);
            }
          } else {
            if (!foundIDName(state?.subCategory, `/results/${state?.subCategoryId}`) || !isAlreadySet) {
            setIsAlreadySet(true)

              setheaderNavigation([
                {
                  name: "Home",
                  path: "/dashboard",
                  state: {}

                },
                {
                  name: "Results",
                  path: "/results",
                  state: {}

                },
                // {
                //   name: state?.category,
                //   path: `/results/${state?.category_id}`,
                //   active: false,
                // },
                {
                  name: state?.subCategory,
                  path: `/results/${state?.subCategoryId}`,
                  active: true,
                  state: state

                },
              ]);
            }
          }
        }
      } else {
        if (state.category && pathname !== "/results") {
          if (pathname.includes("results")) {
            if (!foundIDName("Results", `/results`) || !isAlreadySet) {
            setIsAlreadySet(true)

              setheaderNavigation([
                {
                  name: "Home",
                  path: "/dashboard",
                  state: {}
                },
                {
                  name: "Results",
                  path: "/results",
                  state: {}
                },
                // {
                //   name: state?.category,
                //   path: `/results/${state?.categoryId}`,
                //   active: true,
                // },
              ]);
            }
          }
        }
      }
    }
    if(!state) {
      if (pathname.includes("/testcategory/") && Object.keys(selectedCategoryData).length) {
        if (!foundIDName(selectedCategoryData?.category, `/testcategory/${selectedCategoryData?.categoryId}`) || !isAlreadySet) {
        console.log("selectedCategoryData called here ", headerNavigation, selectedCategoryData,  foundIDName(selectedCategoryData?.category, `/testcategory/${selectedCategoryData?.categoryId}`), isAlreadySet)

          setIsAlreadySet(true)
          console.log("setting data ", `[
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Test",
              path: "/testcategory",
              state: {}
            },
            {
              name: ${selectedCategoryData.category ? selectedCategoryData.category : null},
              path: ${`/testcategory/${selectedCategoryData?.categoryId}`},
              active: true,
              state: selectedCategoryData
            },
          ]`);
          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Test",
              path: "/testcategory",
              state: {}
            },
            {
              name: selectedCategoryData.category ? selectedCategoryData.category : null,
              path: `/testcategory/${selectedCategoryData?.categoryId}`,
              active: true,
              state: {...selectedCategoryData}
            },
          ]);
        }

      }
      if (pathname.includes("/plans/") && Object.keys(selectedPlan).length) {
        if (!foundIDName(selectedPlan?.name, `/plans/${selectedPlan?.planId}`) || !isAlreadySet) {
          setIsAlreadySet(true)
         
          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Plans",
              path: "/plans",
              state: {}
            },
            {
              name: selectedPlan.name ? selectedPlan.name : null,
              path: `/plans/${selectedPlan?.planId}`,
              active: true,
              state: {...selectedPlan}
            },
          ]);
        }

      }
      if (pathname.includes("/test/") && Object.keys(selectedSubcategoryData).length && Object.keys(selectedCategoryData).length) {
        if (!foundIDName(selectedSubcategoryData?.subCategory , `/test/${selectedSubcategoryData?.subCategoryId}`) || !isAlreadySet) {
          setIsAlreadySet(true)

          const newState = {
            ...(state || selectedSubCat)
          }
          delete newState?.subCategory
          delete newState?.subCategoryId
          delete newState?.subCategory_desc
          console.log("subCategory_desc", newState);
          setheaderNavigation([
            {
              name: "Home",
              path: "/dashboard",
              state: {}
            },
            {
              name: "Test",
              path: "/testcategory",
              state: {}
            },
            {
              name: selectedCategoryData.category ? selectedCategoryData.category : null,
              path: `/testcategory/${selectedCategoryData?.categoryId}`,
              active: false,
              state: selectedCategoryData
            },
            {
              name: selectedSubcategoryData?.subCategory,
              path: `/test/${selectedSubcategoryData?.subCategoryId}`,
              active: true,
              state: selectedSubcategoryData
            },
          ]);
        }

      }
    }
  }, [pathname]);

  const onClickHandler = (navigationItem) => {
    console.log("navigationItem.state =======", navigationItem.state);
    navigate(navigationItem.path,  { state: navigationItem.state });
  };
  const resultHandler = () => {
    console.log("result selected");
    dispatch(updateSelectedInviteData([]));
    dispatch(updateIsSelectedUser(false));
    dispatch(updateSelectedIndividualInvitId(""));

    const selectedcategories = headerNavigation?.filter((item) => {
      return (
        item?.name === averageByInviteUserSlice?.learnMoreQuestioncategories
      );
    });
    if (selectedcategories.length !== 0) {
      dispatch(updateisSocialResultNavigate(true));
    }
    // is learnMore state update
    dispatch(updateisLearnMore(false));
    dispatch(updateLearnMoreQuestionCategories(""));
    dispatch(updateSelectedQuestionCategories(""));
    setheaderNavigation([
      {
        name: "Home",
        path: "/dashboard",
      },
      {
        name: "Results",
        path: "/results",
      },
    ]);
    setIsAlreadySet(true)
  };

  return (
    <>
      {showNavigation && headerNavigation.map((navigationItem, index) => {
        // console.log(headerNavigation.length-1 ===index)
        // console.log(index)
        return (
          <div
            className={classes.navigationLink}
            key={index}
            onClick={() => {
              if (index !== headerNavigation.length - 1) {
                onClickHandler(navigationItem);
              }
            }}
          >
            <Typography
              variant="body3"
              color={index === headerNavigation.length - 1 ? "#9747FF" : ""}
              // onClick={
              //   navigationItem.name ==
              //   averageByInviteUserSlice?.learnMoreQuestioncategories
              //     ? reultChartHandler()
              //     : navigationItem.name === "Results"
              //     ? resultHandler
              //     : null
              // }
              onClick={navigationItem.name === "Results" ? resultHandler : null}
            >
              {navigationItem.name}
            </Typography>
            {index !== headerNavigation.length - 1 && <img src={RightArrow} />}
          </div>
        );
      })}
    </>
  );
};
export default HeaderNavigation;
