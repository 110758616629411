import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    Typography,
    Grid,
    Snackbar,
    styled,
    LinearProgress,
    linearProgressClasses,
} from "@mui/material";
import XP from "../../assets/Svg/XP.svg";
import classes from "./Dashboard.module.css";
import EmptyStateBox from "./DashboardEmptyBox";
import { FindAndGetXPLevel, imageURLRender } from "../../utils";
import Ludus from "../../assets/Svg/Ludus.svg";
import ArrowUp from "../../assets/Svg/ArrowUp.svg";
import ArrowDown from "../../assets/Svg/ArrowDown.svg";
import clsx from "clsx";
import moment from "moment";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { goalUpdateHabitAction, updateHabitActionCalender } from "../../services/auth";
import { setDahData, updateScheduleAtIndex } from "../../Store/Reducers/dashboard";
import { updateUserCions } from "../../Store/Reducers/userProfileSlice";
import { memo, useState } from "react";
import { pxToRem } from "../../theme/typography";
import GoalDateTimePicker from "../../components/EditRemoveGoal/GoalDateTimePicker/GoalDateTimePicker";
import useCustomNotificationColourPick from "../../Hooks/useCustomNotificationColourPick";
import { updateScheduleState } from "../../Store/Reducers/goalActionData";
const label = (id) => ({ inputProps: { "aria-label": "Checkbox" + id } });
const ScheduleDataContent = memo(({ openCloseJournalModal, grwothNotFound, testNotCompleted,
    resultNotUnlocked, contentLoading, callToGetData }) => {
    const dispatch = useDispatch()
    const {
        userData = {} } = useSelector(state => state.userProfile);
    const dashboardDataSlice = useSelector(state => state.dashboardDataSlice);
    const updateProfileXp = (xp, add) => {
        const updatedXp = !add ? (userData.xp - xp) : (userData.xp + xp)
        dispatch(updateUserCions(updatedXp))

    }
    const {
        NotificationContentColourPickJSX,
        customNotificationColourPick,
        setCustomNotificationColourPick,
    } = useCustomNotificationColourPick();
    const handleOpenCloseAccordion = (type, visible) => {
        if (type === "Today") {
            dispatch(setDahData({
                openToday: visible,
            }))
        }
        if (type === "Unscheduled") {
            dispatch(setDahData({
                openUnschedule: visible,
            }))
        }
        if (type === "Upcoming") {
            dispatch(setDahData({
                openUpcoming: visible,
            }))
        }

    }
    const handleLoadMore = (type) => {
        if (type === "Today") {
            const newDataLength = [...dashboardDataSlice.AllscheduleData].splice(0, (dashboardDataSlice.scheduleData.length + 3))
            dispatch(setDahData({
                scheduleData: newDataLength,
            }))
        }
        if (type === "Unscheduled") {
            const newDataLength = [...dashboardDataSlice.AllunSchedule].splice(0, (dashboardDataSlice.unSchedule.length + 3))
            dispatch(setDahData({
                unSchedule: newDataLength,
            }))
        }
        if (type === "Upcoming") {
            const newDataLength = [...dashboardDataSlice.AllupcomingResponse].splice(0, (dashboardDataSlice.upcomingResponse.length + 3))
            dispatch(setDahData({
                upcomingResponse: newDataLength,
            }))
        }

    }
    const updateXp = (xp, add) => {
        const updatedXp = !add ? (dashboardDataSlice.completedXP - xp) : (dashboardDataSlice.completedXP + xp)
        dispatch(setDahData({
            completedXP: updatedXp,
            visibleCelebrationmodal: dashboardDataSlice.goalXp <= updatedXp ? true : false
        }))
        updateProfileXp(xp, add)
    }
    const findAndUpdateStatus = (actionId, habitId, status, type) => {

        dispatch(updateScheduleAtIndex({
            actionId,
            habitId,
            obj: {
                status,
                calenderStatus: status
            },
            type
        }))
        // const updateObj = [...dashboardData]
        // console.log("updateObj", updateObj, updateObj[foundIndex]);
        // updateObj[foundIndex].status = status
        // updateObj[foundIndex].calenderStatus = status
        // this.props.setDahData({
        //   scheduleData: [...updateObj]
        // })
    }
    const handleIsTrue = (e, action, foundXp, type) => {
        e.preventDefault()
        e.stopPropagation()
        console.log("action ===========", action, foundXp);
        if (action.actionId) {
            let createActionData;
            if (action.calenderStatus === "IN_PROGRESS") {

                createActionData = {
                    uid: localStorage.getItem("userDetail"),
                    id: action.actionId,
                    type: "ACTION",
                    action: {
                        scheduled_type: action.scheduled_type,
                        // active: "false",
                        colour: action.colour,
                        textColour: action.textColour,
                        executionDate: action.executionDate,
                        executionDateEnd: action.executionDateEnd,
                        calenderStatus: "COMPLETE",
                        status: "COMPLETE",
                        xpChange: "INCREASE"
                    },
                    xpChange: "INCREASE"
                };
            } else {
                createActionData = {
                    uid: localStorage.getItem("userDetail"),
                    id: action.actionId,
                    type: "ACTION",
                    action: {
                        scheduled_type: action.scheduled_type,
                        // active: "false",
                        colour: action.colour,
                        textColour: action.textColour,
                        executionDate: action.executionDate,
                        executionDateEnd: action.executionDateEnd,
                        calenderStatus: "IN_PROGRESS",
                        status: "IN_PROGRESS",
                        xpChange: "DECREASE"
                    },
                    xpChange: "DECREASE"
                };
            }
            console.log("createActionData ========", createActionData);
            if (Object.keys(createActionData).length > 0) {
                findAndUpdateStatus(action.actionId, null, createActionData.action.status, type)
                updateHabitActionCalender(createActionData)
                    .then((res) => {
                        // this.props.setDahData({
                        //   completedXP: createActionData.action.status === "IN_PROGRESS" ?  (this.props.dashboardData.completedXP - foundXp) : (this.props.dashboardData.completedXP + foundXp)
                        // })
                        updateXp(foundXp, createActionData.action.status !== "IN_PROGRESS")
                        //setIsSetDurationAction(false);
                        // this.callToGetData(
                        //   this.props.dashboardData.selectedDate,
                        //   this.props.dashboardData.selectedDate
                        // );
                        console.log("res = ", res);
                    })
                    .catch((err) => {
                        console.log("err = ", err);
                    });
            }
        } else {
            let createHabitData;
            if (action._id) {
                if (action.calenderStatus === "IN_PROGRESS") {
                    createHabitData = {
                        uid: localStorage.getItem("userDetail"),
                        id: action._id,
                        type: "HABIT",
                        habit: {
                            scheduled_type: action.scheduled_type,
                            executionDate: action.executionDate,
                            executionDateEnd: action.executionDateEnd,
                            calenderStatus: "COMPLETE",
                            status: "COMPLETE",
                            xpChange: "INCREASE"
                        },
                        xpChange: "INCREASE"
                    };
                } else {
                    createHabitData = {
                        uid: localStorage.getItem("userDetail"),
                        id: action._id,
                        type: "HABIT",
                        habit: {
                            scheduled_type: action.scheduled_type,
                            executionDate: action.executionDate,
                            executionDateEnd: action.executionDateEnd,
                            calenderStatus: "IN_PROGRESS",
                            status: "IN_PROGRESS",
                            xpChange: "DECREASE"
                        },
                        xpChange: "DECREASE"
                    };
                }
                console.log("createHabitData ========", createHabitData);
                if (Object.keys(createHabitData).length > 0) {
                    findAndUpdateStatus(null, action._id, createHabitData.habit.status, type)
                    updateHabitActionCalender(createHabitData)
                        .then((res) => {
                            // this.props.setDahData({
                            //   completedXP: createHabitData.habit.status === "IN_PROGRESS" ?  (this.props.dashboardData.completedXP - foundXp) : (this.props.dashboardData.completedXP + foundXp)
                            // })
                            updateXp(foundXp, createHabitData.habit.status !== "IN_PROGRESS")
                            // this.callToGetData(
                            //   this.props.dashboardData.selectedDate,
                            //   this.props.dashboardData.selectedDate
                            // );
                            console.log("res = ", res);
                        })
                        .catch((err) => {
                            console.log("err = ", err);
                        });
                }
            }
        }
    };
    const setIsAskOtherModalOpen = (visible) => {
        dispatch(setDahData({
            isAskOtherModalOpenAction: visible
        }))
    }
    
    const setActionState = (data) => {
        dispatch(setDahData({
            actionState: data
        }))
    }
    const setIsSchedule = val => dispatch(setDahData({
        isScheduleAction: val
    }))
    const editShedualdataHandler = (executionStart, executionEnd) => {
        const user = localStorage.getItem("userDetail");
        const goalId = dashboardDataSlice?.scheduleActionState?.actionId;
        const tempEditedAction = {
            actionName: dashboardDataSlice?.scheduleActionState.actionName,
            actionDetail: dashboardDataSlice?.scheduleActionState.actionDetail,
            executionDate: executionStart,
            executionDateEnd: executionEnd,
            scheduled_type: "TIME_BASED",
            status: "IN_PROGRESS",
            calenderStatus: "IN_PROGRESS",
        };

        const updatedActionState = {
            uid: user,
            id: goalId,
            type: "ACTION",
            action: tempEditedAction,
        };
        goalUpdateHabitAction(updatedActionState)
            .then((res) => {
                // console.log(res);
                callToGetData(dashboardDataSlice?.selectedDate)
                dispatch(setDahData({
                    actionIdDialog: "",
                    isScheduleAction: false,
                    scheduleActionState: {}
                }))
                dispatch(updateScheduleState({}));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => { });
    };
    const onClickSchedule = (obj) => {
        console.log("obj", obj);
        const newObj = {...obj}
        if (newObj && newObj.scheduled_type === "DEFAULT") {
            newObj.executionDate = moment().format()
            newObj.executionDateEnd = moment().add(30, 'minutes').format()
        }
        dispatch(
            updateScheduleState({
              actionName: newObj.actionName,
              actionDetail: newObj.actionDetail,
              executionDate: newObj?.executionDate
                ? moment(newObj?.executionDate).format()
                : new Date(),
              executionDateEnd: newObj.executionDateEnd
                ? newObj.executionDateEnd
                : newObj?.executionDate
                  ? newObj?.executionDate
                  : new Date(),
              actionId: newObj.actionId,
            })
          );
        dispatch(setDahData({
            actionIdDialog: newObj.actionId,
            isScheduleAction: true,
            scheduleActionState: {
                actionName: newObj.actionName,
                actionDetail: newObj.actionDetail,
                executionDate: newObj?.executionDate
                    ? moment(newObj?.executionDate).format()
                    : new Date(),
                executionDateEnd: newObj.executionDateEnd
                    ? newObj.executionDateEnd
                    : newObj?.executionDate
                        ? newObj?.executionDate
                        : new Date(),
                actionId: newObj.actionId,
            }
        }))
       
        // setidDialog(actionId);
        // setIsSchedule(true);
        // dispatch(
        //   updateScheduleState({

        //   })
        // );
    }
    const arrToRender = []
    console.log("dashboardDataSlice.isScheduleAction", dashboardDataSlice.isScheduleAction, dashboardDataSlice?.scheduleActionState);
    if ([...dashboardDataSlice.scheduleData].length > 0) {
        arrToRender.push(<ScheduleDataBox data={[...dashboardDataSlice.scheduleData]} allData={dashboardDataSlice.AllscheduleData}
            handleIsTrue={handleIsTrue} openCloseJournalModal={openCloseJournalModal} open={dashboardDataSlice.openToday} key={"today"} type="Today" handleOpenCloseAccordion={handleOpenCloseAccordion} handleLoadMore={handleLoadMore} />)
    }
    if ([...dashboardDataSlice.unSchedule].length > 0) {
        arrToRender.push(<ScheduleDataBox data={[...dashboardDataSlice.unSchedule]} allData={dashboardDataSlice.AllunSchedule}
            handleIsTrue={handleIsTrue} openCloseJournalModal={openCloseJournalModal} open={dashboardDataSlice.openUnschedule} key={"unscheduled"} type="Unscheduled" handleOpenCloseAccordion={handleOpenCloseAccordion} handleLoadMore={handleLoadMore} onClickSchedule={onClickSchedule} />)
    }
    if ([...dashboardDataSlice.upcomingResponse].length > 0) {
        arrToRender.push(<ScheduleDataBox data={[...dashboardDataSlice.upcomingResponse]} open={dashboardDataSlice.openUpcoming} allData={dashboardDataSlice.AllupcomingResponse}
            handleIsTrue={handleIsTrue} openCloseJournalModal={openCloseJournalModal} key={"upcoming"} type="Upcoming" handleOpenCloseAccordion={handleOpenCloseAccordion} handleLoadMore={handleLoadMore} />)
    }

    if (arrToRender.length > 0) {
        return <>{arrToRender}
            {dashboardDataSlice.isScheduleAction && dashboardDataSlice?.actionIdDialog === dashboardDataSlice?.scheduleActionState?.actionId && (
                <GoalDateTimePicker
                    isAskOtherModalOpen={dashboardDataSlice?.isAskOtherModalOpenAction}
                    setIsAskOtherModalOpen={setIsAskOtherModalOpen}
                    setActionState={setActionState}
                    actionState={dashboardDataSlice?.actionState}
                    editActionTextHandler={editShedualdataHandler}
                    isSchedule={dashboardDataSlice.isScheduleAction}
                    setIsSchedule={setIsSchedule}
                    scheduleState={dashboardDataSlice?.scheduleActionState}
                    setCustomNotificationColourPick={setCustomNotificationColourPick}
                />
            )}
            {customNotificationColourPick && (
                <NotificationContentColourPickJSX
                    message="Start Time should be less than End Time "
                    isThumbIcon={false}
                    position="top"
                />
            )}
        </>
    }
    return <EmptyStateBox grwothNotFound={grwothNotFound} testNotCompleted={testNotCompleted}
        resultNotUnlocked={resultNotUnlocked} contentLoading={contentLoading} />
})

export default ScheduleDataContent

const ScheduleDataBox = memo(({ data, handleIsTrue, openCloseJournalModal, type, open, handleOpenCloseAccordion, allData, handleLoadMore, onClickSchedule }) => {
    console.log("this child re-rendered");
    return <>
        <div className={classes.scheduleDataTitle}>
            <Typography sx={{ fontSize: `${pxToRem(24)} !important`, fontWeight: 600, color: type === "Upcoming" ? "#B4B0B4" : "#190D1A" }}>{type}</Typography>
            {open ? <img
                src={ArrowUp}
                onClick={() => handleOpenCloseAccordion(type, false)}
                alt="up"
            /> : <img
                src={ArrowDown}
                onClick={() => handleOpenCloseAccordion(type, true)}
                alt="down"
            />}
        </div>
        {open && data.map((it) => {
            const { data } = it;
            const foundXp = FindAndGetXPLevel(it.level, false)
            return (
                <Box className={classes.actionGroupBox} key={it.actionId || it._id}>
                    <Checkbox
                        {...label(it.actionId || it._id)}
                        sx={{ pl: "0", height: "20px" }}
                        checked={
                            it.calenderStatus === "COMPLETE"
                        }
                        // disabled={type !== "Today"}
                        onChange={(e) =>  handleIsTrue(e, it, foundXp, type)}
                    />
                    <div className={classes.scheduleAction}>
                        <div className={classes.scheduleActionDataContent}>
                            <div
                                className={
                                    classes.scheduleActionDataContentData
                                }
                            >
                                <div
                                    className={
                                        classes.scheduleActionDataNameContentData
                                    }
                                >
                                    {data && data[0] && data[0].url ? (
                                        <img
                                            src={imageURLRender(data[0]?.url)}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={Ludus}
                                            alt=""
                                        />
                                    )}

                                    <span
                                        className={
                                            classes.scheduleActionDataContentTitle
                                        }
                                    >
                                        {it?.questionCategory}
                                    </span>
                                </div>
                                <div
                                    className={
                                        classes.scheduleActionDataTagContentData
                                    }
                                >
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="flex-end"
                                    >
                                        {it?.scheduled_type ===
                                            "UNSPECIFIED" || type === "Unscheduled" ? null : (
                                            <Chip
                                                label={
                                                    moment(it?.executionDate)
                                                        .format("HH:mm") +
                                                    " - " +
                                                    moment(it?.executionDateEnd)
                                                        .format("HH:mm")
                                                }
                                                className={clsx(classes.backgroundChip)}
                                            />
                                        )}

                                        {foundXp && <Chip
                                            label={foundXp}
                                            avatar={<Avatar alt="Natacha" src={XP} className={classes.avatarClass} />}
                                            className={clsx(classes.backgroundChip)}
                                        />}
                                    </Stack>
                                </div>
                            </div>
                            {it && it.actionName && (
                                <span className={classes.scheduleActionText}>
                                    <span className={clsx(classes.strike, it.status === "COMPLETE" ? classes.completedStrike : "")}>{it?.actionName}</span>
                                    
                                </span>
                            )}
                            {it?.habitName && (
                                <span className={classes.scheduleActionText}>
                                    <span className={clsx(classes.strike, it.status === "COMPLETE" ? classes.completedStrike : "")}>{it?.habitName}</span>
                                </span>
                            )}

                        </div>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                        }}>
                            <Button
                                variant="contained"
                                className={classes.scheduleActionButton}
                                onClick={() => {
                                    openCloseJournalModal(true, it);
                                }}
                            >
                                Open Journal
                            </Button>
                            {type === "Unscheduled" && (
                                <Button
                                    variant="contained"
                                    className={classes.scheduleActionButton}
                                    onClick={() => {
                                        onClickSchedule(it);
                                    }}
                                >
                                    Schedule
                                </Button>
                            )}

                        </Box>

                    </div>
                </Box>
            );
        })}
        {data.length < allData.length ? (
            <Button
                variant="contained"
                className={classes.loadMoreButton}
                onClick={() => {
                    handleLoadMore(type)
                    // openCloseJournalModal(true, it);
                }}
            >
                Load More
            </Button>
        ) : null}

    </>

})

function CheckBoxForm({ checkedElm, it, handleIsTrue, foundXp }) {
    const [checked, setChecked] = useState(checkedElm);
    return
}
