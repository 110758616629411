import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import classes from "./plans.module.css"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContainerWrapper } from "./plans.styles";
import { imageURLRender } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import PlansServices from "../../services/Plans";
import { setStoreData } from "../../Store/Reducers/plans";
import PlanFallback from "../../assets/Svg/PlanFallback.svg"
import clsx from "clsx";
import { GeneralSX } from "../../components/Text/Text";
import palette from "../../theme/palette";
import PlanModal from "./PlanModals/CommonModal";
import PlanLow from "../../assets/Svg/PlanLow.svg"
import PlanMid from "../../assets/Svg/PlanMid.svg"
import PlanHigh from "../../assets/Svg/PlanHigh.svg"
import PlanModalConfirmation from "./confirmationModal";
const benefits = [
    {
        label: 'Lorem ipsum dolor sit amet consectetur neque cum'
    },
    {
        label: 'Lorem ipsum dolor sit amet consectetur neque cum'
    },
    {
        label: 'Lorem ipsum dolor sit amet consectetur neque cum'
    },
    {
        label: 'Lorem ipsum dolor sit amet consectetur neque cum'
    },
]
function LinearProgressWithLabel(props) {
    return (
        <Box className={classes.progressBarWrapper}>
            <Box sx={{ width: "100%", mr: "15px" }}>
                <LinearProgress
                    className={
                        props.isprogressdisable
                            ? classes.progressDisabeled
                            : classes.progress
                    }
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box>
                <Typography variant="body3" color="#190D1A" sx={{
                    ...GeneralSX({
                        fontSize: 12,
                    })
                }}>{`${Math.round(
                    props.openCount
                )}`}</Typography>
                <Typography variant="body3" color="#B4B0B4" sx={{
                    ...GeneralSX({
                        fontSize: 12,
                        color: '#B4B0B4'
                    })
                }}>{` out of ${Math.round(
                    props.planActions
                )} days completed`}</Typography>
                {/* <Typography variant="body3" color="#190D1A">{`${Math.round(
                    props.value
                )}%`}</Typography> */}
            </Box>
        </Box>
    );
}

const PlanDetails = () => {
    const [showImage, setShowImage] = useState(true);
    const { selectedPlan, activeTabObject, planActions } = useSelector((state) => state.planData);
    const dispatch = useDispatch()
    const hideImg = (event) => {
        // this.setState({ showImg: false });
        setShowImage(true);
    };
    const { questionCategoryData = [], userPlansData = [] } = selectedPlan
    const { status = "active" } = (userPlansData[0] || {})
    const openDays = [...(planActions || [])]?.filter(it => it?.dayOpen)
    console.log("PlanDetails selectedPlan", selectedPlan, planActions)
    const totalCompleted = (100 * openDays.length) / [...(planActions || [])].length
    console.log("PlanDetails selectedPlan", selectedPlan, selectedPlan.planLevel, planActions, openDays.length, [...(planActions || [])].length, totalCompleted)

    return <>
        <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
        //   sx={{ background: 'yellow'  }}
        >
            <ContainerWrapper>
                <div className={classes.planFullScreen}>
                    <div className={classes.frame4426}>
                        {/* {showImage ? ( */}
                        <div className={classes.emptyBox}>
                            {selectedPlan.planLevel === "Low" ? (
                                <img
                                    //src={SmallEngagement} 
                                    src={PlanLow}
                                    alt="low-plan"
                                    style={{
                                        objectFit: "cover",
                                        borderRadius: "0px 20px 20px 0px",
                                        width: '100%'
                                    }}
                                    onError={({ currentTarget }) => {
                                        // currentTarget.onerror = null; // prevents looping
                                        // currentTarget.src = PlanFallback;
                                    }}
                                />
                            ) : selectedPlan.planLevel === "Moderate" ? (
                                <img
                                    //src={SmallEngagement} 
                                    src={PlanMid}
                                    alt="moderate-plan"
                                    style={{
                                        objectFit: "cover",
                                        borderRadius: "0px 20px 20px 0px",
                                        width: '100%'
                                    }}
                                    onError={({ currentTarget }) => {
                                        // currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = PlanFallback;
                                    }}
                                />
                            ) : selectedPlan.planLevel === "High" ? (
                                <img
                                    //src={SmallEngagement} 
                                    src={PlanHigh}
                                    alt="high-plan"
                                    style={{
                                        objectFit: "cover",
                                        borderRadius: "0px 20px 20px 0px",
                                        width: '100%'
                                    }}
                                    onError={({ currentTarget }) => {
                                        // currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = PlanFallback;
                                    }}
                                />
                            ) : <img src={imageURLRender(questionCategoryData[0]?.url)} alt="" onError={hideImg} />}

                        </div>
                        {/* ) : (
                            <EmptyBox />
                        )} */}
                        <div className={classes.frame4424}>
                            <div className={classes.frame4443}>
                                <div className={classes.frame4392}>
                                    <div className={classes.frame4384}>
                                        <Typography sx={{
                                            ...GeneralSX({
                                                color: palette.common.white,
                                                fontSize: 10,
                                                fontWeight: 500,
                                                lineHeight: "100%",
                                                letterSpacing: '-0.1px'
                                            })
                                        }}>{selectedPlan?.questionCategory}</Typography>
                                    </div>
                                    <div className={clsx(classes.frame4391, classes.borderBgPrimary)}>
                                        <Typography sx={{
                                            ...GeneralSX({
                                                color: palette.primary.main,
                                                fontSize: 10,
                                                fontWeight: 500,
                                                lineHeight: "100%",
                                                letterSpacing: '-0.1px'
                                            })
                                        }}>{selectedPlan?.weekDuration} weeks</Typography>
                                    </div>
                                    <div className={clsx(classes.frame43921, classes.borderBgPrimary)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <circle cx="6" cy="6" r="6" fill="#9747FF" />
                                            <circle cx="5.99883" cy="6.00014" r="5.2" fill="white" />
                                            <path d="M3.25016 3.75L4.31184 5.51001H4.34651L5.40819 3.75H6.18386L4.80151 6L6.19253 8.25H5.41252L4.34651 6.51416H4.31184L3.24583 8.25H2.46582L3.88067 6L2.47449 3.75H3.25016Z" fill="#9747FF" />
                                            <path d="M6.84795 8.25V3.75H8.42964C8.77486 3.75 9.06087 3.81372 9.28765 3.94116C9.51443 4.0686 9.68415 4.24292 9.79682 4.46411C9.90949 4.68384 9.96582 4.9314 9.96582 5.20679C9.96582 5.48364 9.90876 5.73267 9.79465 5.95386C9.68198 6.17358 9.51154 6.3479 9.28331 6.47681C9.05653 6.60425 8.77125 6.66797 8.42747 6.66797H7.33979V6.09229H8.3668C8.58492 6.09229 8.76186 6.0542 8.89764 5.97803C9.03342 5.90039 9.13309 5.79492 9.19665 5.66162C9.2602 5.52832 9.29198 5.37671 9.29198 5.20679C9.29198 5.03687 9.2602 4.88599 9.19665 4.75415C9.13309 4.62231 9.0327 4.51904 8.89548 4.44434C8.7597 4.36963 8.58058 4.33228 8.35814 4.33228H7.51746V8.25H6.84795Z" fill="#9747FF" />
                                        </svg>
                                        <Typography sx={{
                                            ...GeneralSX({
                                                color: palette.primary.main,
                                                fontSize: 10,
                                                fontWeight: 500,
                                                lineHeight: "100%",
                                                letterSpacing: '-0.1px'
                                            })
                                        }}>{selectedPlan?.xpLevel}</Typography>
                                    </div>
                                </div>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M15.8921 4.99643C15.5516 4.65576 15.1473 4.38552 14.7023 4.20115C14.2574 4.01677 13.7804 3.92187 13.2988 3.92188C12.8171 3.92187 12.3402 4.01677 11.8952 4.20115C11.4502 4.38552 11.0459 4.65576 10.7054 4.99643L9.99878 5.70309L9.29211 4.99643C8.60431 4.30863 7.67146 3.92223 6.69878 3.92223C5.72609 3.92223 4.79324 4.30863 4.10544 4.99643C3.41765 5.68422 3.03125 6.61707 3.03125 7.58976C3.03125 8.56245 3.41765 9.4953 4.10544 10.1831L4.81211 10.8898L9.99878 16.0764L15.1854 10.8898L15.8921 10.1831C16.2328 9.84259 16.503 9.4383 16.6874 8.99333C16.8718 8.54836 16.9667 8.07142 16.9667 7.58976C16.9667 7.1081 16.8718 6.63116 16.6874 6.18619C16.503 5.74122 16.2328 5.33693 15.8921 4.99643V4.99643Z" stroke="#B4B0B4" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> */}

                            </div>
                            <div className={classes.frame4390}>
                                <Typography sx={{
                                    ...GeneralSX({
                                        color: palette.common.mainBlack,
                                        fontSize: 18,
                                        fontWeight: 600,
                                        lineHeight: "116%",
                                        letterSpacing: '-0.18px'
                                    })
                                }}>{selectedPlan?.name}</Typography>
                                <Typography sx={{
                                    ...GeneralSX({
                                        color: palette.common.darkGray,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: "145%",
                                        letterSpacing: '-0.14px'
                                    }),
                                    whiteSpace: 'pre-line',
                                }}>{selectedPlan?.longDescription}</Typography>
                            </div>
                        </div>

                    </div>
                    <div className={classes.frame4431}>
                        {status === "freeze" ? (
                            <Button variant="outlined" className={classes.outlinedButton} onClick={() => {
                                dispatch(setStoreData({
                                    viewPlanConfirmationModal: true,
                                    isForUnfreeze: true
                                }));
                            }}>
                                <Typography sx={{
                                    ...GeneralSX({
                                        color: palette.primary.main,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        lineHeight: "100%",
                                        letterSpacing: '-0.16px'
                                    })
                                }}>Unfreeze</Typography>
                            </Button>
                        ) : (
                            <div className={classes.flexbuttonbox}>
                                {["MY_PLANS"].includes(activeTabObject.type) && (
                                    <Button variant="outlined" className={classes.outlinedButton} sx={{
                                        width: '100%'
                                    }} onClick={() => {
                                        dispatch(setStoreData({
                                            viewPlanConfirmationModal: true,
                                            isForUnfreeze: false
                                        }));
                                    }}>
                                        <Typography sx={{
                                            ...GeneralSX({
                                                color: palette.primary.main,
                                                fontSize: 16,
                                                fontWeight: 400,
                                                lineHeight: "100%",
                                                letterSpacing: '-0.16px'
                                            })
                                        }}>Freeze</Typography>
                                    </Button>
                                )}

                                <Button variant="outlined" className={classes.buttonPlan} sx={{
                                    width: '100%'
                                }} onClick={() => {
                                    dispatch(setStoreData({
                                        viewPlanModal: true,
                                        setp: 1
                                    }));
                                }}>
                                    <Typography sx={{
                                        ...GeneralSX({
                                            color: palette.common.white,
                                            fontSize: 16,
                                            fontWeight: 400,
                                            lineHeight: "100%",
                                            letterSpacing: '-0.16px'
                                        })
                                    }}>View plan</Typography>
                                </Button>
                            </div>
                        )}

                    </div>
                    {["MY_PLANS"].includes(activeTabObject.type) && (
                        <LinearProgressWithLabel
                            value={Math.round(totalCompleted)}
                            openCount={openDays.length}
                            planActions={planActions.length}
                            size={2}
                            isprogressdisable={false}
                        />
                    )}

                </div>
            </ContainerWrapper>
        </Grid>
        <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
        // sx={{ overflowY: "scroll" }}
        >
            <ContainerWrapper>
                <div className={classes.planBenefits}>
                    <Typography sx={{
                        ...GeneralSX({
                            color: palette.common.mainBlack,
                            fontSize: 18,
                            fontWeight: 500,
                            lineHeight: "145%",
                            letterSpacing: '-0.18px'
                        })
                    }}>What’s there for you</Typography>
                    <div className={classes.benefitList}>
                        {selectedPlan?.planBenefits?.length > 0 && selectedPlan?.planBenefits.map(it => <div className={classes.benefitItem} key={it}>
                            <div className={classes.frame4437}>
                                <Typography sx={{
                                    ...GeneralSX({
                                        color: palette.common.mainBlack,
                                        fontSize: 20,
                                        fontWeight: 400,
                                        lineHeight: "100%",
                                        letterSpacing: '-0.2px'
                                    })
                                }}>⭐️</Typography>
                            </div>
                            <Typography sx={{
                                ...GeneralSX({
                                    color: palette.common.mainBlack,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: "145%",
                                    letterSpacing: '-0.14px'
                                })
                            }}>{it}</Typography>
                        </div>)}
                    </div>
                </div>
            </ContainerWrapper>

        </Grid>
        <PlanModal selectedPlan={selectedPlan} />
        <PlanModalConfirmation selectedPlan={selectedPlan} />
    </>
}

export default PlanDetails

const EmptyBox = () => {
    return <div className={classes.emptyBox}>
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53168 111.468C19.9022 122.839 38.3374 122.839 49.7079 111.468L59.9998 101.176L70.2921 111.469C81.6626 122.839 100.098 122.839 111.468 111.469C122.839 100.098 122.839 81.6631 111.468 70.2926L101.176 60.0003L111.472 49.7041C122.843 38.3336 122.843 19.8984 111.472 8.52786C100.102 -2.84263 81.6665 -2.84261 70.296 8.52788L59.9998 18.8241L49.7041 8.52837C38.3336 -2.84213 19.8984 -2.84213 8.52786 8.52836C-2.84263 19.8988 -2.84261 38.3341 8.52788 49.7046L18.8236 60.0003L8.53169 70.2921C-2.8388 81.6626 -2.83881 100.098 8.53168 111.468Z" fill="white" />
        </svg>
    </div>
}