import React, { useState } from "react";
import classes from "./TestPageLayout.module.css";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Card } from "@mui/material";
import CloseIcon from "../../assets/Svg/CloseIcon.svg";
import CloseIconBlack from "../../assets/Svg/CloseIconBlack.svg";
import Flag from "../../assets/Svg/Flag.svg";
import AlertModal from "../../components/AlertModal/AlertModal";

const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  // display: "block",
  // paddingBlock: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  minHeight: "100vh",
  position: 'relative'
}));

const CardContent = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "inline-flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "0",
  // [theme.breakpoints.up("xl")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.down("md")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // background: 'yellow',
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    with: "100%",
    padding: "0px",
  },
}));

const CardContent2 = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  padding: "40px",
  // [theme.breakpoints.up("xl")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // [theme.breakpoints.down("md")]: {
  //   padding: "6.3rem 7.4rem",
  // },
  // background: 'yellow',
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    with: "100%",
    padding: "40px",
  },
}));

const MainCard = styled(Box)(({ theme }) => ({
  background: "#fff",
  width: "692px",
  marginTop: '20px',
  marginBottom: "20px",
  overflowY: "auto",
  // minHeight: "calc(100vh - 4rem)",
  position: "relative",
  [theme.breakpoints.up("xl")]: {
    width: "692px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "692px",
  },
  [theme.breakpoints.down("md")]: {
    width: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    width: "100%",
  },
}));
const BrandWrapper = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px',
  gap: '6px',

  position: 'absolute',
  width: '219px',
  height: '38px',
  // left: '611px',
  top: '12px',

  background: '#9747FF',
  borderRadius: '12px',
}))
const CloseIconWrapper = styled("div")(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  zIndex: '100',
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    top: '7px',
    right: '10px'
  },
}));

const FlagIconWrapper = styled("div")(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  zIndex: '100',
  // [theme.breakpoints.up("xl")]: {
  // },
  // [theme.breakpoints.down("lg")]: {
  // },
  [theme.breakpoints.down("md")]: {
    bottom: '0px',
    right: '7px',
  },
  // [theme.breakpoints.down("sm")]: {
  // },
}));

const BranchnameText = styled("span")(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '100%',
  /* identical to box height, or 14px */

  letterSpacing: '-0.01em',

  /* Main/White */

  color: '#FFFFFF',
}))

const TestConfirmPageLayout = ({ showCloseIcon = true, showFlag = true, showBrand = false }) => {
  const [openAlert, setOpenAlert] = useState(false);

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };
  return (
    <>
      <RootStyle className={classes.main_wrapper}>
        <CloseIconWrapper onClick={handleClickOpen} style={{ width: "32px", height: "32px", background: "white", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img src={CloseIconBlack} alt="" />
        </CloseIconWrapper>
        <MainCard
          sx={{
            minWidth: 250,
            borderRadius: "32px",
            marginTop: '50px',
            // marginInline: "auto",
          }}
        >

          <CardContent>
            <Outlet />
          </CardContent>
        </MainCard>
        <AlertModal open={openAlert} handleClose={handleClose} fromConfirmationPage={true}/>
      </RootStyle>
    </>
  );
};

export default TestConfirmPageLayout;
