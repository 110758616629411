import { Button, CircularProgress, Dialog, TextField, Typography } from "@mui/material";
import classes from "./invitemodal.module.css"
import StarHome from "./../../../assets/Svg/StarHomeWhite.svg"
// import StarHome from "./../../../assets/Svg/StarHome.png"
import Ellipse11 from "./../../../assets/Svg/Ellipse11.svg"
import Ellipse12 from "./../../../assets/Svg/Ellipse12.svg"
import RectangleHome from "./../../../assets/Svg/RectangleHome.svg"
import Vector16 from "./../../../assets/Svg/Vector16.svg"
import Vector21 from "./../../../assets/Svg/Vector21.svg"
import WinningCel from "./../../../assets/Svg/WinningCel.svg"
import { ReactComponent as Connecter } from "./../../../assets/Svg/Connecter.svg"
import { ReactComponent as Mail } from "./../../../assets/Svg/Mail-24px.svg"
import QuoteIcon from "./../../../assets/Svg/quoteIcon.svg"
import { useEffect, useState } from "react";
import { MultiLineInput } from "../../InviteFlow/StyledComponent";
import DashboardService from "../../../services/Dashboard";
const InviteReferModal = (props) => {
    const [clickedmail, setClickedmail] = useState(false)
    const [email, setEmail] = useState(null)
    const [message, setMessage] = useState(null)
    const [errors, setErrors] = useState({})
    const [isValidEmail, setIsValidemail] = useState(false)
    const {
        openInviteModal,
        openCloseInviteModal,
        sendInvite,
        loadingButton
    } = props;
    useEffect(() => {
        return () => {
            // setClickedmail(false)
        }
    })
    const ValidateEmail = (input) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
            return true;
        } else {
            return false;
        }
    }


    useEffect(() => {
        if (email) {
            const validatEmail = ValidateEmail(email)
            console.log("email.length", validatEmail);
            if (!validatEmail) {
                setIsValidemail(false)
                setErrors({
                    ...errors,
                    email: "Please enter proper email"
                })
            } else {
                setIsValidemail(true)
                setErrors({
                    ...errors,
                    email: ""
                })
            }
        } else {
            setIsValidemail(false)
            setErrors({
                ...errors,
                email: ""
            })
        }
    }, [email])

    return (
        <Dialog
            open={openInviteModal}
            onClose={() => {
                openCloseInviteModal(false);
            }}
            sx={{
                "& .MuiPaper-root": {
                    // position: 'absolute',
                    // width: '560px',
                    // height: '696px',
                    // left: 'calc(50% - 560px/2)',
                    // top: 'calc(50% - 696px/2)',
                    position: 'relative',
                    width: '1440px',
                    height: 'calc(100vh - 5%)',
                    background: 'transparent'
                },
            }}
            className={classes.inviteInfoPopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <div className={classes.inviteCard} style={{
                // backgroundImage: `url(${EarlyAccess})`
            }}>
                {/* <Confetti width={200} height={400} /> */}
                {/* <StarHome /> */}
                <img src={StarHome} alt="" style={{
                    position: 'absolute',
                    width: '23.59px',
                    height: '23.59px',
                    left: '22.78px',
                    top: '21.95px',
                }} />
                <img src={StarHome} alt="" style={{
                    position: 'absolute',
                    width: '22.51px',
                    height: '22.51px',
                    left: '34.24px',
                    top: '113.7px',
                }} />
                <img src={StarHome} alt="" style={{
                    position: 'absolute',
                    width: '22.51px',
                    height: '22.51px',
                    left: '196.24px',
                    top: '19.02px',
                }} />
                <img src={StarHome} alt="" style={{
                    position: 'absolute',
                    width: '23.59px',
                    height: '23.59px',
                    left: '266.7px',
                    top: '119.52px',

                    transform: 'matrix(1, 0, 0, 1, 0, 0)',
                }} />
                <img src={Ellipse11} alt="" style={{
                    position: 'absolute',
                    width: '8px',
                    height: '8px',
                    left: '240.88px',
                    top: '58.18px',

                    opacity: '1',
                    /* Main/Purple */

                    // border: '3.37617px solid #9747FF',
                    transform: 'matrix(-0.39, -0.92, 0.92, -0.39, 0, 0)',

                }} />
                <img src={Ellipse12
                } alt="" style={{
                    position: 'absolute',
                    width: '6px',
                    height: '6px',
                    left: '256.98px',
                    top: '118.2px',

                    opacity: '1',
                    /* Main/Purple */

                    // border: '3.37617px solid #9747FF',
                    transform: 'matrix(-0.39, -0.92, 0.92, -0.39, 0, 0)',

                }} />
                <img src={
                    RectangleHome
                } alt="" style={{
                    position: 'absolute',
                    width: '5px',
                    height: '20.49px',
                    left: '103.31px',
                    top: '17.39px',
                    opacity: '0.92',
                    /* Main / Orange */

                    border: '1.12539px solid #EF8B6E',
                    background: '#EF8B6E',
                    transform: 'matrix(0.37, -0.93, 0.93, 0.37, 0, 0)',
                }} />
                <img src={
                    Vector16
                } alt="" style={{
                    position: 'absolute',
                    width: '11.21px',
                    height: '16.97px',
                    left: '272.24px',
                    top: '7.91px',

                    opacity: '1',
                    /* Main/Purple */

                    // border: '3.37617px solid #9747FF',
                    transform: 'matrix(2.73, -0.32, 1.32, 1.73, 0, 0)',

                }} />
                <img src={
                    Vector21
                } alt="" style={{
                    position: 'absolute',
                    width: '7.54px',
                    height: '11.41px',
                    left: '12.74px',
                    top: '65.61px',

                    opacity: '1',
                    /* Main/Purple */

                    // border: '3.37617px solid #9747FF',
                    transform: 'matrix(0.47, -0.88, 0.88, 0.47, 0, 0)',

                }} />
                <img src={

                    WinningCel} alt="" style={{
                        position: 'absolute',
                        width: '24px',
                        height: '24px',
                        left: '62.99px',
                        top: '47.72px',

                        opacity: '1',
                        /* Main/Purple */

                        // border: '3.37617px solid #9747FF',
                        transform: 'matrix(0.38, -0.93, 0.93, 0.38, 0, 0)',

                    }} />
                <img src={

                    WinningCel} alt="" style={{
                        position: 'absolute',
                        width: '38px',
                        height: '38px',
                        left: '257.07px',
                        top: '23.09px',

                        opacity: '1',
                        /* Main/Purple */

                        // border: '3.37617px solid #9747FF',
                        transform: 'rotate(-1.42deg)',

                    }} />
                <Typography
                    variant="caption"
                    component="div"
                    className={classes.premiumText}
                >
                    Early Access
                </Typography>
                <Typography
                    variant="caption"
                    component="div"
                    className={classes.premiumScientific}
                >
                    {window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery"}
                </Typography>
                {/* <Typography
                    variant="caption"
                    component="div"
                    className={classes.premiumScientific2}
                >
                    30-day free pass
                </Typography> */}
                {/* <Typography
                    variant="caption"
                    component="div"
                    className={classes.premium30DayFree}
                  >
                    30-day free pass
                  </Typography> */}
            </div>
            <div>

                <div className={classes.invitePopupInfo}>
                    <div className={classes.closeSection} >
                        <div
                            className={classes.closeIcon}
                            onClick={() => {
                                openCloseInviteModal(false);
                            }}
                        >
                            x
                        </div>
                    </div>
                    {clickedmail ? (
                        <div className={classes.dataContent}>
                            <Typography
                                variant="caption"
                                component="div"
                                className={classes.dataContentTitle}
                            >
                                Send invitation
                            </Typography>
                            <div className={classes.dataContentStepper}>
                                <TextField
                                    size="small"
                                    name="habitName"
                                    className={classes.goalActionInput}
                                    error={!!errors.email}
                                    helperText={errors.email && errors.email}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "1px solid #f3f3f3",
                                            borderRadius: "16px",
                                        },
                                        " & .MuiOutlinedInput-input": {
                                            color: " #190d1a",
                                            padding: "16px 10px",
                                            background: '#f9f9f9',
                                            borderRadius: "16px",
                                            '&::placeholder': {
                                                color: '#757575'
                                            }
                                        },
                                        " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                                            { border: "1px solid #9747FF" },
                                    }}
                                    InputProps={{
                                        sx: {
                                            "&:hover": {
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #e5d5fc",
                                                },
                                            },
                                        },
                                    }}
                                    fullWidth
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    type="text"
                                    placeholder="Type Email"
                                    id="outlined-start-adornment"
                                />
                                <MultiLineInput
                                    sx={{
                                        maxWidth: "528px !important",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "1px solid #f3f3f3",
                                            borderRadius: "16px",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            padding: 0
                                        },
                                        " & .MuiOutlinedInput-input": {
                                            color: " #190d1a",
                                            padding: "16px 10px",
                                            background: '#f9f9f9',
                                            borderRadius: "16px",
                                            '&::placeholder': {
                                                color: '#757575'
                                            }
                                        },
                                        " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                                            { border: "1px solid #9747FF" },
                                    }}
                                    //   value={progress || goalDetail.progress}
                                    //   onChange={onchangeHandler}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                    InputProps={{
                                        sx: {
                                            "&:hover": {
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #e5d5fc",
                                                },
                                            },
                                            '&::placeholder': {
                                                color: 'red'
                                            }
                                        },
                                    }}
                                    multiline
                                    size="small"
                                    fullWidth
                                    key="pogress"
                                    type="text"
                                    placeholder="Type in your custom note for who you are inviting"
                                />
                                <div style={{ width: "100%", bottom: "0px" }}>
                                    <Button
                                        className={classes.inviteButton}
                                        variant={loadingButton ? "outlined" : "contained"}
                                        onClick={() => {
                                            sendInvite({
                                                "user_email": email,
                                                "customNote": message,
                                                uid: localStorage.getItem("userDetail"),
                                            })
                                            // onNext();
                                            // questionCategoryid === ""
                                            //     ? getGoalDetail(
                                            //         "questionCategoryId",
                                            //         goalDetail?.questionCategoryId
                                            //     )
                                            //     : getGoalDetail("questionCategoryId", questionCategoryid);
                                        }}
                                        disabled={
                                            !email || !isValidEmail || !(message && message.trim())
                                        }
                                    >
                                        {loadingButton ? <CircularProgress /> : 'Invite'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={classes.dataContent}>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    className={classes.dataContentTitle}
                                >
                                    How does it work?
                                </Typography>
                                <div className={classes.dataContentStepper}>
                                    <Connecter style={{ position: 'absolute', zIndex: 1, marginTop: '10px' }} />
                                    {[{
                                        step: 1,
                                        title: 'Refer a friend',
                                        description: `Send invitation to your friends via email  `
                                    }, {
                                        step: 2,
                                        title: `They join ${window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery"}`,
                                        description: `Your friends need to sign up and complete onboarding `
                                    }].map(it => {
                                        return <div key={it} className={classes.stepperItem}>
                                            <div className={classes.stepperCounter}>{<Typography
                                                variant="caption"
                                                component="div"
                                                className={classes.stepperCounterCount}
                                            >
                                                {it.step}
                                            </Typography>}</div>
                                            <div className={classes.stepperContebnt}>
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    className={classes.stepperContebntTitle}
                                                >
                                                    {it.title}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    className={classes.stepperContebntTitle1}
                                                >
                                                    {it.description}
                                                </Typography>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className={classes.inviteBlock}>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    className={classes.inviteBlockTitle}
                                >
                                    Choose how to invite
                                </Typography>
                                <div className={classes.CTAButtonGroup}>
                                    {[{
                                        title: 'Mail',
                                        icon: <Mail />
                                    },/* {
                        title: 'Copy Link'
                    }, */].map(it => <div className={classes.CTAButton} onClick={() => setClickedmail(true)}>
                                        <div className={classes.CTAIcon}>{it.icon}</div>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            className={classes.CTAText}
                                        >
                                            {it.title}
                                        </Typography>
                                    </div>)}
                                </div>

                            </div>
                        </>
                    )
                    }

                </div >
            </div>

        </Dialog >
    )
}

export default InviteReferModal