import { Fragment, createRef, useEffect, useState } from "react"
import { LoadingButton } from "@mui/lab";
import classes from "./plaanModal.module.css"
import { GeneralSX } from "../../../components/Text/Text"
import { Avatar, Button, Chip, Typography, TextField, Dialog, DialogTitle, DialogActions, } from "@mui/material"
import palette from "../../../theme/palette"
import { LeftIconModal } from "../plans.styles"
import { useDispatch, useSelector } from "react-redux"
import { setStoreData, updateJournalOfPlanAction, updateStatusOfAction } from "../../../Store/Reducers/plans"
import XP from "../../../assets/Svg/XP.svg";
import JournalEntryModal from "./JournalEntryModal"
import PlansServices from "../../../services/Plans";
import { updateUserCions } from "../../../Store/Reducers/userProfileSlice";

const Step3JournalEntry = ({ setCustomNotification, setNotificationMessage, setShowThumbIcon }) => {
    const dispatch = useDispatch()
    const [savingJournal, setSavingJournal] = useState(false)
    const {  userData = {} } = useSelector(
        (state) => state.userProfile
      );
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [value, setInputValue] = useState("");
    const textInput = createRef(null);
    const { setp, selectedAction, selectedJournalAction } = useSelector((state) => state.planData);
    console.log("selectedAction", selectedAction)
    useEffect(() => {
        if (selectedJournalAction?.journal) {
            setInputValue(selectedJournalAction?.journal);
        }
        setTimeout(() => {
            console.log("textInput", textInput.current?.focus);
            if (textInput.current?.focus) {
                textInput.current?.focus();
                textInput.current?.scrollIntoView();
            }
        }, 1000);

        return () => setInputValue("");
    }, [selectedJournalAction]);
    const handleCloseModal = () => {
        setOpenConfirmation(false)
        if (textInput.current?.focus) {
            textInput.current?.focus();
            textInput.current?.scrollIntoView();
        }
    }
    const handleYes = () => {
        dispatch(setStoreData({
            visibleJournalModal: false,
            setp: setp - 1
        }));
    }
    const addUpdateJournalData = () => {
        setSavingJournal(true)
        PlansServices.updateJournal({
            "userPlanActionId": selectedJournalAction.userPlanActionId,
            "journal": value
        }).then(() => {
            dispatch(updateJournalOfPlanAction({
                value
            }))
            setShowThumbIcon(true)
            setNotificationMessage("Saved")
            setCustomNotification(true)
            let createActionData;
            if (selectedJournalAction.status === "IN_PROGRESS") {
                createActionData = {
                    uid: localStorage.getItem("userDetail"),
                    userPlanActionId: selectedJournalAction.userPlanActionId,
                    status: "COMPLETE"
                };
            }
            if (Object.keys(createActionData).length > 0) {
                PlansServices.updateActionStatus(createActionData)
                    .then((res) => {
                        dispatch(updateStatusOfAction(createActionData))
                        console.log("res = ", res);
                        const updatedXp =  Number(userData.xp || 0) + Number(selectedJournalAction.xp || 0)
                    dispatch(updateUserCions(updatedXp))
                    })
                    .catch((err) => {
                        console.log("err = ", err);
                    });
            }
            if (selectedAction?.actions && selectedAction?.actions?.length) {
                const withoutJournalActions = [...selectedAction?.actions].filter(it => it.status === "IN_PROGRESS")
                console.log("withoutJournalActions", withoutJournalActions)
                if (setp === 2 && !withoutJournalActions.length) {
                    dispatch(setStoreData({
                        setp: 99
                    }));
                }
            } else {
                dispatch(setStoreData({
                    visibleJournalModal: false,
                    setp: setp - 1
                }));
            }
           
        }).catch((err) => {
            console.log("err", err)
        }).finally(() => {
            setSavingJournal(false)
        })
    }
    console.log("selectedJournalAction", selectedJournalAction)
    return <Fragment>
        <div className={classes.modalTitle}>

            <LeftIconModal onClick={() => {
                if (value && selectedJournalAction.journal !== value) {
                    setOpenConfirmation(true)
                } else {
                    dispatch(setStoreData({
                        setp: setp - 1
                    }));
                }

            }} />
            <Typography sx={{
                ...GeneralSX({
                    color: palette.common.mainBlack,
                    fontSize: 24,
                    fontWeight: 600,
                    lineHeight: "100%",
                    letterSpacing: '-0.24px'
                })
            }}> {selectedJournalAction?.actionName || selectedJournalAction?.habitName || ''}</Typography>
        </div>
        <TextField
            id="standard-multiline-static"
            multiline
            InputProps={{
                disableUnderline: true,
                style: {
                    fontWeight: 400,
                    fontSize: "16px",
                    letterSpacing: "-0.01em",
                    width: '100%',
                    color: "#190D1A",
                },
            }}
            sx={{
                width: '100%',
            }}
            onChange={(e) => {
                setInputValue(e.target.value);
            }}
            value={value}
            autoFocus={true}
            minRows={19}
            ref={textInput}
            maxRows={19}
            defaultValue="Default Value"
            variant="standard"
        />
        <div className={classes.modalButtonDiv}>
            <LoadingButton
                loading={savingJournal}
                variant="contained"
                disabled={value.length > 0 ? false : true}
                className={classes.modalButton}
                onClick={(e) => {
                    addUpdateJournalData();
                }}
            >
                <Typography sx={{
                    ...GeneralSX({
                        color: palette.common.white,
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "100%",
                        letterSpacing: '-0.16px'
                    })
                }}> Save response</Typography>

            </LoadingButton>
        </div>
        {/* <JournalEntryModal /> */}
        <AlerModal open={openConfirmation} handleClose={handleCloseModal} handleYes={handleYes} />
    </Fragment>
}

export default Step3JournalEntry

const AlerModal = ({ open, handleClose, handleYes, }) => {
    return <Dialog
        open={open}
        sx={{
            '& .MuiPaper-root': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '40px',
                gap: '20px',
                isolation: 'isolate',

                /* Main/White */

                background: '#FFFFFF',
                borderRadius: '24px',
            }
        }}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title" sx={{ marginBottom: 0 }}>
            <Typography sx={{ fontSize: '1.5rem!important', fontWeight: 600, color: '#190D1A', textAlign: 'center', marginBottom: 0 }}>
                {"Are you want to exit without saving? Data will be lost."}

            </Typography>
        </DialogTitle>
        <DialogActions sx={{ padding: "10px 24px", width: '100%' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: '0px',
                gap: '12px',
                width: '100%',
                justifyContent: 'space-between',
            }}>
                <Button
                    disableRipple
                    disableFocusRipple
                    onClick={handleYes}
                    variant={"outlined"}
                    size="small"
                    sx={{
                        padding: '16px', width: '100%', borderRadius: '8px', fontSize: '1rem', fontWeight: 400, height: '48px',
                        "&:hover": { backgroundColor: "transparent" }
                    }}
                >
                    {"Yes, go back"}
                </Button>
                <Button
                    disableRipple
                    disableFocusRipple
                    onClick={handleClose}
                    variant="contained"
                    size="small"
                    sx={{ padding: '16px', width: '100%', borderRadius: '8px', fontSize: '1rem', fontWeight: 400, height: '48px', }}
                >
                    {"No, let me save"}
                </Button>
            </div>

        </DialogActions>
    </Dialog>
}