import { Box, Button, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import React, { useState } from "react";
import typography from "../../../theme/typography";
import { CustomShedualButton } from "../../GoalComponent/GoalButton";
import { CardContent, MainCard } from "../../GoalComponent/GoalDialog";
import classes from "./ActionDialog.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import { v4 as uuidv4 } from "uuid";
import { XPLevels } from "../../../utils";
import { GeneralSX } from "../../Text/Text";
import { XPIcon } from "../../XPIcon";

function ActionDialog({ goalName, addMoreActionHandler, setIsActionOPen, imageCompo }) {
  const [actionState, setActionState] = useState([
    {
      actionName: "",
      actionDetail: "",
      active: "true",
      scheduled_type: "DEFAULT",
    },
  ]);
  console.log("actionState", actionState);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...actionState];
    tempList[index][name] = value;
    setActionState(tempList);
  };
  const handleChangeAction = (value, index) => {
    const tempList = [...actionState];
    tempList[index]["level"] = value;
    setActionState(tempList);
  }
  const addMoreHandler = () => {
    if (
      actionState[actionState.length - 1].actionName !== "" &&
      actionState[actionState.length - 1].actionDetail !== ""
    ) {
      setActionState([
        ...actionState,
        {
          // actionId: uuidv4(),
          actionName: "",
          actionDetail: "",
          active: "true",
          scheduled_type: "DEFAULT",
        },
      ]);
    }
  };
  console.log("actionState = ", actionState);
  return (
    <MainCard
      sx={{
        minWidth: 250,
        borderRadius: "40px",
        marginInline: "auto",
        height: "530px !important",
      }}
    >
      <CardContent>
        <div className={classes.StepFiveWrapper}>
          <div className={classes.StepFiveSubWrapper}>
            {imageCompo ? imageCompo : <img src={Hedonism} alt="" />}
            <Typography
              variant="h5"
              sx={{ textAlign: "center", margin: "16px 0px" }}
            >
              Add new action to
              <span style={{ color: "#9747FF" }}> {goalName}</span>
            </Typography>
            {actionState?.map((item, i) => {
              return (
                <div style={{ width: "100%", marginTop: "10px" }}>
                  <Typography variant="subtitle1" sx={{ marginTop: i ? '10px' : '0' }}>Give it a name</Typography>
                  <TextField
                    size="small"
                    className={classes.goalActionInput}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    name="actionName"
                    value={item.actionName}
                    onChange={(e) => handleInputChange(e, i)}
                    type="text"
                    placeholder="Text"
                    id="outlined-start-adornment"
                  />
                  <Typography variant="subtitle1" sx={{ marginTop: i ? '10px' : '0' }}>
                    Tell us why this action
                  </Typography>

                  <TextField
                    size="small"
                    className={classes.goalActionInput}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    name="actionDetail"
                    value={item.actionDetail}
                    onChange={(e) => handleInputChange(e, i)}
                    type="text"
                    placeholder="Text"
                    id="outlined-start-adornment"
                  />
                  <Typography variant="subtitle1" sx={{ marginTop: i ? '10px' : '0', marginBottom: '10px' }}>
                    Select Difficulty
                  </Typography>
                  <div className={classes.customActionLevelGroup} style={{
                    marginBottom: "12px",
                  }}>
                    <ToggleButtonGroup
                      color="primary"
                      value={item.level}
                      className={classes.customActionLevelGroup}
                      exclusive
                      onChange={(e, val) => {
                        handleChangeAction(val, i)
                      }}
                      size="small"
                      aria-label="Platform"
                    >
                      {XPLevels.map(it => (
                        <ToggleButton value={it.level} className={item.level === it.level ? classes.selectedtoggleButton : classes.toggleButton}> {
                          item.level === it.level ? <>
                            <Box sx={{
                              display: 'flex',
                              paddingRight: '8px',
                              alignItems: 'center',
                              gap: '6px',
                              borderRight: '1px solid #F3F3F3',
                            }}>
                              {it.icon} <span className="toggle-text">{it.level}</span>
                            </Box>
                            <Box sx={{
                              display: 'flex',
                              paddingLeft: '8px',
                              alignItems: 'center',
                              gap: '4px',
                            }}>
                              <span className="toggle-text">{it.xp}</span>
                              <XPIcon />

                            </Box>
                          </> : <>
                            {it.icon} <span className="toggle-text">{it.level}</span>
                          </>
                        }</ToggleButton>

                      ))}
                    </ToggleButtonGroup>
                  </div>

                </div>
              );
            })}
            {/* <div style={{ width: "100%", marginBottom: "12px" }}>
              <CustomShedualButton
                style={{ maxWidth: "91px" }}
                onClick={() => {
                  addMoreHandler();
                }}
              >
                Add more +
              </CustomShedualButton>
            </div> */}
            <div style={{ width: "100%", position: "absolute", bottom: "0px" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.buttonWrapper}
                rowSpacing="12px"
                columnSpacing="12px"
              >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Button
                    className={classes.previousButton}
                    sx={{ ...typography.subtitle1 }}
                    variant="outlined"
                    onClick={() => {
                      setIsActionOPen(false);
                    }}
                  // disabled={isPreviousBtnDisable}
                  >
                    Discard
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
                >
                  <Button
                    className={classes.nextButton}
                    sx={{ ...typography.subtitle1 }}
                    variant="outlined"
                    onClick={() => {
                      addMoreActionHandler(actionState);
                      setIsActionOPen(false);
                    }}
                    disabled={
                      actionState[actionState.length - 1].actionName === "" ||
                      actionState[actionState.length - 1].actionDetail === "" ||
                      !actionState[actionState.length - 1].level
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </CardContent>
    </MainCard>
  );
}
export default ActionDialog;
