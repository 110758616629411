import styled from "@emotion/styled";
import { Box, Dialog, Popover, Select, Snackbar, Tooltip } from "@mui/material";

export const AddMoreDialog = styled(Dialog)`
  & .MuiDialog-container {
    & .MuiPaper-root {
      width: 100%;
      max-width: 300px;
      padding: "16px";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
export const MainCard = styled(Box)(({ theme }) => ({
  background: "#fff",
  width: "640px",
  height: "740px",

  [theme.breakpoints.up("xl")]: {
    width: "568px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "568px",
  },
  [theme.breakpoints.down("md")]: {
    width: "568px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 6rem)",
    width: "100%",
  },
}));
export const CardContent = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "40px 40px",
  // gap: '16px',
  // position: "relative",
  [theme.breakpoints.up("xl")]: {
    padding: "40px 40px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "40px 40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    with: "100%",
    padding: "24px 12px",
  },
}));

// custom selection
export const CustomSelection = styled(Select)`
  width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
export const EditRemoveSnackbar = styled(Snackbar)`
  position: absolute;
  right: 20px !important;
  top: 0px !important;
  // min-width: 96px;
  // width: 96px;
  // height: 60px;
  box-shadow: 0px 0px 15px rgba(25, 13, 26, 0.05);
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px !important;
    min-width: auto;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
  }
`;
export const SelfTextSnackbar = styled(Snackbar)`
  position: absolute;
  right: 42px !important;
  top: 0px !important;
  min-width: 244px;
  width: 244px;
  height: 40px;
  box-shadow: 0px 0px 15px rgba(25, 13, 26, 0.05);
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px !important;
    min-width: 244px;
    // height: 40px;
    padding-left: 0px !important;
    padding: 0px !important;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
    // height: 40px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
    // height: 40px;
  }
`;
export const CustomSnackbar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 16px !important;
    width: 300px !important;
    min-width: 300px;
    padding: 16px !important;
    padding-left: 0px !important;
    height: 147px;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    // padding: 0px;
  }
`;
export const DuplicateSnackbar = styled(Snackbar)`
  position: absolute;
  right: 42px !important;
  top: 0px !important;
  min-width: 96px;
  width: 96px;
  height: 38px;
  box-shadow: 0px 0px 15px rgba(25, 13, 26, 0.05);
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px !important;
    min-width: 96px;
    padding-left: 0px !important;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
  }
`;

export const EditActionSnakebar = styled(Snackbar)`
  position: absolute;
  left: -100px !important;
  top: 30px !important;
  z-index: 16;
  min-width: 244px;
  width: 244px;
  min-height: 200px;
  border-radius: 12px !important;
  box-shadow: 0px 0px 15px rgba(25, 13, 26, 0.05);
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px !important;
    min-width: 244px;
    padding-left: 0px !important;
  }
  & .notification {
    & .MuiSnackbarContent-root {
      background: rgb(50, 50, 50);
      padding: 6px 16px;
      justify-content: space-between;
      padding-left: 16px!important;
    }
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
  }
`;
export const DropGrowthSnakebar = styled(Snackbar)`
  left: 53% !important;
  top: 0% !important;
  min-width: 244px;
  width: 244px;
  overflow: auto;
  border-radius: 12px !important;
  flex: none;
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px !important;
    min-width: 244px;
    padding-left: 0px !important;
  }
  & .MuiSnackbar-root {
    flex: none !important;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
  }
`;
export const CalanderDialog = styled(Dialog)`
  & .MuiDialog-container {
    & .MuiPaper-root {
      min-width: 343px;
      min-height: 450px;
      border-radius: 12px;
    }
  }
  & .MuiDialog-container {
    justify-content: center !important;
    align-items: center !important;
  }
`;
export const CalanderActionDialog = styled(Dialog)`
  & .MuiDialog-container {
    & .MuiPaper-root {
      min-width: 384px;
      min-height: 242px;
      border-radius: 12px;
    }
  }
  & .MuiDialog-container {
    justify-content: center !important;
    align-items: center !important;
  }
`;
export const CalanderHabitDialog = styled(Dialog)`
  & .MuiDialog-container {
    flex: none;
    
    & .MuiPaper-root {
      ${'' /* min-width: 384px; */}
      ${'' /* min-height: 450px; */}
      border-radius: 12px;
    }
  }
  & .MuiDialog-container {
    flex: none;
  }
`;

export const CompletedAcrchiveGoalDialog = styled(Dialog)`
  & .MuiDialog-container {
    & .MuiPaper-root {
      max-width: 456px;
      border-radius: 16px;
      position: unset;
    }
  }
`;

export const MobileSiderBar = styled(Snackbar)`
  left: 0%;
  top: 9%;
  min-width: 100%;
  width: 244px;
  height: 100vh !important;
  ${"" /* overflow: auto; */}
  border-radius: 12px;
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px;
    min-width: 244px;
    padding-left: 0px;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
  }
  & .MuiPaper-root {
    padding: 0px !important;
  }
`;

export const DropActionSnakebar = styled(Snackbar)`
  left: 41% !important;
  top: 0% !important;
  min-width: 244px;
  width: 244px;
  overflow: auto;
  border-radius: 12px !important;
  z-index: 1;
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px !important;
    min-width: 244px;
    padding-left: 0px !important;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
  }
  & .MuiPaper-root {
    background: transparent !important;
  }
  & .MuiSnackbar-root {
  }
`;
export const DropHabitSnakebar = styled(Snackbar)`
  left: 41% !important;
  top: 0% !important;
  min-width: 244px;
  width: 244px;
  overflow: auto;
  border-radius: 12px !important;
  z-index: 1;
  & .MuiSnackbarContent-root {
    background: #ffffff;
    border-radius: 12px !important;
    min-width: 244px;
    padding-left: 0px !important;
  }
  & .MuiSnackbarContent-message {
    padding: 0px;
  }
  & .MuiSnackbarContent-action {
    margin: 0px !important;
    padding: 0px;
  }
  & .MuiPaper-root {
    background: transparent !important;
  }
  & .MuiSnackbar-root {
  }
`;