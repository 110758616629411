import classes from "../Onbording.module.css";
import { Box } from "@mui/system";
import Input from "../../../components/Input/Input";
import Text from "../../../components/Text/Text";
import { PrevAndNextButtonGroup } from "../../../components/Button/Button";
import { Step, StepsWrapper } from "../StyledComponent";
import { useDispatch, useSelector } from "react-redux";
import { updateAge, updateDOB } from "../../../Store/Reducers/onbordingSlice";
import { useState } from "react";
import {
  ageValidation,
  nameValidation,
} from "../../../validations/OnbordingValidation/StepOne";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import { Input as Dateinput } from "../../../components/Settings/StyledElement/StyledformInput";
export default function StepTwo({ onNext, onPrevious }) {
  const { age, dob, name } = useSelector((state) => state.onbording);
  console.log(age, 19999);
  const [datePickerValue, setDatePickerValue] = useState(dob);
  const [errorMessage, setErrorMessage] = useState("");
  const [ageValue, setAgeValue] = useState(age);
  const [dateValue, setDateValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const onNextStep = () => {
    if (!dateValue) {
      setErrorMessage("Age is required");
      return;
    }
    // dispatch(updateAge({ ageValue, dob: datePickerValue }));
    dispatch(updateDOB(dateValue));
    onNext();
  };
  console.log("isOpen", isOpen)
  const handleChangeInput = (e) => {
      setIsOpen(true);
  };

  const setDateValueFinal = (val) => {
    setIsOpen(false);
    setDateValue(val)
  }
  console.log(dateValue, moment.utc(new Date(dateValue)).format("DD/MM/yyyy"));

  return (
    <>
      <StepsWrapper>
        <Step className={classes.active}></Step>
        <Step ></Step>
        <Step></Step>
        {/* <Step></Step> */}
      </StepsWrapper>
      <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px" }}>
        <Text textValue={`${name}, it’s nice to meet you!`} variant="h2" />
      </Box>
      <Box className={classes.wrapper}>
        <Text textValue="What’s your age?" variant="subtitle1" />
      </Box>
      <Box className={classes.wrapper}>
        <Text textValue="We use this to calibrate your results." variant="subtitle1" color="#A59EA6"/>
      </Box>
      <Box className={classes.wrapper} onClick={handleChangeInput}>
        {/* <Input
          placeholder="Age"
          value={ageValue}
          onChange={setAgeValue}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          validationFunction={ageValidation}
          type="number"
        /> */}
        <Dateinput
          name={"dob"}
          placeholder="dd/mm/yyyy"
          value={dateValue ? moment.utc(new Date(dateValue)).format("DD/MM/yyyy") : undefined}
          onChange={handleChangeInput}
          type={"date"}
          
        />
      </Box>
      {/* <Box className={classes.wrapper}>
        <Input
          placeholder="Birthdate"
          type="date"
          value={datePickerValue}
          onChange={setDatePickerValue}
          errorMessage={""}
          setErrorMessage={()=>{}}
          validationFunction={ageValidation}
        />
      </Box> */}
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "60%",
            background: "white",
            left: "20%",
            zIndex: 10
          }}
        >
          <DatePicker
            currentDate={new Date(dateValue)}
            setCurrentDate={setDateValueFinal}
          />
        </div>
      )}
      <Box sx={{ width: "100%" }}>
        <PrevAndNextButtonGroup
          // isPreviousButton={true}
          onNext={onNextStep}
          // onPrevious={onPrevious}
          // isDisabled={!ageValue || !datePickerValue || errorMessage}
          isDisabled={!dateValue || errorMessage}
        />
      </Box>
    </>
  );
}
