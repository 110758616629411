import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    TextField,
} from "@mui/material";
import { setJournalStoreData } from "../../../Store/Reducers/journals";
import classes from "./../journals.module.css";
import CloseButton from "../../../components/CloseButton";
import PlansServices from "../../../services/Plans";
import DashboardService from "../../../services/Dashboard";
import moment from "moment";
import useCustomNotification from "../../../Hooks/useCustomNotification";
const JournalEntry = (props) => {
    const closeModal = () => {
        dispatch(setJournalStoreData({
            clickedButtonObject: {},
            visibleModal: false
        }))
    }
    const { NotificationContentJSX, customNotification, setCustomNotification } = useCustomNotification()
    const dispatch = useDispatch()
    const [value, setInputValue] = useState("");
    const [savingJournal, setSavingJournal] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [showThumbIcon, setShowThumbIcon] = useState(true)
    const textInput = createRef(null);
    const { visibleModal, clickedButtonObject, selectedSearchJournal } = useSelector((state) => state.journalData);
    useEffect(() => {
        if (clickedButtonObject?.editItem && clickedButtonObject?.editItem?.journal) {
            setInputValue(clickedButtonObject?.editItem?.journal);
        }
        setTimeout(() => {
            console.log("textInput", textInput.current?.focus);
            if (textInput.current?.focus) {
                textInput.current?.focus();
                textInput.current?.scrollIntoView();
            }
        }, 1000);

        return () => setInputValue("");
    }, [visibleModal]);
    console.log("selectedSearchJournal", selectedSearchJournal)
    console.log("clickedButtonObject", clickedButtonObject)
    const addUpdateJournalData = () => {
        setSavingJournal(true)
        if (clickedButtonObject?.planId) {
            PlansServices.updateJournal({
                "userPlanActionId": clickedButtonObject?.editItem.userPlanActionId,
                "journal": value
            }).then(() => {

                setShowThumbIcon(true)
                setNotificationMessage("Saved")
                setCustomNotification(true)
                props?.getJournalData(moment().subtract(3, 'year').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"), selectedSearchJournal?.questionCategoryId)

            }).finally(() => {
                setSavingJournal(false)
            })
        } else {
            const user = localStorage.getItem("userDetail");
            DashboardService.UPDATE_JOURNAL({
                uid: user,
                id: clickedButtonObject?.editItem?.id,
                type: clickedButtonObject?.editItem.habitId
                    ? "HABIT"
                    : clickedButtonObject?.editItem.actionId
                        ? "ACTION"
                        : null,
                journal: value,
            })
                .then((res) => {
                    setShowThumbIcon(true)
                    setNotificationMessage("Saved")
                    setCustomNotification(true)
                    props?.getJournalData(moment().subtract(3, 'year').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"), selectedSearchJournal?.questionCategoryId)
                }).finally(() => {
                    setSavingJournal(false)
                })
        }
        console.log("value", value)
    }
    console.log("clickedButtonObject", clickedButtonObject)
    return <> <Dialog
        open={visibleModal}
        onClose={closeModal}
        sx={{
            "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "20px",
                // position: "unset",
                padding: "20px",
                position: 'relative'
            },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 'calc(100% - 20px)' }}>
            <div className={classes.modalTitle}>
                {clickedButtonObject?.editItem?.actionName || clickedButtonObject?.editItem?.habitName || ''}
            </div>
        </div>
        <div
            className={classes.closeIcon}
            onClick={closeModal}
        >
            <CloseButton />
        </div>
        <TextField
            id="standard-multiline-static"
            multiline
            InputProps={{
                disableUnderline: true,
                style: {
                    fontWeight: 400,
                    fontSize: "16px",
                    letterSpacing: "-0.01em",

                    color: "#190D1A",
                },
            }}
            onChange={(e) => {
                setInputValue(e.target.value);
            }}
            value={value}
            autoFocus={true}
            minRows={20}
            ref={textInput}
            maxRows={20}
            defaultValue="Default Value"
            variant="standard"
        />
        <div className={classes.modalButtonDiv}>
            <LoadingButton
                loading={savingJournal}
                variant="contained"
                disabled={value.length > 0 ? false : true}
                className={classes.modalButton}
                onClick={addUpdateJournalData}
            >
                Save response
            </LoadingButton>
        </div>
    </Dialog>
        {customNotification && <NotificationContentJSX message={notificationMessage} isThumbIcon={showThumbIcon} position="top" customStyle={{
            zIndex: 10000
        }} />}
    </>
}

export default JournalEntry