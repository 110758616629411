import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./StepFour.module.css";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import styled from "@emotion/styled";
import {
  CheckBoxInputCheckedGoal,
  CheckBoxInputCheckedGoalNew,
  CheckBoxInputGoal,
  CheckBoxInputGoalNew,
  FavouriteMultilineCheckbox,
} from "../../../components/Input/Input";
import { ReactComponent as Sparkles } from "../../../assets/Svg/Sparkles.svg";
import typography, { pxToRem } from "../../../theme/typography";
import { goalGetPreSelectedGrowth } from "../../../services/auth";
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { CustomShedualButton } from "../../../components/GoalComponent/GoalButton";
import { imageURLRender } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
import HTMLTooltip from "../../../components/ToolTipWithIcon/HTMLTooltip";
import { TeriteryButton, TeriteryButtonChip, TeriteryButtonContained } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import GoalFavouriteModal from "../../GoalListPage/GoalFavouriteModal";
function StepFour({ onNext, onPrevious, getGoalDetail, goalDetail, handleClose, selectedDiamenstion, selectedDiamenstionImage }) {
  const navigate = useNavigate()
  const getGoalSuggested = () => {
    let user = localStorage.getItem("userDetail");
    let questionCategoryId = goalDetail?.questionCategoryId;
    goalGetPreSelectedGrowth(user, questionCategoryId)
      .then((res) => {
        setgoalGrowthArea(res?.data);
        fill();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisGoalGrowth(true);
      });
  }
  useEffect(() => {

    if (!goalGrowthArea?.length) {
      getGoalSuggested()
    }
  }, []);
  const [goalGrowthArea, setgoalGrowthArea] = useState([]);
  const [growthVisible, setGrowthVisible] = useState(false)
  const [isGoalGrowth, setisGoalGrowth] = useState(false);
  const [preselectedState, setPreselectedState] = useState([]);
  const [newInputList, setNewInputList] = useState([
  ]);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...newInputList];
    // console.log("tempList==>", tempList);
    tempList[index][name] = value;
    setNewInputList(tempList);
  };
  const handleAddMore = () => {
    const isText = newInputList[newInputList.length - 1]?.text;
    console.log(isText);
    if (isText) {
      setNewInputList([...newInputList, { text: "", type: "SELF" }]);
    }
  };

  const addFirstInput = () => setNewInputList([{ text: "", type: "SELF", id: 1, saved: false }]);

  // function
  const growthArea = goalDetail?.preSelectedGrowth?.filter(
    (data) => data.type == "SUGGESTED"
  );
  const selfgrowthAreas = goalDetail?.preSelectedGrowth?.filter(
    (data) => data.type == "SELF"
  );
  const fill = () => {
    if (growthArea) {
      setPreselectedState(growthArea);
      setNewInputList(selfgrowthAreas);
    }
  };
  const growthAreaHandler = (index, item) => {
    const findData = preselectedState.find((o) => o.index === index);
    if (findData) {
      setPreselectedState((current) =>
        current.filter((data) => data.index !== index)
      );
    } else {
      setPreselectedState([...preselectedState, { index: index, text: item }]);
    }
  };
  const updateMainState = () => {
    let tempArr = [];
    preselectedState?.forEach((data) => {
      tempArr.push({ text: data.text, type: "SUGGESTED", index: data.index });
    });
    newInputList?.forEach((data) => {
      tempArr.push({ text: data.text, type: "SELF" });
    });
    getGoalDetail("preSelectedGrowth", tempArr);
  };
  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    border-radius: 16px;
    background: #ffffff;

    & .MuiTextField-root {
      width: 100%;
      :hover {
      }
    }
    &.MuiCheckbox-root {
      :hover {
        border: 1px solid #9747FF;
        background: #FFF;
      }
    }
  `;
  const savedAtIndex = (i) => {
    const newInputs = [...newInputList];
    newInputs[i].saved = true
    setNewInputList(newInputs)
  }
  const removeAtIndex = (i) => {
    const newInputs = [...newInputList];
    newInputs.splice(i, 1)
    setNewInputList(newInputs)
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} skipButton={<Typography variant="h5" sx={{
        textAlign: "right", fontSize: '0.75rem !important', position: 'absolute',
        left: '20px', cursor: 'pointer'
      }} onClick={() => {
        updateMainState();
        onNext();
      }}>
        <span style={{ color: "#9747FF" }}>{"Skip Growth Area"}</span>{" "}
      </Typography>} />
      <div className={classes.StepThreeWrapper}>
        {" "}
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step></Step>
          <Step></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Select growth areas{" "}
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Add actions
          </Typography>
          <Typography variant="body3" className={classes.progressTittle}>
            Set habits
          </Typography>
        </div>
        <div className={classes.StepThreeSubWrapper}>

          {isGoalGrowth && <div>
            <img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" />
          </div>}


          <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            {/* <Typography
              variant="body4"
              sx={{ margin: "16px 0px", textAlign: "center", justifySelf: "end" }}
            >
              Choose preselected growth area or type in your own
            </Typography> */}
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Add growth area to{" "}
              <span style={{ color: "#9747FF" }}>
                {" "}
                {selectedDiamenstion}
              </span>{" "}
            </Typography>
            <div style={{ position: "absolute", right: "0" }}>
              <HTMLTooltip
                title={"Growth Areas (GAs) are targeted, action-oriented questions designed to guide you in creating meaningful actions for your personal growth journey."}
                placement="right"
                showArrow={true}
                sx={{
                  '& .MuiTooltip-tooltip': {
                    background: "#FFF",
                    color: "#000",
                    maxWidth: "350px"
                  }
                }}
              />
            </div>

          </div>

          {!isGoalGrowth ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : goalGrowthArea && goalGrowthArea.length ? (
            <Box sx={{
              display: 'flex',
              padding: '16px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              alignSelf: 'stretch',
              borderRadius: '16px',
              background: '#F9F9F9',
              marginTop: '24px'
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '4px',
                alignSelf: 'stretch',
              }}>
                <Sparkles /> <Typography sx={{
                  color: '#9747FF',
                  fontSize: `${pxToRem(14)}!important`,
                  fontWeight: 400,
                  lineHeight: '100%'
                }}>Recommended</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',

              }}>
                {goalGrowthArea?.map((item, index) => {
                  return (
                    <CustomCheckBox
                      key={index}
                      // sx={{ margin: "6px 0px" }}
                      {...label}
                      icon={<CheckBoxInputGoalNew placeholder={item?.point} />}
                      checkedIcon={
                        <CheckBoxInputCheckedGoalNew placeholder={item?.point} />
                      }
                      onChange={() => {
                        growthAreaHandler(index, item?.point);
                      }}
                      checked={preselectedState.find((o) => o.index === index) ? true : false}
                    />
                  );
                })}
              </Box>
              <TeriteryButtonContained onClick={() => setGrowthVisible(true)}>Add more growth areas</TeriteryButtonContained>
            </Box>
          ) : <Box sx={{
            display: 'flex',
            padding: '16px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch',
            borderRadius: '16px',
            background: '#F7F2FE',
            marginTop: '24px'

          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '12px',
              alignSelf: 'stretch',
            }}>
              <div>
                <Sparkles height={'20px'} width={"20px"} />
              </div>
              <Typography sx={{
                color: '#9747FF',
                fontSize: `${pxToRem(16)}!important`,
                fontWeight: 400,
                lineHeight: '150%',
                letterSpacing: '-0.16px'
              }}>You haven’t liked any growth areas for this dimension in the results. Click the ‘open results’, like interesting expert-crafted growth areas, and add them to this goal.</Typography>
            </Box>
            <TeriteryButtonContained onClick={() => setGrowthVisible(true)}>View growth areas</TeriteryButtonContained>
          </Box>}
          {newInputList.length <= 0 && (
            <div style={{ width: "100%", margin: "16px 0" }}>
              <TeriteryButtonChip
                // style={{ maxWidth: "91px" }}
                onClick={addFirstInput}
              >
                Add growth area manually
              </TeriteryButtonChip>
            </div>
          )}
          {newInputList.length > 0 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '24px',
              flexShrink: 0,
              width: '100%',
              marginTop: '24px',
              marginBottom: '24px',
            }}>
              {newInputList?.map((data, i) => {
                return <Box sx={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '16px',
                  alignSelf: 'stretch',
                  borderRadius: '16px',
                  background: '#F9F9F9',
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between'
                  }}>
                    <Typography component="span" sx={{
                      color: "#190D1A",
                      fontSize: `${pxToRem(14)} !important`,
                      fontWeight: 400,
                      lineHeight: '100%'
                    }}>Growth Area #{i + 1}</Typography>
                    <span onClick={() => removeAtIndex(i)} style={{
                      cursor: "pointer"
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M12 4L4 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4 4L12 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </Box>
                  <TextField
                    key={i}
                    size="small"
                    className={classes.goalActionInput}
                    sx={{
                      // marginBottom: "12px",
                      background: '#FFF',
                      borderRadius: '16px',
                      '& .MuiOutlinedInput-root': {
                        paddingTop: '18px',
                        "&.Mui-focused .MuiOutlinedInput-input": {
                          color: '#9747FF'
                        },
                        "&.Mui-focused .MuiInputLabel-root": {
                          color: '#767476'
                        }
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                        top: 0,
                        '& legend': {
                          height: 0,
                          width: 0
                        }
                      },
                      '& label': {
                        color: '#767476',
                        top: '18px',
                        left: '-3px',
                        "&.Mui-focused": {
                          color: '#767476'
                        }
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                        borderRadius: "16px",
                        background: "#fff"
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      // color: '#767476'
                    }}

                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    label="Name growth area"
                    name="text"
                    value={data.text}
                    onChange={(e) => handleInputChange(e, i)}
                    type="text"
                    placeholder="Text"
                    id="outlined-start-adornment"
                  />
                  {i === newInputList.length - 1 && (
                    <TeriteryButtonContained disabled={!data.text} onClick={() => {
                      if (data.saved) {
                        handleAddMore()
                      } else {
                        savedAtIndex(i)
                      }
                    }}>{data.saved ? 'Add more' : 'Save'}</TeriteryButtonContained>
                  )}

                </Box>
              })
              }
            </Box>
          )}
        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0", padding: "0 40px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.buttonWrapper}
            rowSpacing="12px"
            columnSpacing="12px"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={classes.previousButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainState();
                  onPrevious();
                }}
              // disabled={isPreviousBtnDisable}
              >
                Previous
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
            >
              <Button
                className={classes.nextButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainState();
                  onNext();
                }}
                disabled={preselectedState.length === 0 && !selfgrowthAreas && !newInputList[newInputList.length - 1]?.text}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {growthVisible && <GoalFavouriteModal visible={growthVisible} onClose={() => {
        getGoalSuggested()
        setGrowthVisible(false);
      }} questionCategoryId={goalDetail?.questionCategoryId}/>}
    </>

  );
}
export default StepFour;
