import { Box, Typography } from "@mui/material";
import { GeneralSX } from "../Text/Text";

export const XPIcon = () => (
    <Box height={"16px"} bgcolor={"#F7F2FE"} width={"16px"} display={"flex"} padding={"2px"} border={'1px solid #9747ff'} alignItems={"center"} borderRadius={"100%"}>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <circle cx="7" cy="7" r="7" fill="#9747FF" />
      <circle cx="6.99831" cy="7.00017" r="6.06667" fill="#F7F2FE" />
      <path d="M3.79202 4.375L5.03064 6.42834H5.07109L6.30971 4.375H7.21467L5.60193 7L7.22478 9.625H6.31477L5.07109 7.59985H5.03064L3.78696 9.625H2.87695L4.52761 7L2.88706 4.375H3.79202Z" fill="#9747FF" />
      <path d="M7.98944 9.625V4.375H9.83474C10.2375 4.375 10.5712 4.44934 10.8357 4.59802C11.1003 4.7467 11.2983 4.95007 11.4298 5.20813C11.5612 5.46448 11.627 5.7533 11.627 6.07459C11.627 6.39758 11.5604 6.68811 11.4273 6.94617C11.2958 7.20251 11.097 7.40588 10.8307 7.55627C10.5661 7.70496 10.2333 7.7793 9.83221 7.7793H8.56325V7.10767H9.76143C10.0159 7.10767 10.2223 7.06323 10.3807 6.97437C10.5392 6.88379 10.6554 6.76074 10.7296 6.60522C10.8037 6.44971 10.8408 6.27283 10.8408 6.07459C10.8408 5.87634 10.8037 5.70032 10.7296 5.54651C10.6554 5.3927 10.5383 5.27222 10.3782 5.18506C10.2198 5.0979 10.0108 5.05432 9.75132 5.05432H8.77053V9.625H7.98944Z" fill="#9747FF" />
    </svg> */}
        <Typography sx={{
            ...GeneralSX({
                fontSize: 8,
                color: "#9747ff"
            })
        }}>XP</Typography>
    </Box>
)