import { Fragment, useEffect } from "react"
import classes from "./plaanModal.module.css"
import { GeneralSX } from "../../../components/Text/Text"
import { Button, Typography } from "@mui/material"
import palette from "../../../theme/palette"
import { LeftIconModal } from "../plans.styles"
import { useDispatch, useSelector } from "react-redux"
import { setStoreData } from "../../../Store/Reducers/plans"
import PlansServices from "../../../services/Plans"


const Step1Day = ({ setCustomNotification, setNotificationMessage, setShowThumbIcon }) => {
    const dispatch = useDispatch()
    const { userData } = useSelector((state) => state.userProfile);
    const { setp, planActions, selectedPlan } = useSelector((state) => state.planData);
    const setSelectPlan = () => {
        if (selectedPlan.planSelected) {
            const lastOfOpen = [...planActions].filter(it => it.dayOpen)
            dispatch(setStoreData({
                setp: setp + 1,
                selectedAction: lastOfOpen[lastOfOpen.length - 1]
                // selectedPlan: {...selectedPlan, planSelected: true}
            }));
        } else {
            PlansServices.selectPlan({
                "uid": userData?.uid,
                planId: selectedPlan.planId,
            }).then(() => {
                dispatch(setStoreData({
                    // setp: setp + 1,
                    selectedPlan: { ...selectedPlan, planSelected: true }
                }));
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.message) {
                    setShowThumbIcon(false)
                    setNotificationMessage(err.response.data.message)
                    setCustomNotification(true)
                }
                console.log("err", err)
            })
        }

    }

    return <Fragment>
        <div className={classes.modalTitle}>
            <LeftIconModal onClick={() => {
                dispatch(setStoreData({
                    viewPlanModal: false,
                    setp: 1
                }));
            }} />
            <Typography sx={{
                ...GeneralSX({
                    color: palette.common.mainBlack,
                    fontSize: 24,
                    fontWeight: 600,
                    lineHeight: "100%",
                    letterSpacing: '-0.24px'
                })
            }}>{selectedPlan.name}</Typography>
        </div>
        <div className={classes.planModalContent}>
            {planActions.length > 0 && planActions.map((it, index) => {
                if (selectedPlan.planSelected) {
                    // index === planActions.length - 1 && adjustLine(
                    //     document.getElementById(`point${0}`),
                    //     document.getElementById(`point${planActions.length - 1}`) ? document.getElementById(`point${planActions.length - 1}`) : document.getElementById(`point${0}`),
                    //     document.getElementById('line')
                    // )
                    return <div className={classes.planDayData}>
                        <div className={classes.dahedLine} style={index === 0 ? {
                            height: '60%',
                            bottom: -10
                        } : index === planActions.length - 1 ? {
                            height: '60%',
                            top: -10
                        } : {
                            top: -10,
                            height: '112%',
                            bottom: -10
                        }}></div>
                        <div className={classes.frame4437} id={`point${index}`}>
                            <Typography sx={{
                                display: 'flex',
                                ...GeneralSX({
                                    color: palette.common.white,
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: "112%",
                                    letterSpacing: '-0.12px',
                                    extra: {
                                        fontFamily: 'Coffee Mood'
                                    }
                                })
                            }}>{it._id.dayNumber}</Typography>
                        </div>

                        {/* <hr /> */}
                        <div className={classes.frame4491}>
                            <div className={classes.frame4490}>
                                <Typography sx={{
                                    display: 'flex',
                                    ...GeneralSX({
                                        color: palette.common.mainBlack,
                                        fontSize: 18,
                                        fontWeight: 500,
                                        lineHeight: "145%",
                                        letterSpacing: '-0.18px',
                                    })
                                }}> {it.dayOpen ? it.actions[0].actionName : "Unlocks tomorrow"}</Typography>
                                {it.dayOpen && <Typography sx={{
                                    display: 'flex',
                                    ...GeneralSX({
                                        color: palette.common.darkGray,
                                        fontSize: 12,
                                        fontWeight: 500,
                                        lineHeight: "100%",
                                        letterSpacing: '-0.12px',
                                    })
                                }}>{/* it.min */25} min</Typography>}

                            </div>
                            {selectedPlan.planSelected && (
                                <div className={it.dayOpen ? classes.frame4438 : ""}>
                                    {it.dayOpen && it.actions.length - 1 > 0 ? (
                                        <div>
                                            <Typography sx={{
                                                display: 'flex',
                                                whiteSpace: 'nowrap',
                                                ...GeneralSX({
                                                    color: '#767476',
                                                    fontSize: 15,
                                                    fontWeight: 500,
                                                    lineHeight: "100%",
                                                    letterSpacing: '-0.14px',
                                                })
                                            }}>+{it.actions.length - 1}</Typography>
                                        </div>
                                    ) : !it.dayOpen && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9" stroke="#9747FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7324 15C13.9026 14.7058 14 14.3643 14 14C14 12.8954 13.1046 12 12 12C10.8954 12 10 12.8954 10 14C10 14.3643 10.0974 14.7058 10.2676 15C10.0974 15.2942 10 15.6357 10 16C10 17.1046 10.8954 18 12 18C13.1046 18 14 17.1046 14 16C14 15.6357 13.9026 15.2942 13.7324 15ZM13.7324 15C13.3866 14.4022 12.7403 14 12 14C11.2597 14 10.6134 14.4022 10.2676 15C10.6134 15.5978 11.2597 16 12 16C12.7403 16 13.3866 15.5978 13.7324 15Z" fill="#190D1A" />
                                    </svg>}

                                </div>
                            )}
                        </div>

                        { }
                    </div>
                }
                return <div className={classes.planDayData}>
                    <div className={classes.frame4439}>
                        <Typography sx={{
                            display: 'flex',
                            ...GeneralSX({
                                color: palette.common.mainBlack,
                                fontSize: 12,
                                fontWeight: 400,
                                lineHeight: "112%",
                                letterSpacing: '-0.12px',
                                extra: {
                                    fontFamily: 'Coffee Mood'
                                }
                            })
                        }}>Day {it._id.dayNumber}</Typography>
                    </div>
                    <div className={classes.frame4490}>
                        <Typography sx={{
                            display: 'flex',
                            ...GeneralSX({
                                color: palette.common.mainBlack,
                                fontSize: 18,
                                fontWeight: 500,
                                lineHeight: "145%",
                                letterSpacing: '-0.18px',
                            })
                        }}> {it.actions[0].actionName}</Typography>
                        <Typography sx={{
                            display: 'flex',
                            ...GeneralSX({
                                color: palette.common.darkGray,
                                fontSize: 12,
                                fontWeight: 500,
                                lineHeight: "100%",
                                letterSpacing: '-0.12px',
                            })
                        }}>{/* it.min */25} min</Typography>

                    </div>
                </div>
            })}

        </div>
        <Button variant="contained" className={classes.modalBottomButton} onClick={setSelectPlan}>
            <Typography sx={{
                ...GeneralSX({
                    color: palette.common.white,
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "100%",
                    letterSpacing: '-0.16px'
                })
            }}>{selectedPlan.planSelected ? "Continue" : "Select Plan"}</Typography>
        </Button>
    </Fragment>
}

export default Step1Day