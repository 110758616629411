import { Avatar, Box, Button, Checkbox, Typography } from "@mui/material"
import classes from "./planActions.module.css"
import { GeneralSX } from "../../components/Text/Text";
import palette from "../../theme/palette";
import { imageURLRender } from "../../utils";
import XP from "../../assets/Svg/XP.svg";
import { Tag } from "../PlanDetails/PlanModals/Step2PlanInfo";
import { useDispatch, useSelector } from "react-redux";
import PlansServices from "../../services/Plans";
import { updateUserCions } from "../../Store/Reducers/userProfileSlice";
import { setDahData, updateJournalOfPlanActionDashboard, updateStatusOfActionDashboard } from "../../Store/Reducers/dashboard";
import PlanJournalEntryModal from "./Modal/planJournalEntry";
import PlanReadModal from "./Modal/planReadModal";
import clsx from "clsx";
const label = (id) => ({ inputProps: { "aria-label": "Checkbox" + id } });
const PlanActinos = () => {
    const dispatch = useDispatch()
    const {
        userData = {} } = useSelector(state => state.userProfile);
    const dashboardDataSlice = useSelector(state => state.dashboardDataSlice);
    const { userPlanActionsData, openPlanJournalModal, openPlanJournalItem, savingJournal, openPlanJournalModalPlanId, viewPlanReadModal } = useSelector(state => state.dashboardDataSlice);
    const handleIsTrue = (e, action, plan) => {
        e.preventDefault()
        e.stopPropagation()
        console.log("action", action)
        let createActionData;
        if (action.status === "IN_PROGRESS") {
            createActionData = {
                uid: localStorage.getItem("userDetail"),
                userPlanActionId: action.userPlanActionId,
                status: "COMPLETE"
            };
        }
        if (createActionData && Object.keys(createActionData).length > 0) {
            PlansServices.updateActionStatus(createActionData)
                .then((res) => {
                    dispatch(updateStatusOfActionDashboard({
                        ...createActionData,
                        planId: plan._id.planId
                    }))
                    const updatedXp = Number(userData.xp || 0) + Number(action.xp || 0)
                    dispatch(updateUserCions(updatedXp))
                    console.log("dashboardDataSlice.completedXP", dashboardDataSlice.completedXP, action.xp)
                    const updatedXp1 = Number(dashboardDataSlice.completedXP || 0) + Number(action.xp || 0)
                    dispatch(setDahData({
                        completedXP: updatedXp1,
                        visibleCelebrationmodal: dashboardDataSlice.goalXp <= updatedXp1 ? true : false
                    }))
                    // this.props.setDahData({
                    //   completedXP: createActionData.action.status === "IN_PROGRESS" ?  (this.props.dashboardData.completedXP - foundXp) : (this.props.dashboardData.completedXP + foundXp)
                    // })
                    // updateXp(foundXp, createActionData.action.status !== "IN_PROGRESS")
                    //setIsSetDurationAction(false);
                    // this.callToGetData(
                    //   this.props.dashboardData.selectedDate,
                    //   this.props.dashboardData.selectedDate
                    // );
                    // console.log("res = ", res);
                })
                .catch((err) => {
                    console.log("err = ", err);
                });
        }
    }
    const openCloseJournalModal = (visible, itm = {}, planid = undefined) => {
        dispatch(setDahData({
            openPlanJournalModal: visible,
            openPlanJournalItem: itm,
            openPlanJournalModalPlanId: planid
        }))
    };
    const addUpdateJournalData = (value) => {
        dispatch(setDahData({
            savingJournal: true,
        }));
        const user = localStorage.getItem("userDetail");
        PlansServices.updateJournal({
            "userPlanActionId": openPlanJournalItem.userPlanActionId,
            "journal": value
        }).then(() => {
            dispatch(setDahData({
                openNotification: true,
                notificationMessage: 'Saved'
            }))
            setTimeout(() => {
                dispatch(setDahData({
                    openNotification: false,
                    notificationMessage: ''
                }))
            }, 2000);
            dispatch(updateJournalOfPlanActionDashboard({
                userPlanActionId: openPlanJournalItem.userPlanActionId,
                journal: value,
                planId: openPlanJournalModalPlanId
            }))
            let createActionData;
            if (openPlanJournalItem.status === "IN_PROGRESS") {
                createActionData = {
                    uid: localStorage.getItem("userDetail"),
                    userPlanActionId: openPlanJournalItem.userPlanActionId,
                    status: "COMPLETE"
                };
            }
            console.log("createActionData", createActionData, userData.xp, openPlanJournalItem.xp)
            if (Object.keys(createActionData).length > 0) {
                PlansServices.updateActionStatus(createActionData)
                    .then((res) => {
                        dispatch(updateStatusOfActionDashboard({
                            ...createActionData,
                            planId: openPlanJournalModalPlanId
                        }))
                        console.log("res = ", res);
                        const updatedXp1 = (dashboardDataSlice.completedXP + Number(openPlanJournalItem.xp || 0))
                        dispatch(setDahData({
                            completedXP: updatedXp1,
                            visibleCelebrationmodal: dashboardDataSlice.goalXp <= updatedXp1 ? true : false
                        }))
                        const updatedXp = Number(userData.xp || 0) + Number(openPlanJournalItem.xp || 0)
                        dispatch(updateUserCions(updatedXp))
                    })
                    .catch((err) => {
                        console.log("err = ", err);
                    });
            }

        }).catch((err) => {
            console.log("err", err)
        }).finally(() => {
            dispatch(setDahData({
                savingJournal: false,
            }));
        })
        // DashboardService.UPDATE_JOURNAL({
        //     uid: user,
        //     id: id,
        //     type: type,
        //     journal: value,
        // })
        //     .then((res) => {
        //         callToGetData(dashboardDataSlice.selectedDate, dashboardDataSlice.selectedDate);
        //         openCloseJournalModal(false);
        //         console.log("res", res);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
        //     .finally(() => {
        //         dispatch(setDahData({
        //             savingJournal: false,
        //         }));
        //     });
    };
    return <>{userPlanActionsData && userPlanActionsData.length > 0 && userPlanActionsData.map((it, index) => {
        console.log("res.data", it)
        const { planQuotesData = [], userPlansData = [] } = it
        return <Box className={classes.boxContainer} sx={{
            marginTop: !index ? '-50px' : '10px'
        }} key={it._id.planId}>
            <Typography component={"span"} sx={{
                ...GeneralSX({
                    color: '#190D1A',
                    fontSize: 18,
                    fontWeight: 500,
                    lineHeight: '145%'
                })
            }}>Start you day with daily action</Typography>
            <div className={classes.stepper} id="stepper">


                <div className={classes.step}>
                    <div className={classes.counter} id="counter1">
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.common.white,
                                fontSize: 20,
                                fontWeight: 400,
                                lineHeight: "100%",
                                letterSpacing: '-0.2px',
                                extra: {
                                    fontFamily: 'Coffee Mood'
                                }
                            }),
                            zIndex: 2
                        }}>1</Typography>
                        <div className={classes.connector}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="400" viewBox="0 0 17 260" fill="none" id="connector" style={{
                                zIndex: 1
                            }}>
                                <path d="M10.649 1C3.33654 44.9184 -6.89121 135.642 10.8282 132.145C26.2841 129.095 2.1719 86.4124 2.1732 144.955C2.17363 164.474 10.649 261.867 10.649 400" stroke="#9747FF" stroke-width="2" />
                            </svg>
                        </div>
                    </div>
                    <div className={classes.quote}>
                        <div style={{
                            position: 'absolute',
                            left: '10px',
                            top: 0,
                            zIndex: 0
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="164" height="92" viewBox="0 0 164 92" fill="none">
                                <g clip-path="url(#clip0_581_5540)">
                                    <path d="M43.2 29.8H46C53.6 29.8 60.4 32.7333 66.4 38.6C72.5333 44.3333 75.6 51.5333 75.6 60.2C75.6 68.7333 72.5333 75.8 66.4 81.4C60.4 86.8667 52.9333 89.6 44 89.6C34.2667 89.6 26.1333 85.7333 19.6 78C13.2 70.1333 10 59 10 44.6C10 31.4 12.8 19.0667 18.4 7.6C24.1333 -4 31.0667 -13.1333 39.2 -19.8C47.4667 -26.6 54.8 -30 61.2 -30C65.4667 -30 68.8 -28.7333 71.2 -26.2C73.6 -23.6667 74.8 -20.4 74.8 -16.4C74.8 -10.5333 72.2 -6.2 67 -3.4C59.9333 0.466667 54.3333 5 50.2 10.2C46.0667 15.4 43.7333 21.9333 43.2 29.8ZM131.6 29.8H134.2C141.667 29.8 148.4 32.7333 154.4 38.6C160.533 44.3333 163.6 51.5333 163.6 60.2C163.6 68.6 160.533 75.6667 154.4 81.4C148.4 87.1333 140.933 90 132 90C122.133 90 114 86 107.6 78C101.2 70 98 58.8667 98 44.6C98 34.3333 99.6667 24.6667 103 15.6C106.333 6.53334 110.6 -1.33333 115.8 -8C121.133 -14.8 126.8 -20.1333 132.8 -24C138.933 -28 144.4 -30 149.2 -30C153.6 -30 156.933 -28.7333 159.2 -26.2C161.6 -23.8 162.8 -20.5333 162.8 -16.4C162.8 -10.4 160.2 -6.06667 155 -3.4C147.667 0.733335 142.133 5.33334 138.4 10.4C134.667 15.3333 132.4 21.8 131.6 29.8Z" fill="#AC6CFF" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_581_5540">
                                        <rect width="164" height="92" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className={classes.dataContent}>
                            <div className={classes.frame4516}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <g clip-path="url(#clip0_581_5544)">
                        <path d="M10.5 20C16.0228 20 20.5 15.5229 20.5 10C20.5 4.47716 16.0228 0 10.5 0C4.97715 0 0.5 4.47716 0.5 10C0.5 15.5229 4.97715 20 10.5 20Z" fill="white" />
                        <path d="M15.7904 4.57505C15.6913 4.59983 15.6205 4.68482 15.6063 4.78397L15.5992 4.83708C15.5319 4.85125 15.4611 4.8902 15.4044 4.95394L13.9738 6.47306L12.9965 6.06583L12.6778 6.13666L13.3223 6.69969L12.9009 7.19544H7.97527L7.55387 6.69969L8.19835 6.13666L7.87965 6.06583L6.90231 6.47306L5.47172 4.95394C5.41506 4.8902 5.34424 4.85125 5.27696 4.83708L5.26987 4.78397C5.25925 4.68482 5.18489 4.59983 5.08574 4.57505C4.91931 4.53255 4.75996 4.67065 4.78121 4.84416C4.79183 4.94331 4.8662 5.0283 4.96535 5.05309L5.01847 5.06725C5.02201 5.13807 5.05388 5.21243 5.10699 5.27617L6.42781 6.89091L5.89665 7.81513L5.92497 8.13028L6.56945 7.56726L7.05811 8.07717V10.7153C7.05811 11.5899 7.32725 12.4469 7.81592 13.1586L6.56945 14.6175L6.51634 15.2124L7.09707 15.0814L8.33999 13.7854C8.36832 13.8137 8.3931 13.8385 8.42143 13.8668L10.0114 15.3576C10.2416 15.5736 10.5957 15.5736 10.8258 15.3576L12.4158 13.8668C12.4512 13.835 12.4831 13.7995 12.5185 13.7677L13.7791 15.0814L14.3598 15.2124L14.3067 14.6175L13.039 13.1338C13.5171 12.4256 13.7826 11.5793 13.7826 10.7153V8.11612L14.3067 7.56726L14.9512 8.13028L14.9795 7.81513L14.4484 6.89091L15.7692 5.27617C15.8258 5.21243 15.8542 5.13807 15.8577 5.06725L15.9108 5.05309C16.01 5.0283 16.0808 4.94331 16.095 4.84416C16.1162 4.67065 15.9568 4.53255 15.7904 4.57505ZM6.67568 15.0248L8.07087 13.4879C8.07441 13.495 8.08149 13.4986 8.08503 13.5056L6.67568 15.0248ZM14.2005 15.0248L12.7734 13.4844C12.777 13.4773 12.784 13.4738 12.7876 13.4667L14.2005 15.0248Z" fill="#777777" />
                        <path d="M12.0193 13.4136L10.7941 14.5609C10.5817 14.7592 10.2523 14.7592 10.0434 14.5609L8.8182 13.4136C8.0852 12.7266 7.6709 11.767 7.6709 10.7613V8.27905C7.6709 8.05596 7.85149 7.87537 8.07458 7.87537H12.763C12.986 7.87537 13.1666 8.05596 13.1666 8.27905V10.7613C13.1666 11.7635 12.7523 12.7266 12.0193 13.4136Z" fill="#9B5151" />
                        <path d="M12.7629 7.87537H10.4541V14.7096C10.578 14.7026 10.6984 14.653 10.794 14.5609L12.0192 13.4136C12.7523 12.7266 13.1666 11.767 13.1666 10.7613V8.27905C13.1666 8.05596 12.986 7.87537 12.7629 7.87537Z" fill="#BF5151" />
                        <path d="M12.0862 12.068C12.0897 12.0538 12.0862 12.0397 12.0791 12.0291C12.0826 12.0432 12.0826 12.0538 12.0862 12.068Z" fill="#1D2A3A" />
                        <path d="M9.45898 12.5638C9.46252 12.5602 9.45898 12.5567 9.45898 12.5531C9.45898 12.5567 9.45898 12.5602 9.45898 12.5638Z" fill="#1D2A3A" />
                        <path d="M8.8568 11.1438C8.84618 11.1544 8.84972 11.1509 8.8568 11.1438V11.1438Z" fill="#1D2A3A" />
                        <path d="M8.86768 11.1331C8.87122 11.1296 8.87122 11.1261 8.87477 11.1261C8.87122 11.1261 8.87122 11.1296 8.86768 11.1331Z" fill="#1D2A3A" />
                        <path d="M8.87451 11.1225C8.87451 11.119 8.87805 11.119 8.87451 11.1225V11.1225Z" fill="#1D2A3A" />
                        <path d="M9.73499 9.47947C9.73499 9.47947 9.73145 9.47592 9.73145 9.47238C9.73145 9.47592 9.73145 9.47593 9.73499 9.47947Z" fill="#1D2A3A" />
                        <path d="M9.71045 9.44406C9.71399 9.4476 9.71752 9.45468 9.72107 9.45822C9.71398 9.45114 9.71045 9.4476 9.71045 9.44406Z" fill="#1D2A3A" />
                        <path d="M9.71021 9.44407C9.70667 9.44053 9.70667 9.44052 9.70312 9.43698C9.70312 9.43698 9.70667 9.44053 9.71021 9.44407Z" fill="#1D2A3A" />
                        <path d="M12.2176 10.17C12.1751 10.2019 12.2672 10.2019 12.2176 10.17V10.17Z" fill="#1D2A3A" />
                    </g>
                    <rect x="1" y="0.5" width="19" height="19" rx="9.5" stroke="#D0CED0" />
                    <defs>
                        <clipPath id="clip0_581_5544">
                            <rect x="0.5" width="20" height="20" rx="10" fill="white" />
                        </clipPath>
                    </defs>
                </svg> */}
                                {userPlansData[0]?.questionCategoriesData && userPlansData[0]?.questionCategoriesData[0] && <img src={imageURLRender(userPlansData[0]?.questionCategoriesData[0]?.url)} alt={userPlansData[0]?.questionCategory} />}
                                <Typography sx={{
                                    ...GeneralSX({
                                        color: palette.common.white,
                                        fontSize: 16,
                                        fontWeight: 500,
                                        lineHeight: "100%",
                                        letterSpacing: '-0.18px'
                                    })
                                }}>{userPlansData[0]?.questionCategory}</Typography>
                            </div>
                            <Typography sx={{
                                ...GeneralSX({
                                    color: palette.common.white,
                                    fontSize: 18,
                                    fontWeight: 500,
                                    lineHeight: "145%",
                                    letterSpacing: '-0.18px'
                                }),
                                textAlign: 'center'
                            }}>{planQuotesData[0] && planQuotesData[0]?.title}</Typography>
                        </div>
                        <Button className={classes.dataContentButton} onClick={() => {
                            // dispatch(setStoreData({
                            //     setp: 4
                            // }));
                            dispatch(setDahData({
                                viewPlanReadModal: true,
                                planReadItem: it
                            }))
                        }}>
                            <Typography sx={{
                                ...GeneralSX({
                                    color: palette.primary.main,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: "100%",
                                    letterSpacing: '-0.14px'
                                })
                            }}>{planQuotesData[0] && planQuotesData[0].userplanquoteData?.length > 0 ? 'Read full' : `Read full and earn ${planQuotesData[0] && planQuotesData[0]?.xp}`} </Typography>
                            {planQuotesData[0] && planQuotesData[0].userplanquoteData?.length > 0 ? null : <Avatar alt="Natacha" sx={{ width: '12px', height: '12px' }} src={XP} />}
                        </Button>
                    </div>
                </div>
                <div className={classes.step}>
                    <div className={classes.counter} id="counter2">
                        <Typography sx={{
                            ...GeneralSX({
                                color: palette.common.white,
                                fontSize: 20,
                                fontWeight: 400,
                                lineHeight: "100%",
                                letterSpacing: '-0.2px',
                                extra: {
                                    fontFamily: 'Coffee Mood'
                                }
                            })
                        }}>2</Typography>

                    </div>
                    <div className={classes.planActionGroup}>
                        {it?.actions.length > 0 && (it?.actions).map((ite, index) => <div className={classes.planJournalButton1}>
                            <Checkbox
                                {...label(ite.actionId || ite._id)}
                                sx={{ pl: "0", height: "20px" }}
                                checked={
                                    ite.status === "COMPLETE"
                                }
                                // disabled={type !== "Today"}
                                onChange={(e) => handleIsTrue(e, ite, it)}
                            />
                            <div className={classes.planJournalButton}>
                                <div className={classes.planAction}>

                                    <Typography sx={{
                                        ...GeneralSX({
                                            color: palette.common.mainBlack,
                                            fontSize: 16,
                                            fontWeight: 400,
                                            lineHeight: "150%",
                                            letterSpacing: '-0.16px',
                                        })
                                    }} >
                                        <span className={clsx(classes.strike, ite.status === "COMPLETE" ? classes.completedStrike : "")}>{ite.actionName}</span></Typography>

                                    <Tag xp={ite.xp} />

                                </div>
                                <Button variant="contained" sx={{
                                    width: 'max-content'
                                }} className={classes.journalButton} onClick={() => {
                                    openCloseJournalModal(true, ite, it._id.planId)
                                    // dispatch(setStoreData({
                                    //     // visibleJournalModal: true,
                                    //     selectedJournalAction: it,
                                    //     setp: setp + 1
                                    // }));
                                }}>
                                    Open journal
                                </Button>
                            </div>
                        </div>
                        )}
                    </div>
                </div>

            </div>
        </Box>
    })}
        {viewPlanReadModal && <PlanReadModal />}
        <PlanJournalEntryModal openJournalModal={openPlanJournalModal} openCloseJournalModal={openCloseJournalModal} addUpdateJournalData={addUpdateJournalData} openJournalItem={openPlanJournalItem} savingJournal={savingJournal} />
    </>
}

export default PlanActinos