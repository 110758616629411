import { Tooltip, tooltipClasses, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Info from "../../assets/Svg/Info.svg";
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#9847ff',
        color: '#fff',
        maxWidth: 550,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #9847ff',
        borderRadius: '12px',
        padding: '16px'
    },
    
}));

const HTMLTooltip = ({ title = "", placement = "bottom", showArrow = true, sx = {}, customIcon = null }) => {
    return <HtmlTooltip
        title={
            <Typography color="inherit">{title}</Typography>
        }
        placement={placement}
        arrow={showArrow}
        sx={sx && Object.keys(sx).length ? {
            ...sx,
            '& .MuiTooltip-arrow': {
                color: "#FFF",
                // borderTop: '1px solid #9747FF',
                // borderLeft: '1px solid #9747FF',
                // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                '&:before': {
                    border: "1px solid #9747FF"
                }
            }
        } : {
            '& .MuiTooltip-arrow': {
                color: "#9747FF",
                // borderTop: '1px solid #9747FF',
                // borderLeft: '1px solid #9747FF',
                // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                '&:before': {
                    border: "1px solid #9747FF"
                }
            }
        }}
        disableInteractive
    >{customIcon ? customIcon : <img src={Info} alt="" />}</HtmlTooltip>
}

export default HTMLTooltip