import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import classes from "./TestPage.module.css";

import Slider, {

} from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuestionsList, storeAnswer } from "../../services/auth";
import {
  storeAnswerId,
  storeAnswerValue,
  storeQuestionList,
} from "../../Store/Reducers/questionsListSlice";
import { imageURLRender } from "../../utils";
import { GeneralSX } from "../../components/Text/Text";
const stpperdescription = [{
  count: 1,
  name: "Honest Answers Enable Honest Results",
  description: "Providing genuine responses ensures the most accurate feedback and insights for your personal growth."
},
{
  count: 2,
  name: "Balance Heart and Mind",
  description: "Let your emotions guide you, but also allow space for reflection and thought."
},
{
  count: 3,
  name: "Every Response is a Step Forward",
  description: "Each answer, whether positive or negative, provides valuable insight into your personal journey."
}]
const TestConfirmationPage = () => {
  const { questionsList } = useSelector((state) => state.questionsList);
  // console.log("questionsList",questionsList)
  const [isLoading, setIsLoading] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);


  // console.log("ddhdhdh",answer,questionsList[questionIndex].ansValue )

  const answerIdRef = useRef("");
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);
  const dispatch = useDispatch();
  useEffect(() => {
    const uid = localStorage.getItem("userDetail");
    // if (uid) {
    // getQuestionsList({ uid, subCategory_id: state.testId })
    //   .then((questions, i) => {
    //     // identify if user give some ans
    //     for (let i = 0; i < questions?.response?.length; i++) {
    //       if (questions?.response[i]?.answerId?.length === 0) {
    //         setQuestionIndex(i);
    //         break;
    //       } else {
    //       }
    //     }
    //     const arr = Array.from({ length: questions.response.length - 1 + 1 }, (_, i) => i + 1);
    //     var arrayOfArrays = [];
    //     for (let i = 0; i < arr.length; i += Math.ceil(questions.response.length / pointLength)) {
    //       arrayOfArrays.push(arr.slice(i, i + Math.ceil(questions.response.length / pointLength)));
    //     }
    //     setarrayOfArraysRange(arrayOfArrays)
    //     console.log("arrayOfArrays", arrayOfArrays);

    //     // console.log("question", questions);
    //     // can we need state for store this question array ?
    //     if (questions?.response?.length === 0) {
    //       // if question array are empty
    //       console.log("no question Found");
    //       // navigate("/testcategory");
    //       setNoQuestionFound(true);
    //     } else {
    //       dispatch(storeQuestionList(questions.response));
    //       dispatch(storeAnswerValue());
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("57 error", error);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
    // } else {
    //   console.log("user not logedin");
    //   navigate("/")
    // setIsLoading(false);
    // }
  }, []);


  // useEffect(() => {
  // ===0 && answer === 0 || questionsList[questionIndex]?.ansValue===0 && answer > 0 ? middleValue : questionsList[questionIndex]?.ansValue
  //   const tempMiddlevalue =
  //     questionsList[questionIndex]?.questionData?.options?.length *
  //       questionsList[questionIndex]?.questionData?.options?.length -
  //     questionsList[questionIndex]?.questionData?.options?.length;
  //   console.log("tempMiddlevalue==>", tempMiddlevalue);
  //   if (tempMiddlevalue) {
  //     setMiddleValue(tempMiddlevalue / 2);
  //   }
  //   console.log("answer==>", middleValue);
  // }, [middleValue, questionsList]);


  const TestAnswerSlider = styled(Slider)`
    // background: yellow;
    width: 98.2%;
    padding: 0px;
    & .MuiSlider-rail {
      background: #e5d5fc;
      height: 12px;
    }

    & .MuiSlider-thumbSizeMedium {
      margin-left: 5px;
    }
    & .MuiSlider-rail {
      border-color: #f7f2fe;
      width: 102%;
    }

    & .MuiSlider-track {
      height: 12px;
      background: #f7f2fe;
      border-color: #f7f2fe;
    }

    & .MuiSlider-mark {
      // display: none;
      background: #9747ff ;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    & .MuiSlider-markActive {
      // display: none;
      background: #9747ff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    & .MuiSlider-valueLabelOpen {
      background: #f7f2fe;
      color: #9747ff;
    }

    & .MuiSlider-thumbColorPrimary {
      box-shadow: none;
    }
  `;

  const MiddleContainerWrapper = styled("div")(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    // justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
    minHeight: "216px",
    borderRadius: "32px",
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  // console.log(questionIndex, questionsList.length - 1);

  const nextClickHandler = () => {
    // console.log(answerIdRef.current);
    // console.log("questionid",questionsList[questionIndex].questionData.questionId)
    setIsLoading(true);

    if (questionIndex <= questionsList.length - 1) {
      const questionId = questionsList[questionIndex]?.questionData?.questionId;
      const uid = localStorage.getItem("userDetail");
      const answerId = answerIdRef.current;

      // if(uid){
      storeAnswer({ questionId, uid, answerId })
        .then((data) => {
          if (data) {
            // console.log("data", data);
            dispatch(storeAnswerId({ answerId, questionId }));
            dispatch(storeAnswerValue());
            if (questionIndex !== questionsList.length - 1) {
              setQuestionIndex((prev) => prev + 1);
            } else {
              // console.log("test is over");
              navigate("/startTest/testend", {
                state: { subCategory: state.subCategory },
              });
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // }else{
      //   console.log(160,"user not logedin")
      //   navigate("/")
      //   setIsLoading(false)
      // }
    } else {
      setIsLoading(false);
    }
  };

  // console.log("answer = ", answer);
  const prevClickHandler = () => {
    setQuestionIndex((prev) => prev - 1);
  };


  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box sx={{
            width: '100%',
            height: '260px',
            borderRadius: '32px',
            orverflow: 'hidden'
          }}>
            <div className={classes.Imageoverlay}>

              <img src={imageURLRender(state?.imageUrl)} alt="" />

            </div>
          </Box>
          <Box sx={{
            display: 'flex',
            padding: '40px',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
          }}>
            <Typography component={"span"} sx={{
              ...GeneralSX({
                fontSize: 32,
                fontWeight: 600,
                lineHeight: '105%',
                letterSpacing: '-0.32px'
              }), textAlign: "center"
            }}>You are about to take a '{state.subCategory}' test</Typography>
            <Box className={classes.dataCOntent}>
              <Typography component={"span"} sx={{
                ...GeneralSX({
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: '135%',
                  letterSpacing: '-0.24px'
                }), textAlign: "center",
                alignSelf: 'stretch'
              }}>Here are some tips on how to do it more efficiently</Typography>
              <Box className={classes.stepprr}>
                <span className={classes.connector}></span>
                {stpperdescription.map(it => <Box key={it.count} className={classes.stepperItem}>
                  <Box className={classes.counter}>
                    <Box className={classes.counterLayout}>
                      <Typography component={"span"} sx={{
                        ...GeneralSX({
                          color: '#fff',
                          fontSize: 20,
                          fontWeight: 400,
                          lineHeight: '100%',
                          letterSpacing: '-0.2px'
                        }), textAlign: "center",
                        alignSelf: 'stretch',
                        fontFamily: 'Coffee Mood',

                      }}>{it.count}</Typography>
                    </Box>

                  </Box>
                  <Box className={classes.counterCOntent}>
                    <Box className={classes.counterCOntentTitle}>
                      <Typography component={"span"} sx={{
                        ...GeneralSX({
                          fontSize: 18,
                          fontWeight: 500,
                          lineHeight: '145%',
                          letterSpacing: '-0.18px'
                        }),
                        alignSelf: 'stretch',

                      }}>{it.name}</Typography>
                    </Box>
                    <Typography component={"span"} sx={{
                      ...GeneralSX({
                        color: '#767476',
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '145%',
                        letterSpacing: '-0.14px'
                      }),
                      alignSelf: 'stretch',

                    }}>{it.description}</Typography>
                  </Box>
                </Box>)}
              </Box>
            </Box>
            <Button fullWidth variant="contained" className={classes.confirmButton} onClick={() => {
                console.log("this clicked")
                navigate("/startTest", { state: { subCategory: state?.subCategory, imageUrl: state?.imageUrl, testId: state?.testId, coins: state?.coins, points: state?.points } });
              }}>
              <Typography component={"span"} sx={{
                ...GeneralSX({
                  color: '#FFF',
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: '100%',
                  letterSpacing: '-0.18px'
                }),
              }} >Continue</Typography>
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default TestConfirmationPage;
