import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function AlertModal({
  open,
  handleClose,
  isAskOtherModalOpen,
  setIsAskOtherModalOpen,
  setIsAskOtherModalOpenSocial,
  fromMainPage = false,
  fromConfirmationPage= false
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const  { state } = useLocation();
  console.log("state alert modal", state);
  const handleYes = () => {
    console.log("this clicked", isAskOtherModalOpen)
    if (location.pathname === "/startTest") {
      navigate(-2);
    } else if (location.pathname === "/startTest/testend") {
      navigate(-3);
    } else if (location.pathname === "/proceed-test") {
      navigate(`/test/${state?.testId}`, {
        state: {
          ...(state||{})
        },
      });
    }
    if (isAskOtherModalOpen) {
      setIsAskOtherModalOpen(false);
    }
    setIsAskOtherModalOpenSocial && setIsAskOtherModalOpenSocial(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        sx={{
          '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px',
            gap: '20px',
            isolation: 'isolate',

            /* Main/White */

            background: '#FFFFFF',
            borderRadius: '24px',
          }
        }}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ marginBottom: 0 }}>
          <Typography sx={{ fontSize: '1.5rem!important', fontWeight: 600, color: '#190D1A', textAlign: 'center', marginBottom: 0 }}>
            {fromConfirmationPage ? "Are you sure you want to exit?": fromMainPage ? "Are you sure you want to close? Your progress won’t be saved" : "Your progress will be saved and accesible either via test category or on dashboard"}
            
          </Typography>
        </DialogTitle>
        <DialogActions sx={{ padding: "10px 24px", width: '100%' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '0px',
            gap: '12px',
            width: '100%',
            justifyContent: 'space-between',
          }}>
            <Button
            disableRipple
            disableFocusRipple
            onClick={handleYes}
            variant={"outlined"}
            size="small"
            sx={{ padding: '16px', width: '100%', borderRadius: '8px', fontSize: '1rem', fontWeight: 400, height: '48px',
            "&:hover": { backgroundColor: "transparent" } }}
          >
            {(fromConfirmationPage || fromMainPage) ? "Yes" : "Save & exit"}
          </Button>
          <Button
            disableRipple
            disableFocusRipple
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{ padding: '16px', width: '100%', borderRadius: '8px', fontSize: '1rem', fontWeight: 400, height: '48px', }}
          >
            {fromConfirmationPage? "No, Continue": fromMainPage ? "No" : "Continue"}
          </Button>
          </div>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
