import { Typography } from "@mui/material";
import { pxToRem } from "../../theme/typography";

const Text = ({ textValue, variant, width = "100%", color = "#190D1A", otherSx = {} }) => {
  return (
    <Typography variant={variant} component="div" sx={{ textAlign: "center", width: width, color: color, ...otherSx }}>
      {textValue}
    </Typography>
  );
};


export default Text;

export const GeneralSX = ({ color = '#190D1A', fontSize = 14, fontWeight = 400, lineHeight = '100%', letterSpacing = '-0.14px', extra = {} } = {}) => {
  return {
    color: color,
    fontSize: `${pxToRem(fontSize)}!important`,
    fontStyle: 'normal',
    fontWeight: fontWeight,
    lineHeight: lineHeight, /* 14px */
    letterSpacing: letterSpacing,
    ...extra
  }
}