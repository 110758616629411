import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  Step,
  StepsWrapper,
} from "../../../components/GoalComponent/GoalCustomDiv";
import { CustomSelection } from "../../../components/GoalComponent/GoalDialog";
import {
  CheckBoxInputCheckedDay,
  CheckBoxInputDay,
} from "../../../components/Input/Input";
import { dates, days } from "../../../Constant/GoalConstant";
import typography, { pxToRem } from "../../../theme/typography";
import classes from "./HabitStep.module.css";
import RightSign from "../../../assets/Svg/RightSign.svg";
import Hedonism from "../../../assets/Svg/Hedonism.svg";
import { CustomShedualButton } from "../../../components/GoalComponent/GoalButton";
import GoalTimePicker from "../../../components/EditRemoveGoal/GoalTimePicker/GoalTimePicker";
import GoalDatePicker from "../../../components/EditRemoveGoal/GoalDatePicker/GoalDatePicker";
import { Key } from "@mui/icons-material";
import moment from "moment";
import { imageURLRender, XPLevels } from "../../../utils";
import { AddNewGoalDialogTitle } from "../../../components/AddNewGoal/AddNewGoal";
import HTMLTooltip from "../../../components/ToolTipWithIcon/HTMLTooltip";
import { TeriteryButtonChip, TeriteryButtonContained } from "../../../components/Button/Button";
import { XPIcon } from "../../../components/XPIcon";
function HabitStepSix({
  onPrevious,
  getGoalDetail,
  goalDetail,
  stepSixNextHandler,
  selectedDiamenstion,
  selectedDiamenstionImage,
  handleClose
}) {
  console.log("goalDetail", goalDetail);
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false);
  const [isDatePickerOpen, setisDatePickerOpen] = useState(false);
  const [selectedDaystate, setSelectedDaystate] = useState([]);
  const [selectedDate, setselectedDate] = useState(
    moment(new Date()).format("DD")
  );
  const [habitInputData, setHabitInputData] = useState([

  ]);
  const addFirstInput = () => setHabitInputData([{
    habitName: "",
    habitDetail: "",
    habitScheduleType: "",
    daysData: [],
    dateData: "",
    level: '', id: 1, saved: false
  }]);
  const savedAtIndex = (i) => {
    const newInputs = [...habitInputData];
    newInputs[i].saved = true
    setHabitInputData(newInputs)
  }
  const removeAtIndex = (i) => {
    const newInputs = [...habitInputData];
    newInputs.splice(i, 1)
    setHabitInputData(newInputs)
  }
  console.log(selectedDaystate);
  useEffect(() => {
    console.log("goalDetail?.habit", goalDetail?.habit);
    if (goalDetail?.habit && goalDetail?.habit.length) {
      setHabitInputData(goalDetail?.habit);
      if (goalDetail?.habit[0]?.habitScheduleType === "WEEKLY") {
        setSelectedDaystate(goalDetail?.habit[0]?.days);
      } else if (goalDetail?.habit[0]?.habitScheduleType === "MONTHLY") {
        setselectedDate(goalDetail?.habit[0]?.habitSchedule?.split("-")[1]);
      }
    }
  }, []);
  const setselectedDayHandler = (day) => {
    const findData = selectedDaystate.find((o) => o === day);
    if (findData) {
      setSelectedDaystate((current) => current.filter((data) => data !== day));
    } else {
      setSelectedDaystate([...selectedDaystate, day]);
    }
  };
  const dateselectorHandler = (date) => {
    setselectedDate(date);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempList = [...habitInputData];
    tempList[index][name] = value;
    setHabitInputData(tempList);
  };
  const handleChangeAction = (value, index) => {
    const tempList = [...habitInputData];
    tempList[index]["level"] = value;
    setHabitInputData(tempList);
  }
  const dayModalCloseHandler = (index) => {
    const tempList = [...habitInputData];
    tempList[index]["daysData"] = selectedDaystate;
    setHabitInputData(tempList);
    setIsDayPickerOpen(false);
  };
  const dateModalClosehandler = (index) => {
    const tempList = [...habitInputData];
    tempList[index]["dateData"] = selectedDate;
    setHabitInputData(tempList);
    setisDatePickerOpen(false);
  };
  const addMoreHandler = () => {
    if (
      habitInputData[habitInputData.length - 1].habitName !== "" &&
      habitInputData[habitInputData.length - 1].habitDetail !== "" &&
      habitInputData[habitInputData.length - 1].habitScheduleType !== ""
    ) {
      setHabitInputData([
        ...habitInputData,
        {
          habitName: "",
          habitDetail: "",
          habitScheduleType: "",
          daysData: [],
          dateData: "",
        },
      ]);
    }
  };
  const updateMainState = () => {
    let tempHabitData = [];
    habitInputData?.forEach((item) => {
      if (item.habitName && item.habitDetail && item.habitScheduleType) {
        tempHabitData.push({
          habitName: item.habitName,
          habitDetail: item.habitDetail,
          habitScheduleType: item.habitScheduleType,
          level: item.level,
          days: item.habitScheduleType === "WEEKLY" ? item.daysData : [],
          habitSchedule:
            item.habitScheduleType === "MONTHLY"
              ? `${moment().format("MM")}-${item.dateData}-${moment().format(
                "YYYY"
              )}`
              : false,
        });
      }

    });
    getGoalDetail("habit", tempHabitData);
  };
  const updateMainStateNext = () => {
    let tempHabitData = [];
    habitInputData?.forEach((item) => {
      if (item.habitName && item.habitDetail && item.habitScheduleType) {
        tempHabitData.push({
          habitName: item.habitName,
          habitDetail: item.habitDetail,
          habitScheduleType: item.habitScheduleType,
          level: item.level,
          days: item.habitScheduleType === "WEEKLY" ? item.daysData : [],
          habitSchedule:
            item.habitScheduleType === "MONTHLY"
              ? `${moment().format("MM")}-${item.dateData}-${moment().format(
                "YYYY"
              )}`
              : false,
        });
      }

    });
    getGoalDetail("habit", tempHabitData);
  };
  return (
    <>
      <AddNewGoalDialogTitle handleClose={handleClose} skipButton={<Typography variant="h5" sx={{
        textAlign: "right", fontSize: '0.75rem !important', position: 'absolute',
        left: '20px', cursor: 'pointer'
      }} onClick={() => {
        updateMainStateNext();
        stepSixNextHandler();
      }}>
        <span style={{ color: "#9747FF" }}>{"Skip Habit"}</span>{" "}
      </Typography>} />
      <div className={classes.StepFiveWrapper}>
        {" "}
        <StepsWrapper>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
          <Step className={classes.active}></Step>
        </StepsWrapper>
        <div className={classes.progressBarWrapper}>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Choose dimension
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Select growth areas{" "}
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Add actions
          </Typography>
          <Typography
            variant="body3"
            className={classes.progressTittle}
            sx={{ color: "#9747FF !important" }}
          >
            Set habits
          </Typography>
        </div>
        <div className={classes.StepFiveSubWrapper}>

          <div><img style={{ margin: "16px auto", height: '58px', }} src={selectedDiamenstionImage ? imageURLRender(selectedDiamenstionImage) : Hedonism} alt="Hedonism" /></div>
          <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            {/* <Typography
              variant="body4"
              sx={{ margin: "16px 0px", textAlign: "center", justifySelf: "end" }}
            >
              Choose preselected growth area or type in your own
            </Typography> */}
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Add habit/s to{" "}
              <span style={{ color: "#9747FF" }}>
                {" "}
                {selectedDiamenstion}
              </span>{" "}
            </Typography>
            <div style={{ position: "absolute", right: "0" }}>
              <HTMLTooltip
                title={"Habits are recurring actions designed to consistently guide you towards your character goals."}
                placement="right"
                showArrow={true}
                sx={{
                  '& .MuiTooltip-tooltip': {
                    background: "#FFF",
                    color: "#000",
                    maxWidth: "350px"
                  }
                }}
              />
            </div>

          </div>
          {habitInputData.length <= 0 && (
            <div style={{ width: "100%", marginTop: "16px" }}>
              <TeriteryButtonChip
                // style={{ maxWidth: "91px" }}
                onClick={addFirstInput}
              >
                Add habit manually
              </TeriteryButtonChip>
            </div>
          )}
          {habitInputData.length > 0 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '24px',
              flexShrink: 0,
              width: '100%',
              marginTop: '24px',
              marginBottom: '24px',
            }}>
              {habitInputData?.map((item, i) => {
                return <Box sx={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '16px',
                  alignSelf: 'stretch',
                  borderRadius: '16px',
                  background: '#F9F9F9',
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between'
                  }}>
                    <Typography component="span" sx={{
                      color: "#190D1A",
                      fontSize: `${pxToRem(14)} !important`,
                      fontWeight: 400,
                      lineHeight: '100%'
                    }}>Habit #{i + 1}</Typography>
                    <span onClick={() => removeAtIndex(i)} style={{
                      cursor: "pointer"
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M12 4L4 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4 4L12 12" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </Box>
                  <TextField
                    key={i}
                    size="small"
                    className={classes.goalActionInput}
                    sx={{
                      // marginBottom: "12px",
                      background: '#FFF',
                      borderRadius: '16px',
                      '& .MuiOutlinedInput-root': {
                        paddingTop: '18px',
                        "&.Mui-focused .MuiOutlinedInput-input": {
                          color: '#9747FF'
                        },
                        "&.Mui-focused .MuiInputLabel-root": {
                          color: '#767476'
                        }
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                        top: 0,
                        '& legend': {
                          height: 0,
                          width: 0
                        }
                      },
                      '& label': {
                        color: '#767476',
                        top: '18px',
                        left: '-3px',
                        "&.Mui-focused": {
                          color: '#767476'
                        }
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                        borderRadius: "16px",
                        background: "#fff"
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      // color: '#767476'
                    }}

                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    label="Name habit"
                    name="habitName"
                    value={item.habitName}
                    onChange={(e) => {
                      handleInputChange(e, i);
                    }}
                    type="text"
                    placeholder="Text"
                    id="outlined-start-adornment"
                  />
                  <TextField
                    size="small"
                    name="habitDetail"
                    className={classes.goalActionInput}
                    sx={{
                      background: '#FFF',
                      borderRadius: '16px',
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #f3f3f3",
                        borderRadius: "16px",
                      },
                      " & .MuiOutlinedInput-input": {
                        color: " #190d1a",
                        padding: "16px 10px",
                      },
                      " & .MuiInputBase-formControl .Mui-focused .MuiOutlinedInput-notchedOutline":
                        { border: "1px solid #9747FF" },
                    }}
                    InputProps={{
                      sx: {
                        "&:hover": {
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #e5d5fc",
                          },
                        },
                      },
                    }}
                    fullWidth
                    value={item.habitDetail}
                    onChange={(e) => {
                      handleInputChange(e, i);
                    }}
                    type="text"
                    placeholder="Tell yourself why this habit"
                    id="outlined-start-adornment"
                  />
                  <div style={{ width: "100%" }}>

                    <CustomSelection
                      value={item.habitScheduleType}
                      displayEmpty
                      onChange={(e) => {
                        handleInputChange(e, i);
                      }}
                      name="habitScheduleType"
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                          disableScrollLock: true,
                        },
                      }}
                      placeholder="Select how often"
                      sx={{
                        background: '#FFF',
                        borderRadius: '16px',
                        // margin: '12px 0px 32px 0px',
                        // color: "#434243",
                        border: "1PX SOLID GREEN",
                        color: !item.habitScheduleType ? '#767476' : '#190D1A',
                        "& .MuiSelect-root": {
                          color: "#434243",
                        },
                      }}
                      className="AKSKASAJSKAJSA"
                    >
                      <MenuItem
                        value=""
                        disabled
                        sx={{
                          color: "#767476",
                        }}
                      >
                        Select how often
                      </MenuItem>
                      <MenuItem sx={{ color: "#434243" }} value={"DAILY"}>
                        Daily{" "}
                      </MenuItem>
                      <MenuItem
                        sx={{ color: "#434243" }}
                        value={"WEEKLY"}
                        onClick={() => {
                          setIsDayPickerOpen(true);
                        }}
                      >
                        Weekly{" "}
                      </MenuItem>
                      {/* <MenuItem
                    sx={{ color: "#434243" }}
                    value={"MONTHLY"}
                    onClick={() => {
                      setisDatePickerOpen(true);
                    }}
                  >
                    Monthly
                  </MenuItem> */}
                    </CustomSelection>
                  </div>
                  <Box sx={{
                    display: 'flex',
                    padding: '16px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '8px',
                    alignSelf: 'stretch',
                    borderRadius: '16px',
                    background: '#FFF',

                  }}>
                    <Typography variant="subtitle1" sx={{
                      color: '#767476',
                      fontSize: `${pxToRem(10)}!important`,
                      fontWeight: 400,
                      lineHeight: '100%', /* 10px */
                      letterSpacing: '-0.1px',

                    }}>
                      Select Difficulty
                    </Typography>
                    <div className={classes.customActionLevelGroup}>
                      <ToggleButtonGroup
                        color="primary"
                        value={item.level}
                        className={classes.customActionLevelGroup}
                        exclusive
                        onChange={(e, val) => {
                          handleChangeAction(val, i)
                        }}
                        size="small"
                        aria-label="Platform"
                      >
                        {XPLevels.map(it => (
                          <ToggleButton value={it.level} className={item.level === it.level ? classes.selectedtoggleButton : classes.toggleButton}> {
                            item.level === it.level ? <>
                              <Box sx={{
                                display: 'flex',
                                paddingRight: '8px',
                                alignItems: 'center',
                                gap: '6px',
                                borderRight: '1px solid #F3F3F3',
                              }}>
                                {it.icon} <span className="toggle-text">{it.level}</span>
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                paddingLeft: '8px',
                                alignItems: 'center',
                                gap: '4px',
                              }}>
                                <span className="toggle-text">{it.xp}</span>
                                <XPIcon />

                              </Box>
                            </> : <>
                              {it.icon} <span className="toggle-text">{it.level}</span>
                            </>
                          } </ToggleButton>

                        ))}
                      </ToggleButtonGroup>
                    </div>
                  </Box>
                  {i === habitInputData.length - 1 && (
                    <TeriteryButtonContained disabled={!(item.habitName && item.habitDetail && item.habitScheduleType && item.level)} onClick={() => {
                      if (item.saved) {
                        addMoreHandler()
                      } else {
                        savedAtIndex(i)
                      }
                    }}>{item.saved ? 'Add more' : 'Save'}</TeriteryButtonContained>
                  )}
                  {isDayPickerOpen && (
                    <GoalTimePicker
                      isDayPickerOpen={isDayPickerOpen}
                      dayModalCloseHandler={() => dayModalCloseHandler(i)}
                      setselectedDayHandler={setselectedDayHandler}
                      selectedDaystate={selectedDaystate}
                    />
                  )}
                  {isDatePickerOpen && (
                    <GoalDatePicker
                      isDatePickerOpen={isDatePickerOpen}
                      dateModalClosehandler={() => dateModalClosehandler(i)}
                      dateselectorHandler={dateselectorHandler}
                      selectedDate={selectedDate}
                    />
                  )}
                </Box>
              })}
            </Box>
          )}

        </div>
        <div style={{ width: "100%", position: "absolute", bottom: "0px", padding: "0 40px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.buttonWrapper}
            rowSpacing="12px"
            columnSpacing="12px"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                className={classes.previousButton}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                // onClick={onPrevious}
                onClick={() => {
                  onPrevious();
                  updateMainState();
                }}
              >
                Previous
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ paddingLeft: { xl: "12px", lg: "12px", md: "12px" } }}
            >
              <Button
                className={classes.nextButtonGrp}
                sx={{ ...typography.subtitle1 }}
                variant="outlined"
                onClick={() => {
                  updateMainStateNext();
                  stepSixNextHandler();
                }}
                disabled={
                  habitInputData[habitInputData.length - 1]?.habitName === "" ||
                  habitInputData[habitInputData.length - 1]?.habitDetail ===
                  "" ||
                  habitInputData[habitInputData.length - 1]?.habitScheduleType === "" ||
                  !habitInputData[habitInputData.length - 1]?.level
                }
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>

  );
}

export default HabitStepSix;
