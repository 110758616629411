import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
} from "@mui/material";
import { createRef, useEffect, useState } from "react";
import CloseButton from "../../../components/CloseButton";
import classes from "./../Dashboard.module.css";
const PlanJournalEntryModal = (props) => {
  const [value, setInputValue] = useState("");
  const textInput = createRef(null);
  const {
    openJournalModal,
    openCloseJournalModal,
    openJournalItem,
    addUpdateJournalData,
    savingJournal,
  } = props;
  useEffect(() => {
    console.log("openJournalItem", openJournalItem)
    if (openJournalItem?.journal) {
      setInputValue(openJournalItem?.journal);
    }
    setTimeout(() => {
      console.log("textInput", textInput.current?.focus);
      if (textInput.current?.focus) {
        textInput.current?.focus();
        textInput.current?.scrollIntoView();
      }
    }, 1000);

    return () => setInputValue("");
  }, [openJournalModal]);

  return (
    <Dialog
      open={openJournalModal}
      onClose={() => {
        openCloseJournalModal(false);
      }}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          borderRadius: "20px",
          // position: "unset",
          padding: "20px",
          position: 'relative'
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 'calc(100% - 20px)' }}>
        <div className={classes.modalTitle}>
          {openJournalItem?.actionName || openJournalItem?.habitName || ''}
        </div>
        
      </div>
      <div
          className={classes.closeIcon}
          onClick={() => {
            openCloseJournalModal(false);
          }}
        >
          <CloseButton />
        </div>
      <TextField
        id="standard-multiline-static"
        multiline
        InputProps={{
          disableUnderline: true,
          style: {
            fontWeight: 400,
            fontSize: "16px",
            letterSpacing: "-0.01em",

            color: "#190D1A",
          },
        }}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        value={value}
        autoFocus={true}
        minRows={20}
        ref={textInput}
        maxRows={20}
        defaultValue="Default Value"
        variant="standard"
      />
      <div className={classes.modalButtonDiv}>
        <LoadingButton
          loading={savingJournal}
          variant="contained"
          disabled={value.length > 0 ? false : true}
          className={classes.modalButton}
          onClick={(e) => {
            addUpdateJournalData(
              value,
            );
          }}
        >
          Save response
        </LoadingButton>
      </div>
    </Dialog>
  );
};

export default PlanJournalEntryModal;
