import { Button, Typography } from "@mui/material";
import classes from "./EmptyStateBox.module.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import HTMLTooltip from "../../components/ToolTipWithIcon/HTMLTooltip";
import { useDispatch } from "react-redux";
import { setStoreData } from "../../Store/Reducers/plans";
const getButtonTextToolTipRoute = (grwothNotFound = false, testNotCompleted = false,
    resultNotUnlocked = false, myPlans, dispatch) => {
        
    if (myPlans) {
        return {
            mainTitle: "You haven’t selected a plan",
            subtitle: "Select a plan to continue.",
            buttonText: "Select Plan",
            tooltip: "Your selected plans will be displayed here. You need to select a plan first. ",
            onClick: () => {
                dispatch(setStoreData({
                    activeTabObject: {
                        type: "ALL",
                        lable: "All",
                        isAll: true,
                        showIcon: false
                    }
                }))
            }
        }
    }
    if (testNotCompleted) {
        return {
            mainTitle: "You haven’t taken any test",
            subtitle: "Plans need data.",
            buttonText: "Start Test",
            tooltip: "Your plans will be displayed here. You need to complete a test first. ",
            routeName: "/testcategory"
        }
    } else if (resultNotUnlocked) {
        return {
            mainTitle: "You haven’t unlocked a dimension",
            subtitle: "Plans need dimensions.",
            buttonText: "Unlock dimension",
            tooltip: "Your plans will be displayed here. You need to unlock a dimension first. ",
            routeName: "/results"
        }
    } else if (grwothNotFound) {
        return {
            mainTitle: "You haven’t added any goal",
            subtitle: "Plans need goals.",
            buttonText: "Add growth goal",
            tooltip: "Your plans will be displayed here. You need to select a dimension as a growth goal first. ",
            routeName: "/goals"
        }
    } else {
        return {
            mainTitle: "You haven’t taken any test",
            subtitle: "Plans need data",
            buttonText: "Start Test",
            tooltip: "Your plans will be displayed here. You need to complete a test first. ",
            routeName: "/testcategory"
        }
    }
}

const EmptyStateBox = ({ activeTab, grwothNotFound = false, testNotCompleted = false,
    resultNotUnlocked = false, contentLoading }) => {
    console.log("grwothNotFound", grwothNotFound, grwothNotFound, testNotCompleted, resultNotUnlocked);
    let navigate = useNavigate();
    const dispatch = useDispatch()
    console.log("activeTab", activeTab)
    console.log("grwothNotFound", grwothNotFound)
    const { buttonText, routeName, tooltip, subtitle, mainTitle, onClick } = getButtonTextToolTipRoute(grwothNotFound, testNotCompleted, resultNotUnlocked, activeTab === "MY_PLANS", dispatch)
    return activeTab !== "FOR_YOU" && activeTab !== "MY_PLANS" && grwothNotFound ? null : <div className={classes.mainEmptyBox}>
        <div className={classes.emptyContentBox}>
            <div className={classes.illuBox}>
                <div className={classes.rectangle}></div>

                <div className={classes.illuCheckboxContent}>
                    {new Array(6).fill(1).map((it, index) => {
                        return <div key={index} className={classes.frameWithCheckboxChecked}>
                            <div className={classes.emptycheckbox}>
                                <div className={classes.emptyinnercheckbox}>
                                    {index < 3 && (
                                        <span style={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#9747FF',
                                            textAlign: 'center'
                                        }}>
                                            &#10003;
                                        </span>
                                    )}
                                </div>
                            </div>
                            {index < 3 ? (
                                <div className={classes.zeroindexbarlines}>

                                    {!index && (
                                        <>
                                            <div className={classes.zeroBar1}></div>
                                            <div className={classes.zeroBar2}></div>
                                        </>

                                    )}
                                    {index === 1 && (
                                        <>
                                            <div className={classes.oneBar1}></div>
                                            <div className={classes.oneBar2}></div>
                                            <div className={classes.oneBar3}></div>
                                        </>
                                    )}
                                    {index === 2 && (
                                        <>
                                            <div className={classes.twoBar1}></div>
                                            <div className={classes.twoBar2}></div>
                                            <div className={classes.twoBar3}></div>
                                            <div className={classes.twoBar4}></div>
                                        </>
                                    )}
                                </div>
                            ) : null}

                        </div>
                    })}
                </div>
            </div>
            <div className={classes.emptyTextBox}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#190D1A',
                    textAlign: 'center'
                }}>
                    {mainTitle || "You haven’t added unlocked a dimension"}
                </Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#434243',
                    textAlign: 'center'
                }}>
                    {contentLoading ? null : subtitle}

                </Typography>
            </div>
        </div>
        {!contentLoading && (
            <div className={classes.emptyActionButton}>
                <Button variant="contained" sx={{
                    background: '#9747FF',
                    borderRadius: '100px',
                    fontSize: '14px !important',
                    padding: '12px 16px',
                    height: '38px'
                }} onClick={() => {
                    if(!routeName) {
                        onClick()
                    } else {
                        navigate(routeName);
                    }
                }}>{buttonText}</Button>
                <HTMLTooltip
                    title={tooltip}
                    placement="right"
                    showArrow={true}
                    sx={{
                        '& .MuiTooltip-tooltip': {
                            background: "#FFF",
                            color: "#000",
                            maxWidth: "300px"
                        }
                    }}
                />
            </div>
        )}

    </div>
}

export default EmptyStateBox
