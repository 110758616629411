import { memo, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Header from "../../components/Header/Header";

import TestResultData from "./reportData"
import { useLocation } from "react-router-dom";
const TestResultCategoriesList = () => {

  const RightSideContentWrapper = styled("div")(({ theme }) => ({
    padding: "0px 20px",
    overflowY: "auto",
    height: "100vh",
  }));



  return (
    <RightSideContentWrapper>
      <Header />
      <TestResultData />
    </RightSideContentWrapper>
  );
};
export default memo(TestResultCategoriesList);
