import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import classes from "./SideBar.module.css";
import { Box } from "@mui/system";
import Dashboard from "../../assets/Svg/dashboard-icon.svg";
import Tests from "../../assets/Svg/tests-icon.svg";
import Settings from "../../assets/Svg/settings-icon.svg";
import Results from "../../assets/Svg/results-icon.svg";
import Plans from "../../assets/Svg/Plans.svg";
import Goals from "../../assets/Svg/goals-icon.svg";
import Growth from "../../assets/Svg/growth-icon.svg";
import Blog from "../../assets/Svg/Blog.svg";
import Store from "../../assets/Svg/store-icon.svg";
import HelpSidebar from "../../assets/Svg/HelpSidebar.svg";
import Logout from "../../assets/Svg/Logout.svg";
import Journals from "../../assets/Svg/Journals.svg"
import { Chip, Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { resetStore } from '../../Store/Reducers/onbordingSlice';
import TestIcon from "../../assets/Svg/TestIcon";
import ResultIcon from "../../assets/Svg/ResultIcon";
import GoalsIcon from "../../assets/Svg/GoalsIcon";
import SettingsIcon from "../../assets/Svg/SettingsIcon";
import { resetGrowthStore } from "../../Store/Reducers/goalGrowthCalendar";
import { resetUsrStore } from "../../Store/Reducers/userProfileSlice";
import { resetGrowthCalendarData } from "../../Store/Reducers/getCalendarData";
import { storeTestCategoriesList } from "../../Store/Reducers/testCategoriesList";
import { resetHeaderState } from "../../Store/Reducers/headerData";
import { resetGoalState } from "../../Store/Reducers/goalDataSlice";
import { resetDashboardState } from "../../Store/Reducers/dashboard";
import { resetJournalState } from "../../Store/Reducers/journals";

const SideBarMenu = [
  {
    name: "Home",
    navigation: '/dashboard',
    icon: Dashboard
  },
  {
    name: "Tests",
    navigation: '/testcategory',
    icon: Tests
  },
  {
    name: "Goals",
    navigation: '/goals',
    icon: Goals
  },
  !window.x_version && {
    name: "Growth calendar",
    navigation: '/growthCalendar',
    icon: Growth
  },
  {
    name: "Results",
    navigation: '/results',
    icon: Results
  },
  {
    name: "Journal",
    navigation: '/journals',
    icon: Journals
  },
  !window.x_version && {
    name: "Store",
    navigation: '/store',
    icon: Store
  },
  // {
  //   name: "Plans",
  //   navigation: '/plans',
  //   icon: Plans
  // },
  {
    name: "Settings",
    navigation: '/settings',
    icon: Settings
  },
]

const SideBar = ({ isGrowthSideBar = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useLocation();
  const [value, setValue] = useState("recent");
  const [sideBarValue, setSideBarValue] = useState(false);
  // console.log(value);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (path.pathname) {
      const foundName = [...SideBarMenu].filter(it => it).find(it => path.pathname.includes(it.navigation))
      foundName && foundName.name && setValue(foundName.name)
    }
    setSideBarValue(isGrowthSideBar);
  });
  const SideBarContent = styled("div")(({ theme }) => ({
    // padding: '20px',
    // background: 'yellow',
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  }));

  const SideBarItem = styled("div")(({ theme }) => ({
    width: "100%",
    // height: "2rem",
    margin: '10px 0',
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    // border: '1px solid black',
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  }));

  const MenuItems = styled(Typography)(({ theme }) => ({
    marginLeft: "8px",
    // border: '1px solid black',
    [theme.breakpoints.up("xl")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  }));
  const logOutHandler = () => {
    const ipAddress = localStorage.getItem("ipAddress")
    localStorage.clear();
    dispatch(resetStore())
    dispatch(resetGrowthStore())
    dispatch(resetUsrStore())
    dispatch(resetGrowthCalendarData())
    dispatch(storeTestCategoriesList([]));
    dispatch(resetHeaderState())
    dispatch(resetGoalState())
    dispatch(resetDashboardState())
    dispatch(resetJournalState())
    localStorage.setItem("ipAddress", ipAddress)

    navigate("/")

  }
  const navigatehandle = (url) => {

    navigate(url)
  }
  let gcStyles = {};
  if (isGrowthSideBar) {
    gcStyles = {
      justifyContent: 'center'
    }
  }
  return (
    <>
      {isGrowthSideBar === false ? (
        <Box className={classes.appLogo}>{window.florishApp ? "FlourishLabs" : "SSD"}
          {!window.florishApp && <Chip label="Beta" color="primary" sx={{
            '& .MuiChip-label': {
              fontWeight: 400,
              fontSize: '0.625rem',
              /* Main/White */
              padding: 0,
              color: '#FFFFFF'
            }
          }} className={classes.betaTag}></Chip>}</Box>
      ) : (
        <Box className={classes.appGrowthLogo}>
          <Typography
            component={"div"}
            className={classes.logoBox}
          >
            <span className={classes.logoText}>S</span>
          </Typography>
        </Box>
      )}
      <Box className={classes.sideBarItems}>
        {[...SideBarMenu].filter(it => it).map(it => (
          <SideBarItem key={it.name} onClick={it.navigation ? () => {

            navigatehandle(it.navigation)
            setTimeout(() => {
              localStorage.setItem("lastSelectedKey", it.name)
            }, 1000);
          } : null}>
            <img src={it.icon} alt={it.name} className={classes.sideBarIcons} />
            {isGrowthSideBar === false && (
              <MenuItems variant="body2" component="div" sx={{ ml: "8px", ...(value === it.name && { color: "#9747FF" }) }}>
                {it.name}
              </MenuItems>
            )}

          </SideBarItem>
        ))}
      </Box>
      <div className={classes.sideBarLogoutButton} >
        {!window.florishApp && (
          <SideBarItem /* className={classes.sideBarLogoutButton} */ style={gcStyles} onClick={() => window.open("https://blog.scientific-selfdiscovery.com", "_blank")}>
            <img src={Blog} alt="" className={classes.sidebarBottomIcon} />
            {isGrowthSideBar === false && (
              <MenuItems variant="body2" component="div" sx={{ ml: "8px", color: "#B4B0B4" }}>
                Blog
              </MenuItems>)}
          </SideBarItem>
        )}
        {!window.florishApp && (
          <SideBarItem /* className={classes.sideBarLogoutButton} */ style={gcStyles} onClick={() => window.open("https://scientific-selfdiscovery.com/help-centre", "_blank")}>
            <img src={HelpSidebar} alt="" className={classes.sidebarBottomIcon} />
            {isGrowthSideBar === false && (
              <MenuItems variant="body2" component="div" sx={{ ml: "8px", color: "#B4B0B4" }}>
                Help centre
              </MenuItems>)}
          </SideBarItem>
        )}
        <SideBarItem /* className={classes.sideBarLogoutButton} */ style={gcStyles} onClick={logOutHandler}>
          <img src={Logout} alt="" className={classes.sidebarBottomIcon} />
          {isGrowthSideBar === false && (
            <MenuItems variant="body2" component="div" sx={{ ml: "8px", color: "#190D1A" }}>
              Log out
            </MenuItems>)}
        </SideBarItem>
      </div>

    </>
  );
};

export default SideBar;
