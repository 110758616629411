import styled from "@emotion/styled";
import { CheckBox } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./ResultChart.module.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { borderRadius } from "@mui/system";
import Gift from "../../assets/Svg/Gift.svg";
import CloseSocial from "../../assets/Svg/CloseSocial.svg";
import Search from "../../assets/Svg/Search.svg";
import {
  RESULTSSUBTYPE,
  SOCIALGRAPHTYPE,
  TYPEOFRESULTS,
} from "../../Constant/Invite";
import SocialIndividul from "../SocialIndividual/SocialIndividul";
import {
  allDiamentionAverageByInviteUser,
  AllDiamentionAverageInvite,
  InviteUserList,
} from "../../services/auth";
import { createPortal } from "react-dom";
import BasicDateRangePicker from "../ResultRangePicker/ResultRangePicker";
import StaticDateRangePickerDemo from "../ResultRangePicker/ResultRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import Box from "@mui/material/Box";
import moment from "moment";
import { hover } from "@testing-library/user-event/dist/hover";
import { useDispatch, useSelector } from "react-redux";
import {
  updateIsSelectedUser,
  updateSelectedIndividualInvitId,
  updateSelectedInviteData,
} from "../../Store/Reducers/selectedInviteData";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  updateisLearnMore,
  updateisSocialResultNavigate,
  updateLearnMoreQuestionCategories,
  updateSelectedQuestionCategories,
} from "../../Store/Reducers/averageByInviteUser";
import DateRangePicker from "./DateRangePicker";
import { imageURLRender } from "../../utils";
import SocialEmptyResult from "./SocialEmptyResult";
function ResultChart({
  setSpacificPersonInviteId,
  setspecificInviterName,
  spacificPersonInviteId,
  setTypeofResult,
}) {
  const [resultsubtype, setResultsubtype] = useState(RESULTSSUBTYPE.AVERAGE);
  const [chartdata, setchartdata] = useState([]);
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [isAskOtherModalOpen, setIsAskOtherModalOpen] = useState(false);
  const [graphType, setGraphType] = useState("BASED_ON_PERSONAL_STANDARDS");
  const [value, setValue] = useState([new Date(), new Date()]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [searchResultArray, setSearchResultArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isCompare, setisCompare] = useState(false);
  const [PercentData, setPercentData] = useState([]);
  const [compare, setCompare] = useState([]);
  const [iconUrlArray, setIconUrlArray] = useState([]);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [questionCategoryId, setQuestionCategoryId] = useState([]);
  const [selectedQuetioncategoriesId, setselectedQuetioncategoriesId] =
    useState("");
  // const [IslearMore, setIslearMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [labelvalue, setlabelvalue] = useState("");
  const dispatch = useDispatch();
  const { averageByInviteUserSlice } = useSelector((state) => state);
  useEffect(() => {
    const uid = localStorage.getItem("userDetail");
    let type = "NORMAL";
    let from = "";
    let to = "";
    let feedbackType;
    if (graphType === "BASED_ON_AVERAGE_POPULATION") {
      feedbackType = "BASED_ON_AVERAGE_POPULATION";
    } else {
      feedbackType = "BASED_ON_PERSONAL_STANDARDS";
    }
    if (isCompare) {
      type = "COMPARE";
    }
    if (fromdate !== "") {
      from = fromdate;
      to = todate;
    }
    if (averageByInviteUserSlice?.isLearnMore) {
      let questionCategoryId =
        averageByInviteUserSlice?.selectedQuestioncategoriesId;
      allDiamentionAverageByInviteUser(
        uid,
        type,
        from,
        to,
        feedbackType,
        questionCategoryId
      )
        .then((data) => {
          setchartdata(data?.allPercentage);

          // set data
          let PercentGraphsData = data?.allPercentage?.map((data) => {
            return data.percent.toFixed();
          });
          setPercentData(PercentGraphsData);

          // set icons
          let iconsArray = data?.allPercentage?.map((data) => {
            return data?.icon?.url
              ? imageURLRender(data?.icon?.url)
              : Gift;
          });
          setIconUrlArray(iconsArray);

          // set compair data
          data?.allPercentage?.map((data) => {
            let comapairData = data?.compare?.map((item) => {
              return item.percent;
            });
            setCompare(comapairData);
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    } else {
      AllDiamentionAverageInvite(uid, type, from, to, feedbackType)
        .then((data) => {
          setchartdata(data?.allPercentage);

          // set data
          let PercentGraphsData = data?.allPercentage?.map((data) => {
            return Number(data.percent.toFixed());
          });
          setPercentData(PercentGraphsData);

          // set icons
          let iconsArray = data?.allPercentage?.map((data) => {
            return imageURLRender(data?.icon?.url);
          });
          setIconUrlArray(iconsArray);

          // set compair data
          data?.allPercentage?.map((data) => {
            let comapairData = data?.compare?.map((item) => {
              return Number(item.percent.toFixed());
            });
            setCompare(comapairData);
          });
          let quetioncategories = [];
          data?.allPercentage?.forEach((data) =>
            quetioncategories.push(data._id.questionCategory)
          );

          setQuestionCategories(quetioncategories);

          // set questionCategoriesID
          let questionCategoryIds = [];
          data?.allPercentage?.forEach((data) =>
            questionCategoryIds.push(data._id.questionCategoryId)
          );

          setQuestionCategoryId(questionCategoryIds);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }, [
    startPeriod,
    endPeriod,
    graphType,
    averageByInviteUserSlice?.isLearnMore,
  ]);

  const [inviteUserList, setInviteUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const uid = localStorage.getItem("userDetail");
    InviteUserList(uid)
      .then((res) => {
        setInviteUserList(res.data);
      })
      .catch((err) => {
        console.log("ye hello ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // isLearnMore function
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setIslearMore(true);
    dispatch(updateisLearnMore(true));
    dispatch(updateLearnMoreQuestionCategories(labelvalue));
    dispatch(updateSelectedQuestionCategories(selectedQuetioncategoriesId));
  };
  // for searching
  const SearchCategoryList = (e) => {
    setSearchValue(e.target.value);
    const filteredSocialUser = inviteUserList.filter((user) => {
      return user._id?.name
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchResultArray(filteredSocialUser);
  };
  // const rangePickerhandler = (start, end) => {
  //   setStartPeriod(moment(start).format("MMM Do"));
  //   setEndPeriod(moment(end).format("MMM Do"));
  //   setFromdate(moment(start).format("MM-DD-YYYY"));
  //   setTodate(moment(end).format("MM-DD-YYYY"));
  //   setisCompare(true);
  //   setIsAskOtherModalOpen(false);

  //   // const uid = localStorage.getItem("userDetail");
  //   // // const uid = "tdl76tm6yJO2bGk3ac1Ro3rhlax2";
  //   // const type = "COMPARE";
  //   // let from;
  //   // let to;
  //   // let feedbackType
  //   // if(graphType==="BASED_ON_AVERAGE_POPULATION"){
  //   //   feedbackType=BASED_ON_AVERAGE_POPULATION
  //   // }
  //   // else{
  //   //   feedbackType=BASED_ON_AVERAGE_POPULATION
  //   // }
  //   // // const  = "BASED_ON_AVERAGE_POPULATION";
  //   // console.log("5555555", fromdate, to);
  //   // if (fromdate !== "") {
  //   //   from = fromdate;
  //   //   to = todate;
  //   // }
  //   // AllDiamentionAverageInvite(uid, type, from, to, feedbackType)
  //   //   .then((data) => {
  //   //     setchartdata(data?.allPercentage);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   })
  //   //   .finally(() => {});
  // };

  const today = new Date();
  const CustomiseDateRangePicker = styled(StaticDateRangePicker)`
    & .MuiDateRangePickerViewDesktop-root {
      margin: auto;
      justify-content: center;
    }
    // & .MuiButtonBase-root {
    //   background-color: #fff;
    //   color: #190d1a;
    //   border-radius: 4px;
    // }
    // .MuiDateRangePickerDay-root {
    //   background-color: #fff;
    // }
    // .MuiButtonBase-root:focus.Mui-selected {
    //   background-color: #9747ff;
    // }
  `;
  const IndividualButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "10px",
    fontSize: "10px !important",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  }));
  const AverageButton = styled(Button)(({ theme }) => ({
    height: "30px",
    borderRadius: "4px",
    fontSize: "10px !important",
    padding: "4px 8px 4px 8px",

    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  }));
  const IndividualAverage = styled("div")(({ theme }) => ({
    padding: "4px 8px 4px 8px",
    borderRadius: "6px",
    background: "#F9F9F9",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
    },
  }));
  const CompareButton = styled(Button)(({ theme }) => ({
    borderRadius: "16px",
    color: " #9747FF",
    height: "28px",
    background: "#F7F2FE",
    border: "1px solid #f7f2fe",
    fontSize: "12px",
    marginLeft: "16px",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
    },
  }));
  const SaveButton = styled(Button)(({ theme }) => ({
    borderRadius: "16px",
    color: " #9747FF",
    height: "28px",
    background: "#F7F2FE",
    border: "1px solid #f7f2fe",
    fontSize: "12px",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
    },
  }));
  const CustomRadio = styled(Radio)`
    // &.MuiButtonBase-root {
    // padding: 0px 9px;
    // }
    &.highcharts-background {
      fill: green !important;
    }
    & .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }
  `;
  const CalanderDialog = styled(Dialog)`
    & .MuiDialog-container {
      & .MuiPaper-root {
        min-width: 388px;
        min-height: 384px;
        border-radius: 20px;
      }
    }
  `;

  let myArray = [];
  var categoryLinks = {
    iconUrlArray,
  };
  var categoryvalue = {
    questionCategories,
  };
  const Chart = (props) => {
    const [options] = useState({
      chart: {
        type: "column",
        events: chartdata.length !== 0 &&
          !averageByInviteUserSlice?.isLearnMore &&
          !isCompare && {
            legendItemClick: function () {
              this.slice(null);
              return false;
            },
            load() {
              let chart = this;

              chart.xAxis[0].labelGroup.div.childNodes.forEach(function (
                label
              ) {
                label.style.cursor = "pointer";
                label.onclick = function () {
                  setselectedQuetioncategoriesId(
                    label.childNodes[0].childNodes[4].innerText
                  );
                  setOpen(true);
                  setlabelvalue(label.childNodes[0].childNodes[2].innerText);
                };
              });
            },
          },
      },
      title: {
        text: "",
      },
      legend: {
        style: {
          color: "#efefef",
        },
        itemStyle: {
          color: "#efefef",
        },
        itemHoverStyle: {
          color: "grey",
        },
      },
      yAxis: {
        min: 0, // Lowest value to show on the yAxis
        max: 100,
        tickPixelInterval: 200,
        title: {
          text: "", // Title for the yAxis
        },
        barPercentage: 0.5,
        barThickness: 0,
      },
      xAxis: {
        categories: [categoryvalue?.questionCategories,"lalo"],
        title: {
          text: null, // Title for the yAxis
        },
        tickInterval: 10,
        startOnTick: true,
        tickMarkPlacement: "between",
        labels: {
          useHTML: true,

          formatter: function () {
            return (
              '<div style="background: #F7F2FE;border-radius: 50%; width: 28px; height: 28px;display: flex;justify-content: center;align-items: center;"><img  style="width:28px;height:28px" src=' +
              categoryLinks?.iconUrlArray.map((image) => image) +
              "> <div style='display:none;'>" +
              categoryvalue?.questionCategories[this.value] +
              "</div> <span style='display:none;'>" +
              questionCategoryId[this.value] +
              "</span></div>"
            );
          },
        },
      },
    //   chart: {
    //     marginBottom: 1,
    //     marginLeft: 0,
    //     marginRight: 0,
    //     marginTop: 0,
    //     renderTo: container,
    //     type: 'column',
    // },
      series: [
        {
          name: "Current results",
          data: PercentData,
          color: "#F7F2FE",
          pointPlacement: !compare && 0.152,
          states: {
            hover: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
            color: "#9747FF",
            borderRadius: 50,
            backgroundColor: "#9747FF",
            borderWidth: 1,
            states: {
              hover: {
                enabled: false,
              },
            },
            style: {
              fontSize: "0px",
            },
          },
        },
        startPeriod === ""
          ? {
              name: "Previous results",
            }
          : {
              name: "Previous results",
              data: compare,
              color: "#EF8B6E",
              states: {
                hover: {
                  enabled: false,
                },
              },
              dataLabels: {
                enabled: true,
                color: "#EF8B6E",
                borderRadius: 50,
                backgroundColor: "#EF8B6E",
                borderWidth: 1,
                style: {
                  fontSize: "0px",
                },
              },
            },
      ],
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          pointWidth: 10,
          // pointPadding: 15,
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
            },
          },
        },
        legendItemClick: false,
      },
      legend: startPeriod !== "" && {
        style: {
          color: "#efefef",
        },
        itemStyle: {
          color: "#efefef",
        },
        itemHoverStyle: {
          color: "#434243",
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        align: "left",
        itemStyle: {
          color: "#434243",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "400",
        },
      },
    });
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ className: props.className }}
      />
    );
  };

  const HighChartWrapper = styled(Chart)`
    /* some styles here */
    // .highcharts-plot-border {
    //   fill: #ffffff !important;
    // }
    // .highcharts-crosshair-label {
    //   stroke-width: 1px;
    //   stroke: gray;
    // }
  `;
  const InputField = styled(TextField)`
  height: 40px;
  background: #F9F9F9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-input {
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }
  }
`;
  return (
    <>
      {" "}
      <div
        className={
          resultsubtype === RESULTSSUBTYPE.INDIVIDUAL
            ? classes.indivdualWrapper
            : classes.mainWrapper
        }
      >
        <div className={classes.headerWrapper}>
          {resultsubtype === RESULTSSUBTYPE.AVERAGE ? (
            <div>
              <Typography variant="h4" sx={{ fontSize: "28px" }}>
                {averageByInviteUserSlice?.isLearnMore
                  ? `Results on ${averageByInviteUserSlice?.learnMoreQuestioncategories}`
                  : "Average results from all respondents"}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="h4" sx={{ fontSize: "28px" }}>
                Select a person to view how they scored
              </Typography>
            </div>
          )}
          <div>
            {!averageByInviteUserSlice?.isLearnMore && (
              <IndividualAverage>
                <IndividualButton
                  variant={
                    resultsubtype === RESULTSSUBTYPE.INDIVIDUAL
                      ? "contained"
                      : null
                  }
                  onClick={() => {
                    setResultsubtype(RESULTSSUBTYPE.INDIVIDUAL);
                    dispatch(updateisSocialResultNavigate(false));
                  }}
                >
                  Individual
                </IndividualButton>
                <AverageButton
                  variant={
                    resultsubtype === RESULTSSUBTYPE.AVERAGE
                      ? "contained"
                      : null
                  }
                  onClick={() => {
                    setResultsubtype(RESULTSSUBTYPE.AVERAGE);
                  }}
                >
                  Average
                </AverageButton>
              </IndividualAverage>
            )}
          </div>
        </div>
        {/* <div>
       <CustomHighchartsReact highcharts={Highcharts} options={options} />
     </div> */}

        {/* serach name */}
        {resultsubtype === RESULTSSUBTYPE.INDIVIDUAL && inviteUserList.length > 0 && (
          <div className={classes.SearchBar}>
            <InputField
              size="small"
              fullWidth
              type="text"
              autoFocus={searchValue !== ""}
              value={searchValue}
              disabled={inviteUserList.length === 0}
              onChange={(e) => SearchCategoryList(e)}
              placeholder="Type in a name"
              id="outlined-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Search} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        {resultsubtype === RESULTSSUBTYPE.AVERAGE ? (
          // IslearMore ? (
          <div>
            {" "}
            <div>
              {chartdata.length !== 0 ? <HighChartWrapper /> : <SocialEmptyResult />}
              
            </div>
            {startPeriod !== "" && (
              <Divider sx={{ margin: "4px 16px 24px 16px" }} />
            )}
            {chartdata.length !== 0 ? <div className={classes.footerWrapper}>
              <div>
                {startPeriod === "" ? (
                  <CompareButton
                    variant="outlined"
                    sx={{ fontSize: "12px !important" }}
                    onClick={() => {
                      setIsAskOtherModalOpen(true);
                    }}
                  >
                    Compare periods
                  </CompareButton>
                ) : (
                  <CompareButton
                    variant="outlined"
                    sx={{ fontSize: "12px !important" }}
                    onClick={() => {
                      setStartPeriod("");
                      setValue([new Date(), new Date()]);
                      setFromdate("");
                      setTodate("");
                      setisCompare(false);
                    }}
                  >
                    {startPeriod} - {endPeriod}
                    <img
                      style={{
                        height: "10px",
                        width: "10px",
                        marginLeft: "7px",
                      }}
                      src={CloseSocial}
                      alt=""
                    />
                  </CompareButton>
                )}
              </div>
              <div className={classes.AvgPrnWrapper}>
                <div className={classes.AvgPopulationBtn}>
                  <CustomRadio
                    label="Average population"
                    value="BASED_ON_AVERAGE_POPULATION"
                    checked={graphType === "BASED_ON_AVERAGE_POPULATION"}
                    onChange={(e) => setGraphType(e.target.value)}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#434243",
                      margin: "0px",
                    }}
                  >
                    Average population
                  </Typography>
                </div>
                <div className={classes.PersPopulationBtn}>
                  <CustomRadio
                    label="Personal standard"
                    value="BASED_ON_PERSONAL_STANDARDS"
                    checked={graphType === "BASED_ON_PERSONAL_STANDARDS"}
                    onChange={(e) => setGraphType(e.target.value)}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#434243",
                      margin: "0px",
                    }}
                  >
                    Personal standard
                  </Typography>
                </div>
              </div>
            
            </div> : null}
            
          </div>
        ) : (
          // ) : (
          //   <div>
          //     {" "}
          //     <div>
          //       <HighChartWrapper />
          //     </div>
          //     {startPeriod !== "" && (
          //       <Divider sx={{ margin: "4px 16px 24px 16px" }} />
          //     )}
          //     <div className={classes.footerWrapper}>
          //       <div>
          //         {startPeriod === "" ? (
          //           <CompareButton
          //             variant="outlined"
          //             sx={{ fontSize: "12px !important" }}
          //             onClick={() => {
          //               setIsAskOtherModalOpen(true);
          //             }}
          //           >
          //             Compare periods
          //           </CompareButton>
          //         ) : (
          //           <CompareButton
          //             variant="outlined"
          //             sx={{ fontSize: "12px !important" }}
          //             onClick={() => {
          //               setStartPeriod("");
          //               setValue([new Date(), new Date()]);
          //               setFromdate("");
          //               setTodate("");
          //               setisCompare(false);
          //             }}
          //           >
          //             {startPeriod} - {endPeriod}
          //             <img
          //               style={{
          //                 height: "10px",
          //                 width: "10px",
          //                 marginLeft: "7px",
          //               }}
          //               src={CloseSocial}
          //               alt=""
          //             />
          //           </CompareButton>
          //         )}
          //       </div>
          //       <div className={classes.AvgPrnWrapper}>
          //         <div className={classes.AvgPopulationBtn}>
          //           <CustomRadio
          //             label="Average population"
          //             value="BASED_ON_AVERAGE_POPULATION"
          //             checked={graphType === "BASED_ON_AVERAGE_POPULATION"}
          //             onChange={(e) => setGraphType(e.target.value)}
          //           />
          //           <Typography
          //             variant="h6"
          //             sx={{ fontSize: "14px", color: "#434243" }}
          //           >
          //             Average population
          //           </Typography>
          //         </div>
          //         <div className={classes.PersPopulationBtn}>
          //           <CustomRadio
          //             label="Personal standard"
          //             value="BASED_ON_PERSONAL_STANDARDS"
          //             checked={graphType === "BASED_ON_PERSONAL_STANDARDS"}
          //             onChange={(e) => setGraphType(e.target.value)}
          //           />
          //           <Typography
          //             variant="h6"
          //             sx={{ fontSize: "14px", color: "#434243" }}
          //           >
          //             Personal standard
          //           </Typography>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // )
          <Grid
            container
            spacing={2}
            sx={{ width: "100%" }}
            className={classes.socialCardMainWrapper}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "calc(100vh - 228px)",
                }}
              >
                <CircularProgress />
              </div>
            ) : searchResultArray.length !== 0 ? (
              searchResultArray?.map((ele, index) => {
                const { _id, inviteID } = ele;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    xl={3}
                    key={index}
                    sx={{ cursor: "pointer" }}
                    className={classes.socialGrid}
                    onClick={() => {
                      setspecificInviterName(_id?.name);
                      setSpacificPersonInviteId(_id?.inviteUserUid);
                      setTypeofResult(TYPEOFRESULTS.PERSONAL);
                      dispatch(updateSelectedInviteData(_id));
                      dispatch(updateIsSelectedUser(true));
                      dispatch(updateSelectedIndividualInvitId(inviteID));
                    }}
                  >
                    <div className={classes.socialCardWrapper}>
                      <div className={classes.userIconeWrapper}>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: "#9747FF",
                            height: "10px",
                            widht: "16px",
                            textAlign: "center",
                          }}
                        >
                          {_id.name
                            .split(" ")
                            .map((word) => word[0])
                            .join(" ")}
                        </Typography>
                      </div>
                      <div className={classes.textWrapper}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 }}
                        >
                          {_id?.name}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                );
              })
            ) : (
              inviteUserList.length ? inviteUserList?.map((ele, index) => {
                const { _id, inviteID } = ele;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    xl={3}
                    key={index}
                    sx={{ cursor: "pointer" }}
                    className={classes.socialGrid}
                    onClick={() => {
                      setSpacificPersonInviteId(_id?.inviteUserUid);
                      setspecificInviterName(_id?.name);
                      setTypeofResult(TYPEOFRESULTS.PERSONAL);
                      dispatch(updateSelectedInviteData(_id));
                      dispatch(updateSelectedIndividualInvitId(inviteID));
                      dispatch(updateIsSelectedUser(true));
                    }}
                  >
                    <div className={classes.socialCardWrapper}>
                      <div className={classes.userIconeWrapper}>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: "#9747FF",
                            height: "10px",
                            widht: "16px",
                            textAlign: "center",
                          }}
                        >
                          {_id.name
                            .split(" ")
                            .map((word) => word[0])
                            .join(" ")}
                        </Typography>
                      </div>
                      <div className={classes.textWrapper}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 }}
                        >
                          {_id?.name}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                );
              }) : <SocialEmptyResult />
            )}
          </Grid>
        )}
      </div>
      <>
        {isAskOtherModalOpen && (
          <CalanderDialog
            open={isAskOtherModalOpen}
            onClose={() => setIsAskOtherModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DateRangePicker
              value={value}
              setValue={setValue}
              // rangePickerhandler={rangePickerhandler}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              startTime={startTime}
              endTime={endTime}
              setStartPeriod={setStartPeriod}
              setEndPeriod={setEndPeriod}
              setFromdate={setFromdate}
              setTodate={setTodate}
              setisCompare={setisCompare}
              setIsAskOtherModalOpen={setIsAskOtherModalOpen}
            />
          </CalanderDialog>
        )}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "#9747FF",
              color: "white",
              width: "186px",
              minHeight: "92px",
              borderRadius: "12px",
              padding: "16px",
              // height: 'auto',
            },
          }}
        >
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              height: "100%",
              padding: "0px",
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                color: "#FFFFFF",
                fontSize: "14px !important",
                width: "100%",
                padding: "0px !important",
              }}
            >
              {labelvalue}
            </DialogTitle>
            {/* <DialogContent>
     <DialogContentText id="alert-dialog-slide-description" sx={{color:'#FFFFFF',fontSize: '14px'}}>
     </DialogContentText>
   </DialogContent> */}
            <Button
              onClick={handleClose}
              sx={{
                background: "#F7F2FE",
                borderRadius: " 16px",
                width: "154px",
                height: "28px",
                color: "#9747FF",
                textAlign: "center",
                padding: "8px 12px",
                fontSize: "12px !important",
                ":hover": { backgroundColor: "#FFFFFF" },
              }}
            >
              Learn more...
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
}
export default React.memo(ResultChart);
