import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
} from "@mui/material";
import { createRef, useEffect, useState } from "react";
import CloseButton from "../../../components/CloseButton";
import classes from "./plaanModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setStoreData, updateJournalOfPlanAction } from "../../../Store/Reducers/plans";
import PlansServices from "../../../services/Plans";
const JournalEntryModal = (props) => {
  const [savingJournal, setSavingJournal] = useState(false)
  const dispatch = useDispatch()
  const [value, setInputValue] = useState("");
  const textInput = createRef(null);
  const { visibleJournalModal, selectedJournalAction, selectedAction, planActions } = useSelector((state) => state.planData);
  console.log("selectedJournalAction", selectedJournalAction, selectedAction, planActions)

  const addUpdateJournalData = () => {
    setSavingJournal(true)
    PlansServices.updateJournal({
      "userPlanActionId": selectedJournalAction.userPlanActionId,
      "journal": value
    }).then(() => {
      dispatch(updateJournalOfPlanAction({
        value
      }))
      dispatch(setStoreData({
        visibleJournalModal: false,
      }));
    }).catch((err) => {
      console.log("err", err)
    }).finally(() => {
      setSavingJournal(false)
    })
  }
  useEffect(() => {
    if (selectedJournalAction?.journal) {
      setInputValue(selectedJournalAction?.journal);
    }
    setTimeout(() => {
      console.log("textInput", textInput.current?.focus);
      if (textInput.current?.focus) {
        textInput.current?.focus();
        textInput.current?.scrollIntoView();
      }
    }, 1000);

    return () => setInputValue("");
  }, [visibleJournalModal]);
  const handleCloseModal = () => {
    dispatch(setStoreData({
      visibleJournalModal: false
    }));
  }
  return (
    <Dialog
      open={visibleJournalModal}
      onClose={handleCloseModal}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          borderRadius: "20px",
          // position: "unset",
          padding: "20px",
          position: 'relative'
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ display: "flex", justifyContent: "space-between", maxWidth: 'calc(100% - 20px)' }}>
        <div className={classes.modalTitleJournal}>
          {selectedJournalAction?.actionName || selectedJournalAction?.habitName || ''}
        </div>

      </div>
      <div
        className={classes.closeIcon}
        onClick={handleCloseModal}
      >
        <CloseButton />
      </div>
      <TextField
        id="standard-multiline-static"
        multiline
        InputProps={{
          disableUnderline: true,
          style: {
            fontWeight: 400,
            fontSize: "16px",
            letterSpacing: "-0.01em",

            color: "#190D1A",
          },
        }}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        value={value}
        autoFocus={true}
        minRows={20}
        ref={textInput}
        maxRows={20}
        defaultValue="Default Value"
        variant="standard"
      />
      <div className={classes.modalButtonDiv}>
        <LoadingButton
          loading={savingJournal}
          variant="contained"
          disabled={value.length > 0 ? false : true}
          className={classes.modalButton}
          onClick={(e) => {
            addUpdateJournalData();
          }}
        >
          Save response
        </LoadingButton>
      </div>
    </Dialog>
  );
};

export default JournalEntryModal;
