import { ReactComponent as Trival } from "../assets/Svg/Trival.svg";
import { ReactComponent as Easy } from "../assets/Svg/Easy.svg";
import { ReactComponent as Medium } from "../assets/Svg/Medium.svg";
import { ReactComponent as Hard } from "../assets/Svg/Hard.svg";
import { IMAGE_BASE_URL } from "../services/config";
import axios from "axios";
export const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  let counter = "";
  if (Number(d) > 0) counter += d + "d ";
  if (Number(h) <= 0 && counter.length) counter += h + "h ";
  if (Number(h) > 0) counter += h + "h ";
  if (Number(m) <= 0 && counter.length) counter += m + "m ";
  if (Number(m) > 0) counter += m + "m ";
  if (Number(s) <= 0 && counter.length) counter += s + "s ";
  if (Number(s) > 0) counter += s + "s ";
  return counter;
};
export const XPLevels = [
  {
    icon: <Trival />,
    level: "Trivial",
    xp: 1,
  },
  {
    icon: <Easy />,
    level: "Easy",
    xp: 3,
  },
  {
    icon: <Medium />,
    level: "Medium",
    xp: 6,
  },
  {
    icon: <Hard />,
    level: "Hard",
    xp: 10,
  },
];
export const FindAndGetXPLevel = (val, returnFor = "name") => {
  const foundXp = XPLevels.findIndex((it) => {
    if (returnFor === "icon") {
      return it.xp === val;
    }
    if (returnFor === "name") {
      return it.xp === val;
    }
    return it.level === val;
  });
  if (foundXp > -1) {
    if (returnFor === "icon") {
      return XPLevels[foundXp].icon;
    }
    if (returnFor === "name") {
      return XPLevels[foundXp].level;
    }
    return XPLevels[foundXp].xp;
  }
  return null;
};

export const randomXP = () => {
  const random = Math.floor(Math.random() * XPLevels.length);
  return XPLevels[random];
};

export const imageURLRender = (imageUrl) => {
  if (imageUrl) {
    if(imageUrl.includes("/public")) {
      return `${IMAGE_BASE_URL}${imageUrl.replace("/public", "")}`;
    }
    return imageUrl
  }
  return "";
};

export const userNamerender = (name = '') => {
  const arr = name.split(" ");
  const stringArrr = []
  for (var i = 0; i < arr.length; i++) {
    stringArrr.push(arr[i].charAt(0).toUpperCase())
  }
  return stringArrr.join("")
}

export const firstLetterUpper = (str) => {
  const arr = str.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const str2 = arr.join(" ");
  console.log("str2", str2);
  return str2
};
export function CheckImage(path) {
  axios
    .get(path)
    .then((data) => {
      console.log("data", data);
      return true;
    })
    .catch(() => {
      return false;
    });
}
