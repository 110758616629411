import styled from "@emotion/styled";
import { Checkbox, Typography } from "@mui/material";

export const StepsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  // top: "48px",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    top: "12px",
  },
}));

export const Step = styled("div")(({ theme }) => ({
  width: "124.5px",
  height: "12px",
  border: "1px solid #F7F2FE",
  borderRadius: "10px",
  background: "#F7F2FE",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  [theme.breakpoints.down("sm")]: {
    //   width: "82px",
    height: "8px",
  },
}));
export const RightSideContentWrapper = styled("div")(({ theme }) => ({
  padding: "0px 20px",
  overflow: "hidden",
  height: "calc(100% - 20px)",
  zIndex: 0,
  // position: 'relative',
  overflowY: 'auto',
  [theme.breakpoints.down("sm")]: {
    padding: "0px 0px",
  },
}));
export const SchedualdateWrapper = styled("div")(({ theme }) => ({
  width: "48px",
  height: "48px",
  borderRadius: " 50%",
  border: "2px solid #f7f2fe",

  [theme.breakpoints.down("sm")]: {
    width: "32px",
    height: "32px",
  },
}));
export const SchedualdateWrapperNew = styled("div")(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: " 50%",
  border: "2px solid #f7f2fe",
 


}));
export const CustomCheckBox = styled(Checkbox)`
  padding: 0;
  width: 100%;
   & .MuiTextField-root {
    width: 100%;
  }
`;

export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "42px",
  [theme.breakpoints.down("md")]: {
    order: "2",
    marginTop: "12px",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    order: "2",
    marginTop: "12px",
    fontSize: "28px",
  },
}));