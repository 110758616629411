import classes from "../Onbording.module.css";
import Box from "@mui/material/Box";
import Text from "../../../components/Text/Text";
import Input, {
  CheckBoxInput,
  CheckBoxInputChecked,
  InputCheckBox,
} from "../../../components/Input/Input";
import { PrevAndNextButtonGroup } from "../../../components/Button/Button";
import { Step, StepsWrapper } from "../StyledComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateMotivates, updateSelectedTopic, updateTopicData, updateTopicDataProfile, updateTopicDataYoutubeLink } from "../../../Store/Reducers/onbordingSlice";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { getTopicList, getUserData, updateUserData, updateUserSignupData } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { Autocomplete, CircularProgress, IconButton, InputAdornment, Popper, Snackbar, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useNotifications from "../../../Hooks/useNotifications";
import moment from "moment";
import { userProfileUpdate } from "../../../Store/Reducers/userProfileSlice";
import CloseSmalIcon from "../../../assets/Svg/CloseSmalIcon.svg";
export default function StepGoal({ onNext, onPrevious }) {
  const { motivates, otherMotivationesValue, name, imageUrl, age, gender, surname, dob, topicData, selectedTopic} =
    useSelector((state) => state.onbording);
  console.log("selectedTopic", selectedTopic, );
  console.log("surName", surname, );
  const [motivatesValue, setMotivatesValue] = useState(motivates);
  const [motivatesName, setMotivatesName] = useState([]);
  const [topicInput, setTopicInput] = useState(selectedTopic.topic || "");
  const [isLoading, setIsLoading] = useState(false);
  const { Notification, updateNotificationState } = useNotifications(false);
  const [inputFocus, setInputFocus] = useState(true);
  const [others, setOthers] = useState(otherMotivationesValue);
  let navigate = useNavigate();
  console.log("topicInput", selectedTopic, topicInput);
  useEffect(() => {
    if(!topicData.length) {
      getTopicList().then(data => {
        const { topicData = []} = data
        dispatch(updateTopicData(topicData))
      })
    }

  })
  const dispatch = useDispatch();
  const onNextStep = () => {
    // dispatch(updateMotivates({ motivatesName, others }));
    // onNext();
    const uid = localStorage.getItem("userDetail");
    const currentYear = new Date().getFullYear() - new Date(dob).getFullYear()
    let userData = { name, surName: surname, imageUrl, age: parseInt(currentYear), gender, uid, dob: moment(dob).format("MM-DD-yyyy"), motivates: others !== "" ? [...motivatesName, others] : [...motivatesName], topicId: selectedTopic.topicId };
    // const userData = {name,age: parseInt(age),gender,motivates: [...motivatesName,others],uid};
    // if (others !== "") {
    //   userData.motivates = [...motivatesName, others];
    // } else {
    //   userData.motivates = [...motivatesName];
    // }

    // updateUserData(userData)
    //   .then((data) => {
    //     console.log(data);
    //     getUserData(uid)
    //     .then((response) => {
    //       console.log(response.data);
    //       // navigate("/onbording");
    //       dispatch(userProfileUpdate(response.data));
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    //     // Navigate("/onbording");
    //     localStorage.setItem("newSignup", "true")
    //     navigate("/testcategory");
    //   })
    //   .catch((error) => {
    //     console.log(error.response.data.description);
    //     if(error.response.data.description === "Uid is not registered"){
    //       updateNotificationState("User is not Registered yet");
    //     }
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
    updateUserSignupData(userData).then(data => {
      const {topicData
      } = data
      console.log("===== topic data", topicData)
      if(topicData.link) {
        dispatch(updateTopicDataYoutubeLink(window.florishApp ? topicData.linkFlourish :topicData.link))
      }
      getUserData(uid)
        .then((response) => {
          console.log(response.data);
          // navigate("/onbording");
          dispatch(userProfileUpdate(response.data));
          if(topicData) {
            dispatch(updateTopicDataProfile(topicData))
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
        localStorage.setItem("newSignup", "true")
        navigate("/testcategory");
    })
      .catch((error) => {
        console.log(error.response.data.description);
        if(error.response.data.description === "Uid is not registered"){
          updateNotificationState("User is not Registered yet");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const CustomCheckBox = styled(Checkbox)`
    padding: 0;
    width: 100%;
    & .MuiTextField-root {
      width: 100%;
    }
  `;

  const handelChange = (event) => {
    setMotivatesValue({
      ...motivatesValue,
      [event.target.name]: event.target.checked,
    });
    let currvalue = [...motivatesName];
    if (event.target.checked) {
      if (!currvalue.includes(event.target.name)) {
        currvalue.push(event.target.name);
        setMotivatesName(currvalue);
      }
    } else {
      if (currvalue.includes(event.target.name)) {
        let newMotivationList = currvalue.filter(
          (value) => value !== event.target.name
        );
        setMotivatesName(newMotivationList);
      }
    }
  };

  const InputField = styled(TextField)`
  height: 56px;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-input {
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #434243;
    }
  }
`;
const PopperMy = function (props) {
  return <Popper {...props} sx={{
    borderRadius: '16px',
    background: '#FFF',
    padding: '20px',
    boxShadow: '0px 4px 40px 0px rgba(17, 17, 17, 0.08)',
    marginTop: '-5px !important',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      '& li': {
        marginLeft: 0
      },
      '& .MuiListSubheader-root': {
        color: '#B4B0B4',
        padding: 0
      }
    }
  }} placement="bottom-start" />;
};
  const foundValueOption = topicData.find(it => it.topic === topicInput)
  return (
    <>
          <StepsWrapper>
            <Step className={classes.active}></Step>
            <Step className={classes.active}></Step>
            <Step className={classes.active}></Step>
            {/* <Step ></Step> */}
          </StepsWrapper>
          <Box className={classes.wrapper} sx={{ mb: "16px", py: "1px" }}>
            <Text
              textValue="What do you most want to change or improve?"
              variant="h2"
            />
          </Box>
          <Box className={classes.wrapper}>
            <Text
              textValue="Select a category to start your journey"
              variant="subtitle1"
            />
          </Box>
          <Box className={classes.wrapper}>
        <Text textValue="We use to recommend you a test." variant="subtitle1" color="#A59EA6" />
      </Box>
          <Box className={classes.wrapper}>
          <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              sx={{
                width: '100%'
              }}
              PopperComponent={PopperMy}
              disableClearable
              onChange={(e, val) => {
                console.log("val", val)
                const found = topicData.find(it => it.topic === val.topic)
                console.log("found", found);
                if(found) {
                  setTopicInput(found.topic)
                  dispatch(updateSelectedTopic(found))
                } else {
                  dispatch(updateSelectedTopic({}))

                }
                // setQuestionText(val)
              }}
              value={selectedTopic}
              inputValue={topicInput}
              renderOption={(props, option) => {
                console.log("option", option);
                return (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, borderBottom: option.topic !== topicData[topicData.length - 1].topic &&  '1px solid #F7F2FE', marginLeft: 0, padding: '12px 0 12px 0!important', }} {...props}>
                    <Typography sx={{ color: '#190D1A', fontSize: '14px', fontWeight: 400 }}>
                      {option.topic}
                    </Typography>
                  </Box>
                )
              }}
              getOptionLabel={(option) => option.topic}
              options={topicData}
              renderInput={(params) => (
                <InputField
                  value={topicInput}
                  onChange={(e) => {
                    if(!e.target.value) {
                      dispatch(updateSelectedTopic({}))
                    }
                    if(!topicData.find(it => it.topic === e.target.value)) {
                      dispatch(updateSelectedTopic({}))
                    }
                    setTopicInput(e.target.value)
                  }}
                  size="small"
                  fullWidth
                  autoFocus={inputFocus}
                  type="text"
                  placeholder="Start typing"
                  id="outlined-start-adornment"
                  {...params}
                />
              )}
            />
          </Box>
         
          <Box sx={{ width: "100%" }}>
            <PrevAndNextButtonGroup
              isPreviousButton={true}
              onNext={onNextStep}
              onPrevious={onPrevious}
              nextButtonText="Save"
              isDisabled={!Object.keys(selectedTopic).length || !foundValueOption}
              isLoading={isLoading}
            />
          </Box>
          {Notification}
    </>
  );
}
