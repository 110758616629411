import { Box, Button, LinearProgress, Slider, Typography } from "@mui/material";
import styled from "@emotion/styled";
import classes from "./PersonalEmptyResult.module.css"
import LockIcon from "./../../assets/Svg/unlockButtonEmpty.svg"
import { imageURLRender } from "../../utils";
import Coin from "../../assets/Svg/Coin.svg";
import DiamondCoin from "../../assets/Svg/DiamondCoin.svg";
import Result_Wellbeing from "../../assets/Svg/Result_Wellbeing.svg"
import XP from "../../assets/Svg/XP.svg";
import LinearProgressWithLabel from "../../components/ProgressBar/ProgressBar";
import { TestCategoryCard } from "../../components/TestCategoryCard/TestCategoryCard";
import ResultCategoryCard from "../../components/ResultCategoryCard/ResultCategoryCard";
import { SELECTLAYOUTBTN } from "../../Constant/Invite";
import { useNavigate } from "react-router-dom";
import Agape from "../../assets/Svg/Agape.svg";
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// const LightTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: 'rgba(0, 0, 0, 0.87)',
//         boxShadow: `0px 0px 44px rgba(25, 13, 26, 0.05)`,
//         fontSize: 11,
//         borderRadius: '12px',
//         padding: '16px',
//         gap: '12px'
//     },
//     [`& .${tooltipClasses.tooltipArrow}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: 'rgba(0, 0, 0, 0.87)',
//         boxShadow: theme.shadows[1],
//         fontSize: 11,
//         border: '1px solid #F3F3F3'
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//         color: theme.palette.common.white,
//         '&::before': {
//             border: '1px solid #F3F3F3'

//         }
//     },
// }));
const TestQuestionSlider = styled(Slider)`
    // background: yellow;
    padding: 0px !important;
    & .MuiSlider-rail {
      background: linear-gradient(
        90deg,
        #d7e0fb 0%,
        #dcfaf0 20.28%,
        #e6f8de 46.45%,
        #fff8bd 69.19%,
        #fce9a5 100%
      );
      height: 12px;
    }

    & .MuiSlider-track {
      background: linear-gradient(
        90deg,
        #d7e0fb 0%,
        #dcfaf0 20.28%,
        #e6f8de 46.45%,
        #fff8bd 69.19%,
        #fce9a5 100%
      );
      height: 12px;
      border: none;
    }

    & .MuiSlider-mark {
      // left: 0% !important;

      // width: unset;
      // height: unset;
      // background: none;

      // border-radius: 50%;

      // background: #9747FF;

      //   background: none;
      width: 4px;
      height: 12px;
      left: 110px;
      top: 0px;
      background: #ffffff;
    }

    & .MuiSlider-markActive {
      // left: 0% !important;
      background: none;
      // background-image: url("../../assets/Svg/SliderMark.svg");
      & :nth-child(1) {
        background: ;
      }
    }

    & .MuiSlider-thumbColorPrimary {
      box-shadow: none;
      background: #e5d5fc;
      width: 16px;
      height: 16px;
    }
  `;
const PersonalEmptyResult = ({
    oopsText = 'Oops, you don’t have results yet...',
    oopsSubText = 'Unlock results by taking tests',
    buttonText = 'Go to Tests',
    redirectUrl = "/testcategory"
}) => {
    const navigate = useNavigate()
    return <div className={classes.emptybox}>
        <div className={classes.emptyContentBox}>
            <div className={classes.graphicalBox}>
                <div className={classes.illubox}>
                    <div className={classes.testCard}>
                        <div className={classes.labelImageBox}>
                            <div className={classes.imageBar}>
                                <div className={classes.imageClss}>
                                    <img src={Result_Wellbeing} alt="image" />
                                </div>
                                <div className={classes.testbar}>
                                    <div className={classes.tags}>
                                        <div className={classes.tagcoin}>
                                            <img src={Coin} alt="" />
                                            <Typography variant="body3" color="#9747FF">
                                                {"20"}
                                            </Typography>
                                        </div>
                                        <div className={classes.tagdimond}>
                                            <img src={DiamondCoin} alt="" />
                                            <Typography variant="body3" color="#9747FF">
                                                {"3"}
                                            </Typography>
                                        </div>
                                        <div className={classes.tagdimond}>
                                            <img src={XP} alt="" />
                                            <Typography variant="body3" color="#9747FF">
                                                {"23"}
                                            </Typography>
                                        </div>
                                        <div className={classes.tagtime}>
                                            <Typography variant="body3" color="#9747FF" sx={{ whiteSpace: 'nowrap' }}>
                                                {"15 min"}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.testText}>
                                <Typography sx={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                    color: '#190D1A',
                                }}>
                                    Well-being
                                </Typography>
                                <Typography sx={{
                                    fontSize: '10.57px',
                                    fontWeight: 400,
                                    color: '#434243',
                                }}>
                                    Work on your well-being to decrease sleeping problems and enhance productivity
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.cardProgressBar}>
                            <Box className={classes.progressBarWrapper}>
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress
                                        className={classes.progress}
                                        variant="determinate"
                                        value={1}
                                    />
                                </Box>
                                {(
                                    <Box>
                                        <Typography variant="body3" color="#190D1A">{`${Math.round(
                                            0
                                        )}%`}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </div>
                        <div className={classes.takeresult}>
                            <div className={classes.numberbox}>
                                <Typography sx={{
                                    fontFamily: 'Coffee Mood',
                                    fontSize: '11.9223px',
                                    fontWeight: 600,
                                    color: '#190D1A',
                                    textAlign: 'center'
                                }}>
                                    1
                                </Typography>
                            </div>

                            <Typography sx={{
                                fontSize: '11.9223px',
                                fontWeight: 600,
                                color: '#190D1A',
                            }}>
                                Take a test
                            </Typography>
                        </div>
                    </div>
                    <img src={LockIcon} className={classes.lockIcon} alt="lock-icon" />

                    <div className={classes.resultCard}>
                        {/* <ResultCategoryCard
                            category={"Agape"}
                            preDescription={["This score indicates how selfless, equanimous, and altruistic your love is as opposed to egoistic, conditional, and opportunistic"]}
                            percentage={"70"}
                            unlock={true}
                            selectLayoutBtn={SELECTLAYOUTBTN.GRID}
                            score={[70]}
                            imageUrl={"/public/images/"}
                        ></ResultCategoryCard> */}
                        <div className={classes.maincontainerWrapper}>
                            <div className={classes.mainWrapper}>
                                <div className={classes.headerWrapper}>
                                    <div className={classes.headerTitleWrapper}>
                                        <div>
                                            <img
                                                src={
                                                    Agape
                                                }
                                                alt=""
                                                style={{
                                                    height: '25px',
                                                    width: '25px',
                                                }}
                                            />
                                        </div>
                                        <Typography variant="caption" sx={{ fontSize: '14.5194px', color: "#190D1A", fontWeight: 500 }}>{"Agape"}</Typography>
                                    </div>
                                    <div className={classes.contentWrapper}>
                                        <Typography sx={{ color: "#434243", fontSize: "10.5737px", fontWeight: 400 }} /* className="text-3lines" */>
                                            {/* {props.preDescription} */}
                                            {"This score indicates how selfless, equanimous, and altruistic your love is as opposed to egoistic, conditional, and opportunistic"}
                                        </Typography>
                                    </div>
                                </div>
                                {/* slider High low */}
                                <div className={classes.procesingWrapper}>

                                    <TestQuestionSlider
                                        defaultValue={0}
                                        // getAriaValueText={valuetext}
                                        valueLabelDisplay="off"
                                        step={50}
                                        marks
                                        min={0}
                                        max={100}
                                        value={70}
                                        // components={{ Input: SilderMarkComponent }}
                                        onChange={(e) => {
                                            // console.log("value", e.target.value);
                                        }}
                                    />
                                    <div className={classes.ScoreHighLowWrapper}>
                                        <div>
                                            <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                                                Low
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography sx={{ color: "#434243", fontSize: "10px" }}>
                                                High
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.unlockResults}>
                            <div className={classes.numberbox}>
                                <Typography sx={{
                                    fontFamily: 'Coffee Mood',
                                    fontSize: '11.9223px',
                                    fontWeight: 600,
                                    color: '#190D1A',
                                    textAlign: 'center'
                                }}>
                                    2
                                </Typography>
                            </div>

                            <Typography sx={{
                                fontSize: '11.9223px',
                                fontWeight: 600,
                                color: '#190D1A',
                            }}>
                                Unlock results
                            </Typography>
                        </div>
                    </div>


                </div>
            </div>
            <div className={classes.emptyTextBox}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#190D1A',
                    textAlign: 'center'
                }}>
                    {oopsText}
                </Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#434243',
                    textAlign: 'center'
                }}>
                    {oopsSubText}
                </Typography>
            </div>
        </div>
        <div className={classes.emptyActionButton}>
            <Button variant="contained" sx={{
                background: '#9747FF',
                borderRadius: '100px',
                fontSize: '14px !important',
                padding: '12px 16px',
                height: '38px'
            }} onClick={() => {
                navigate(redirectUrl);
            }}>{buttonText}</Button>
            {/* <LightTooltip title={
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#190D1A',
                }}>
                    Before adding an action, you would need to set a goal in Goals. As you start adding actions to goals and schedule them, they will appear on your home page
                </Typography>
            } arrow placement="top"
            >
                <InfoOutlinedIcon sx={{
                    color: '#434243',
                    height: '16px'
                }} />
            </LightTooltip> */}
        </div>
    </div>
}

export default PersonalEmptyResult