import React from "react";
import classes from "./OnbordingLayout.module.css";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";
// import { ReactComponent as Login1 } from "./../../assets/Svg/login1.svg"
import Login1 from "./../../assets/Svg/login11.svg"
import Login11 from "./../../assets/Svg/login111.svg"
import Login2 from "./../../assets/Svg/login2.svg"
import Login3 from "./../../assets/Svg/login3.svg"
import Login4 from "./../../assets/Svg/login4.svg"
import Login5 from "./../../assets/Svg/login5.svg"

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./cars.css";
import Text from "../../components/Text/Text";

const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  // display: "block",
  // paddingBlock: "2rem",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  minHeight: "100vh",
}));

const LoginFRootStyle = styled("div")(({ theme }) => ({
  width: "100%",
  // display: "block",
  // paddingBlock: "2rem",
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  minHeight: "100vh",
  padding: '20px'
}));

const CardContent = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "6.3rem 7.4rem",
  gap: '16px',
  [theme.breakpoints.up("xl")]: {
    padding: "6.3rem 7.4rem",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "6.3rem 7.4rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "6.3rem 7.4rem",
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    with: "100%",
    padding: "0.75rem",
  },
}));

const LoginCardContent = styled("div")(({ theme }) => ({
  width: "100%",
  // minHeight: "calc(100vh - 4rem)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "0",
  gap: '16px',
  [theme.breakpoints.up("xl")]: {
    padding: "0",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "0",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    with: "100%",
    padding: "0",
  },
}));

const MainCard = styled(Box)(({ theme }) => ({
  background: "#fff",
  width: "692px",
  height: '700px',
  // minHeight: "calc(100vh - 4rem)",
  position: "relative",
  [theme.breakpoints.up("xl")]: {
    width: "692px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "692px",
  },
  [theme.breakpoints.down("md")]: {
    width: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 4rem)",
    width: "100%",
  },
}));
const LoginMainCard = styled(Box)(({ theme }) => ({
  background: "#fff",
  width: "100%",
  height: '100%',
  // minHeight: "calc(100vh - 4rem)",
  position: "relative",
  // [theme.breakpoints.up("xl")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "692px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "600px",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   height: "calc(100vh - 4rem)",
  //   width: "100%",
  // },
}));

const OnbordingLayout = () => {
  console.log("window.location", window.location)
  return (
    <>
      {(window.location.pathname === "/" || window.location.pathname === "/signup") ? (
        <LoginFRootStyle className={classes.main_wrapper}>

          <Box
            sx={{
              // maxWidth: 622,
              width: '100%',
              height: '100%',
              marginInline: "auto",
              borderRadius: '38px',
              background: '#FFF !important',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <LoginMainCard sx={{
              // minWidth: 250,
              marginInline: "auto",
              borderRadius: '38px',
              // border: '24px solid #FFF',
              // background: 'linear-gradient(135deg, #F1EDFF 0%, #D5CAFB 100%)',
              // boxShadow: '-24px -24px 100px 0px rgba(25, 13, 26, 0.05) inset',
            }}>

              <LoginCardContent>
                <Caras />
              </LoginCardContent>
            </LoginMainCard>

          </Box>

          <LoginMainCard
            sx={{
              minWidth: 250,
              borderRadius: "40px",
              marginInline: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <CardContent style={{
              padding: window.florishApp && "6.3rem 2.0rem"
            }}>
              <Outlet />
            </CardContent>
          </LoginMainCard>
        </LoginFRootStyle >
      ) : (
        <RootStyle className={classes.main_wrapper}>
          <MainCard
            sx={{
              minWidth: 250,
              borderRadius: "40px",
              marginInline: "auto",
            }}
          >

            <CardContent>
              <Outlet />
            </CardContent>
          </MainCard>
        </RootStyle>
      )}

    </>
  );
};

export default OnbordingLayout;


const Caras = () => {


  return <Carousel infiniteLoop showThumbs={false} showArrows={false} showStatus={false} autoPlay>
    <div style={{
      height: '100%'
    }}>
      <img src={window.florishApp ? Login11:Login1} alt="login-1" />
      {/* <Login1 /> */}
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src={Login2} alt="login-1" />
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src={Login3} alt="login-1" />
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src={Login4} alt="login-1" />
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src={Login5} alt="login-1" />
    </div>
  </Carousel>
}
