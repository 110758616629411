import { useDispatch, useSelector } from "react-redux"
import AlertModal from "../../components/AlertModal/AlertModal"
import { setIsAskOtherModalOpenTest, setOpenConfirmatinModal, setShowConformationModal } from "../../Store/Reducers/testCategories"

const ConfirmationModalClose = ({openConfirmationModal, closeConformation, setIsAskOtherModalOpenSocial }) => {
    const { isAskOtherModalOpen, } = useSelector(state => state.testCategories)
    const dispatch = useDispatch()
    const handleClose = () => {
        closeConformation(false)
        dispatch(setShowConformationModal(true))
    }
    const setIsAskOtherModalOpen = (visi) => {
        dispatch(setIsAskOtherModalOpenTest(visi))
    }
    return (
        <AlertModal
            open={openConfirmationModal}
            handleClose={handleClose}
            setIsAskOtherModalOpenSocial={setIsAskOtherModalOpenSocial}
            isAskOtherModalOpen={isAskOtherModalOpen}
            setIsAskOtherModalOpen={setIsAskOtherModalOpen}
            fromMainPage={true}
        />
    )
}

export default ConfirmationModalClose