import { Box } from "@mui/material"
import QuoteIcon from "./../../assets/Svg/quoteIcon.svg"
import classes from "./quote-share.module.css"
const QuoteDescription = ({ description = `` }) => {
    console.log("description", description);
    return (
        <Box className={classes.quotedescription} >
            {/* <img src={QuoteIcon} alt="" /> */}
            <span className={classes.quoteDescriptionDataContentText} >
                {description}
            </span>
        </Box>
    )
}

export default QuoteDescription