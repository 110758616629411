import { useSelector } from "react-redux"
import classes from "./plans.module.css"
import { InputField } from "./plans.styles";
import { useState } from "react";
import { InputAdornment } from "@mui/material";
import {ReactComponent as Search} from "../../assets/Svg/Search.svg";
import PlanTabs from "./Tabs";
import PlanTable from "./PlanTable";
import { useParams } from "react-router-dom";
const PlanData = () => {
    const { userData } = useSelector((state) => state.userProfile);
    const [searchValue, setSearchValue] = useState("")
    const [disableButton, setDisableButton] = useState(false)
    const SearchCategoryList = (e) => {
        setSearchValue(e.target.value);
      
    };
    return <div className={classes.planBody}>
        <div className={classes.planControls}>
            <InputField
                size="small"
                fullWidth
                type="text"
                autoFocus={searchValue !== ""}
                value={searchValue}
                onChange={(e) => SearchCategoryList(e)}
                placeholder="Search for plan"
                id="outlined-start-adornment"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search stroke="red" className={classes.searchSVG}/>
                        </InputAdornment>
                    ),
                }}
            />
            <PlanTabs setDisableButton={setDisableButton} disableButton={disableButton}/>
        </div>
        <div className={classes.plans}>
            <PlanTable setDisableButton={setDisableButton}/>
        </div>
    </div>
}

export default PlanData