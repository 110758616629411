import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useEffect, useState } from "react";
import { imageURLRender } from "../../utils";
import FriendsResultBlank from "../../Common/FriendResultBlank";
export const FriendChart = ({ othresArray, others, data, width = 400 }) => {
  const [options, setoptions] = useState({});
  useEffect(() => {
    setoptions({
      chart: {
        type: "column",
        className: "personalChart",
        width: width,
        scrollablePlotArea: othresArray?.length === 0
            ? {
                minWidth: 502,
                scrollPositionX: 0,
              }
            : {
                minWidth: width,
                scrollPositionX: 1,
              },
      },
      title: {
        text: "",
      },
      xAxis:
      othresArray?.length === 0
          ? {
              type: "category",
              labels: {
                overflow: "justify",
              },
              max: null,
            }
          : othresArray?.length <= 6
          ? {
              type: "category",
              labels: {
                overflow: "justify",
              },
            }
          : {
              type: "category",
              labels: {
                overflow: "justify",
              },
              min: 0,
              max: 6,
            },
      yAxis: {
        // tickWidth: 1,
        min: 0, // Lowest value to show on the yAxis
        max: 100,
        tickPixelInterval: 200,
        title: {
          text: "",
        },
        barPercentage: 0.5,
        barThickness: 0,
      },
      tooltip: {
        enabled: false,
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            useHTML: true,
            enabled: true,

            format:
            (!others || !others.length || !others[0]?.imageUrl[0] || others[0]?.imageUrl[0] === "/public/images/")
            ? '<div style="background: #F7F2FE;border-radius: 50%; width: 28px; height: 28px;display: flex;justify-content: center;align-items: center;"><div style="color:#9747FF;font-size:8px">' +
              "{point.userName}" +
              "</div></div>"
            : '<div style="background: #F7F2FE;border-radius: 50%; width: 28px; height: 28px;display: flex;justify-content: center;align-items: center;"><div style="color:#9747FF;font-size:8px">' +
              '<img style= "border-radius: 50%; width: 28px; height: 28px;" src=' +
              `${imageURLRender(others[0]?.imageUrl[0])}` +
              "> " +
              "</div></div>",
          },
        },
      },
      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
      },
      accessibility: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "Personal",
          data: othresArray,
          colorByPoint: true,
        },
      ],
    });
  }, [othresArray]);
  return othresArray && othresArray.length ? <HighchartsReact highcharts={Highcharts} options={options} /> : <FriendsResultBlank data={data}/>;
};
