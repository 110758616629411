import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import classes from "./sixdigit.module.css";
import BoxError from "../../assets/Svg/BoxError.svg";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
// import { response } from "express";
import { setNewPasscode } from "../../services/auth";

const SixDigitCode = ({ setIsCode }) => {
  const navigate = useNavigate();
  const [codeError, setCodeError] = useState(true);
  const oldCode = "123456";
  const [passcode, setPasscode] = useState(new Array(6).fill(""));
  const [activeIndex, setActiveIndex] = useState(0);
  const inputRef = useRef(null);

  const handleChange = (e, index) => {
    const { value } = e.target;
    // console.log("value  ===", value);
    const newOtp = [...passcode];
    newOtp[index] = value.substring(value.length - 1);
    // console.log("value print", newOtp);
    setPasscode(newOtp);

    if (!value) {
      setActiveIndex(index - 1);
    } else {
      setActiveIndex(index + 1);
    }
    console.log("passcode =>", passcode);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeIndex]);

  const handleClick = () => {
    console.log("passcode", passcode);
    const userGetCode = passcode.join(",").replace(/,/g, "");
    console.log("oldCode", oldCode);
    console.log("userGetCode", userGetCode);
      console.log("Data submited");
      let details = {
        passcode: userGetCode,
        uid: localStorage.getItem("userDetail"),
      };
      setNewPasscode(details)
        .then((res) => {
          console.log("response update =>", res);
          setIsCode(true);
        })
        .catch((err) => {
          console.log("err = ", err);
          setCodeError(false);

        })
        .finally(() => {
          // setCodeError(true);
        });

      console.log("code Error :", codeError);
   
  };

  return (
    <>
      <Box className={classes.wrapper}>{window.florishApp ? "FlourishLabs" : "Scientific Self-Discovery"}</Box>
      <Grid className={classes.code_title}>
        To get access to the app, enter your code or join waitlist
      </Grid>
      <Grid className={classes.email_title}>
        Enter membership ID number attached sent to your email
      </Grid>
      <Grid className={classes.inputWrapper}>
        <Grid className={classes.se_inputField}>
          {passcode?.map((_, index) => {
            return (
              <React.Fragment key={index}>
                <input
                  className={clsx(
                    codeError === false ? classes.errorColor : classes.box
                  )}
                  ref={index === activeIndex ? inputRef : null}
                  type="text"
                  value={passcode[index]}
                  onChange={(e) => handleChange(e, index)}
                />
              </React.Fragment>
            );
          })}
        </Grid>
        {codeError === false && (
          <span className={classes.setError}>
            <img src={BoxError} /> Oops, this is not the code associated with
            your email
          </span>
        )}
      </Grid>
      <Grid>
        <Button className={classes.set_btn} onClick={handleClick}>
          Confirm
        </Button>
      </Grid>
      <Typography variant="p" className={classes.set_text}>
        Don’t have a code? <span onClick={() => navigate('/join-waitlist')}> Join waitlist</span>
      </Typography>
    </>
  );
};

export default SixDigitCode;
